// @flow
import React from 'react';
import TextField, { Input } from '../../../material-components/TextField';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import AutoFocusInput from '../../AutoFocusInput';
import { withTranslation } from 'react-i18next';

type Props = {
  username: string | null,
  password: string | null,
  onNext: Function,
  onUsernameChange: Function,
  onPasswordChange: Function,
  t: Function,
};

function DocCheckModalFragment({
  username,
  password,
  onNext,
  onUsernameChange,
  onPasswordChange,
  t,
}: Props) {
  return (
    <React.Fragment>
      <form
        style={{ position: 'relative' }}
        onSubmit={(e) => e.preventDefault()}
      >
        <TextField dense outlined style={{ width: '100%' }}>
          <AutoFocusInput
            id="email"
            name="email"
            type="text"
            style={{ textAlign: 'center' }}
            placeholder={`${t('LOGIN_DOCCHECK_USERNAME_PLACEHOLDER')}`}
            value={username}
            onChange={(e) => onUsernameChange(e.target.value)}
          />
        </TextField>
        <TextField dense outlined style={{ width: '100%', marginTop: '10px' }}>
          <Input
            id="password"
            name="password"
            type="password"
            style={{ textAlign: 'center' }}
            placeholder={`${t('LOGIN_DOCCHECK_PASSWORD_PLACEHOLDER')}`}
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
          />
        </TextField>
      </form>
      <Box mt={2}>
        <Button
          id="sign-in-doccheck"
          data-keystroke
          outlined
          style={{ width: '100%' }}
          onClick={onNext}
        >{`${t('MODAL_SIGN_IN')}`}</Button>
      </Box>
      <Box mt={2} textAlign="center">
        <a
          href="https://www.doccheck.com/reset-password-request"
          target="blank"
        >{`${t('MODAL_PASSWORD_FORGOTTEN')}`}</a>
      </Box>
      <Box mt={2} textAlign="center">
        <span>DocCheck</span>{' '}
        <a href="http://info.doccheck.com/com/privacy/" target="blank">{`${t(
          'COMMON_PRIVACY'
        )}`}</a>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(DocCheckModalFragment);
