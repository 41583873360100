import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesLongCopyModel extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata(['question', 'url', 'text']);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesLongCopyModel.prototype.question = '';
XirclesLongCopyModel.prototype.url = '';
XirclesLongCopyModel.prototype.text = '';

XirclesLongCopyModel.MIMEType = 'application/xircles.long-copy+json';
XirclesLongCopyModel.LabelSingular = 'Long Copy';
XirclesLongCopyModel.messageRenderer = 'xircles-long-copy-message-view';

Root.initClass.apply(XirclesLongCopyModel, [
  XirclesLongCopyModel,
  'XirclesLongCopyModel',
]);
Client.registerMessageTypeModelClass(
  XirclesLongCopyModel,
  'XirclesLongCopyModel'
);

export default XirclesLongCopyModel;
