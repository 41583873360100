var requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame;

var easeInOutQuad = function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

var animatedScrollTo = function animatedScrollTo(
  element,
  to,
  duration,
  callback
) {
  var start = element.scrollTop;
  var change = to - start;
  var animationStart = Date.now();
  var animating = true;
  var lastpos = null;

  if (element.scrollTop === to) return callback();

  var animateScroll = function animateScroll() {
    if (!animating) {
      return;
    }
    requestAnimFrame(animateScroll);
    var now = Date.now();
    var val = Math.floor(
      easeInOutQuad(now - animationStart, start, change, duration)
    );
    if (lastpos) {
      if (lastpos === element.scrollTop) {
        element.scrollTop = val;
        lastpos = element.scrollTop;
      } else {
        animating = false;
      }
    } else {
      element.scrollTop = val;
      lastpos = element.scrollTop;
    }
    if (now > animationStart + duration) {
      element.scrollTop = to;
      animating = false;
      if (callback) {
        callback();
      }
    }
  };
  requestAnimFrame(animateScroll);
  var cancel = function cancel() {
    return (animating = false);
  };

  return cancel;
};

export default animatedScrollTo;
