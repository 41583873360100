import moment from 'moment';

export default (time) => {
  const diffMinutes = moment
    .duration(moment(new Date()).diff(moment(time)))
    .asMinutes();

  if (diffMinutes <= 50) {
    return `${Math.round(diffMinutes)} ${
      Math.round(diffMinutes) === 1 ? 'minute' : 'minutes'
    } ago`;
  } else if (diffMinutes > 50 && diffMinutes <= 1439) {
    return `${Math.round(diffMinutes / 60)} ${
      Math.round(diffMinutes / 60) === 1 ? 'hour' : 'hours'
    } ago`;
  } else {
    return `${Math.floor(diffMinutes / (60 * 24))} ${
      Math.round(diffMinutes / (60 * 24)) === 1 ? 'day' : 'days'
    } ago`;
  }
};
