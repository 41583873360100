/* global grecaptcha */
import config from '../config.json';
import { verifyCaptcha, wrapCatch } from '../utils/api';

export const executeRecaptcha = (action) => {
  if (window.grecaptcha) {
    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(config.ReCaptchaKey, { action });
      await wrapCatch(verifyCaptcha({ token }));
    });
  } else {
    setTimeout(() => executeRecaptcha(action), 1000);
  }
};
