/**
 * GTM - Trigger
 *
 * name: Select on Feedback flow
 * fires on: gtm.layer-choice-select with all custom events
 */

import Layer from '@layerhq/web-xdk';
import textHandlers from '@layerhq/web-xdk/ui/handlers/text/text-handlers';
import {
  CHOICE_ACTIONS,
  AUTOSTART_ACTIONS,
  CHOICE_ACTION_EVENTS,
} from '../const/choice-actions';
import { layerClient } from '../get-layer';
import settings from '@layerhq/web-xdk/settings';
import _get from 'lodash/get';
import i18next from 'i18next';

const ChoiceMessageViewMixin = {
  methods: {
    _isFeedbackFlow: function () {
      const disclaimerMessage = document.querySelector(
        'layer-message-item-received.layer-message-item-xircles-disclaimer-message-view:not(.layer-list-item-last)'
      );
      return !!disclaimerMessage;
    },
    sendSelection: function () {
      // TODO: unify sendSelection and _sendChoice
      const view = this;
      const listItem = view.closest('.layer-list-item');
      const conversationId = view.messageViewer.message.conversationId;
      let choiceMessageID = view.closest('layer-message-item-received');
      if (!choiceMessageID) {
        choiceMessageID = view.closest('layer-message-item-sent');
      }
      choiceMessageID = choiceMessageID
        .getAttribute('layer-item-id')
        .split('_')[1];
      const model = view.properties.model;
      const ids = model.selectedAnswer.split(',');
      const result = model.choices.reduce(
        function (result, next) {
          if (ids.indexOf(next.id) > -1) {
            if (result.id) {
              result.id = `${result.id}; ${next.id}`;
              result.text = `${result.text}; ${next.text}`;
            } else {
              result.id = next.id;
              result.text = next.text;
            }
          }
          return result;
        },
        { text: '', id: '' }
      );

      if (result.id) {
        if (model.customData) {
          result.customData = { action: model.customData.action };
        }
        const metadata = _get(this.mainComponent, 'conversation.metadata');
        result.conversationId = conversationId;
        result.name = 'xircles-choice-send-track';
        result.question = metadata.conversationName;
        result.location = window.location.href;
        result.elementId = '-';
        result.suggestion = '-';
        result.drug = '-';
        result.customData.choiceMessageID = choiceMessageID;
        result.customData.isMultiSelect = model.allowMultiselect;
        result.customData.isFeedbackFlow = this._isFeedbackFlow();
        listItem.className = `${listItem.className} xircles-hiding`;
        setTimeout(() => {
          this._removeBorder();
          view.trigger('xircles-choice-send', result);
          view.trigger('xircles-choice-send-track', result);
          listItem.classList.add('xircles-hide');
        }, 100);
      }
    },
    _sendChoice(result) {
      if (!this.destroyed) {
        const view = this;
        // send ResponseMessage
        view.model.enabledFor = Layer.client.user.id;
        view.onChoiceSelect({ id: result.id });
        const rootPart = view.model.message.getPartsMatchingAttribute({
          role: 'root',
        })[0];
        const rootModel = settings.client.getMessageTypeModel(rootPart.id);
        view.trigger(view.model.responseName, {
          model: view.model,
          data: view.model,
          rootModel,
        });

        // send ConfirmChoiceMessage
        const listItem = view.closest('.layer-list-item');
        const conversationId = _get(
          view,
          'messageViewer.message.conversationId'
        );
        let choiceMessageID = view.closest('layer-message-item-received');
        if (!choiceMessageID) {
          choiceMessageID = view.closest('layer-message-item-sent');
        }
        choiceMessageID = choiceMessageID
          .getAttribute('layer-item-id')
          .split('_')[1];
        const model = view.properties.model;
        if (model.customData) {
          result.customData = { action: model.customData.action };
        }
        result.conversationId = conversationId;
        result.customData.choiceMessageID = choiceMessageID;
        result.customData.isMultiSelect = model.allowMultiselect;
        result.customData.isFeedbackFlow = this._isFeedbackFlow();
        listItem.className = `${listItem.className} xircles-hiding`;
        setTimeout(() => {
          this._removeBorder();
          view.trigger('xircles-choice-send', result);
          view.trigger('xircles-choice-send-track', result);
          // hide choices list initially. Will be deleted on server too.
          listItem.classList.add('xircles-hide');
        }, 100);
      }
    },
    _removeBorder() {
      const [checboxes] = document.getElementsByClassName(
        'layer-message-item-xircles-checkbox-message-view'
      );
      if (!checboxes) {
        const [itemTop] = document.getElementsByClassName(
          'layer-message-item__border-top'
        );
        itemTop && itemTop.classList.remove('layer-message-item__border-top');
        const [itemBottom] = document.getElementsByClassName(
          'layer-message-item__border-bottom'
        );
        itemBottom &&
          itemBottom.classList.remove('layer-message-item__border-bottom');
        const [itemSides] = document.getElementsByClassName(
          'layer-message-item__border-sides'
        );
        itemSides &&
          itemSides.classList.remove('layer-message-item__border-sides');
      }
    },
    _searchAutoStartActions(listItem, evtName, choiceId) {
      const searchAutoStartAction = AUTOSTART_ACTIONS.find(
        (action) => action.actionId === choiceId
      );

      if (searchAutoStartAction) {
        // if only one autostart action id was found, whole choices list will be hidden
        listItem.classList.add('xircles-hide');

        const { disableMessage } = searchAutoStartAction;

        if (
          layerClient.user &&
          layerClient.user.metadata &&
          layerClient.user.metadata.user_status !== 'confirmed'
        ) {
          console.log(layerClient.user.metadata.user_status);
          this._triggerAutoStartActions(evtName, disableMessage);
        }
      }
    },
    _triggerAutoStartActions(evtName) {
      const lastMsgId = this.mainComponent.conversation.lastMessage.id;
      const id = this.properties.messageViewer.item.id;
      const isLastMsg = lastMsgId.endsWith(id);
      if (isLastMsg) {
        // and autostart triggered if it last message
        window.dispatchEvent(new Event(evtName));
      }
    },
    _loginCanceled() {
      this._sendChoice({
        text: `${i18next.t('LOGIN_CANCELED')}`,
        id: `${CHOICE_ACTIONS.LOGIN}_${CHOICE_ACTIONS.CANCEL}`,
      });
    },
    _loginSucceded() {
      this._sendChoice({
        text: `${i18next.t('LOGIN_SUCCEEDED')}`,
        id: CHOICE_ACTIONS.LOGIN,
      });
    },
    _docCheckCanceled() {
      this._sendChoice({
        text: `${i18next.t('REGISTER_DOCCHECK_LOGIN_CANCELED')}`,
        id: `${CHOICE_ACTIONS.DOCCHECK}_${CHOICE_ACTIONS.CANCEL}`,
      });
    },
    _docCheckSucceded() {
      this._sendChoice({
        text: `${i18next.t('REGISTER_DOCCHECK_LOGIN_SUCCEEDED')}`,
        id: CHOICE_ACTIONS.DOCCHECK,
      });
    },
    _confirmRegistrationCanceled() {
      this._sendChoice({
        text: `${i18next.t('REGISTER_CONFIRM_REGISTRATION_CANCELED')}`,
        id: `${CHOICE_ACTIONS.CONFIRM_REGISTER}_${CHOICE_ACTIONS.CANCEL}`,
      });
    },
    _confirmRegistrationSucceded() {
      this._sendChoice({
        text: `${i18next.t('REGISTER_CONFIRM_REGISTRATION_SUCCEEDED')}`,
        id: CHOICE_ACTIONS.CONFIRM_REGISTER,
      });
    },
    _getMessageId(choiceMessageId) {
      return choiceMessageId.substring(
        choiceMessageId.indexOf('message') + 'message'.length + 2,
        choiceMessageId.length
      );
    },
    _removePreviousChoiceMessage() {
      const [prevChoiceMessage, currentChoiceMessage] =
        document.getElementsByClassName(
          'layer-message-item-layer-choice-message-view'
        );

      if (prevChoiceMessage && currentChoiceMessage) {
        prevChoiceMessage.destroy();
      }
    },
    onCreate() {
      this._removePreviousChoiceMessage();
    },
    onAfterCreate: {
      mode: Layer.UI.registerComponent.MODES.OVERWRITE,
      value() {
        const ownerId = _get(this.mainComponent, 'conversation.metadata.owner');
        const isNotOwner =
          ownerId && layerClient.user && ownerId !== layerClient.user.id;

        const isReframeQuestion = this.model.choices.find(
          (choice) => choice.id === CHOICE_ACTIONS.REFRAME
        );
        const isMoreInfoRequired = this.model.choices.find(
          (choice) => choice.id === CHOICE_ACTIONS.NEED_MORE_INFO
        );
        if (
          (isReframeQuestion && this.model.choices.length === 1) ||
          (this.model.choices.includes(isReframeQuestion) &&
            this.model.choices.includes(isMoreInfoRequired) &&
            this.model.choices.length === 2)
        ) {
          document.dispatchEvent(
            new CustomEvent('tr-custom-event', {
              detail: {
                conversationId: this.messageViewer.message.conversationId,
                name: 'not-found-search-result',
              },
            })
          );
        }

        const view = this;
        const listItem = view.closest('.layer-list-item');
        this.destroyed = false;

        this.model.choices.forEach((choice) => {
          let evtName = 'layer-choice-select';
          if (CHOICE_ACTION_EVENTS[choice.id]) {
            evtName = CHOICE_ACTION_EVENTS[choice.id];
            switch (choice.id) {
              case CHOICE_ACTIONS.LOGIN:
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.login_cancel,
                  this._loginCanceled.bind(this)
                );
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.login_success,
                  this._loginSucceded.bind(this)
                );
                break;

              case CHOICE_ACTIONS.DOCCHECK:
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.doccheck_cancel,
                  this._docCheckCanceled.bind(this)
                );
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.doccheck_success,
                  this._docCheckSucceded.bind(this)
                );
                break;

              case CHOICE_ACTIONS.CONFIRM_REGISTER:
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.confirm_register_cancel,
                  this._confirmRegistrationCanceled.bind(this)
                );
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.confirm_register_success,
                  this._confirmRegistrationSucceded.bind(this)
                );
                break;

              default: {
                const r = new RegExp(`_${CHOICE_ACTIONS.CANCEL}$`);
                if (r.test(choice.id)) {
                  // hide _cancel choices. used only as response
                  evtName = 'hide';
                }
                break;
              }
            }

            this._searchAutoStartActions(listItem, evtName, choice.id);
          }
          let button = view.createElement('layer-action-button', {
            text: choice.text,
            tooltip: choice.tooltip,
            event: evtName,
            data: { id: choice.id },
            parentNode: view.nodes.choices,
          });
          if (isNotOwner) {
            const buttonElmNoEvents = button.cloneNode(true);
            button.parentNode.replaceChild(buttonElmNoEvents, button);
            buttonElmNoEvents.firstChild.classList.add('xircles-disabled');
            buttonElmNoEvents.firstChild.disabled = 'disabled';
            button = buttonElmNoEvents;
          }
          if (evtName === 'hide') {
            button.classList.add('xircles-hide');
          }
        });
        this.nodes.label.innerHTML = textHandlers.processText(this.model.label);
        const conversationId = view.messageViewer.message.conversationId;
        const div = document.createElement('div');
        view.appendChild(div);
        // add send button
        view.nodes.sendButton = document.createElement('button');
        view.nodes.sendButton.classList.add('xircles-icons', 'icon-send');
        view.nodes.sendButton.innerHTML = `${i18next.t('COMMON_SEND_ANSWER')}`;
        div.appendChild(view.nodes.sendButton);

        // add back button
        if (view.properties.model.customData.showBack) {
          view.nodes.backButton = document.createElement('button');
          view.nodes.backButton.classList.add('xircles-icons', 'icon-up');
          view.nodes.backButton.innerHTML = 'Back';
          div.appendChild(view.nodes.backButton);
          view.nodes.backButton.addEventListener('click', () => {
            view.trigger('xircles-choice-send', {
              conversationId,
              text: 'back',
              id: 'back',
            });
            listItem.classList.add('xircles-hide');
          });
        }

        if (view.properties.model.customData.orientation === 'landscape') {
          view.nodes.choices.classList.add('xircles-choice-list-landscape');
        }

        if (
          view.properties.model.allowMultiselect ||
          view.properties.model.allowReselect
        ) {
          view.nodes.choices.childNodes.forEach((child) => {
            child.firstChild.classList &&
              child.firstChild.classList.add('xircles-icons', 'icon-circle');
          });
          view.nodes.sendButton.addEventListener(
            'click',
            view.sendSelection.bind(view)
          );
        } else {
          view.nodes.sendButton.classList.add('xircles-hide');
          // single choice on click
          view.addEventListener(
            'layer-choice-select',
            view.sendSelection.bind(view)
          );
        }
      },
    },
    onDestroy() {
      this.destroyed = true;
      window.removeEventListener(
        CHOICE_ACTION_EVENTS.login_cancel,
        this._loginCanceled.bind(this)
      );
      window.removeEventListener(
        CHOICE_ACTION_EVENTS.login_success,
        this._loginSucceded.bind(this)
      );

      window.removeEventListener(
        CHOICE_ACTION_EVENTS.doccheck_cancel,
        this._docCheckCanceled.bind(this)
      );
      window.removeEventListener(
        CHOICE_ACTION_EVENTS.doccheck_success,
        this._docCheckSucceded.bind(this)
      );

      window.removeEventListener(
        CHOICE_ACTION_EVENTS.confirm_register_cancel,
        this._confirmRegistrationCanceled.bind(this)
      );
      window.removeEventListener(
        CHOICE_ACTION_EVENTS.confirm_register_success,
        this._confirmRegistrationSucceded.bind(this)
      );
    },
  },
};

export default ChoiceMessageViewMixin;
