/* eslint-disable react/jsx-key */
// @flow

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';
import './styles.less';

import MenuContainer from '../../containers/MenuContainer';
import BrandFooter from '../BrandFooter';
import BrandHeader from '../BrandHeader';
import Navigation from '../Navigation';
import ProductCard from '../ProductCard';
import conversationManager from '../../hocs/conversationManager';
import { getDrugsCatalogue, wrapCatch } from '../../utils/api';
import { START_PAGE_MODES } from '../../const/start-page-modes';
import { removeRegisteredSymbol, slugify } from '../../utils/common';

type Props = {};

type State = {};

class DrugsCataloguePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      selectedFilter: '1',
      secondPagination: {
        currentPage: 0,
        pageNames: [],
      },
    };
  }

  t = this.props.t;
  filterChars = '1abcdefghijklmnopqrstuvwxyz'.split('');

  async componentDidMount() {
    window.scrollTo(0, 0);

    const startPageMode = _get(
      this.props.themeSettings,
      'startPageMode',
      START_PAGE_MODES.ALL_PRODUCTS
    );

    if (startPageMode === START_PAGE_MODES.MULTI_PRODUCTS) {
      return this.props.history.replace('/');
    }

    const { pagination: paginationParam, subpagination = 1 } =
      this.props.match.params;

    const {
      data: { drugs, pagination },
    } = await wrapCatch(
      getDrugsCatalogue(
        paginationParam || this.state.selectedFilter,
        subpagination - 1,
        true
      )
    );

    this.setState({
      products: drugs,
      selectedFilter: paginationParam || this.state.selectedFilter,
      secondPagination: {
        currentPage:
          subpagination && pagination && pagination.page_names
            ? subpagination - 1
            : 0,
        pageNames: (pagination && pagination.page_names) || [],
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.newConversationId !== prevProps.newConversationId) {
      this.props.history.push({
        pathname: `/questions/${this.props.newConversationId}`,
        state: {
          detail: {
            corrections: this.props.corrections,
            originalText: this.props.originalText,
            prevLocation: this.props.history.location.pathname,
          },
        },
      });
    }
  }

  handleFilterItemClick = async (e) => {
    const clickedFilter = e.target.innerHTML;

    if (clickedFilter.length === 1) {
      const {
        data: { drugs, pagination },
      } = await wrapCatch(getDrugsCatalogue(clickedFilter, null, true));

      this.setState(
        {
          selectedFilter: clickedFilter,
          products: drugs,
          secondPagination: {
            currentPage: 0,
            pageNames: (pagination && pagination.page_names) || [],
          },
        },
        () => {
          const secondPaginationParam =
            this.state.secondPagination.pageNames.length > 1 ? '/1' : '';

          this.props.history.replace(
            `/medikamente/katalog/${this.state.selectedFilter}${secondPaginationParam}`
          );
        }
      );
    }
  };

  handleProductCardClick = (brand_slug) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          pathname: `/medikamente/${brand_slug}`,
          name: 'select-drug',
          drug: brand_slug,
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          question: '-',
          text: '-',
          id: '-',
        },
      })
    );

    window.dispatchEvent(
      new CustomEvent('select-drug', { detail: { selectedProduct: null } })
    );
    this.props.history.push(`/medikamente/${brand_slug}`);
  };

  handleProductXircleCardClick = (brand_slug, { company_name }) => {
    this.props.history.push({
      pathname: `/${slugify(company_name)}/${slugify(brand_slug)}`,
      state: {
        detail: {
          isProductXircle: true,
        },
      },
    });
  };

  handleLogoClick = () => this.props.history.push('/');

  handlePostQuestion = (question, corrections, originalText) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          question,
          name: 'search-submit',
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          drug: '-',
          text: '-',
          id: '-',
        },
      })
    );
    this.props.createConversation(
      question,
      { goBackPath: this.props.history.location.pathname, stepBackNumber: -2 },
      corrections,
      originalText
    );
  };

  handleSecondPaginationChange = (pageIndex) => async () => {
    const {
      data: { drugs },
    } = await wrapCatch(
      getDrugsCatalogue(this.state.selectedFilter, pageIndex, true)
    );

    this.setState(
      {
        products: drugs,
        secondPagination: {
          ...this.state.secondPagination,
          currentPage: pageIndex,
        },
      },
      () => {
        this.props.history.replace(
          `/medikamente/katalog/${this.state.selectedFilter}/${pageIndex + 1}`
        );
      }
    );
  };

  render() {
    return (
      <div className="drugs-catalogue-page">
        <BrandHeader
          {...this.props}
          className="drugs-catalogue-page__header"
          onPostQuestion={this.handlePostQuestion}
          onLogoClick={this.handleLogoClick}
        >
          <h1>{this.t('COMMON_ALL_DRUGS_A_Z')}</h1>
        </BrandHeader>
        <div className="drugs-catalogue-page__content">
          <Navigation
            links={[
              {
                url: '/',
                title: this.t('COMMON_START_PAGE'),
              },
              {
                url: '/medikamente/katalog/1',
                title: this.t('COMMON_MEDICATIONS'),
                onClick: () =>
                  this.handleFilterItemClick({ target: { innerHTML: '1' } }),
              },
            ]}
          />
          <div
            className="drugs-catalogue__filter"
            onClick={this.handleFilterItemClick}
          >
            {this.filterChars.map((char, index) => (
              <a
                rel="canonical"
                key={`filter-char-${index}`}
                className={`drugs-catalogue__filter-item${
                  char === this.state.selectedFilter
                    ? ' drugs-catalogue__filter-item--selected'
                    : ''
                }`}
                href={`${window.location.origin}/medikamente/katalog/${char}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.replace(`/medikamente/katalog/${char}`);
                }}
              >
                {char}
              </a>
            ))}
          </div>
          {this.state.secondPagination.pageNames.length > 1 && (
            <div className="drugs-catalogue__second-pagination">
              {this.state.secondPagination.pageNames.map((pageName, index) => (
                <span
                  key={`/medikamente/katalog/${this.state.selectedFilter}/${
                    index + 1
                  }`}
                  onClick={this.handleSecondPaginationChange(index)}
                >
                  <a
                    rel="canonical"
                    className={`drugs-catalogue__second-pagination-item${
                      +this.state.secondPagination.currentPage === index
                        ? ' selected'
                        : ''
                    }`}
                    href={`${window.location.origin}/medikamente/katalog/${
                      this.state.selectedFilter
                    }/${index + 1}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    {pageName}
                  </a>
                </span>
              ))}
            </div>
          )}
          <div className="drugs-catalogue-page__products">
            {this.state.products.map((product, index) => (
              <Fragment key={`product-card-fragment-${index}`}>
                {product.px_info && (
                  <ProductCard
                    isPX
                    size="small"
                    id={product.brand_id}
                    pxInfo={product.px_info}
                    key={`${product.brand_id}-px-xircle-${index}`}
                    primaryColor={product.px_info.primary_color}
                    name={
                      <Fragment>
                        {removeRegisteredSymbol(product.px_info.brand_name)}
                        <sup>®</sup>
                        {' Xircle'}
                      </Fragment>
                    }
                    brand_slug={product.brand_slug}
                    compoundName={product.px_info.company_name}
                    onProductCardClick={this.handleProductXircleCardClick}
                    appUrl={this.props.appUrl}
                  />
                )}
                {((product.px_info &&
                  product.num_documents > product.px_info.num_documents) ||
                  !product.px_info) && (
                  <ProductCard
                    size="small"
                    id={product.brand_id}
                    key={product.brand_id}
                    name={product.brand_name}
                    brand_slug={product.brand_slug}
                    companiesNum={product.num_companies}
                    documentsNum={product.num_documents}
                    compoundName={product.molecule_names.join(', ')}
                    isManufactureVisible
                    onProductCardClick={this.handleProductCardClick}
                    appUrl={this.props.appUrl}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
        {_get(this.props.themeSettings, 'isMenuVisible', false) && (
          <MenuContainer />
        )}
        {_get(this.props.themeSettings, 'isFooterVisible', false) && (
          <BrandFooter />
        )}
      </div>
    );
  }
}

const DrugsCataloguePageWithConversationManager =
  conversationManager(DrugsCataloguePage);

export default withTranslation()(DrugsCataloguePageWithConversationManager);
