import React from 'react';
import DocumentPage from '../components/Document';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import { normalizePathname } from '../utils/common';
import logout from '../common/logout';
import config from '../config.json';

export default function DocumentPageContainer(props) {
  return (
    <LayerContext.Consumer>
      {({
        displayName,
        layerClient,
        isLoggedIn,
        userId,
        onLoginAnonymously,
      }) => (
        <CommonContext.Consumer>
          {({ onCurrentActionChange }) => (
            <DocumentPage
              {...props}
              userId={userId}
              isLoggedIn={isLoggedIn}
              layerClient={layerClient}
              displayName={displayName}
              onLogin={(e) => {
                e.preventDefault();
                const hash = props.history.location.hash || '';
                props.history.push(
                  normalizePathname(props.history.location.pathname) +
                    '/login' +
                    hash
                );
              }}
              onLogoClick={(e) => {
                e.preventDefault();
                props.history.push('/');
              }}
              onDisplayName={(e) => {
                e.preventDefault();
                const hash = props.history.location.hash || '';
                const redirectPath = config.UserManagementEnabled
                  ? '/my-account'
                  : '/logout';
                props.history.push(
                  normalizePathname(props.history.location.pathname) +
                    redirectPath +
                    hash
                );
              }}
              onToggleMenu={() => onCurrentActionChange('menu')}
              onLogout={() =>
                logout()
                  .then(() => onCurrentActionChange(null))
                  .then(onLoginAnonymously)
              }
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  );
}
