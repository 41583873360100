// @flow

import React, { Component } from 'react';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import './styles.less';

import AnswerPageContainer from '../../containers/AnswerPageContainer';
import ProductPageContainer from '../../containers/ProductPageContainer';
import ProductXirclePageContainer from '../../containers/ProductXirclePageContainer';
import LoadingSpinner from '../LoadingSpinner';

import { getDrugInfos } from '../../utils/api';
import {
  getPathnameWithoutModal,
  slugify,
  sortNumArray,
} from '../../utils/common';

type Props = {};

type State = {};

class ProductPageSwitch extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      isProductXircle: false,
    };
  }

  triggerAnswerPage = () =>
    this.setState({
      isLoading: false,
      isAnswerPage: true,
      isProductXircle: false,
    });

  triggerProductPage = async (brandSlug) => {
    this.setState({ isLoading: true });

    const response = await getDrugInfos(brandSlug, 1).catch(() =>
      this.props.history.push('/')
    );

    const documents = _get(response, 'data.documents', []);
    const molecules = (_get(response, 'data.molecules', []) || []).join(', ');
    const pxInfo = _get(response, 'data.px_info');
    const brandName = _get(response, 'data.brand');

    const isProductXircle = pxInfo
      ? _isEqual(
          sortNumArray(pxInfo.drug_intro.mmi_documents),
          sortNumArray(documents.map((doc) => doc.id))
        )
      : false;

    const isProductXircleState = _get(
      this.props.location,
      'state.detail.isProductXircle'
    );

    this.setState({
      isLoading: false,
      isAnswerPage: false,
      isProductXircle: isProductXircleState || isProductXircle,
      pxInfo,
      brandName,
      molecules,
    });
  };

  handlePageRedirect = () => {
    const { brands, history } = this.props;

    const pathname = getPathnameWithoutModal(history.location.pathname);

    const [, basePath, path] = pathname.split('/');

    const actions = {
      [basePath === 'questions']: () => this.triggerAnswerPage(),
      [basePath === 'medikamente']: () => this.triggerProductPage(path),
      [brands.findIndex((brand) => slugify(brand) === basePath) >= 0]: () =>
        this.triggerAnswerPage(),
      [brands.findIndex((brand) => slugify(brand) === path) >= 0]: () =>
        this.triggerProductPage(path),
    };

    if (actions[true]) {
      const nextAction = actions[true];
      nextAction();
    } else {
      history.push('/');
    }
  };

  componentDidMount() {
    window.addEventListener('product-page-trigger', this.handleProductPage);
    this.handlePageRedirect();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.handlePageRedirect();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('product-page-trigger', this.handleProductPage);
  }

  handleProductPage = () => {
    const pathname = getPathnameWithoutModal(this.props.location.pathname);
    const [path] = /[^/]+(?=\/$|$)/.exec(pathname);

    if (path) {
      this.triggerProductPage(path);
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    const {
      onConversationLoaded,
      onSearchInputChange,
      selectedProduct,
      themeSettings,
      prevLocation,
      documentId,
      appUrl,
      tags,
    } = this.props;

    const {
      brandName,
      isAnswerPage,
      isLoading,
      isProductXircle,
      molecules,
      pxInfo,
    } = this.state;

    return (
      <div className="product-page-switch">
        {isLoading && (
          <LoadingSpinner className="loading-spinner__product-page" />
        )}
        {!isLoading && !isProductXircle && !isAnswerPage && (
          <ProductPageContainer
            {...this.props}
            onSearchInputChange={onSearchInputChange}
            selectedProduct={selectedProduct}
            themeSettings={themeSettings}
            documentId={documentId}
            appUrl={appUrl}
            tags={tags}
          />
        )}
        {!isLoading && isProductXircle && (
          <ProductXirclePageContainer
            {...this.props}
            brandName={brandName}
            molecules={molecules}
            pxInfo={pxInfo}
          />
        )}
        {!isLoading && isAnswerPage && (
          <AnswerPageContainer
            {...this.props}
            onConversationLoaded={onConversationLoaded}
            themeSettings={themeSettings}
            prevLocation={prevLocation}
          />
        )}
      </div>
    );
  }
}

export default ProductPageSwitch;
