// @flow
import lineClamp from 'line-clamp';
import AlloyFinger from 'alloyfinger';
import getStatusClass from '../common/status/getStatusClass';
import translateInternalStatus from '../common/status/translateInternalStatus';
import { CONVERSATION_ACTIONS } from '../const/conversation-actions';
import themeSettings from '../polyfills';
import i18next from 'i18next';
import _get from 'lodash/get';

import Layer from '@layerhq/web-xdk';
import { isNotConfirmed, isOperator } from '../utils/user';
import lastActionTime from '../common/lastActionTime';
import translatePvStatus from '../common/status/translatePvStatus';
import translateOperatorInternalStatus from '../common/status/translateOperatorInternalStatus';
import xirclesStatuses from '../const/status/xircles-status';

import {
  isDeletedRequestsRoute,
  isFirefox,
  isIE,
  mouseHandler,
} from '../utils/common';
import { updateTicket, wrapCatch } from '../utils/api';
import config from '../config.json';

require('alloyfinger/transformjs/transform');

const {
  ANSWER_DELIVERED,
  ARCHIVED,
  CLOSED,
  DELETED,
  FORWARDED,
  FORWARD_REQUESTED,
  TAKEOVER,
} = xirclesStatuses;

const DATA_OPEN_CARD_TAG = 'data-card-open';
const DATA_SWIPE_CARD_TAG = 'data-card-swiped';
/* TODO: should be changed back to -212 value for three buttons on the Header Menu */
const OPEN_TRANSFROM_X_VALUE =
  config.RegistrationEnabled && config.FeatureConversationSharingEnabled
    ? -153
    : _get(themeSettings, 'isConversationCardSwipeable', false)
    ? 0
    : -88;

// const isOperatorUser = true
// console.log(Layer.client.user)

const ConversationItemMixin = {
  methods: {
    onCreate() {
      const xirclesActions = {
        deletedRequests: [
          {
            'layer-id': 'actions-delete',
            icon: 'delete',
            text: 'COMMON_DELETE_PERMANENTLY',
          },
          {
            'layer-id': 'actions-restore',
            className: 'icon-restore-request',
            text: 'COMMON_RESTORE',
          },
        ],
        default: [
          {
            'layer-id': 'actions-delete',
            icon: 'delete',
            className: 'action-delete-card-icon',
            text: 'COMMON_DELETE',
          },
        ],
      };

      if (
        config.RegistrationEnabled &&
        config.FeatureConversationSharingEnabled
      ) {
        xirclesActions.default.push({
          'layer-id': 'actions-share',
          icon: 'share',
          className: 'action-share-card-icon',
          text: 'COMMON_SHARE',
        });
      }

      const actions = isDeletedRequestsRoute(window.location.pathname)
        ? xirclesActions.deletedRequests
        : xirclesActions.default;

      const cnfrm = this.getElementsByClassName('xircles-confirm-text');
      if (cnfrm && cnfrm[0]) {
        cnfrm[0].innerText = i18next.t('COMMON_CONFIRM');
      }

      const [actionElements] = this.getElementsByClassName('xircles-actions');
      if (actionElements) {
        const [list] = actionElements.getElementsByTagName('ul');

        for (let i = 0; i < actions.length; i++) {
          const li = document.createElement('li');
          li.setAttribute('layer-id', actions[i]['layer-id']);

          const icon = document.createElement('i');
          icon.classList.add('material-icons', actions[i].className);

          if (actions[i].icon) {
            icon.innerHTML = actions[i].icon;
          }

          li.appendChild(icon);

          const text = document.createTextNode(i18next.t(actions[i].text));
          li.appendChild(text);
          list.appendChild(li);
        }
      }

      if (!isOperator(Layer.client.user)) {
        const item = document.createElement('div');
        item.classList.add('xircles-status');
        item.innerHTML = '...';

        this.nodes.title.parentNode.insertBefore(
          item,
          this.nodes.title.nextSibling
        );

        const getInnerNodeTransformValue = () => {
          return this.innerNode.style.transform
            ? Number(this.innerNode.style.transform.split('(')[1].split('p')[0])
            : 0;
        };

        const getInnerNodeLeftValue = () =>
          this.innerNode.style.left
            ? parseInt(this.innerNode.style.left.replace(/^\D+/g, ''), 10)
            : 0;

        const deleteConversation = () => {
          setTimeout(
            () => this.item.delete(Layer.Constants.DELETION_MODE.ALL),
            200
          );
        };

        const getConversationId = () => {
          const layerItemId = this.getAttribute('layer-item-id');
          const conversationId = layerItemId.replace('conversations_', '');
          return conversationId;
        };

        const shareConversation = () => {
          const conversationId = getConversationId();

          if (isNotConfirmed(Layer.client.user)) {
            window.dispatchEvent(
              new CustomEvent('redirect-share-modal', {
                detail: {
                  isRedirect: true,
                  conversationId,
                  shareUrl: window.location.pathname + '/conversation/share',
                  title: this.item.metadata.conversationName,
                },
              })
            );
            window.dispatchEvent(
              new CustomEvent('change-route', {
                detail: { url: window.location.pathname + '/need-to-login' },
              })
            );
          } else {
            window.dispatchEvent(
              new CustomEvent(CONVERSATION_ACTIONS.share, {
                detail: {
                  conversationId,
                  title: this.item.metadata.conversationName,
                },
              })
            );
          }
        };

        const restoreConversation = (closeCard) => {
          const conversationId = getConversationId();

          clearRemoveItemClass();
          closeCard();

          setTimeout(() => {
            this.classList.add('removed-item');
            this.addEventListener('animationend', function () {
              if (this.item.metadata.xircles_status === ARCHIVED) {
                wrapCatch(
                  updateTicket({
                    external_id: conversationId,
                    ticket: {
                      xircles_status: this.item.metadata.prev_xircles_status,
                    },
                  })
                );
              }
            });
          }, 200);
        };

        const isCardOpen = () =>
          this.innerNode.hasAttribute(DATA_OPEN_CARD_TAG);
        const setCardOpen = () =>
          this.innerNode.setAttribute(DATA_OPEN_CARD_TAG, '');
        const removeCardOpen = (conversation = this) =>
          conversation.innerNode.removeAttribute(DATA_OPEN_CARD_TAG);

        const isCardSwipe = () =>
          this.innerNode.hasAttribute(DATA_SWIPE_CARD_TAG);
        const setCardSwipe = () =>
          this.innerNode.setAttribute(DATA_SWIPE_CARD_TAG, '');
        const removeCardSwipe = () =>
          this.innerNode.removeAttribute(DATA_SWIPE_CARD_TAG);

        const isNotDeletedRequestsUrl = () =>
          !window.location.pathname.includes('/deleted-requests');
        const clearRemoveItemClass = () => {
          if (this.classList.contains('removed-item')) {
            this.classList.remove('removed-item');
          }
        };

        if ('ontouchstart' in document.documentElement) {
          const closeCard = (conversation = this) => {
            conversation.innerNode.style.left = 0;
            conversation.nodes.confirmAction.classList.remove('open');
            removeCardOpen(conversation);
          };

          const closeAllCard = () => {
            let conversationList = document
              .getElementsByTagName('layer-conversation-list')[0]
              .getElementsByTagName('layer-conversation-item');
            [].forEach.call(conversationList, (conversation) =>
              closeCard(conversation)
            );
          };

          const openCard = () => {
            closeAllCard();
            this.innerNode.style.left = `${OPEN_TRANSFROM_X_VALUE}px`;
            setCardOpen();
            let closeCardEvent = new AlloyFinger(window, {
              touchEnd: (evt) => {
                closeCard();
                closeCardEvent.destroy();
              },
            });
          };

          new AlloyFinger(this.nodes.handle, {
            touchStart: () => {
              this.innerNode.classList.add('swipe-animation');
            },
            touchEnd: (evt) => {
              this.innerNode.classList.remove('swipe-animation');
              evt.preventDefault();
              evt.stopPropagation();
              isCardOpen() ? closeCard() : openCard();
            },
          });

          new AlloyFinger(this.nodes.confirmAction, {
            touchEnd: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            },
          });

          new AlloyFinger(this.nodes.actions, {
            touchEnd: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            },
          });

          new AlloyFinger(
            this.nodes.actions.querySelector('[layer-id="actions-delete"]'),
            {
              tap: (evt) => {
                window.dispatchEvent(
                  new CustomEvent('tr-event', {
                    detail: { target: evt.target },
                  })
                );
                clearRemoveItemClass();
                if (isNotDeletedRequestsUrl()) {
                  const conversationId = getConversationId();
                  window.dispatchEvent(new CustomEvent('archive-conversation'));

                  closeCard();
                  setTimeout(() => {
                    this.classList.add('removed-item');
                    this.addEventListener('animationend', function () {
                      wrapCatch(
                        updateTicket({
                          external_id: conversationId,
                          ticket: { xircles_status: ARCHIVED },
                        })
                      );
                    });
                  }, 200);
                } else {
                  this.nodes.confirmAction.classList.add('open');
                  this.nodes.confirmAction.classList.remove('close');

                  let confirmYes = new AlloyFinger(
                    this.nodes.confirmAction.querySelector('.confirm-yes'),
                    {
                      tap: (evt) => {
                        closeCard();
                        setTimeout(() => {
                          this.classList.add('removed-item');
                          deleteConversation();
                          confirmYes.destroy();
                        }, 200);
                      },
                    }
                  );

                  let confirmClose = new AlloyFinger(
                    this.nodes.confirmAction.querySelector('.confirm-no'),
                    {
                      tap: (evt) => {
                        this.nodes.confirmAction.classList.remove('open');
                        this.nodes.confirmAction.classList.add('close');
                        confirmClose.destroy();
                      },
                    }
                  );
                }
              },
            }
          );

          const actionsShare = this.nodes.actions.querySelector(
            '[layer-id="actions-share"]'
          );
          if (actionsShare) {
            new AlloyFinger(actionsShare, {
              tap: (e) => {
                window.dispatchEvent(
                  new CustomEvent('tr-event', { detail: { target: e.target } })
                );
                shareConversation();
              },
            });
          }

          const actionsRestore = this.nodes.actions.querySelector(
            '[layer-id="actions-restore"]'
          );
          if (actionsRestore) {
            new AlloyFinger(actionsRestore, {
              tap: () => restoreConversation(closeCard),
            });
          }

          new AlloyFinger(this.innerNode, {
            touchEnd: (evt) => {
              const startPosition = isCardOpen() ? OPEN_TRANSFROM_X_VALUE : 0;
              const endPosition = getInnerNodeLeftValue();
              Math.abs(startPosition - endPosition) > 15
                ? setCardSwipe()
                : removeCardSwipe();
              if (isCardOpen() || isCardSwipe()) {
                isCardOpen() === isCardSwipe() ? closeCard() : openCard();
              }
            },
          });

          new AlloyFinger(this, {
            pressMove: (evt) => {
              let x = evt.deltaX - getInnerNodeLeftValue();

              if (x < OPEN_TRANSFROM_X_VALUE) {
                x = OPEN_TRANSFROM_X_VALUE;
              } else if (x > 0) {
                x = 0;
              }

              this.innerNode.style.left = `${x}px`;
            },
          });
        } else {
          const openCard = () => {
            const [xirclesHandle] =
              this.innerNode.getElementsByClassName('xircles-handle');
            xirclesHandle &&
              window.dispatchEvent(
                new CustomEvent('tr-event', {
                  detail: { target: xirclesHandle },
                })
              );
            window.dispatchEvent(new Event('mousedown'));
            this.innerNode.style.transform = `translateX(${OPEN_TRANSFROM_X_VALUE}px)`;
            setCardOpen();
            window.addEventListener('mousedown', closeCard);
          };

          const closeCard = () => {
            this.nodes.confirmAction.classList.remove('open');
            removeCardOpen();
            this.innerNode.style.transform = 'translateX(0)';
            window.removeEventListener('mousedown', closeCard);
          };

          let startPosition = 0;

          let prevX = 0;
          const mouseMove = (event) => {
            let movementX;
            // IE doesn't support movementX property in MouseEvent object,
            // so wee need to replicate it's behaviour by taking the delta between subsequent screenX values.
            if (isIE()) {
              movementX = prevX ? event.screenX - prevX : 0;
              prevX = event.screenX;
            } else {
              movementX = event.movementX;
            }
            let x = movementX + getInnerNodeTransformValue();

            if (x > 0) {
              x = 0;
            } else if (x < OPEN_TRANSFROM_X_VALUE) {
              x = OPEN_TRANSFROM_X_VALUE;
            }

            this.innerNode.style.transform = `translateX(${x}px)`;
          };

          const mouseUp = (evt) => {
            evt.stopPropagation();
            let x = getInnerNodeTransformValue();
            Math.abs(startPosition - x) > 5
              ? setCardSwipe()
              : removeCardSwipe();
            x < OPEN_TRANSFROM_X_VALUE / 2 ? openCard() : closeCard();

            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
          };

          const swipe = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            startPosition = getInnerNodeTransformValue();

            const removeSwipe = (evt) => {
              removeCardSwipe();
              window.removeEventListener('click', removeSwipe);
            };
            window.addEventListener('click', removeSwipe);

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
          };

          this.addEventListener('mousedown', (e) => mouseHandler(e, swipe));

          const conversationItemClick = (evt) => {
            if (isCardOpen() || isCardSwipe()) {
              evt.preventDefault();
              evt.stopPropagation();
              isCardSwipe() ? removeCardSwipe() : closeCard();
            }
          };

          this.addEventListener('click', conversationItemClick);

          const handleClick = (evt) => {
            evt.stopPropagation();
            isCardOpen() && !isCardSwipe() ? closeCard() : openCard();
            removeCardSwipe();
          };

          if (this.nodes && this.nodes.handle) {
            this.nodes.handle.addEventListener('click', handleClick);
          }

          const deleteAction = (evt) => {
            window.dispatchEvent(
              new CustomEvent('tr-event', { detail: { target: evt.target } })
            );
            clearRemoveItemClass();
            if (isNotDeletedRequestsUrl()) {
              const conversationId = getConversationId();
              window.dispatchEvent(new CustomEvent('archive-conversation'));
              closeCard();
              setTimeout(() => {
                this.classList.add('removed-item');
                this.addEventListener('animationend', function () {
                  wrapCatch(
                    updateTicket({
                      external_id: conversationId,
                      ticket: { xircles_status: ARCHIVED },
                    })
                  );
                });
              }, 200);
            } else {
              this.nodes.confirmAction.classList.add('open');
              this.nodes.confirmAction.classList.remove('close');

              this.nodes.confirmAction.addEventListener('click', (evt) =>
                evt.stopPropagation()
              );
              this.nodes.confirmAction.addEventListener('mousedown', (evt) =>
                evt.stopPropagation()
              );

              const confirmListener = (evt) => {
                this.nodes.confirmAction
                  .querySelector('.confirm-yes')
                  .removeEventListener('click', confirmListener);
                closeCard();
                setTimeout(() => {
                  this.classList.add('removed-item');
                  deleteConversation();
                }, 200);
              };

              this.nodes.confirmAction
                .querySelector('.confirm-yes')
                .addEventListener('click', confirmListener);
            }
          };

          if (this.nodes && this.nodes.handle) {
            const deleteElement = this.nodes.actions.querySelector(
              '[layer-id="actions-delete"]'
            );
            if (deleteElement) {
              deleteElement.addEventListener('click', deleteAction);
            }
          }

          const shareAction = (evt) => {
            window.dispatchEvent(
              new CustomEvent('tr-event', { detail: { target: evt.target } })
            );
            shareConversation();
          };

          if (this.nodes && this.nodes.handle) {
            const shareElement = this.nodes.actions.querySelector(
              '[layer-id="actions-share"]'
            );
            if (shareElement) {
              shareElement.addEventListener('click', shareAction);
            }
          }

          const restoreAction = () => restoreConversation(closeCard);

          if (this.nodes && this.nodes.handle) {
            const restoreElement = this.nodes.actions.querySelector(
              '[layer-id="actions-restore"]'
            );
            if (restoreElement) {
              restoreElement.addEventListener('click', restoreAction);
            }
          }

          if (this.nodes && this.nodes.handle) {
            this.nodes.actions.addEventListener('click', (evt) =>
              evt.stopPropagation()
            );
            this.nodes.actions.addEventListener('mousedown', (evt) =>
              evt.stopPropagation()
            );
          }

          if (this.nodes && this.nodes.confirmAction) {
            this.nodes.confirmAction
              .querySelector('.confirm-no')
              .addEventListener('click', (evt) => {
                this.nodes.confirmAction.classList.remove('open');
                this.nodes.confirmAction.classList.add('close');
              });
          }
        }
      } else {
        const item = document.createElement('div');
        item.classList.add('xircles-status');
        item.innerHTML = '...';

        this.nodes.title.parentNode.insertBefore(
          item,
          this.nodes.title.nextSibling
        );
        this.style.height = '186px';
        this.getElementsByClassName(
          'layer-conversation-item-wrapper'
        )[0].classList.add('layer-conversation-item-wrapper__operator');
      }
    },
    onRerender() {
      const [layerAvatar] = this.getElementsByTagName('layer-avatar');
      if (
        layerAvatar &&
        this.item.metadata.isPublic &&
        !layerAvatar.classList.contains('is-public')
      ) {
        layerAvatar.classList.add('is-public');
      }

      if (
        this.getElementsByClassName('xircles-handle')[0] &&
        this.item.metadata.isPublic
      ) {
        this.getElementsByClassName('xircles-handle')[0].style.display = 'none';
        this.addEventListener(
          'mousedown',
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          true
        );
        this.addEventListener(
          'mousemove',
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          true
        );
        this.addEventListener(
          'touchmove',
          () => {
            this.innerNode.style.left = '0';
          },
          { passive: true }
        );
      }

      if (isIE() || isFirefox()) {
        lineClamp(this.nodes.lastMessage, 4);
      } else {
        lineClamp(this.nodes.lastMessage, 3);
      }

      this.nodes.title.innerHTML = '';

      let statusElement =
        this.nodes.title.parentNode.getElementsByClassName('xircles-status')[0];

      let isStatusBadgeVisible = [
        FORWARD_REQUESTED,
        ANSWER_DELIVERED,
        FORWARDED,
        TAKEOVER,
        DELETED,
        CLOSED,
      ].some((item) => item === this.item.metadata.xircles_status);

      if (isOperator(Layer.client.user) || isStatusBadgeVisible) {
        statusElement.style.display = 'block';
        const publicStatus = i18next.t(
          translateInternalStatus(this.item.metadata.xircles_status)
        );
        statusElement.innerHTML = i18next.t(getStatusClass(publicStatus)[0]);
        statusElement.className = `xircles-status ${i18next.t(
          getStatusClass(publicStatus)[1]
        )}`;
      }

      if (isOperator(Layer.client.user) && this.item.lastMessage) {
        const lastActionTimeValue = lastActionTime(
          this.item.lastMessage.sentAt
        );
        const lastActionTimeElement =
          this.getElementsByClassName('action-time')[0];
        lastActionTimeElement.innerHTML = lastActionTimeValue;

        const layerItemId = this.getAttribute('layer-item-id');
        const conversationId = layerItemId.replace('conversations_', '');

        const [conversationIdLabel] = this.getElementsByClassName(
          'conversation-id-label'
        );

        if (conversationId && conversationIdLabel) {
          conversationIdLabel.innerHTML = `[${conversationId}]`;
        }

        const publicStatus = i18next.t(
          translateOperatorInternalStatus(this.item.metadata.xircles_status)
        );
        const publicPvStatus = i18next.t(
          translatePvStatus(this.item.metadata.pv_status)
        );
        const statusPvElement = this.getElementsByClassName(
          'operator-status__first'
        )[0];
        const statusElement = this.getElementsByClassName(
          'operator-status__second'
        )[0];

        statusPvElement.innerHTML = publicPvStatus;
        statusElement.innerHTML = publicStatus;
      }
    },
    onSelection(isSelected) {
      if (!isOperator(Layer.client.user)) {
        const view = this;
        if (isSelected)
          window.onpopstate = (e) => {
            view.isSelected = false;
          };
      }
    },
  },
};

export default ConversationItemMixin;
