export default [
  'layer-status-message-view',
  'layer-receipt-message-view',
  'layer-choice-message-view',
  'layer-carousel-message-view',
  'layer-buttons-message-view',
  'layer-file-message-view',
  'layer-compose-bar-one-line-of-text',
  'layer-location-message-view',
  'layer-product-message-view',
  'layer-feedback-message-view',
  'layer-send-button',
  'layer-file-upload-button',
  'layer-conversation-list',
  'layer-identity-list',
  'layer-response-message-view',
  'xircles-button-link-message-view',
  'xircles-checkbox-message-view',
  'doccheck-message-view',
  'xircles-related-question-message-view',
  'xircles-matched-questions-message-view',
  'xircles-related-questions-message-view',
  'xircles-text-link-message-view',
  'xircles-button-link-message-view',
  'xircles-long-copy-message-view',
  'xircles-content-card-message-view',
  'xircles-content-card-message-view-swipe',
  'xircles-disclaimer-message-view',
  'xircles-doccheck-message-view',
  'xircles-checkbox-message-view',
  'xircles-question-message-view',
  'xircles-control-message-view',
  'xircles-result-message-view',
  'xircles-results-message-view',
  'xircles-simple-answer-message-view',
  'xircles-reference-message-view',
  'xircles-optimise-it-text-message-view',
];
