// @flow
/**
 * Mobile browsers have annoying behaviors when trying to use all available screen real-estate without any up-down scrolling.
 * There are issues with the space the Location bar takes up, as well as issues with the nav buttons some phones have on the bottom.
 * These tweaks attempt to work around those so as to make best use of screen real-estate.
 */

import { Layer } from './get-layer';
import { getIOSVersion, isInsideFrame } from './utils/common';
const { isMobile } = Layer.Utils;

function mobileFixes() {
  var isIOS12;

  const [version] = getIOSVersion();
  if (version && version === 12) {
    isIOS12 = true;
  }

  if (isMobile) {
    function onResize() {
      if (document.body && !isInsideFrame()) {
        document.body.style.height = window.innerHeight + 'px';
      }

      if (document.head && isInsideFrame()) {
        var iframeMdcDialog = document.createElement('style');
        iframeMdcDialog.type = 'text/css';

        if (isIOS12) {
          iframeMdcDialog.innerHTML = `.mdc-dialog { max-height: ${
            window.screen.height - 300 / 2
          }px }`;
        } else {
          iframeMdcDialog.innerHTML = `.mdc-dialog { max-height: ${
            window.screen.height || window.innerHeight
          }px }`;
        }

        document.head.appendChild(iframeMdcDialog);
      }
    }
    window.addEventListener('resize', onResize);
    setTimeout(onResize, 10);
  }
}

export default mobileFixes;
