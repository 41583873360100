// @flow

import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

import './styles.less';

type Props = {
  onLogin: Function,
  onRegister: Function,
  t: Function,
};

function NeedToLoginModalFragment({ onLogin, onRegister, t }: Props) {
  return (
    <React.Fragment>
      <Box mt={1} textAlign="center" className={'warning-header'}>
        <span>{t('LOGIN_NEED_TO_LOGIN_MESSAGE')}</span>
      </Box>
      <Box mt={2}>
        <Button
          id="need-to-login"
          data-keystroke
          outlined
          style={{ width: '100%' }}
          onClick={onLogin}
        >
          {t('LOGIN_TITLE')}
        </Button>
      </Box>
      <Box mt={1}>
        <Button
          id="need-to-register"
          data-keystroke
          outlined
          style={{ width: '100%' }}
          onClick={onRegister}
        >
          {t('REGISTER_TITLE')}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(NeedToLoginModalFragment);
