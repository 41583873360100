// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';

import ShareModalFragment from '../ShareModalFragment';
import IconButton from '../../../material-components/IconButton';
import MaterialIcon from '@material/react-material-icon';
import ShareConfirmationModalFragment from '../ShareConfirmationModalFragment';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '../../../material-components/Dialog';
import ErrorMessage from '../ErrorMessage';
import LoadingSpinner from '../../LoadingSpinner';

type Props = {
  urlParam: string,
  shareModalSettings: {
    dialogTitle: string,
    getBackUrl: Function,
    getSuccessUrl: Function,
    getShareUrl: Function,
  },
  onShareContent: Function,
  validatePhoneNumberOrEmail: Function,
  error: string | null,
  resetError: Function,
  shareState: 'loading' | 'loaded' | 'error',
  t: Function,
};

type State = {
  phoneNumberOrEmail: string,
  errorMessage: string,
};

class ShareModal extends React.Component<Props, State> {
  state = {
    phoneNumberOrEmail: '',
  };

  onShareClick = (history) => () => {
    const {
      location,
      urlParam,
      onShareContent,
      shareModalSettings,
      validatePhoneNumberOrEmail,
    } = this.props;

    const hash = _get(location, 'hash');
    const { phoneNumberOrEmail } = this.state;

    const isPhoneNumberOrEmailValid =
      validatePhoneNumberOrEmail(phoneNumberOrEmail);
    const successUrl = shareModalSettings.getSuccessUrl(urlParam);

    if (isPhoneNumberOrEmailValid) {
      onShareContent(
        urlParam,
        phoneNumberOrEmail,
        shareModalSettings,
        hash
      ).then(({ data }) =>
        history.replace({
          pathname: successUrl,
          hash,
          state: { detail: { shortUrl: data.short_url, phoneNumberOrEmail } },
        })
      );
    }
  };

  handleRenderShareFragment = ({ history }) => {
    const { phoneNumberOrEmail } = this.state;
    const { shareState } = this.props;

    const isLoading = shareState === 'loading';

    return (
      <div>
        {isLoading && <LoadingSpinner />}
        <ShareModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          onPhoneNumberOrEmailChange={(value) =>
            this.setState({ phoneNumberOrEmail: value })
          }
          onShare={this.onShareClick(history)}
        />
      </div>
    );
  };

  handleRenderShareConfirmationFragment = ({ history }) => {
    const { location } = this.props;

    const shortUrl = _get(location, 'state.detail.shortUrl');
    const phoneNumberOrEmail = _get(
      location,
      'state.detail.phoneNumberOrEmail'
    );

    return (
      <ShareConfirmationModalFragment
        conversationUrl={shortUrl}
        phoneNumberOrEmail={phoneNumberOrEmail}
        onAcknowledge={(e) => {
          window.dispatchEvent(
            new CustomEvent('tr-event', { detail: { target: e.target } })
          );
          history.goBack();
        }}
      />
    );
  };

  onEnterErrorMessage = () => {
    this.setState({ errorMessage: this.props.error });
  };

  onExitedErrorMessage = () => {
    this.setState({ errorMessage: null });
  };

  onClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { onToggle } = this.props;

    onToggle(ev);
  };

  renderError() {
    const { error, resetError } = this.props;
    const { errorMessage } = this.state;

    return (
      <ErrorMessage
        error={error}
        errorMessage={errorMessage}
        resetError={resetError}
        onEnterErrorMessage={this.onEnterErrorMessage}
        onExitedErrorMessage={this.onExitedErrorMessage}
      />
    );
  }

  renderCloseButton() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '-11px',
          right: '0px',
          color: 'xSlateLighter',
        }}
      >
        <IconButton
          id="share-modal-close"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (event) => {
      this.props.isOpen && this.props.onToggle(event);
    });
  }

  render() {
    const { t, isOpen, shareModalSettings } = this.props;

    return (
      <Dialog className="xircles-share-content-dialog" open={isOpen}>
        <DialogTitle style={{ textAlign: 'center' }}>
          {this.renderError()}
          {t(shareModalSettings.dialogTitle)}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          <div className="my-wrapper">
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={this.props.location.key}
                timeout={{ enter: 400, exit: 400 }}
                classNames="fade"
              >
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route
                      path={[
                        '/questions/:conversationId/share',
                        '/:productName/conversation/share',
                        '/questions/conversation/share',
                        '/video/:videoId/share',
                        '/documents/*/share',
                      ]}
                      exact
                      render={this.handleRenderShareFragment}
                    />
                    <Route
                      path={[
                        '/questions/:conversationId/shared',
                        '/:productName/conversation/shared',
                        '/questions/conversation/shared',
                        '/video/:videoId/shared',
                        '/documents/*/shared',
                      ]}
                      exact
                      render={this.handleRenderShareConfirmationFragment}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(ShareModal));
