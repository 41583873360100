// @flow

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

function DeleteRequestModalFragment({ onSubmit, onCancel, t }: Props) {
  return (
    <Fragment>
      <Box textAlign="center">{t('MODAL_SUBMIT_DELETE_REQUEST')}</Box>
      <Box mt={4}>
        <Button
          id="submit-delete-request"
          outlined
          style={{ width: '100%' }}
          onClick={onSubmit}
        >
          {t('MODAL_DELETE_REQUEST')}
        </Button>
      </Box>
      <Box mt={1}>
        <Button
          id="cancel-delete-request"
          outlined
          style={{ width: '100%' }}
          color="secondary"
          onClick={onCancel}
        >
          {t('COMMON_CANCEL')}
        </Button>
      </Box>
    </Fragment>
  );
}

export default withTranslation()(DeleteRequestModalFragment);
