const closest = (el, name) => {
  let node = el;
  while (node) {
    if (node.matches(name)) return node;
    else node = node.parentElement;
  }
  return null;
};

export default closest;
