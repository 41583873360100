import Layer from '@layerhq/web-xdk';
import './optimise-it-text-message-model';
import { isUUID, getUUID, waitForPropertyToBeSet } from './../../utils/common';
const config = require('./../../config.json');

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;
const RP_CHAT_CONFIG = {
  deptId: config.RealPersonDepartmentId,
  pagename: '',
  chatType: 'headless',
};

registerComponent('xircles-optimise-it-text-message-view', {
  mixins: [MessageViewMixin],

  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata
          ? Widths.FLEX
          : Widths.ANY;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    getPreferredMinWidth() {},
    async rpAgentConnectedCallback() {
      const { pathname } = document.location;

      // Check if it's conversation page with UUID
      if (!isUUID(pathname)) {
        console.log('UUID not found.');
        return;
      }

      const initialData = [
        `Url: ${window.location.host}`,
        `Service: ${window.rpChatData.xircleName}`,
        `Name: ${window.rpChatData.name}`,
        `Number/Email: ${window.rpChatData.contact}`,
        `Product: ${window.rpChatData.product}`,
        `Question: ${window.rpChatData.text}`,
        `Document: ${window.rpChatData.documentTitle}`,
        `Search results: ${window.rpChatData.headlines}`,
      ];

      initialData.forEach(window.rp.chat.HeadlessChat.sendMessage);

      const [conversationId] = getUUID(pathname);

      // It calls Custom Flow handler to start processing messages from the user
      document.body.dispatchEvent(
        new CustomEvent('xircles-control-message-send', {
          detail: {
            conversationId: conversationId,
            eventName: 'BOT_FLOW',
            data: {
              flow: 'novartisGermanyMedicalRequestFlow',
              state: 'processRpAgentConnected',
            },
          },
        })
      );
    },
    loadRealPersonChat() {
      window.rpChatConfig = RP_CHAT_CONFIG;
      var realPersonChatLoader = document.createElement('script');
      realPersonChatLoader.type = 'text/javascript';
      realPersonChatLoader.async = true;
      realPersonChatLoader.id = 'realpersonChatLoader';
      realPersonChatLoader.src = config.RealPersonLoadChatModuleUrl;
      document
        .getElementsByTagName('head')[0]
        .appendChild(realPersonChatLoader);
    },
    sendChatRequest() {
      const [msg] = this.model.items;
      const body = JSON.parse(msg.__part.__body);
      const { contactDetails, xircleName, ...others } = body.customData;
      const { name, contact } = contactDetails;
      const { product, text } = others.conversation.dialogData.aron;

      window.rpChatData = {
        name,
        contact,
        product,
        text,
        headlines: others.headlines,
        xircleName,
        documentTitle: others.documentTitle,
      };

      window.rp.chat.HeadlessChat.setHeadless();
      window.rp.chat.HeadlessChat.requestChat(window.rpChatData.name);

      /*
          Thrown when an agent joined the chat.
          The payload is of type AgentIdentity.
          Use this event to assume a running session.
        */
      document.addEventListener(
        'rp-agent-identity',
        this.rpAgentConnectedCallback
      );
    },
    async onAfterCreate() {
      if (config.RealPersonLoadChatModuleUrl !== 'empty') {
        if (!window.rp) {
          this.loadRealPersonChat();
        }
        waitForPropertyToBeSet(
          window,
          [
            ['rp'],
            ['chat'],
            ['HeadlessChat'],
            ['setHeadless', 'requestChat', 'sendMessage'],
          ],
          this.sendChatRequest.bind(this)
        );
      }
    },
    onDestroy() {
      document.removeEventListener(
        'rp-agent-identity',
        this.rpAgentConnectedCallback
      );
    },
    onRerender() {
      const view = this;
      view.model.items.forEach((item) => {
        if (item.typeLabel.toLowerCase() === 'text') {
          view.createElement('layer-text-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this,
          });
        } else if (item.typeLabel.toLowerCase() === 'image') {
          view.createElement('layer-image-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this,
          });
        } else {
          view.createElement('xircles-reference-message-view', {
            model: item,
            messageViewContainerTagName: false,
            cardBorderStyle: 'none',
            parentNode: this,
          });
        }
      });
    },
  },
});
