import { layerClient } from '../get-layer';
import config from '../config.json';

const IDLE_TIMEOUT = config.SessionExpireSeconds;
const _localStorageLastActivityKey = 'last_activity_timestamp';
// Start assuming we need to start over (to be safe)
let _lastActivityTimeStamp = 0;
// Try to get local storage or complain in logs
let _localStorage = null;
try {
  _localStorage = window.localStorage;
} catch (e) {
  console.warn('No local storage supported in device browser');
}

/**
 * @function bindEvent
 * Wrapper to attach event listeners for different browsers
 * @param {DOMElement} element
 * @param {string} eventName
 * @param {function} eventHandler
 * @returns {boolean}
 */
const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
    return true;
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
    return true;
  } else {
    return false;
  }
};

/**
 * @function getLastActivityTimestamp
 * @returns {number}
 */
const getLastActivityTimestamp = () => {
  let lastActivityTimeStamp = _lastActivityTimeStamp || 0;
  if (_localStorage) {
    // Try to get is from local storage or reset it always to zero (to be safe)
    try {
      lastActivityTimeStamp =
        parseInt(_localStorage[_localStorageLastActivityKey], 10) || 0;
    } catch (e) {
      console.warn(
        `${new Date().toISOString()} - Could not parse local storage last activity timestamp`
      );
      lastActivityTimeStamp = 0;
    }
    if (isNaN(lastActivityTimeStamp) || lastActivityTimeStamp < 0)
      lastActivityTimeStamp = 0;
  }
  if (config.Debug) {
    console.info(
      `${new Date().toISOString()} - Last activity: ${new Date(
        lastActivityTimeStamp
      ).toISOString()}`
    );
  }
  return lastActivityTimeStamp;
};

/**
 * @function setLastActivityTimeStamp
 * @param {number} timestamp
 */
const setLastActivityTimeStamp = (timestamp) => {
  _lastActivityTimeStamp = timestamp;
  if (_localStorage) {
    _localStorage[_localStorageLastActivityKey] = timestamp;
  }
};

/**
 * @function resetTime
 */
const resetTime = () => setLastActivityTimeStamp(new Date().getTime());

/**
 * @function reloadAfterInactivity
 */
const reloadAfterInactivity = () => {
  console.warn(`${new Date().toISOString()} - Reloading by inactivity`);
  layerClient.trigger('challenge');
  document.location.href = '/';
};

/**
 * @function checkInactivityTime
 * @returns {any}
 */
const checkInactivityTime = () => {
  const currentTimestamp = new Date().getTime();
  const lastActivityTimeStamp = getLastActivityTimestamp() || 0;
  let secondsDiff = Math.floor(
    (currentTimestamp - lastActivityTimeStamp) / 1000
  );
  // Some timezone change could do this?
  if (secondsDiff <= 0) {
    resetTime();
    secondsDiff = 0;
  }
  if (config.Debug) {
    console.info(
      `${new Date().toISOString()} - Inactivity countdown: ${
        IDLE_TIMEOUT - secondsDiff
      }`
    );
  }
  // Reschedule it if not reached
  if (secondsDiff < IDLE_TIMEOUT) {
    return setTimeout(checkInactivityTime, 1000);
  }
  resetTime();
  // Reload right away or wait for coming online
  if (window.navigator.onLine) {
    return reloadAfterInactivity();
  }
};

bindEvent(window, 'online', () => {
  console.warn(`${new Date().toISOString()} - Network connectivity restored`);
  reloadAfterInactivity();
});

// Only start all these listeners and process if enabled to a value > 0
if (IDLE_TIMEOUT && IDLE_TIMEOUT > 0) {
  // Start on windows load, so everything else is loaded (components, etc.)
  bindEvent(window, 'load', () => {
    // Check inactivity right away
    setTimeout(checkInactivityTime, 0);
    // Relevant activity events (not load, that is out of control as can be auto-triggered)
    bindEvent(document, 'click', resetTime);
    bindEvent(document, 'mousemove', resetTime);
    bindEvent(document, 'mousedown', resetTime);
    bindEvent(document, 'keydown', resetTime);
    bindEvent(window, 'scroll', resetTime);
  });
}
