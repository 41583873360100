// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { removeRegisteredSymbol, slugify } from '../../utils/common';
import './styles.less';

const ProductCard = (props: Props) => (
  <div
    className={`product-card${props.isPX ? ' product-xircle-card' : ''}`}
    style={{ backgroundColor: props.primaryColor }}
    onClick={() => props.onProductCardClick(props.brand_slug, props.pxInfo)}
  >
    <p className="product-card__name">
      <a
        rel="canonical"
        href={
          props.isPX
            ? `${props.appUrl}/${slugify(props.pxInfo.company_name)}/${slugify(
                removeRegisteredSymbol(props.pxInfo.brand_name)
              )}`
            : `${props.appUrl}/medikamente/${props.brand_slug}`
        }
        onClick={(e) => {
          if (!props.isLinkClickAllowed) {
            e.preventDefault();
          }
        }}
      >
        {props.name}
      </a>
    </p>
    <p className="product-card__compound_name">{props.compoundName}</p>
    <div className="product-card__information">
      {props.isManufactureVisible && props.companiesNum && (
        <span className="product-card__manufactures">{`${
          props.companiesNum
        } ${props.t('COMMON_MANUFACTURERS')}`}</span>
      )}
      {props.documentsNum && (
        <span className="product-card__technical-informations">{`${
          props.documentsNum
        } ${props.t('COMMON_TECHNICAL_INFORMATIONS')}`}</span>
      )}
      {props.isPX && (
        <p className="product-card__px-open">
          <span>
            {`${removeRegisteredSymbol(props.pxInfo.brand_name)}`}
            <sup>®</sup>
            {` Xircle ${props.t('COMMON_OPEN').toLowerCase()}`}
          </span>
          <span>»</span>
        </p>
      )}
    </div>
  </div>
);

export default withTranslation()(ProductCard);
