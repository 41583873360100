import React from 'react';
import camelCase from 'lodash/camelCase';
import { Route, withRouter } from 'react-router-dom';
import _isUndefined from 'lodash/isUndefined';
import _values from 'lodash/values';

import conversationManager from '../hocs/conversationManager';
import NeedToLoginModal from '../components/Modal/NeedToLoginModal/index';

export const NEED_TO_LOGIN_MODAL_PATHS = {
  NEED_TO_LOGIN_VIDEO: '/video/:videoId/need-to-login',
  NEED_TO_LOGIN_DOCUMENT: '/documents/:documentPath+/need-to-login',
};

export const NEED_TO_LOGIN_MODAL_PATHS_ARRAY = _values(
  NEED_TO_LOGIN_MODAL_PATHS
);

export const NEED_TO_LOGIN_SETTINGS = {
  [NEED_TO_LOGIN_MODAL_PATHS.NEED_TO_LOGIN_VIDEO]: {
    urlParam: 'videoId',
    getBackUrl: (videoId) => `/video/${videoId}`,
  },
  [NEED_TO_LOGIN_MODAL_PATHS.NEED_TO_LOGIN_DOCUMENT]: {
    urlParam: 'documentPath',
    getBackUrl: (documentPath) => `/documents/${documentPath}`,
  },
};

class NeedToLoginModalContainer extends React.Component<Props, State> {
  isNeedToLoginVideoUrl = (url) => /\/video\/.{20}\/(need-to-login)/.exec(url);
  isNeedToLoginDocumentUrl = (url) =>
    /\/documents\/.*\/(need-to-login)/.exec(url);

  getUrlParam = (needToLoginModalSettings, params) => {
    const urlParam = params[needToLoginModalSettings.urlParam];
    return _isUndefined(urlParam) ? this.getConversationId() : urlParam;
  };

  handleToggle = (history, match) => {
    // handle direct link to the page
    if (history.length <= 2) {
      const baseUrl = match.url.replace(/\/need-to-login/, '');
      history.replace(baseUrl);
    } else {
      history.goBack();
    }
  };

  render() {
    return (
      <Route
        path={NEED_TO_LOGIN_MODAL_PATHS_ARRAY}
        render={({ match, history }) => {
          const regexMatchNeedToLoginVideo = this.isNeedToLoginVideoUrl(
            match.url
          );
          const regexMatchNeedToLoginDocument = this.isNeedToLoginDocumentUrl(
            match.url
          );

          const regexMatch =
            regexMatchNeedToLoginVideo || regexMatchNeedToLoginDocument;
          const currentStep = regexMatch ? camelCase(regexMatch[1]) : null;

          return (
            <NeedToLoginModal
              {...this.props}
              isOpen={!!currentStep}
              onToggle={() => this.handleToggle(history, match)}
            />
          );
        }}
      />
    );
  }
}

const NeedToLoginModalContainerWithConversationManager = conversationManager(
  NeedToLoginModalContainer
);
export default withRouter(NeedToLoginModalContainerWithConversationManager);
