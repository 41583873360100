import { Layer } from '../../get-layer';
import './xircle-overview-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const processText = Layer.UI.handlers.text.processText;
const Widths = Layer.UI.Constants.WIDTH;

const lang = {
  singular: {
    unreadMessageCount: 'New message',
    unreadQuestionResponseCount: 'Question response',
    unreadProjectRequestCount: 'Project Request',
  },
  plural: {
    unreadMessageCount: 'New messages',
    unreadQuestionResponseCount: 'Question responses',
    unreadProjectRequestCount: 'Project Requests',
  },
};

registerComponent('xircles-xircle-overview-message-view', {
  mixins: [MessageViewMixin],
  template: `
    <div layer-id='img' class='xircles-xircle-overview-message-image'></div>
    <div class='xircles-xircle-overview-message-view-right'>
      <div>
        <div layer-id='unreadMessageCount' class='xircles-xircle-overview-message-messages'>
          <div layer-id='badge' class='xircles-badge'></div><div layer-id='label'></div>
        </div>
        <div layer-id='unreadQuestionResponseCount' class='layer-receipt-message-view-questions'>
          <div layer-id='badge' class='xircles-badge'></div><div layer-id='label'></div>
        </div>
        <div layer-id='unreadProjectRequestCount' class='layer-receipt-message-view-projects'>
          <div layer-id='badge' class='xircles-badge'></div><div layer-id='label'></div>
        </div>
      </div>
    </div>
    <div layer-id='link' class='xircles-xircle-overview-message-link'></div>
  `,
  style: `
    xircles-xircle-overview-message-view {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  `,

  properties: {
    widthType: {
      value: Widths.FULL,
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'xircles-message-view-container',
    },
  },

  methods: {
    onAfterCreate() {
      this.nodes.img.style.backgroundImage = `url("${this.model.imageUrl}")`;
      const a = document.createElement('a');
      a.href = `/conversations/${this.model.conversationId}`;
      a.innerHTML = 'Open';
      this.nodes.link.appendChild(a);
    },
    onRerender() {
      for (let prop of [
        'unreadMessageCount',
        'unreadQuestionResponseCount',
        'unreadProjectRequestCount',
      ]) {
        if (this.model[prop]) {
          this.nodes[prop].classList.remove('xircles-hide');
          this.nodes[prop].querySelector(
            '[layer-id="badge"]'
          ).innerHTML = `<span>${this.model[prop]}</span>`;
          this.nodes[prop].querySelector('[layer-id="label"]').innerHTML =
            processText(
              lang[this.model[prop] === 1 ? 'singular' : 'plural'][prop]
            );
        } else {
          this.nodes[prop].classList.add('xircles-hide');
        }
      }
    },
  },
});
