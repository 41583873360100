import closest from '../polyfills/closest';

const LongCopyMixin = {
  methods: {
    onAfterCreate() {
      closest(this, '.xircles-long-copy-message-view').addClickHandler(
        'open-link',
        closest(this, '.xircles-long-copy-message-view'),
        () => {
          dispatchEvent(
            new CustomEvent('change-route', { detail: { url: this.model.url } })
          );
        }
      );
    },
  },
};

export default LongCopyMixin;
