// @flow

import React from 'react';
import { isOperator } from '../../../utils/user';
import { getPathnameWithoutModal } from '../../../utils/common';
import { Layer } from '../../../get-layer';

type Props = {
  displayName: string,
  history: any,
  isAllowedWithoutAuth: boolean,
};

type State = {};

class AuthWrapper extends React.Component<Props, State> {
  closePopUp = () => {
    const modal = document.querySelector('.mdc-dialog');
    modal && modal.classList.remove('mdc-dialog--open');
  };

  goBack = () => {
    const { history } = this.props;

    this.closePopUp();

    if (history.length <= 2) {
      const path = getPathnameWithoutModal(history.location.pathname);
      history.replace(path);
    } else {
      history.goBack();
    }
  };

  componentDidMount() {
    const {
      displayName,
      isLoggedIn,
      history,
      isAllowedWithoutAuth,
      isAuthRequired,
    } = this.props;
    const isUserOperator = isOperator(Layer.client.user);

    if (isAuthRequired) {
      if (!isLoggedIn) {
        this.goBack();
      }
    } else {
      const isAccessDenied = [
        !!(displayName || '').trim(),
        !isAllowedWithoutAuth,
      ].some((condition) => !!condition);

      if ((isAccessDenied && history.action === 'POP') || isUserOperator) {
        this.goBack();
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default AuthWrapper;
