import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';

const Navigation = (props) => (
  <ul className="navigation">
    {props.links.map((link, index) => (
      <li key={`link-${index}`} onClick={link.onClick}>
        <Link to={link.url}>{link.title}</Link>
      </li>
    ))}
  </ul>
);

export default Navigation;
