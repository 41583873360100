// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

type Props = {
  fullName: string,
  onAcknowledge: Function,
  t: Function,
};

function DocCheckWelcomeModalFragment({ fullName, onAcknowledge, t }: Props) {
  return (
    <React.Fragment>
      <Box mt={2} textAlign="center" className={'doccheck-welcome'}>
        <Box>{`${t('LOGIN_WELCOME_HELLO')} ${fullName}!`}</Box>
        <Box>{`${t('LOGIN_DOCCHECK_LOGGED_IN')}`}</Box>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          id="welcome-doccheck-ok"
          data-keystroke
          outlined
          style={{ width: '100%' }}
          onClick={onAcknowledge}
        >{`${t('COMMON_CONTINUE')}`}</Button>
      </Box>
      <Box mt={7.5} textAlign="center">
        <span>DocCheck</span>{' '}
        <a href="http://info.doccheck.com/com/privacy/" target="blank">{`${t(
          'COMMON_PRIVACY'
        )}`}</a>
      </Box>
    </React.Fragment>
  );
}

export default withTranslation()(DocCheckWelcomeModalFragment);
