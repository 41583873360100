import React from 'react';

import './styles.less';

const LoadingSpinner = ({ className = '' }) => (
  <div className={`loading-spinner ${className}`}>
    <svg
      className="spinner"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path spinner-border"
        cx="33"
        cy="33"
        r="28"
        stroke="url(#linear-gradient-spinner)"
      ></circle>
      <linearGradient id="linear-gradient-spinner">
        <stop offset="50%" stopOpacity=".85"></stop>
        <stop offset="65%" stopOpacity=".52"></stop>
        <stop offset="100%" stopOpacity="0"></stop>
      </linearGradient>
    </svg>
  </div>
);

export default LoadingSpinner;
