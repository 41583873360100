import React from 'react';
import { withRouter } from 'react-router-dom';

import ProductXirclePage from '../components/ProductXirclePage';
import LayerContext from '../components/LayerContext';

const ProductXirclePageContainer = (props) => (
  <LayerContext.Consumer>
    {({ layerClient }) => (
      <ProductXirclePage {...props} layerClient={layerClient} />
    )}
  </LayerContext.Consumer>
);

export default withRouter(ProductXirclePageContainer);
