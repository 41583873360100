import Layer from '@layerhq/web-xdk';

import './text-link-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-text-link-message-view', {
  mixins: [MessageViewMixin],
  style: `
    xircles-text-link-message-message-view {
      display: block;
    }
    .layer-root-viewer.xircles-text-link-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  template:
    '<a layer-id="link"><i class="material-icons">link</i><span layer-id="title"></span></a>',
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata
          ? Widths.FLEX
          : Widths.ANY;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    _goToUrl() {
      window.dispatchEvent(
        new CustomEvent('change-route', { detail: { url: this.model.url } })
      );
    },
    _handleTextLinkMessageClick() {
      this.nodes.link.addEventListener('click', () => this._goToUrl(this));

      this.nodes.link &&
        'ontouchstart' in document.documentElement &&
        this.addEventListener('touchstart', this._onTouchStart, {
          passive: true,
        });
      this.nodes.link &&
        'ontouchend' in document.documentElement &&
        this.addEventListener('touchend', this._onTouchEnd, { passive: true });
    },
    _onTouchStart(evt) {
      this.pageYTouchStart = evt.pageY || evt.touches[0].pageY;
    },
    _onTouchEnd(evt) {
      this.pageYTouchEnd = evt.changedTouches[0].pageY;

      if (Math.abs(this.pageYTouchStart - this.pageYTouchEnd) < 10) {
        this._goToUrl(this);
      }
    },
    onAfterCreate() {
      this._handleTextLinkMessageClick();
    },
    onRerender() {
      this.nodes.title.innerHTML = this.model.title;
      this.nodes.link.href = this.model.url;
    },
  },
});
