// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import download from '../../../svg/download.svg';

import './styles.less';

type Props = {
  children?: Object | string,
  className?: string,
  title: string,
  links: Object,
  linkColor: string,
};

const createExternalLink = (appUrl, internalLink) => `${appUrl}${internalLink}`;

const InstructionItem = (props: Props) => (
  <div className="instruction_item">
    <div className="instruction_item__header">
      <img
        src={download}
        className="instruction_item__image"
        alt={'Instruction item'}
      />
      <p className="instruction_item__title">{props.title}</p>
    </div>
    <div
      className={`instruction_item__content ${
        props.links.length > 1 ? 'instruction_item__content--multiple' : ''
      }`}
    >
      {props.links.map((link, i) => (
        <div key={`link-${i}`}>
          {link.name}
          <a
            onClick={(e) => {
              e.preventDefault();
              props.history.push(link.internalLink);
            }}
            href={createExternalLink(props.appUrl, link.internalLink)}
            className={`instruction_item__link instruction_item__link--${props.linkColor}`}
            key={`open-${i}`}
            target={'_blank'}
            rel="canonical noreferrer"
          >
            {props.t('COMMON_OPEN')}
          </a>
          <a
            href={link.download.url}
            className={`instruction_item__link instruction_item__link--${props.linkColor}`}
            key={`download-${i}`}
            target={'_blank'}
            rel="noreferrer"
          >
            {props.t('COMMON_DOWNLOAD')}
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default withTranslation()(withRouter(InstructionItem));
