// @flow
/* eslint-disable no-sequences */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import _get from 'lodash/get';
import './styles.less';
import {
  createElementFromHTML,
  getSafeHtml,
  wrapRegisteredSymbol,
} from '../../utils/common';

class ProductOverview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOneparagraphSnippet: false,
    };
  }

  componentDidMount() {
    const htmlSnippet = createElementFromHTML(this.props.productSnippet);
    this.setState({
      isOneparagraphSnippet: Array.from(htmlSnippet).length === 1,
    });
  }

  render() {
    const {
      onProductOverviewLinkClick,
      productCompoundComponent,
      productManufacturer,
      productRelativeUrl,
      isMultiProduct,
      isProductXircle,
      withBackground,
      productSnippet,
      isPXDocument,
      productTitle,
      productUrl,
      linkTitle,
      pxInfo,
      t,
    } = this.props;

    return (
      <div
        style={{
          backgroundColor:
            isProductXircle && _get(pxInfo, 'page_data.primary_color'),
        }}
        className={`product-overview__application-areas ${
          isMultiProduct ? 'product-overview__multi-product' : ''
        } ${
          withBackground ? 'product-overview__application-areas--filled' : ''
        }`}
      >
        <h3
          className="product-overview__title"
          dangerouslySetInnerHTML={{
            __html: wrapRegisteredSymbol(getSafeHtml(productTitle)),
          }}
        ></h3>
        {isMultiProduct && (
          <p className="product-overview__compound-component">
            {productCompoundComponent}
          </p>
        )}
        {!isMultiProduct && (
          <p className="product-overview__manufacturer">
            {productManufacturer}
          </p>
        )}
        {!isMultiProduct && !isProductXircle && (
          <h3 className="product-overview__application-areas-title">
            {t('COMMON_APPLICATION_AREAS')}
          </h3>
        )}
        {!isMultiProduct &&
          (this.state.isOneparagraphSnippet ? (
            <div className="product-overview__description product-overview__description-oneparagraph">
              {Parser(productSnippet)}
            </div>
          ) : (
            <div className="product-overview__description product-overview__description-multipleparagraphs">
              {Parser(productSnippet)}
            </div>
          ))}
        <div className="product-overview__link">
          <a
            rel="canonical"
            href={productUrl}
            style={{
              color: isProductXircle
                ? 'white'
                : _get(pxInfo, 'page_data.on_background_highlight_text_color'),
            }}
            onClick={(e) => {
              e.preventDefault();
              onProductOverviewLinkClick(
                productRelativeUrl,
                isProductXircle,
                isPXDocument
              );
            }}
          >
            {typeof linkTitle === 'string' ? `${linkTitle} »` : linkTitle}
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProductOverview);
