// @flow
/**
 * This UI Component manages the Layer Conversations.
 *
 * It provides a Conversation List on the left, and a Conversation View on the right.
 *
 * It also uses an Identity List via the EditCOnversationDialog.js file to create new Conversations.
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import closestComponent from '../../polyfills/closestComponent';
import shorthash from 'shorthash';
import i18next from 'i18next';

import { LayerReactComponents, Layer } from '../../get-layer';
import getMenuOptions from './sample-menu';
import { customFileSelector } from '../../custom-message-types';
import { CHOICE_ACTION_EVENTS } from '../../const/choice-actions';
import translateInternalStatus from '../../common/status/translateInternalStatus';
import {
  addParticipantConversation,
  getIdentityById,
  wrapCatch,
  getDrugsCatalogue,
  getProductFaqs,
  updateTicket,
} from '../../utils/api';
import { WELCOME_MODAL_TYPES } from '../../const/welcome-modal-types';
import INTERNAL_STATUS from '../../const/status/internal-status';
import { DRAG_AND_DROP_EVENTS } from '../../const/dragndropEvents';
import { START_PAGE_MODES } from '../../const/start-page-modes';

import './styles.less';
import Header from './Header';
import HelmetWrapper from '../HelmetWrapper';
import { isHcp, isNotConfirmed, isOperator } from '../../utils/user';
import translatePvStatus from '../../common/status/translatePvStatus';
import translateOperatorInternalStatus from '../../common/status/translateOperatorInternalStatus';
import {
  getIOSVersion,
  isAndroid,
  isCrawler,
  isIE,
  isFirefox,
  isMobileDevice,
  isQuestionsRoute,
  isReactSnap,
  isTabletDevice,
  slugify,
} from '../../utils/common';
import XIRCLES_STATUSES from '../../const/status/xircles-status';
import { HTTP_STATUSES } from '../../const/http-statuses';
import conversationManager from '../../hocs/conversationManager';
import publicStatus from '../../const/status/public-status';
import MenuContainer from '../../containers/MenuContainer';
import BrandFooter from '../BrandFooter';

const { uuid } = Layer.Utils;

const POPUP_AUTOSTART_DELAY_MS = 2000;
const WAIT_FOR_ANSWER_TIMEOUT_MS = 10000;
const WAIT_FOR_ANSWER_INTERVAL_MS = 100;

const PX_ELEMENTS_COLOR = {
  primaryColor: {
    color: [
      '#back-button i.material-icons',
      'div.login h6',
      'span.match-counter__number',
      '.select-drug button#expand-button i',
      '#expand-button i.material-icons',
      '.xircles layer-message-list xircles-disclaimer-message-view p a',
      '.xircles .layer-choice-message-view button',
      'span.xircles-button.xircles-button-primary:not(:active)',
      'layer-send-button i.material-icons',
      '.xircles layer-message-status > i.material-icons.read',
      'layer-compose-bar .layer-button-panel button',
      'p.correction__content',
    ],
    'background-color': [
      'div.match-counter__circle',
      'span.xircles-button.xircles-button-primary:active',
      'layer-action-button.layer-action-button-selected button',
      '.xircles layer-message-item-sent .layer-root-viewer > layer-standard-message-view-container > .layer-card-top layer-text-message-view, .xircles layer-message-item-sent .layer-root-viewer.layer-card-width-any-width > layer-standard-message-view-container > .layer-card-top layer-text-message-view',
    ],
    'border-color': [
      'body layer-compose-bar .layer-compose-edit-panel',
      '.xircles .layer-choice-message-view button',
      'span.xircles-button.xircles-button-primary',
    ],
  },
  onBackgroundHighlightColor: {
    'background-color': [
      'em',
      '.select-drug',
      '.block-result-message__snippet em',
      '.select-drug button#expand-button',
      'xircles-blocks-result-message-view .block-result-message__collapse-item-label',
    ],
    'border-color': [
      'layer-message-list .layer-message-item__border-bottom::after',
      'layer-message-list .layer-message-item__border-top::before',
    ],
  },
  onBackgroundHighlightTextColor: {
    color: [
      'xircles-blocks-result-message-view .block-result-message__breadcrumbs li:last-of-type > *',
      '.block-result-message__show-more',
      '.select-drug #select-drug-label',
    ],
  },
};

// Extract the Layer XDK UI Components that are to be used in this Project
const { ConversationView, SendButton } = LayerReactComponents;

type Props = {
  history: any,
  location: any,
  match: any,
};

type State = {
  conversation: any,
  xirclesStatus: string,
  xirclesOperatorStatusFirst: string,
  xirclesOperatorStatusSecond: string,
  isLoaded: boolean,
  isAppMenuOpen: boolean,
  conversationId: string,
  showEditConversationDialog: boolean,
  editConversationId: string,
  tabSelected: string,
  startState: string,
  prevLocation: string,
};

class AnswerPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      conversationId: '',
      conversation: null,
      xirclesStatus: '',
      xirclesOperatorStatusFirst: '',
      xirclesOperatorStatusSecond: '',
      isLoaded: false,
      isAppMenuOpen: false,
      showEditConversationDialog: false,
      editConversationId: '',
      tabSelected: 'conversations', // 'conversations', 'bookmarks', 'info'
      startState: '',
      question: '',
      isTakeOverActive: false,
    };
    this.leftPanelFixed = false;
    this.query = null;
    this.identityQuery = null;
    this.isDeepLinkHandled = false;
    this.viewRef = React.createRef();
    this.additionalsRef = React.createRef();
    this.addPhotoRef = React.createRef();
    this.photoRef = React.createRef();
    this.searchRef = React.createRef();
    this.backRef = React.createRef();
  }

  isIOS12 = false;
  preventUpdate = false;

  /**
   * As part of loading this Component, verify that we have an authenticated Client.
   *
   * Redirect to the Login page if we do not.
   *
   * Note that your production applications will _not_ send users to a Login page if their Layer Client isn't authenticated,
   * it will authenticate for them based on the session token they have with your own servers. Only if your *own* session is not authenticated
   * would you send users to a Login page.  But... this is a Demo.
   */
  async componentWillMount() {
    const [version] = getIOSVersion();
    if (version && version === 12) {
      this.isIOS12 = true;
    }

    this.handlePXColors();

    const { layerClient, history, location } = this.props;
    window.addEventListener('xircles-push-history', (path) => {
      history.push({
        pathname: path,
        previousLocation: { pathname: location.pathname },
      });
    });
    layerClient.on(
      'challenge',
      (e) => {
        history.push({
          pathname: '/',
          previousLocation: { pathname: location.pathname },
        });
      },
      this
    );

    const conversationName = _get(this.props.match, 'params.conversationName');
    const conversationId = _get(this.props.match, 'params.conversationId');
    const productName = _get(this.props.match, 'params.productName');
    const hashParam = _get(this.props.match, 'params.hash');

    if (conversationName && productName) {
      const {
        data: { drugs: products },
      } = await wrapCatch(getDrugsCatalogue(null, null, true));

      if (products) {
        const product = products.find(
          (product) => product.brand_slug === productName
        );
        if (product) {
          const { data: productFaqs } = await wrapCatch(
            getProductFaqs(productName)
          );
          if (productFaqs.length > 0) {
            const conversation = productFaqs.find(
              (conversation) =>
                slugify(conversation.metadata.conversationName) ===
                  conversationName &&
                conversation.metadata.product === product.brand_slug &&
                conversation.metadata.isPublic &&
                conversation.metadata.isSeo
            );

            if (conversation) {
              if (isCrawler() || isReactSnap()) {
                this.waitForLayerClient(conversation.id);
              } else {
                const {
                  metadata: { conversationName, product },
                } = conversation;
                this.props.createConversation(conversationName, {
                  product,
                  productName,
                });
              }
            }
          }
        }
      }
    } else {
      if (conversationId && !this.checkHashParam(conversationId, hashParam)) {
        this.waitForLayerClient(conversationId);
      }
    }

    if (window.parentIFrame) {
      setTimeout(() => {
        if (!isQuestionsRoute(this.props.history.location.pathname)) {
          window.scrollTo(0, 0);
          const id = this.isIOS12 ? 'fullscreen-ios-12' : 'fullscreen';
          window.parentIFrame.sendMessage({ id });
        }
      }, 600);
    }
  }

  async componentDidMount() {
    window.addEventListener(CHOICE_ACTION_EVENTS.login, this.showLogin);
    window.addEventListener(
      CHOICE_ACTION_EVENTS.doccheck,
      this.showDocCheckLogin
    );
    window.addEventListener(
      CHOICE_ACTION_EVENTS.cancel,
      this.goToQuestionsPage
    );
    window.addEventListener(
      CHOICE_ACTION_EVENTS.reframe,
      this.goToQuestionsPageReframe
    );
    window.addEventListener(
      CHOICE_ACTION_EVENTS.confirm_register,
      this.showConfirmRegistration
    );
    window.addEventListener('reframe-question', this.goToQuestionsPageReframe);

    if (isAndroid()) {
      const [textArea] = document.querySelectorAll(
        'textarea[layer-id="input"]'
      );
      textArea &&
        textArea.addEventListener('focus', this.handlAnswerInputFocus);
      textArea && textArea.addEventListener('blur', this.handlAnswerInputBlur);
    }

    if (!isOperator(Layer.client.user)) {
      DRAG_AND_DROP_EVENTS.forEach((eventName) => {
        window.addEventListener(eventName, this.preventUploadByDragndrop, true);
      });
    }

    if (!this.isDeepLinkHandled && this.props.userId) {
      const conversationId = _get(this.props.match, 'params.conversationId');
      const hashParam = _get(this.props.match, 'params.hash');

      const isHashCompatible = this.checkHashParam(conversationId, hashParam);

      if (isHashCompatible) {
        this.isDeepLinkHandled = true;
        this.handleDeepLink(conversationId, this.props.userId);
      }
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    if (
      (this.state.xirclesStatus !== nextState.xirclesStatus &&
        nextState.xirclesStatus === XIRCLES_STATUSES.ARCHIVED) ||
      this.preventUpdate
    ) {
      this.preventUpdate = true;
      return false;
    }

    return true;
  }

  componentDidUpdate() {
    if (this.state.xirclesStatus === publicStatus.RESOLVED) {
      setTimeout(() => this.disableConversationItems(), 0);
    }
  }

  handlePXColors = () => {
    const pxColor =
      _get(this.props.history.location, 'state.detail.pxColor') ||
      this.props.pxColor;

    if (pxColor) {
      this.props.onPxColorChange(pxColor);

      Object.entries(PX_ELEMENTS_COLOR).map(([colorType, propertyElements]) =>
        Object.entries(propertyElements).map(([property, selectors]) =>
          selectors.forEach((selector) => {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.setAttribute('data-type', 'px-color-style');
            style.innerHTML = `${selector} { ${property}: ${pxColor[colorType]} !important; }`;

            document.getElementsByTagName('head')[0].appendChild(style);
          })
        )
      );
    }
  };

  componentWillUnmount() {
    const pxColorStyles = document.querySelectorAll(
      '[data-type="px-color-style"]'
    );
    if (pxColorStyles.length > 0) {
      pxColorStyles.forEach((style) => style.remove());
    }

    this.props.onPxColorChange(undefined);

    document.body.classList.remove('conversation-view');

    this.query && this.query.off();
    this.identityQuery && this.identityQuery.off();
    document.querySelector('html').removeAttribute('style');

    this.handleRemoveKnowledgeGraph();

    window.removeEventListener(CHOICE_ACTION_EVENTS.login, this.showLogin);
    window.removeEventListener(
      CHOICE_ACTION_EVENTS.doccheck,
      this.showDocCheckLogin
    );
    window.removeEventListener(
      CHOICE_ACTION_EVENTS.cancel,
      this.goToQuestionsPage
    );
    window.removeEventListener(
      CHOICE_ACTION_EVENTS.reframe,
      this.goToQuestionsPageReframe
    );
    window.removeEventListener(
      CHOICE_ACTION_EVENTS.confirm_register,
      this.showConfirmRegistration
    );
    window.removeEventListener(
      'reframe-question',
      this.goToQuestionsPageReframe
    );

    if (isAndroid()) {
      const [textArea] = document.querySelectorAll(
        'textarea[layer-id="input"]'
      );
      textArea &&
        textArea.removeEventListener('focus', this.handlAnswerInputFocus);
      textArea && textArea.addEventListener('blur', this.handlAnswerInputBlur);
    }

    if (!isOperator(Layer.client.user)) {
      DRAG_AND_DROP_EVENTS.forEach((eventName) => {
        window.removeEventListener(eventName, this.preventUploadByDragndrop);
      });
    }
  }

  getBrandBySlug = (slug) => {
    const { brands } = this.props;
    return (
      brands &&
      brands.find((brand) => slug === brand || slug === slugify(brand))
    );
  };

  getAcceptedAnswerText = () => {
    let acceptedAnswerText = '';

    const resultMessages = document.querySelectorAll(
      'layer-message-item-received.layer-message-item-xircles-results-message-view'
    );
    const simpleAnswers = document.querySelectorAll(
      'layer-message-item-received.layer-message-item-xircles-simple-answer-message-view'
    );

    const answers = [...resultMessages, ...simpleAnswers];

    if (answers.length > 0) {
      acceptedAnswerText = Array.from(answers).reduce(
        (acc, cur) => acc.concat(cur.innerText),
        ''
      );

      if (acceptedAnswerText.length > 0) {
        acceptedAnswerText = acceptedAnswerText.replace(
          'remove_circle_outline',
          ''
        );
      }
    }
    return acceptedAnswerText;
  };

  handleAddKnowledgeGraph = (
    conversation,
    time = WAIT_FOR_ANSWER_TIMEOUT_MS
  ) => {
    const answer = this.getAcceptedAnswerText();
    if (!answer && time > 0) {
      setTimeout(
        () =>
          this.handleAddKnowledgeGraph(
            conversation,
            time - WAIT_FOR_ANSWER_INTERVAL_MS
          ),
        WAIT_FOR_ANSWER_INTERVAL_MS
      );
      return;
    }
    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type': 'Question',
          name: conversation.metadata.conversationName,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer,
          },
        },
      ],
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  handleRemoveKnowledgeGraph = () => {
    const knowledgeGraph = document.querySelector(
      'script[type="application/ld+json"]'
    );
    return (
      knowledgeGraph &&
      knowledgeGraph.parentNode &&
      knowledgeGraph.parentNode.removeChild(knowledgeGraph)
    );
  };

  disableConversationItems = () => {
    const messagesToDisable = document.querySelectorAll(
      'layer-message-item-sent, layer-message-item-received:not(.layer-message-item-xircles-button-link-message-view)'
    );
    if (messagesToDisable.length > 0) {
      Array.from(messagesToDisable).map((message) =>
        message.classList.add('disabled-message')
      );
    }
  };

  removeBorder = () => {
    const [itemTop] = document.getElementsByClassName(
      'layer-message-item__border-top'
    );
    itemTop && itemTop.classList.remove('layer-message-item__border-top');
    const [itemBottom] = document.getElementsByClassName(
      'layer-message-item__border-bottom'
    );
    itemBottom &&
      itemBottom.classList.remove('layer-message-item__border-bottom');
    const [itemSides] = document.getElementsByClassName(
      'layer-message-item__border-sides'
    );
    itemSides && itemSides.classList.remove('layer-message-item__border-sides');
  };

  disableInput = () => {
    const [textArea] = document.getElementsByTagName('textarea');
    textArea && textArea.setAttribute('disabled', true);
  };

  handlAnswerInputFocus = () => {
    const [layerConversationView] = document.getElementsByTagName(
      'layer-conversation-view'
    );
    setTimeout(
      () =>
        (layerConversationView.style.maxHeight =
          document.body.offsetHeight + 'px'),
      350
    );
  };

  handlAnswerInputBlur = () => {
    const [layerConversationView] = document.getElementsByTagName(
      'layer-conversation-view'
    );
    layerConversationView.removeAttribute('style');
  };

  waitForLayerClient = (conversationId) => {
    const { layerClient } = this.props;

    if (layerClient.isReady) {
      this.prepareConversation(layerClient, conversationId);
    } else {
      layerClient.once('ready', () => {
        this.prepareConversation(layerClient, conversationId);
      });
    }
  };

  prepareConversation = async (layerClient, conversationId) => {
    this.state.conversation = layerClient
      .getConversation(conversationId, true)
      .on('conversations:loaded-error', ({ error }) => {
        const modalUrl =
          error.httpStatus === HTTP_STATUSES.NOT_FOUND.code
            ? 'unavailable-content'
            : error.httpStatus === HTTP_STATUSES.TOO_MANY_REQUESTS.code
            ? 'too-many-requests'
            : 'something-went-wrong';
        this.props.history.replace({
          pathname:
            this.props.history.action === 'POP'
              ? this.props.history.go(-1)
              : `/questions/${conversationId}/${modalUrl}`,
          state: { detail: { buttonTitle: 'COMMON_OK' } },
        });
      });

    this.setupConversation();
  };

  checkHashParam = (conversationId, hashParam) => {
    const checkUrl = `/questions/${conversationId}`;
    return shorthash.unique(checkUrl) === hashParam;
  };

  _showPopover = (pathFragment, detail) => {
    const { history } = this.props;
    if (!history.location.pathname.endsWith(pathFragment)) {
      history.push({
        pathname: history.location.pathname + '/' + pathFragment,
        state: { detail },
      });
    }
  };

  preventUploadByDragndrop = (e) => {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  };

  handleCorrectionContentClick = (originalText) => {
    // Fix missing drug selector for the new conversation with non-corrected search term
    const messageList = closestComponent(
      document.getElementsByTagName('layer-message-list')[0],
      'layer-message-list'
    );
    messageList.hasDrugSelector = false;

    this.props.layerClient.syncManager.once({
      'sync:success': () => {
        this.props.createConversation(originalText, {
          product: this.state.conversation.metadata.product,
          productName: this.state.conversation.metadata.productName,
        });
      },
    });

    this.state.conversation.delete(Layer.Constants.DELETION_MODE.ALL);
  };

  showLogin = () => {
    this._showPopover('login');
  };

  showDocCheckLogin = () => {
    this._showPopover('doccheck');
  };

  checkNotFoundFlow = (event) => {
    const notFoundChoicesIds = ['forward', 'reframe', 'cancel'];
    const choices = _get(event, 'detail.model.choices', []).map(
      (choice) => choice.id
    );

    return _isEqual(choices, notFoundChoicesIds);
  };

  goToQuestionsPage = () => {
    const isNotFoundFlow = this.checkNotFoundFlow(window.event);
    const { productName } = this.state.conversation.metadata;

    if (isNotFoundFlow) {
      this.onConversationDelete();
    } else {
      const pathname = productName
        ? '/medikamente/' + slugify(productName)
        : '/questions';
      this.props.history.push(pathname);
    }
  };

  goToQuestionsPageReframe = (e) => {
    const { location, push, replace } = this.props.history;
    const historyAction = e.type === 'reframe-question' ? replace : push;

    const prevLocation = _get(location, 'state.detail.prevLocation');

    this.props.layerClient.syncManager.once({
      'sync:success': () => {
        const { conversationName, goBackPath, product, isPX } =
          this.state.conversation.metadata;

        historyAction({
          pathname: prevLocation
            ? prevLocation
            : goBackPath
            ? goBackPath
            : product
            ? '/medikamente/' + slugify(product)
            : e.detail.goBackUrl,
          state: {
            detail: {
              conversationName,
              isProductXircle: isPX,
            },
          },
        });
      },
    });

    this.state.conversation.delete(Layer.Constants.DELETION_MODE.ALL);
  };

  showConfirmRegistration = async () => {
    const { layerClient, location } = this.props;
    try {
      const prevLocation = _get(location, 'state.detail.prevLocation');
      const isWelcomePrevLocation = /\/questions\/.{36}\/(welcome)/.test(
        prevLocation
      );

      if (!isWelcomePrevLocation) {
        const { userId: user_id } = layerClient.user;
        const { sessionToken: session_token } = layerClient;

        const {
          data: { email_address, phone_number },
        } = await wrapCatch(getIdentityById({ user_id, session_token }));
        const phoneNumberOrEmail = _isEmpty(email_address)
          ? phone_number
          : email_address;

        const detail = {
          modalType: WELCOME_MODAL_TYPES.REGISTER,
          phoneNumberOrEmail,
        };

        setTimeout(
          () => this._showPopover('code', detail),
          POPUP_AUTOSTART_DELAY_MS
        );
      }
    } catch (e) {
      // console.error('Error during getting Identity by id:', e);
    }
  };

  /**
   * Now that we have a Conversation, setup event handlers on it to detect when its loaded,
   * and when its `metadata` property has changed; metadata changes typically mean a Conversation Name change
   * that needs to be rerendered.
   *
   * Note: This works off of `this.conversation` as its input, and this value may be `null`
   */
  setupConversation(con) {
    const { layerClient } = this.props;
    const conversation = con || this.state.conversation;
    //console.log(con || this.state.conversation);
    // If the conversation is still loading, wait for it to finish, and then set isLoaded to true
    if (conversation && conversation.isLoading) {
      conversation.once('conversations:loaded', () => {
        this.setState(
          { isLoaded: true },
          this.handleAddKnowledgeGraph(conversation)
        );
      });
    } else if (conversation) {
      this.handleAddKnowledgeGraph(conversation);
    }

    // Watch for any changes to the metadata and update the conversationName
    if (conversation) {
      conversation.on(
        'conversations:change',
        (evt) => {
          if (evt.hasProperty('metadata')) {
            if (!_isEmpty(conversation.metadata)) {
              this.props.onConversationLoaded(
                !!conversation.metadata.documentId
              );
            }

            this.setState(
              {
                xirclesStatus: i18next.t(
                  translateInternalStatus(conversation.metadata.xircles_status)
                ),
                xirclesOperatorStatusFirst: i18next.t(
                  translatePvStatus(conversation.metadata.pv_status)
                ),
                xirclesOperatorStatusSecond: i18next.t(
                  translateOperatorInternalStatus(
                    conversation.metadata.xircles_status
                  )
                ),
                isTakeOverActive:
                  conversation.metadata.xircles_status ===
                  INTERNAL_STATUS.TAKEOVER,
              },
              () => {
                if (
                  !isOperator(Layer.client.user) &&
                  (conversation.metadata.xircles_status ===
                    INTERNAL_STATUS.TAKEOVER ||
                    conversation.metadata.xircles_status ===
                      INTERNAL_STATUS.ANSWER_DELIVERED)
                ) {
                  this.removeBorder();
                }
              }
            );
          }
        },
        this
      );

      if (!isOperator(Layer.client.user)) {
        this.identityQuery = layerClient.createQuery({
          model: Layer.Core.Query.Identity,
        });
        this.identityQuery.on('change', () => {
          //console.log(this.identityQuery.data);
        });
      }
    }

    if (!_isEmpty(conversation.metadata)) {
      this.props.onConversationLoaded(!!conversation.metadata.documentId);
    }

    // Setup our inital state
    this.setState({
      conversationId: conversation ? uuid(conversation.id) : '',
      conversation,
      xirclesStatus:
        conversation &&
        i18next.t(
          translateInternalStatus(conversation.metadata.xircles_status)
        ),
      xirclesOperatorStatusFirst:
        conversation &&
        i18next.t(translatePvStatus(conversation.metadata.pv_status)),
      xirclesOperatorStatusSecond:
        conversation &&
        i18next.t(
          translateOperatorInternalStatus(conversation.metadata.xircles_status)
        ),
      isTakeOverActive:
        conversation.metadata.xircles_status === INTERNAL_STATUS.TAKEOVER,
      isAnswerDeliveredActive:
        conversation.metadata.xircles_status ===
        INTERNAL_STATUS.ANSWER_DELIVERED,
      isLoaded: conversation && !conversation.isLoading,
    });
  }

  /**
   * Navigate to the modal window to share the conversation
   */
  onConversationShared = (title) => {
    const { conversationId } = this.props.match.params;
    if (isNotConfirmed(Layer.client.user)) {
      window.dispatchEvent(
        new CustomEvent('redirect-share-modal', {
          detail: {
            isRedirect: true,
            shareUrl: `/questions/${conversationId}/share`,
            title,
          },
        })
      );

      this.props.history.push(`/questions/${conversationId}/need-to-login`);
      return;
    }

    this.props.history.push({
      pathname: `/questions/${conversationId}/share`,
      state: { detail: { title } },
    });
  };

  /**
   * Navigate to the modal window to forward the conversation
   */
  onConversationForward = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/forward-to-medinfo`);
  };

  /**
   * Navigate to the modal window to deliver answer
   */
  onDeliverAnswer = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/deliver-answer`);
  };

  /**
   * Navigate to the modal window to report PV
   */
  onConversationPVReporting = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/clear-pv`);
  };

  /**
   * Navigate to the modal window to close request
   */
  onConversationCloseRequest = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/close-request`);
  };

  /**
   * Navigate to the modal window to delete request
   */
  onConversationDeleteRequest = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/delete-request`);
  };

  /**
   * Navigate to the modal window to share the conversation
   */
  onConversationDelete = () => {
    const { prevLocation } = this.props;

    this.props.layerClient.syncManager.once({
      'sync:success': () => this.props.history.push(prevLocation),
    });

    this.state.conversation.delete(Layer.Constants.DELETION_MODE.ALL);
  };

  onConversationRestore = () => {
    const { conversationId } = this.props.match.params;
    const { conversation } = this.state;

    wrapCatch(
      updateTicket({
        external_id: conversationId,
        ticket: { xircles_status: conversation.metadata.prev_xircles_status },
      })
    );
  };

  onConversationArchive = () => {
    const { conversationId } = this.props.match.params;

    wrapCatch(
      updateTicket({
        external_id: conversationId,
        ticket: { xircles_status: XIRCLES_STATUSES.ARCHIVED },
      }).then(() => {
        const { productName } = this.state.conversation.metadata;
        const pathname = productName
          ? '/medikamente/' + slugify(productName)
          : '/questions';
        this.props.history.push(pathname);
      })
    );
  };

  onClickProvideContent = () => {
    const { conversationId } = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/provide-content`);
  };

  /**
   * Whenever properties change, determine if the Conversation ID has changed, and if so:
   *
   * * Unsubscribe to all events from the prior conversation
   * * Call setupConversation() with the new conversation
   */
  componentWillReceiveProps(props: Props) {
    if (
      !props.match.params.welcomeId &&
      this.props.match.params.conversationId !==
        props.match.params.conversationId
    ) {
      this.handleComposeBarVisibility();
      const conversationId = props.match.params.conversationId;
      const newConversation = conversationId
        ? this.props.layerClient.getConversation(conversationId)
        : null;
      if (this.state.conversation)
        this.state.conversation.off(null, null, this);
      this.setState({ conversation: newConversation });
      this.setupConversation(newConversation);
    }

    if (this.props.newConversationId !== props.newConversationId) {
      const [correction] = document.getElementsByClassName('correction');

      if (correction) {
        correction.style.display = 'none';
      }

      const [title] = document.getElementsByClassName('title');

      if (title) {
        title.classList.remove('title-with-correction');
      }

      this.props.history.replace(`/questions/${props.newConversationId}`);
    }

    if (
      !this.isDeepLinkHandled &&
      (this.props.userId !== props.userId || props.userId)
    ) {
      const conversationId = _get(this.props.match, 'params.conversationId');
      const hashParam = _get(this.props.match, 'params.hash');

      const isHashCompatible = this.checkHashParam(conversationId, hashParam);

      if (isHashCompatible) {
        this.isDeepLinkHandled = true;
        this.handleDeepLink(conversationId, props.userId);
      }
    }

    if (this.props.history.location.pathname === '/questions') {
      this.isGoBack = true;
    }
  }

  handleDeepLink = async (conversationId, userId) => {
    await addParticipantConversation(conversationId, userId).catch(() =>
      this.props.history.push('/questions')
    );

    this.props.history.replace(`/questions/${conversationId}`);
    this.waitForLayerClient(conversationId);
  };

  handleComposeBarVisibility = () => {
    const [showMore] = document.getElementsByClassName('show-more');
    showMore && showMore.classList.add('xircles-hide');
    //
    const [layerComposeBar] =
      document.getElementsByTagName('layer-compose-bar');
    layerComposeBar &&
      layerComposeBar.classList.remove('layer-compose-bar__border-top');
  };

  /**
   * Certain types of messages can be filtered out of the Conversation View.
   *
   * Note: this does not at this time filter them out of the Conversation List's Last Message.
   * Just return `false` to prevent a message from rendering.
   */
  filterMessages(message: any) {
    const model = message.createModel();
    return (
      !model ||
      !(model.getModelName() === 'ResponseModel' && !model.displayModel)
    );

    // Uncomment this to hide Response Messages sent by this user
    // return !(model.getModelName() === 'ResponseModel' && (message.sender === layerClient.user || !model.displayModel));
  }

  getTitle() {
    const { conversation } = this.state;
    let title;

    if (conversation) {
      if (conversation.isLoading) {
        title = '...';
      } else {
        title = conversation.metadata.conversationName;
      }
    }
    if (isFirefox() && title) {
      return title.replace(/&nbsp;/gm, ' ');
    }
    return title;
  }

  /**
   * The title of a Conversation is stored in `conversation.metadata.xircles_status`.
   */
  getStatus(type = 'xircle-status') {
    const {
      conversation: activeConversation,
      xirclesStatus,
      xirclesOperatorStatusFirst,
      xirclesOperatorStatusSecond,
    } = this.state;
    let status;

    if (activeConversation) {
      // If the conversation is loading from the server, then just hold off rendering anything other than a placeholder
      if (activeConversation.isLoading) {
        status = '...';
      } else {
        if (type === 'xircle-status') {
          // If there is a conversation xirclesStatus, use it
          if (xirclesStatus) {
            status = xirclesStatus;
          } else {
            status = i18next.t(
              translateInternalStatus(
                activeConversation.metadata.xircles_status
              )
            );
          }
        } else if (type === 'operator-first') {
          if (xirclesOperatorStatusFirst) {
            status = xirclesOperatorStatusFirst;
          } else {
            status = i18next.t(
              translatePvStatus(activeConversation.metadata.pv_status)
            );
          }
        } else {
          if (xirclesOperatorStatusSecond) {
            status = xirclesOperatorStatusSecond;
          } else {
            status = i18next.t(
              translateOperatorInternalStatus(
                activeConversation.metadata.xircles_status
              )
            );
          }
        }
      }
    } else {
      status = '...';
    }
    return status;
  }

  handleAdditionalsClick = () => {
    const [layerComposeBar] =
      document.getElementsByTagName('layer-compose-bar');
    layerComposeBar.classList.add('layer-compose-bar-transition');
    layerComposeBar.classList.add('layer-compose-bar--collapsed');
    setTimeout(() => (layerComposeBar.style.paddingLeft = '120px'), 0);

    this.additionalsRef.current &&
      this.additionalsRef.current.setAttribute('style', 'display: none;');

    this.addPhotoRef.current &&
      this.addPhotoRef.current.setAttribute('style', 'opacity: 1;');
    this.photoRef.current &&
      this.photoRef.current.setAttribute('style', 'opacity: 1;');
    this.searchRef.current &&
      this.searchRef.current.setAttribute('style', 'opacity: 1;');
    this.backRef.current &&
      this.backRef.current.setAttribute('style', 'opacity: 1;');
  };

  handleBackClick = () => {
    this.addPhotoRef.current &&
      this.addPhotoRef.current.setAttribute('style', 'opacity: 0;');
    this.photoRef.current &&
      this.photoRef.current.setAttribute('style', 'opacity: 0;');
    this.searchRef.current &&
      this.searchRef.current.setAttribute('style', 'opacity: 0;');
    this.backRef.current &&
      this.backRef.current.setAttribute('style', 'opacity: 0;');

    setTimeout(() => {
      const [layerComposeBar] =
        document.getElementsByTagName('layer-compose-bar');
      layerComposeBar.classList.remove('layer-compose-bar--collapsed');
      layerComposeBar.classList.add('layer-compose-bar-transition');
      setTimeout(() => (layerComposeBar.style.paddingLeft = '35px'), 0);

      this.additionalsRef.current &&
        this.additionalsRef.current.setAttribute('style', 'display: block;');
    }, 200);
  };

  /**
   * The Conversation View allows for various regions of its panels and the Message Items in its list to be customized.
   *
   * Gather those customizations here.
   *
   * Initially we just add a Send Button, File Upload Button and our Samples Menu button to the Compose Bar.
   */
  customizeConversationView() {
    return {
      composerButtonPanelRight: () => {
        if (!isOperator(Layer.client.user)) {
          return (
            <div>
              <div className="composer-button-panel__actions">
                <button
                  id="additionals"
                  ref={this.additionalsRef}
                  onClick={this.handleAdditionalsClick}
                >
                  <i className="material-icons">add</i>
                </button>
                <button id="add_photo" ref={this.addPhotoRef} disabled>
                  <i className="material-icons material-icons__disabled">
                    add_a_photo
                  </i>
                </button>
                <button id="photo" ref={this.photoRef} disabled>
                  <i className="material-icons material-icons__disabled">
                    photo
                  </i>
                </button>
                <button
                  id="back"
                  ref={this.backRef}
                  onClick={this.handleBackClick}
                >
                  <i className="material-icons">keyboard_arrow_left</i>
                </button>
              </div>
              <SendButton text="">
                <i className="material-icons">send</i>
              </SendButton>
            </div>
          );
        } else {
          return (
            <div>
              <div className="composer-button-panel__actions">
                <button
                  id="additionals"
                  ref={this.additionalsRef}
                  onClick={this.handleAdditionalsClick}
                >
                  <i className="material-icons">add</i>
                </button>
                <button id="photo" ref={this.photoRef} disabled>
                  <i className="material-icons material-icons__disabled">
                    photo
                  </i>
                </button>
                <button
                  id="search"
                  ref={this.searchRef}
                  onClick={this.onClickProvideContent}
                >
                  <i className="material-icons">search</i>
                </button>
                <button
                  id="back"
                  ref={this.backRef}
                  onClick={this.handleBackClick}
                >
                  <i className="material-icons">keyboard_arrow_left</i>
                </button>
              </div>
              <SendButton text="">
                <i className="material-icons">send</i>
              </SendButton>
            </div>
          );
        }
      },
    };
  }

  generateMenu() {
    const { conversation } = this.state;
    if (conversation) {
      return getMenuOptions(conversation);
    }
  }

  // Support use of the PDF Custom Message Type by detecting when the File Upload Widget receives a PDF file.
  filesSelected(evt: CustomEvent) {
    customFileSelector(evt, this.state.conversation);
  }

  // Handle Take over button click
  onClickTakeOver = () => {
    const { userId } = this.props;
    const { isTakeOverActive, conversation, conversationId } = this.state;
    if (isTakeOverActive) {
      document.body.dispatchEvent(
        new CustomEvent('xircles-control-message-send', {
          detail: {
            conversationId: conversationId,
            eventName: 'BOT_RESUME',
            data: {},
          },
        })
      );
      conversation.setMetadataProperties({
        xircles_status: conversation.metadata.last_xircles_status,
      });
      conversation.removeParticipants([userId]);
    } else {
      document.body.dispatchEvent(
        new CustomEvent('xircles-control-message-send', {
          detail: {
            conversationId: conversationId,
            eventName: 'BOT_SUSPEND',
            data: {},
          },
        })
      );

      conversation.setMetadataProperties({
        last_xircles_status: conversation.metadata.xircles_status,
        xircles_status: INTERNAL_STATUS.TAKEOVER,
      });
      conversation.addParticipants([userId]);
    }

    this.viewRef.current.node.trigger('xircles-set-keyboard', {
      visibility: isTakeOverActive ? 'hidden' : 'visible',
      conversation,
    });
    this.setState({ isTakeOverActive: !isTakeOverActive });
  };

  handleArrowClick = (e, history) => {
    window.dispatchEvent(
      new CustomEvent('tr-event', { detail: { target: e.target } })
    );
    const startPageMode = _get(
      this.props.themeSettings,
      'startPageMode',
      START_PAGE_MODES.ALL_PRODUCTS
    );
    const goBackUrl =
      startPageMode === START_PAGE_MODES.SINGLE_PRODUCT ? '/questions' : '/';
    const { product } = this.state.conversation.metadata;
    const { prevLocation } = _get(this.props.location, 'state.detail', {});

    // handle direct link to the page
    if (history.length <= 3) {
      if (product) {
        history.replace(
          prevLocation
            ? prevLocation
            : product
            ? '/medikamente/' + slugify(product)
            : goBackUrl
        );
      } else {
        history.replace(goBackUrl);
      }
    } else {
      history.goBack();
    }
  };

  render() {
    const { history, layerClient } = this.props;
    const {
      question,
      xirclesStatus,
      xirclesOperatorStatusFirst,
      xirclesOperatorStatusSecond,
      conversationId,
      conversation,
      showEditConversationDialog,
      isTakeOverActive,
    } = this.state;
    // Setup the CSS Classes for the root element
    let questionOriginal = question || this.getTitle();
    let rootClasses = 'answerPage';
    const activeConversationId = 'layer:///conversations/' + conversationId;

    if (conversationId || showEditConversationDialog)
      rootClasses += ' has-conversation';
    if (isMobileDevice()) rootClasses += ' mobile-view';
    if (conversation && conversation.metadata && conversation.metadata.product)
      rootClasses += ' product';
    if (isIE()) rootClasses += ' ie';
    if ((xirclesStatus || this.getStatus()) === 'archived')
      rootClasses += ' archived';
    return (
      layerClient.isAuthenticated &&
      conversationId && (
        <div className={rootClasses} ref={(r) => r && r.classList.add('open')}>
          <div
            className={`question-panel ${
              isTabletDevice() ? 'question-panel-tablet' : ''
            }`}
          >
            <HelmetWrapper
              conversation={conversation}
              product={this.getBrandBySlug(conversation.metadata.product)}
              location={this.props.location.pathname}
            />
            <Header
              {...this.props}
              onClickArrow={(e) => this.handleArrowClick(e, history)}
              onClickShare={this.onConversationShared}
              onClickDelete={this.onConversationDelete}
              onClickRestore={this.onConversationRestore}
              onArchiveRequest={this.onConversationArchive}
              onClickForwardToMedinfo={this.onConversationForward}
              onClickClearPV={this.onConversationPVReporting}
              onClickDeliverAnswer={this.onDeliverAnswer}
              onClickCloseRequest={this.onConversationCloseRequest}
              onClickDeleteRequest={this.onConversationDeleteRequest}
              onCorrectionClick={this.handleCorrectionContentClick}
              onClickProvideContent={this.onClickProvideContent}
              question={questionOriginal}
              status={xirclesStatus || this.getStatus()}
              operatorStatusFirst={
                xirclesOperatorStatusFirst || this.getStatus('operator-first')
              }
              operatorStatusSecond={
                xirclesOperatorStatusSecond || this.getStatus('operator-second')
              }
              isUserOperator={isOperator(layerClient.user)}
              isUserHCP={isHcp(layerClient.user)}
              onClickTakeOver={this.onClickTakeOver}
              isTakeOverActive={isTakeOverActive}
              isGoBack={this.isGoBack}
            />
            <ConversationView
              ref={this.viewRef}
              queryFilter={(message) => this.filterMessages(message)}
              composePlaceholder={i18next.t('COMMON_WRITE_HERE')}
              replaceableContent={this.customizeConversationView()}
              // Uncomment this line to add date separators that render between messages sent on different dates
              // onRenderListItem={dateSeparator}
              conversationId={activeConversationId}
              onSendMessage={this.disableInput}
            />
            {_get(this.props.themeSettings, 'isMenuVisible', false) && (
              <MenuContainer />
            )}
            {_get(this.props.themeSettings, 'isFooterVisible', false) && (
              <BrandFooter />
            )}
          </div>
        </div>
      )
    );
  }
}

const AnswerPageWithConversationManager = conversationManager(AnswerPage);
export default withRouter(AnswerPageWithConversationManager);
