import INTERNAL_STATUS from '../../const/status/internal-status';
import PUBLIC_STATUS from '../../const/status/public-status';

export default (status) => {
  switch (status) {
    case INTERNAL_STATUS.NOT_FINISHED:
      return PUBLIC_STATUS.OPEN;
    case INTERNAL_STATUS.REFRAMED:
      return PUBLIC_STATUS.OPEN;
    case INTERNAL_STATUS.FORWARD_REQUESTED:
      return PUBLIC_STATUS.IN_PROGRESS;
    case INTERNAL_STATUS.FORWARDED:
      return PUBLIC_STATUS.IN_PROGRESS;
    case INTERNAL_STATUS.TAKEOVER:
      return PUBLIC_STATUS.IN_PROGRESS;
    case INTERNAL_STATUS.ANSWER_DELIVERED:
      return PUBLIC_STATUS.ANSWER_DELIVERED;
    case INTERNAL_STATUS.HELPFUL:
      return PUBLIC_STATUS.RESOLVED;
    case INTERNAL_STATUS.CLOSED:
      return PUBLIC_STATUS.CLOSED;
    case INTERNAL_STATUS.DELETED:
      return PUBLIC_STATUS.DELETED;
    default:
      return status;
    //TODO throw an error
  }
};
