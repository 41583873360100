// @flow

import * as React from 'react';
import InstructionItem from './InstructionItem';

import './styles.less';

type Props = {
  children?: Object | string,
  className?: string,
  appUrl: string,
  linkColor: string,
  patientLink: string,
  healthcareLinks: Array<Object>,
  links: Array<Object>,
  informationForHCPs: string,
  patientInformationCard: string,
};

const InstructionCard = (props: Props) => (
  <div className={props.className}>
    <InstructionItem
      appUrl={props.appUrl}
      title={props.patientInformationCard}
      links={props.links}
      linkColor={props.linkColor}
    />
    <InstructionItem
      appUrl={props.appUrl}
      title={props.informationForHCPs}
      linkColor={props.linkColor}
      links={props.healthcareLinks}
    />
  </div>
);

export default InstructionCard;
