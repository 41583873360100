const LayerReplaceableContentMixin = {
  methods: {
    /**
     * The originalChildNodes of this Replaceable Content Component can be restored.
     *
     * These were removed by Layer.UI.Component.createdCallback, but since there are no replaceableContents to replace them with,
     * allow them to be restored.
     *
     * TODO: Find a way to prevent their removal until they really need to be removed; this can mean overriding a Layer.UI.Component
     * method that removes these nodes for any other UI Component, but which is overridden for this Component.
     *
     * @method _restoreOriginalChildNodes
     * @private
     */
    _restoreOriginalChildNodes() {
      if (this.properties.originalChildNodes) {
        // Add the original nodes back in
        this.properties.originalChildNodes.forEach((item) =>
          this.nodes.content.appendChild(item)
        );
        // Remove this stale array
        delete this.properties.originalChildNodes;
      }

      // Make sure that any nodes with a layer-id are properly linked in the parent component
      // Note that onReplaceableContentAdded() will not get called to add these the way it is
      // called for any replaceable content that is inserted
      this._findNodesWithin(this, (node, isComponent) => {
        const layerId = node.getAttribute && node.getAttribute('layer-id');
        if (layerId && this.parentComponent)
          this.parentComponent.nodes[layerId] = node;

        // If its a UI Component and not some generic DOM node, setup the originalChildNode's parentComponent pointer as well
        if (isComponent) {
          if (!node.properties) node.properties = {};
          node.properties.parentComponent = this.parentComponent;
        }
      });
    },
  },
};

export default LayerReplaceableContentMixin;
