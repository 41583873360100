// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _get from 'lodash/get';

import MaterialIcon from '@material/react-material-icon';
import IconButton from '../../../material-components/IconButton';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '../../../material-components/Dialog';

import ExternalDisclaimerModalFragment from '../ExternalDisclaimerModalFragment';
import IEWarningModalFragment from '../IEWarningModalFragment';
import LoginWasRemovedModalFragment from '../LoginWasRemovedModalFragment';
import NetworkErrorModalFragment from '../NetworkErrorModalFragment';
import SaveChangesModalFragment from '../SaveChangesModalFragment';
import SomethingWentWrongModalFragment from '../SomethingWentWrongModalFragment';
import UnavailableContentModalFragment from '../UnavailableContentModalFragment';
import TooManyRequestsFragment from '../TooManyRequestsFragment';
import NoProductFragment from '../NoProductFragment';
import FutureProductFragment from '../FutureProductFragment';
import DataPrivacyFragment from '../DataPrivacyFragment';
import PrivacyNoticeFragment from '../PrivacyNoticeFragment';
import TermsOfUseFragment from '../TermsOfUseFragment';

import { INFO_MODAL_PATHS } from '../../../containers/InfoModalContainer';

import './styles.less';

type Props = {
  isOpen: boolean,
  conversationId: string,
  onToggle: Function,
  t: Function,
};

type State = {};

class InfoModal extends React.Component<Props, State> {
  handleIEWarningFragment = () => (
    <IEWarningModalFragment onContinue={this.onClose} />
  );

  handleNetworkErrorFragment = () => (
    <NetworkErrorModalFragment onRetry={this.onClose} />
  );

  handleTooManyRequestsFragment = () => <TooManyRequestsFragment />;

  handleNoProductFragment = () => <NoProductFragment />;

  handleFutureProductFragment = () => (
    <FutureProductFragment onClose={this.onClose} />
  );

  handleExternalDisclaimerFragment = ({ history }) => {
    const redirectUrl = _get(this.props.location, 'state.detail.redirectUrl');

    if (!redirectUrl) {
      return history.goBack();
    }

    return (
      <ExternalDisclaimerModalFragment
        onContinue={(e) => {
          window.open(redirectUrl, '_blank').focus();

          this.onClose(e);
        }}
        onCancel={this.onClose}
      />
    );
  };

  handleLoginWasRemoved = () => {
    const loginType = _get(this.props.location, 'state.detail.loginType');

    return (
      <LoginWasRemovedModalFragment
        loginType={loginType}
        onContinue={this.onClose}
      />
    );
  };

  handleSaveChangesFragment = () => {
    const {
      backUrl,
      isUserValid,
      nextFlow,
      saveChangesData,
      saveChangesEvent,
    } = _get(this.props.location, 'state.detail', {});

    if (!nextFlow) {
      this.onClose();
    }

    return (
      <SaveChangesModalFragment
        onAgree={() => {
          if (isUserValid) {
            window.dispatchEvent(
              new CustomEvent(saveChangesEvent, { detail: saveChangesData })
            );
            this.props.history.replace(nextFlow);
          } else {
            this.props.history.replace({
              pathname: backUrl,
              hash: nextFlow.hash,
            });
          }
        }}
        onDismiss={() => this.props.history.replace(nextFlow)}
      />
    );
  };

  handleSomethingWentWrongFragment = () => (
    <SomethingWentWrongModalFragment onRetry={this.onClose} />
  );

  handleUnavailableContentFragment = () => {
    const buttonTitle = _get(
      this.props.location,
      'state.detail.buttonTitle',
      'COMMON_RETRY'
    );

    return (
      <UnavailableContentModalFragment
        buttonTitle={buttonTitle}
        onRetry={this.onClose}
      />
    );
  };

  handleDataPrivacyFragment = () => <DataPrivacyFragment />;

  handlePrivacyNoticeFragment = () => (
    <PrivacyNoticeFragment onRetry={this.onClose} />
  );

  handleTermsOfUseFragment = () => (
    <TermsOfUseFragment onRetry={this.onClose} />
  );

  onClose = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.props.onToggle(ev);
  };

  renderCloseButton() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '-11px',
          right: '0px',
          color: 'xSlateLighter',
        }}
      >
        <IconButton
          id="close-info-modal"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (event) => {
      this.props.isOpen && this.props.onToggle(event);
    });
  }

  render() {
    const {
      t,
      isOpen,
      onToggle,
      isTermsOfUse,
      isCustomWrapper,
      isPrivacyNotice,
    } = this.props;

    return (
      <Dialog
        className={`xircles-info-dialog ${
          isCustomWrapper ? 'xircles-info-dialog--custom' : ''
        } ${isPrivacyNotice ? 'xircles-info-dialog__privacy-notice' : ''} ${
          isTermsOfUse ? 'xircles-info-dialog__terms-of-use' : ''
        }`}
        open={isOpen}
        onClose={onToggle}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {t(
            isPrivacyNotice
              ? 'MODAL_PRIVACY_NOTICE'
              : isTermsOfUse
              ? 'COMMON_GENERAL_TERMS'
              : 'MODAL_INFO_TITLE'
          )}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          <div className="my-wrapper">
            <section className="route-section">
              <Switch location={this.props.location}>
                <Route
                  exact
                  path={INFO_MODAL_PATHS.IE_WARNING}
                  render={this.handleIEWarningFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.NETWORK_ERROR}
                  render={this.handleNetworkErrorFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.EXTERNAL_DISCLAIMER}
                  render={this.handleExternalDisclaimerFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.LOGIN_WAS_REMOVED}
                  render={this.handleLoginWasRemoved}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.SAVE_CHANGES}
                  render={this.handleSaveChangesFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.SOMETHING_WENT_WRONG}
                  render={this.handleSomethingWentWrongFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.UNAVAILABLE_CONTENT}
                  render={this.handleUnavailableContentFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.TOO_MANY_REQUESTS}
                  render={this.handleTooManyRequestsFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.NO_PRODUCT}
                  render={this.handleNoProductFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.FUTURE_PRODUCT}
                  render={this.handleFutureProductFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.DATA_PRIVACY}
                  render={this.handleDataPrivacyFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.PRIVACY_NOTICE}
                  render={this.handlePrivacyNoticeFragment}
                />
                <Route
                  exact
                  path={INFO_MODAL_PATHS.TERMS_OF_USE}
                  render={this.handleTermsOfUseFragment}
                />
              </Switch>
            </section>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(InfoModal));
