//function getMenuOptions() { return []; }
//function customFileSelector() {}
//export { getMenuOptions, customFileSelector };

import { Layer } from '../get-layer';
import './xircles-message-view-container/xircles-xircle-message-view-container';
import './xircles-xircle-overview-message/xircles-xircle-overview-message-view';
//import './xircles-text-message/text-message-model';
import './xircles-question-message/question-message-model';
import './xircles-question-message/xircles-question-message-view';
import './xircles-related-question-message/related-question-message-model';
import './xircles-related-question-message/xircles-related-question-message-view';
import './xircles-matched-questions-message/matched-questions-message-model';
import './xircles-matched-questions-message/xircles-matched-questions-message-view';
import './xircles-related-questions-message/xircles-related-questions-message-view';
import './xircles-text-link-message/text-link-message-model';
import './xircles-text-link-message/xircles-text-link-message-view';
import './xircles-button-link-message/button-link-message-model';
import './xircles-button-link-message/xircles-button-link-message-view';
import './xircles-long-copy-message/xircles-long-copy-message-model';
import './xircles-long-copy-message/xircles-long-copy-message-view';
import './xircles-content-card-message/xircles-content-card-message-model-swipe';
import './xircles-content-card-message/xircles-content-card-message-view-swipe';
import './xircles-content-card-message/xircles-content-card-message-model';
import './xircles-content-card-message/xircles-content-card-message-view';
import './xircles-confirm-choice-message/confirm-choice-message-model';

import './xircles-disclaimer-message/disclaimer-message-model';
import './xircles-disclaimer-message/xircles-disclaimer-message-view';

import './xircles-doccheck-message/doccheck-message-model';
import './xircles-doccheck-message/xircles-doccheck-message-view';

import './xircles-checkbox-message/checkbox-message-model';
import './xircles-checkbox-message/xircles-checkbox-message-view';

import './xircles-control-message/control-message-model';
import './xircles-control-message/xircles-control-message-view';

import './xircles-result-message/result-message-model';
import './xircles-result-message/xircles-blocks-result-message-view';
import './xircles-results-message/results-message-model';
import './xircles-results-message/xircles-results-message-view';
import './xircles-select-drug/xircles-select-drug-view';

import './xircles-simple-answer-message/simple-answer-message-model';
import './xircles-simple-answer-message/xircles-simple-answer-message-view';

import './xircles-reference-message/reference-message-model';
import './xircles-reference-message/xircles-reference-message-view';
// import XirclesContentCardModelSwipe from "./xircles-content-card-message/xircles-content-card-message-model-swipe";

import './xircles-link-message/link-message-model';
import './xircles-file-message/file-message-model';

import './xircles-optimise-it-text-message/optimise-it-text-message-model';
import './xircles-optimise-it-text-message/xircles-optimise-it-text-message-view';

function getMenuOptions(conversation) {
  return [
    // {
    //   text: 'Create Custom Opinion Message (web only)',
    //   method: function() {
    //     const OpinionModel = Layer.Core.Client.getMessageTypeModelClass('OpinionModel');
    //     const model = new OpinionModel({
    //       comment: 'I love this stuff',
    //       rating: 4,
    //       description: 'Mary had a little lamb, little lamb, little lamb.  Mary had a little lamb, who made a tasty stew!',
    //       author: 'Frodo the Dodo',
    //     });
    //     model.send({ conversation });
    //   },
    // },
    {
      text: 'Create Profile Card Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          url: '/link-to-profile',
          title: 'Representative',
          imageUrl:
            'https://layer.com/wp-content/uploads/2017/07/bezier-blog-header-2x.png',
          description: 'Rainer Emig',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create DocCheck Message',
      method: function () {
        const XirclesDocCheckModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesDocCheckModel'
        );
        const model = new XirclesDocCheckModel({
          enabledFor: Layer.client.user.id,
          label: '',
          choices: [
            {
              text: 'DocCheck',
              id: 'true',
            },
            {
              text: "I'm not a Doctor",
              id: 'false',
            },
          ],
          responseName: 'isDocCheckHCP',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles Checkbox Message',
      method: function () {
        const XirclesCheckboxModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesCheckboxModel'
        );
        const model = new XirclesCheckboxModel({
          enabledFor: Layer.client.user.id,
          label: '<i class="asterisk">*</i> obligatory to use the service',
          allowReselect: false,
          allowMultiselect: true,
          choices: [
            {
              id: 'dps',
              text: 'Yes, I agree that Medicinisto collects, stores and uses my data for the purpose of more comfortable use of the website. <a href="">Data privacy statement</a> <i class="asterisk">*</i>',
            },
            {
              id: 'tac',
              text: 'I have read and accepted the <a href="">terms and conditions</a> <i class="asterisk">*</i>',
            },
            {
              id: 'contact',
              text: 'Medicinisto needs your permission to contact you via email or short message service. <i class="asterisk">*</i>',
            },
          ],
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles OK Button',
      method: function () {
        const XirclesButtonLinkModel =
          Layer.Core.Client.getMessageTypeModelClass('XirclesButtonLinkModel');
        const model = new XirclesButtonLinkModel({
          title: 'OK',
          url: '/questions/',
          align: 'center',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles Question Message',
      method: function () {
        const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesQuestionModel'
        );
        const model = new XirclesQuestionModel({
          customData: { doo: 'it' },
          text: 'Ich hätte da mal ne voll wichtige Frage',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles matched Questions Message',
      method: function () {
        const XirclesMatchedQuestionsModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesMatchedQuestionsModel'
          );
        const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesQuestionModel'
        );
        const model = new XirclesMatchedQuestionsModel({
          text: 'Häh, wat meenste?',
          questions: [
            new XirclesQuestionModel({
              text: 'Ditte?',
            }),
            new XirclesQuestionModel({
              text: 'Dette?',
            }),
          ],
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Results Message',
      method: function () {
        const XirclesResultsMessageModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesResultsMessageModel'
          );
        const XirclesResultMessageModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesResultMessageModel'
          );
        const CarouselModel =
          Layer.Core.Client.getMessageTypeModelClass('CarouselModel');
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const XirclesContentCardModel =
          Layer.Core.Client.getMessageTypeModelClass('XirclesContentCardModel');
        const cModel = new CarouselModel({
          items: [
            new XirclesContentCardModel({
              header: 'Dupixent and the desire to have children',
              text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at autem beatae consequatur, consequuntur dolorem excepturi magnam magni molestias natus nobis omnis quasi quibusdam quod repellendus, rerum sunt tempora tenetur?',
              linkText: 'Link',
              url: '/documents/document',
            }),
            new XirclesContentCardModel({
              header: 'Dupixent and the desire to have children',
              text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at autem beatae consequatur, consequuntur dolorem excepturi magnam magni molestias natus nobis omnis quasi quibusdam quod repellendus, rerum sunt tempora tenetur?',
              linkText: 'Link',
              url: '/documents/document',
            }),
            new LinkModel({
              customData: { type: 'xircles-video-link' },
              url: '/video/self-injection-video',
              title: 'Video',
              imageUrl:
                'https://layer.com/wp-content/uploads/2017/07/bezier-blog-header-2x.png',
              description: 'Watch the mode of action in this video.',
            }),
            new XirclesContentCardModel({
              header: 'Dupixent and the desire to have children',
              text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at autem beatae consequatur, consequuntur dolorem excepturi magnam magni molestias natus nobis omnis quasi quibusdam quod repellendus, rerum sunt tempora tenetur?',
              linkText: 'Link',
              url: '/documents/document',
            }),
            new LinkModel({
              customData: { type: 'xircles-video-link' },
              url: '/video/self-injection-video',
              title: 'Video',
              imageUrl:
                'https://layer.com/wp-content/uploads/2017/07/bezier-blog-header-2x.png',
              description: 'Watch the mode of action in this video.',
            }),
          ],
        });

        const model = new XirclesResultsMessageModel({
          textFound: 'Häh, wat meenste?',
          results: [
            new XirclesResultMessageModel({
              type: 'qa_response',
              title: 'Whats the Question?',
              snippet: 'schnipp schnapp schnippet',
              url: 'dupi/dupi/du',
              tags: ['aaa', 'bbb', 'ccc'],
              matchedTags: ['aaa', 'ccc'],
            }),
            new XirclesResultMessageModel({
              type: 'flow_response',
              title: 'Order! Order! Order!',
              snippet: 'schnipp schnapp schnippet',
              url: 'dupi/dupi/du',
              tags: ['aaa', 'bbb', 'ccc'],
              matchedTags: ['aaa', 'ccc'],
            }),
            new XirclesResultMessageModel({
              type: 'content_response',
              title: '4.2 Da titke (document)',
              snippet: 'schnipp schnapp schnippet',
              url: 'dupi/dupi/du',
              tags: ['aaa', 'bbb', 'ccc'],
              matchedTags: ['aaa', 'ccc'],
            }),
          ],
          textRelated: 'Relli Relli bam bam',
          relatedContent: cModel,
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Empty Results Message',
      method: function () {
        const XirclesResultsMessageModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesResultsMessageModel'
          );
        const CarouselModel =
          Layer.Core.Client.getMessageTypeModelClass('CarouselModel');
        const cModel = new CarouselModel({
          items: [],
        });

        const model = new XirclesResultsMessageModel({
          textFound: 'Häh, wat meenste?',
          results: [],
          textRelated: 'Relli Relli bam bam',
          relatedContent: cModel,
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles related Questions Message',
      method: function () {
        const XirclesRelatedQuestionsModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesRelatedQuestionsModel'
          );
        const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesQuestionModel'
        );
        const model = new XirclesRelatedQuestionsModel({
          text: 'We found some rally much more interesting questions!',
          questions: [
            new XirclesQuestionModel({
              text: 'Does it relate?',
            }),
            new XirclesQuestionModel({
              text: 'Is this relative for me?',
            }),
            new XirclesQuestionModel({
              text: 'Do you love your relatives?',
            }),
          ],
        });
        model.send({ conversation });
      },
    },
    {
      text: 'No Thanks Button (RC)',
      method: function () {
        const ChoiceModel =
          Layer.Core.Client.getMessageTypeModelClass('ChoiceModel');
        const model = new ChoiceModel({
          customData: { action: 'thanks007' },
          enabledFor:
            'layer:///identities/528de023-79a6-44d5-9a50-feee96499a83',
          label: '',
          responseName: 'nothanks',
          choices: [{ text: 'No Thanks!', id: 'No Thanks!' }],
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Xircles related Question Message',
      method: function () {
        const XirclesRelatedQuestionModel =
          Layer.Core.Client.getMessageTypeModelClass(
            'XirclesRelatedQuestionModel'
          );
        const model = new XirclesRelatedQuestionModel({
          text: 'Would you relate this to that?',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Standard Link Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          url: 'https://layer.com/introducing-the-layer-conversation-design-system/',
          title: 'Introducing the Layer Conversation Design System',
          imageUrl:
            'https://layer.com/wp-content/uploads/2017/07/bezier-blog-header-2x.png',
          description:
            'The Layer Conversation Design System helps you imagine.',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Content Card Message',
      method: function () {
        const LongCopy = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesContentCardModelSwipe'
        );
        const model = new LongCopy({
          header: 'Dupixent and the desire to have children',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at autem beatae consequatur, consequuntur dolorem excepturi magnam magni molestias natus nobis omnis quasi quibusdam quod repellendus, rerum sunt tempora tenetur?',
          linkText: 'Link',
          url: '/documents/document',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Long Copy Message',
      method: function () {
        const LongCopy = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesContentCardModelSwipe'
        );
        const model = new LongCopy({
          url: '/long-copy/longcopy',
          question: 'Dupixent and the desire to have children',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda at autem beatae consequatur, consequuntur dolorem excepturi magnam magni molestias natus nobis omnis quasi quibusdam quod repellendus, rerum sunt tempora tenetur?',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Information Link Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          customData: { type: 'xircles-information-link' },
          url: '/documents/document',
          title: 'Introducing the Layer',
          description: 'The Layer Conversation Design System helps you.',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Trial Link Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          customData: { type: 'xircles-trial-link' },
          url: 'https://layer.com/introducing-the-layer-conversation-design-system/',
          title: 'Tagline (eg. study, trial)',
          description: 'Title of the content with up to three lines.',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Study Link Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          customData: { type: 'xircles-study-link' },
          url: 'https://layer.com/introducing-the-layer-conversation-design-system/',
          title: 'Tagline (eg. study, trial)',
          description:
            'Title of the content with up to three lines. Lorem ipsum dolor sit amet, consectetuer adipis …',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Video Link Message',
      method: function () {
        const LinkModel =
          Layer.Core.Client.getMessageTypeModelClass('LinkModel');
        const model = new LinkModel({
          customData: { type: 'xircles-video-link', elementType: 'single' },
          url: '/video/self-injection-video',
          title: 'Video',
          imageUrl:
            'https://layer.com/wp-content/uploads/2017/07/bezier-blog-header-2x.png',
          description: 'Watch the mode of action in this video.',
          from: 'not carousel',
        });
        model.send({ conversation });
      },
    },
    {
      text: 'Create Text Link Message',
      method: function () {
        const XirclesTextLinkModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesTextLinkModel'
        );
        const model = new XirclesTextLinkModel({
          url: 'https://layer.com/introducing-the-layer-conversation-design-system/',
          title: 'This is only a text.',
        });
        model.send({ conversation });
      },
    },
  ];
}

// TODO: This handles file selection using the File Upload Button, but
// does not handle PDF files that are dragged and dropped into the Conversation View.
function customFileSelector(evt, conversation, callback = undefined) {
  const files = evt.detail.files;
  const PDFModel = Layer.Core.Client.getMessageTypeModelClass('PDFModel');
  callback && callback(files);
  if (files.length === 1 && files[0].type === 'application/pdf') {
    evt.preventDefault();

    const model = new PDFModel({
      source: files[0],
      author: Layer.client.user.displayName,
      title: files[0].name,
    });
    model.send({ conversation });
    return;
  }
}

export { getMenuOptions, customFileSelector };
