// @flow

/**
 *      Creates stateless UI component for Menu
 *
 *      onClick         - handle click on <a>, NOT required
 *      text            - text in <a>, required
 *      href            - href in <a>, required
 *      iconName        - string for <i>, input values according your iconClassName, NOT required
 *      iconClassName   - name of <i> className, input name of className according your icon pack, NOT required
 *      className       - className for <li>, for better customizing you write styles for inner <a> and <i>,
 *                        .your-class-name {
 *                            // some styles for <li>
 *                            a {
 *                                // some styles for <a>
 *                            }
 *                            i {
 *                                // some styles for <i>
 *                            }
 *                        }
 *                        NOT required, BUT there are no default styles.
 */

import * as React from 'react';

import './styles.less';

type Props = {
  onClick?: Function,
  text: string,
  href: string,
  iconName?: string,
  iconClassName?: string,
  className?: string,
  target?: string,
};

const MenuButton = (props: Props) => {
  return (
    <li className={props.className}>
      <a target={props.target} href={props.href} onClick={props.onClick}>
        <i className={props.iconClassName}>{props.iconName}</i>
        {props.text}
      </a>
    </li>
  );
};

MenuButton.defaultProps = {
  href: '',
  onClick: null,
  text: '',
  iconName: '',
  iconClassName: '',
  className: '',
};

export default MenuButton;
