// @flow

/**
 * GTM - Triggers
 *
 * name: Click on action menu QP
 * fires on: Click element matches CSS selector div.xircles-handle
 *
 * name: Click on compose bar
 * fires on: Click Element matches CSS selector textarea[layer-id="input"]
 */

import * as React from 'react';
import ReactDom from 'react-dom';
import _get from 'lodash/get';
import i18next from 'i18next';

import './mixins/ClientMixin';

import Layer from '@layerhq/web-xdk';
import '@layerhq/web-xdk/ui/adapters/react';
import '@layerhq/web-xdk/ui/messages/status/layer-status-message-view';
import '@layerhq/web-xdk/ui/messages/receipt/layer-receipt-message-view';
import '@layerhq/web-xdk/ui/messages/choice/layer-choice-message-view';
import '@layerhq/web-xdk/ui/messages/carousel/layer-carousel-message-view';
import '@layerhq/web-xdk/ui/messages/buttons/layer-buttons-message-view';
import '@layerhq/web-xdk/ui/messages/file/layer-file-message-view';
import '@layerhq/web-xdk/ui/messages/location/layer-location-message-view';
import '@layerhq/web-xdk/ui/messages/product/layer-product-message-view';
import '@layerhq/web-xdk/ui/messages/feedback/layer-feedback-message-view';
import '@layerhq/web-xdk/ui/components/layer-send-button';
import '@layerhq/web-xdk/ui/components/layer-file-upload-button';
import '@layerhq/web-xdk/ui/components/layer-conversation-list';
import '@layerhq/web-xdk/ui/components/layer-identity-list';
import '@layerhq/web-xdk/ui/messages/text/layer-text-message-model';

import './polyfills';
import reportError from './common/reportError';
import striptags from 'striptags';

import {
  closeLiveChat,
  isDocumentsRoute,
  isProductRoute,
} from './utils/common';
import { HTTP_STATUSES, SERVICE_NAMES, QUERY_TYPES } from './const';
import { executeRecaptcha } from './common/recaptcha';

import Query from '@layerhq/web-xdk/core/queries/query';
import XirclesConversationsQuery from './layer/core/queries/xircles-conversations-query';
import MessagesQuery from '@layerhq/web-xdk/core/queries/messages-query';
import IdentitiesQuery from '@layerhq/web-xdk/core/queries/identities-query';

import { isUUID, getUUID } from './utils/common';

/**
 * VERSIONING:
 *
 * For backwards compatability for `WebXDK 1.0.0-pre` apps, include the following:
 */
// import '@layerhq/web-xdk/core/models/message-type-response-summary/message-type-response-summmary-v1';

/**
 * PERSISTENCE:
 *
 * Uncomment this line and change `isPersitenceEnabled` to `true` below to enable indexedDB
 * data caching. Note that user must log in with `isTrustedDevice` as `true` as well for
 * indexedDB to be used.
 */
import '@layerhq/web-xdk/core/db-manager';

/**
 *  THEMING:
 *
 * Pick from two themes provided:
 *
 * * The standard layer-basic-blue.css theme is the default
 * * Comment out layer-basic-blue an uncomment the two layer-groups files to enable the layer-groups theme
 */
// import '@layerhq/web-xdk/themes/layer-groups-customizations';
// import '@layerhq/web-xdk/themes/layer-groups.css'
// import '@layerhq/web-xdk/themes/layer-basic-blue.css'
//import './css/xircles-theme.css'
import './styles/theme.less';

import hideVirtualKeyboard from './hideVirtualKeyboard';

import { isBot, isOperator } from './utils/user';

import AvatarMixin from './mixins/AvatarMixin';
import ChoiceMessageViewMixin from './mixins/ChoiceMessageViewMixin';
import TextMessageViewMixin from './mixins/TextMessageViewMixin';
import LinkMessageViewMixin from './mixins/LinkMessageViewMixin';
import MessageListMixin from './mixins/MessageListMixin';
import MessageListBeforeMixin from './mixins/MessageListBeforeMixin';
import MessageListAfterMixin from './mixins/MessageListAfterMixin';
import ConversationLastMessageMixin from './mixins/ConversationLastMessageMixin';
import TypingIndicatorMixin from './mixins/TypingIndicatorMixin';
import ConversationItemMixin from './mixins/ConversationItemMixin';
import ConversationListMixin from './mixins/ConversationListMixin';
import MessageItemReceivedMixin from './mixins/MessageItemReceivedMixin';
import CarouselMessageViewMixin from './mixins/CarouselMessageViewMixin';
import MessageItemSentMixin from './mixins/MessageItemSentMixin';
import LayerMessageStatusMixin from './mixins/LayerMessageStatusMixin';
import LayerComposeBarMixin from './mixins/LayerComposeBarMixin';
import LayerImageMessageViewMixin from './mixins/LayerImageMessageViewMixin';
import LayerReplaceableContentMixin from './mixins/LayerReplaceableContentMixin';

//import './custom-message-types/xircles-text-message/text-message-model';

import ControlMessageModel from './custom-message-types/xircles-control-message/control-message-model';
import config from './config.json';
import INTERNAL_STATUS from './const/status/internal-status';
import LayerFileMessageViewMixin from './mixins/LayerFileMessageViewMixin';
import { isTouchDevice, isConversationRoute } from './utils/common';

const NOVARTIS_GERMANY_MEDICAL_REQUEST_FLOW =
  'novartisGermanyMedicalRequestFlow';

/**
 * INITIALIZATION:
 *
 * Initialize the Layer Client and Libraries.
 *
 * * Pass in your application ID.
 * * Note: A `challenge` event listener is required, but is provided elsewhere
 * * `isPersistenceEnabled` can be left out of typical apps. Most web applications should
 *   treat persisting of data as a security hazard. An example of an exception to this
 *   is a Cordova app installed on a phone.
 */
const layerClient = Layer.init({
  appId: config.LayerAppId,
  logLevel: config.Debug ? Layer.Constants.LOG.DEBUG : Layer.Constants.LOG.NONE,
  isPersistenceEnabled: false,
  useEmojiImages: false,
  mixins: {
    'layer-avatar': AvatarMixin,
    'layer-choice-message-view': ChoiceMessageViewMixin,
    'xircles-doccheck-message-view': ChoiceMessageViewMixin,
    'layer-text-message-view': TextMessageViewMixin,
    'layer-link-message-view': LinkMessageViewMixin,
    'layer-message-list': [
      MessageListBeforeMixin,
      MessageListMixin,
      MessageListAfterMixin,
    ],
    'layer-conversation-last-message': ConversationLastMessageMixin,
    'layer-typing-indicator': TypingIndicatorMixin,
    'layer-conversation-item': ConversationItemMixin,
    'layer-conversation-list': ConversationListMixin,
    'layer-message-item-received': MessageItemReceivedMixin,
    'layer-carousel-message-view': CarouselMessageViewMixin,
    'layer-message-item-sent': MessageItemSentMixin,
    'layer-message-status': LayerMessageStatusMixin,
    'layer-file-message-view': LayerFileMessageViewMixin,
    'layer-compose-bar': LayerComposeBarMixin,
    'layer-image-message-view': LayerImageMessageViewMixin,
    'layer-replaceable-content': LayerReplaceableContentMixin,
  },
});

// monkey patch to allow usage of custom queries
layerClient.createQuery = (options) => {
  if (
    [QUERY_TYPES.PUBLIC_QUESTIONS, QUERY_TYPES.PRODUCT_QUESTIONS].indexOf(
      options.model
    ) > -1
  ) {
    options.type = options.model;
    options.model = Query.Conversation;
  }
  let query;

  if (typeof options.build === 'function') {
    options = options.build();
  }

  switch (options.model) {
    case Query.Identity:
      query = new IdentitiesQuery(options);
      break;
    case Query.Conversation:
      query = new XirclesConversationsQuery(options); // custom query
      break;
    case Query.Channel:
      query = layerClient._createChannelsQuery(options);
      break;
    case Query.Membership:
      query = layerClient._createMembersQuery(options);
      break;
    case Query.Message:
      query = new MessagesQuery(options);
      break;
    case Query.Announcement:
      query = layerClient._createAnnouncementsQuery(options);
      break;

    default:
      query = new Query(options);
  }
  layerClient._addQuery(query);
  return query;
};

layerClient.url = config.LAYER_API;
layerClient.websocketUrl = config.LayerWebsocketUrl;

// monkey-patch to report layer errors to sentry
Layer.Utils.logger.___error = Layer.Utils.logger.error;
Layer.Utils.logger.error = (msg, obj) => {
  reportError(msg, obj);
  Layer.Utils.logger.___error(msg, obj);
};

document.body.addEventListener('xircles-choice-send', function (evt) {
  const conversation = layerClient.getConversation(
    evt.detail.conversationId,
    true
  );
  const XirclesConfirmChoiceModel = Layer.Core.Client.getMessageTypeModelClass(
    'XirclesConfirmChoiceModel'
  );
  const model = new XirclesConfirmChoiceModel({
    customData: evt.detail.customData,
    text: evt.detail.text,
  });
  model.send({ conversation });
});

document.body.addEventListener('xircles-question-send', function (evt) {
  if (evt.detail.text === `${i18next.t('REGISTER_NEED_HCP_CHECK')}`) {
    removeBorder();
    removeChoiceMessage();
  }

  const conversation = layerClient.getConversation(
    evt.detail.conversationId,
    true
  );
  const QuestionModel = Layer.Core.Client.getMessageTypeModelClass(
    'XirclesQuestionModel'
  );
  const model = new QuestionModel({
    text: striptags(evt.detail.text),
    slug: evt.detail.slug,
    data: evt.detail.data,
  });
  model.send({ conversation });
});

document.body.addEventListener('xircles-goto-url', function (evt) {
  const data = evt.detail;
  if (
    data.url.match(
      /flow-responses|predefined-answers|simple-answers|qa-responses/i
    )
  ) {
    document.body.dispatchEvent(
      new CustomEvent('xircles-question-send', {
        detail: {
          conversationId: data.conversationId,
          text: striptags(data.title),
          slug: data.url,
        },
      })
    );
  } else {
    if (data.url.match(/^[a-z]{3,10}:\/\//i)) {
      if (data.externalDisclaimer) {
        window.dispatchEvent(
          new CustomEvent('change-route', {
            detail: {
              url: window.location.pathname + '/external-disclaimer',
              state: {
                redirectUrl: data.url,
              },
            },
          })
        );
      } else {
        window.open(data.url, '_blank');
      }
    } else if (data.url.match(/(no|future)-product/i)) {
      const conversationName =
        isConversationRoute(window.location.pathname) &&
        evt.detail.conversationId
          ? getConversationName(evt.detail.conversationId)
          : undefined;
      const stepBackNumber =
        isConversationRoute(window.location.pathname) &&
        evt.detail.conversationId
          ? getStepBackNumber(evt.detail.conversationId)
          : undefined;
      document.dispatchEvent(
        new CustomEvent('tr-custom-event', {
          detail: {
            conversationId: evt.detail.conversationId,
            name: 'not-found-search-result',
          },
        })
      );
      window.dispatchEvent(
        new CustomEvent('change-route', {
          detail: {
            url: data.url,
            state: { conversationName, stepBackNumber },
          },
        })
      );
    } else if (data.url.match(/^\//i)) {
      window.dispatchEvent(
        new CustomEvent('change-route', {
          detail: {
            url: data.url.toLowerCase(),
            type:
              isProductRoute(data.url) || isDocumentsRoute(data.url)
                ? 'replace'
                : undefined,
            callback: isProductRoute(data.url)
              ? function () {
                  window.dispatchEvent(new CustomEvent('product-page-trigger'));
                }
              : undefined,
          },
        })
      );
    } else {
      window.dispatchEvent(
        new CustomEvent('change-route', {
          detail: {
            url: `/documents/${data.url}`,
          },
        })
      );
    }
  }
});

document.body.addEventListener('xircles-debug-watson', function (evt) {
  // if (!config.Debug) return;
  const data = evt.detail;
  console.table(data.intents);
  console.table(data.entities);
});

document.body.addEventListener('xircles-debug-xai', function (evt) {
  if (!config.Debug) return;
  const data = evt.detail;
  console.table(data.brands);
  console.table(data.headlines);
  console.log(data.logs);
  console.log({ allMatches: data.allMatches, allHeadlines: data.allHeadlines });
});

document.body.addEventListener('xircles-server-error', function (evt) {
  const conversationName =
    isConversationRoute(window.location.pathname) && evt.detail.conversationId
      ? getConversationName(evt.detail.conversationId)
      : undefined;

  if (config.Debug) {
    const data = evt.detail;
    console.error(data);
  }

  if (!window.location.pathname.match(/something-went-wrong/)) {
    window.dispatchEvent(
      new CustomEvent('change-route', {
        detail: {
          url: window.location.pathname + '/something-went-wrong',
          state: { conversationName },
        },
      })
    );
  }
});

document.body.addEventListener('xircles-bot-end', function (evt) {
  const data = evt.detail;
  layerClient.trigger('typing-indicator-change', {
    conversationId: data.conversationId,
    typing: [],
    paused: [layerClient.user],
  });
});

document.body.addEventListener('xircles-related-question-send', function (evt) {
  const conversation = layerClient.getConversation(
    evt.detail.conversationId,
    true
  );
  const RelatedQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
    'XirclesRelatedQuestionModel'
  );
  const model = new RelatedQuestionModel({
    text: evt.detail.id, // evt.detail.id or evt.detail.text
  });
  model.send({ conversation });
});

document.body.addEventListener('xircles-control-message-send', function (evt) {
  const conversation = layerClient.getConversation(
    evt.detail.conversationId,
    true
  );
  const XirclesControlModel = Layer.Core.Client.getMessageTypeModelClass(
    'XirclesControlModel'
  );
  const model = new XirclesControlModel({
    eventName: evt.detail.eventName,
    serviceName: SERVICE_NAMES.NAME_CLIENT,
    data: JSON.stringify({
      slug: evt.detail.data,
    }),
  });
  model.send({ conversation });
});

const rpEventHandlers = [
  {
    event: 'rp-no-agent-available',
    nextState: 'resetThroughInterrupt',
    subState: 'showSmthWentWrong',
  },
  {
    event: 'rp-agent-left',
    nextState: 'sayGoodBye',
  },
  {
    event: 'rp-timeout',
    nextState: 'resetThroughInterrupt',
    subState: 'showSmthWentWrong',
  },
];

/*
  It closes Live-Chat session if browser or its tab has been closed by the user
*/
window.addEventListener('beforeunload', (e) => closeLiveChat());

// We listen any changes on history.pushState to close active Live-Chat
// if user navigates out of the conversation page (e.g. clicking on Xircle Logo)
window.history.pushState = new Proxy(window.history.pushState, {
  apply: (target, thisArg, argArray) => {
    closeLiveChat();
    return target.apply(thisArg, argArray);
  },
});

/* Here we process some events that comming from RP Chat Service:
  1. rp-no-agent-available - Thrown when there is no free agent.
  2. rp-agent-left - Thrown when the agent leaves the session before the customer.
  3. rp-timeout - Thrown when there was a timeout.
  In cases 2 and 3 The chat is no longer to be considered running.
  We MUST call endChat() to return to a clean state.
*/
rpEventHandlers.forEach(({ event, nextState, subState }) => {
  document.addEventListener(event, function () {
    console.log({ event });
    if (window.rp.chat.HeadlessChat.chatIsActive()) {
      window.rp.chat.HeadlessChat.endChat();
    }

    const { pathname } = document.location;

    // Check if it's conversation page with UUID
    if (!isUUID(pathname)) {
      console.log('UUID not found.');
      return;
    }

    const [conversationId] = getUUID(pathname);

    // It calls Custom Flow handler to reset the flow to initial state
    document.body.dispatchEvent(
      new CustomEvent('xircles-control-message-send', {
        detail: {
          conversationId: conversationId,
          eventName: 'BOT_FLOW',
          data: {
            flow: NOVARTIS_GERMANY_MEDICAL_REQUEST_FLOW,
            state: nextState,
            subState,
          },
        },
      })
    );
  });
});

// It ends active chat session if user go to back page
window.onpopstate = () => {
  if (window.rp) {
    if (window.rp.chat.HeadlessChat.chatIsActive) {
      window.rp.chat.HeadlessChat.endChat();
    }
  }
};

/*
  Thrown when there is a new message.
  The payload is of type RPMessage.
  This includes messages from the agent, the system, AND the customer itself.
*/
document.addEventListener('rp-message', function (evt) {
  const allowedSenders = ['operator', 'info'];

  if (allowedSenders.includes(evt.detail.sender)) {
    const { pathname } = document.location;

    // Check if it's conversation page with UUID
    if (!isUUID(pathname)) {
      console.log('UUID not found.');
      return;
    }

    const [conversationId] = getUUID(pathname);

    // It redirects incoming message from RP Chat to Custom Flow to be displayed as "operator" message
    document.body.dispatchEvent(
      new CustomEvent('xircles-control-message-send', {
        detail: {
          conversationId: conversationId,
          eventName: 'BOT_FLOW',
          data: {
            flow: NOVARTIS_GERMANY_MEDICAL_REQUEST_FLOW,
            state: 'processIncomingMessage',
            text: evt.detail.text,
          },
        },
      })
    );
  }
});

// Redirect messages from layer to Headless Chat if it's active
document.body.addEventListener('layer-send-message', function (evt) {
  if (window.rp) {
    if (window.rp.chat.HeadlessChat.chatIsActive()) {
      // Send layer message to RP Chat as well
      window.rp.chat.HeadlessChat.sendMessage(evt.detail.notification.text);

      const [textarea] = document.getElementsByTagName('textarea');
      // Make TextArea element available after layer message is sent
      textarea.disabled = false;
      // Keep focus on it when the user sends a message
      textarea.focus();
    }
  }
});

document.addEventListener('set-conversation-metadata', function (evt) {
  const { pathname } = document.location;

  // Check if it's conversation page with UUID
  if (!isUUID(pathname)) {
    console.log('UUID not found.');
    return;
  }

  const [conversationId] = getUUID(pathname);
  const conversation = layerClient.getConversation(conversationId, true);
  conversation.setMetadataProperties({ ...evt.detail });
});

window.addEventListener(
  'message',
  (ev) => {
    const msg = ev.data;
    if (msg.text && msg.text.match(/xms-error/i)) {
      //console.error(msg);
      if (!window.location.pathname.match(/something-went-wrong/)) {
        window.dispatchEvent(
          new CustomEvent('change-route', {
            detail: { url: window.location.pathname + '/something-went-wrong' },
          })
        );
      }
    } else if (msg.text && msg.text.match(/too_many_requests/i)) {
      if (!window.location.pathname.match(/too-many-requests/)) {
        window.dispatchEvent(
          new CustomEvent('change-route', {
            detail: {
              url:
                window.location.pathname === '/'
                  ? '/too-many-requests'
                  : window.location.pathname + '/too-many-requests',
            },
          })
        );
      }
    }
  },
  false
);

// Lusten to these websocket events/messages in order to react to them
window.addEventListener('layer-ws-message', (evt) => {
  if (!evt.detail) {
    return;
  }
  switch (evt.detail.type) {
    // This one will be triggered backend side when there's a problem with session
    // For example, expired session when trying to communicate thru websockets
    case 'session-error':
      // Trigger a full challenge here, it will wipe out local storage and reauthenticate
      layerClient.trigger('challenge');
      break;
    default:
  }
});

window.addEventListener('execute-recaptcha', () =>
  executeRecaptcha(HTTP_STATUSES.TOO_MANY_REQUESTS.camelCaseMessage)
);

const getConversationName = (conversationId) =>
  _get(
    Layer.client,
    `_models.conversations[${conversationId}].metadata.conversationName`
  );

const getStepBackNumber = (conversationId) =>
  _get(
    Layer.client,
    `_models.conversations[${conversationId}].metadata.stepBackNumber`
  );

const removeBorder = () => {
  const [itemTop] = document.getElementsByClassName(
    'layer-message-item__border-top'
  );
  itemTop && itemTop.classList.remove('layer-message-item__border-top');
  const [itemBottom] = document.getElementsByClassName(
    'layer-message-item__border-bottom'
  );
  itemBottom &&
    itemBottom.classList.remove('layer-message-item__border-bottom');
  const [itemSides] = document.getElementsByClassName(
    'layer-message-item__border-sides'
  );
  itemSides && itemSides.classList.remove('layer-message-item__border-sides');
};

const removeChoiceMessage = () => {
  const [choiceMessage] = document.getElementsByClassName(
    'layer-message-item-layer-choice-message-view'
  );

  if (choiceMessage) {
    choiceMessage.destroy();
  }
};

const isChoiceLastMessage = ({ conversation }) => {
  if (conversation && conversation.lastMessage) {
    const messageIterator = conversation.lastMessage.parts.values();

    if (messageIterator) {
      const firstMessage = messageIterator.next();
      return (
        firstMessage.value.mimeType &&
        firstMessage.value.mimeType.includes('choice')
      );
    }
  }

  return false;
};

const setKeyboard = function (evt) {
  const data = evt.detail;
  const elem = document.querySelector('layer-compose-bar');
  const [textArea] = document.querySelectorAll('textarea[layer-id="input"]');
  const [typingIndicator] = document.getElementsByTagName(
    'layer-typing-indicator'
  );
  if (
    // hide composebar if
    // conversation or event demands it
    (data.conversation &&
      data.conversation.isMessageHideInput && // and conversation is not taken over by operator
      (!data.conversation ||
        data.conversation.metadata.xircles_status !==
          INTERNAL_STATUS.TAKEOVER) && // and the user is not a bot
      !isBot(layerClient.user) && // and there is no modal window to enter code
      !window.location.pathname.includes('/code')) || // or
    // conversation is not taken over by operator
    ((!data.conversation ||
      data.conversation.metadata.xircles_status !== INTERNAL_STATUS.TAKEOVER) && // and user is operator
      isOperator(layerClient.user)) ||
    (data.visibility === 'hidden' &&
      !isOperator(layerClient.user) &&
      data.conversation &&
      data.conversation.metadata.xircles_status !== INTERNAL_STATUS.TAKEOVER) ||
    isChoiceLastMessage(data) ||
    (data.conversation &&
      data.conversation.metadata &&
      data.conversation.metadata.owner !== layerClient.user.id)
  ) {
    textArea && textArea.setAttribute('disabled', true);
    elem && elem.classList.remove('enable-compose-bar');
    hideVirtualKeyboard();
  } else {
    if (
      textArea &&
      !typingIndicator.classList.contains('layer-typing-occuring')
    ) {
      textArea.removeAttribute('disabled');
      if (!isTouchDevice()) {
        setTimeout(() => textArea.focus(), 0);
      }
    }
    elem && elem.classList.add('enable-compose-bar');
  }
};

let setKeyboardDebounced = null;

document.body.addEventListener('xircles-set-keyboard', (evt) => {
  if (setKeyboardDebounced) {
    clearTimeout(setKeyboardDebounced);
  }
  setKeyboardDebounced = setTimeout(() => setKeyboard(evt), 150);
});

Layer.UI.handlers.text.register({
  name: 'html',
  order: 1000,
  handler: function (textData) {
    const txt = document.createElement('textarea');
    txt.innerHTML = textData.text;
    textData.text = txt.value;
  },
});

Layer.UI.UIUtils.registerStatusModel(ControlMessageModel);
Layer.UI.buildAndRegisterTemplate(
  'layer-conversation-item',
  `
  <div class="layer-conversation-item-wrapper" >
    <div class='layer-list-item' layer-id='innerNode'>
      <layer-replaceable-content class='layer-conversation-left-side' name='conversationRowLeftSide'>
      </layer-replaceable-content>
      <div class='layer-conversation-item-content'>
          <layer-conversation-last-message layer-id='lastMessage'></layer-conversation-last-message>
          <div class='layer-conversation-title-row'>
              <layer-conversation-title layer-id='title'></layer-conversation-title>
              <layer-replaceable-content class='layer-conversation-right-side' name='conversationRowRightSide'>
              </layer-replaceable-content>
          </div>
      </div>
      <div id='xircles-handle' class='xircles-handle' layer-id='handle'><div></div></div>
      <div class="xircles-footer">
      <span class='action-time' layer-id='lastActionTime'></span>
      <span class='conversation-id-label' layer-id='conversationIdLabel'></span>
      <div class="operator-status">
        <div class="operator-status__first"></div>
        <div class="operator-status__second"></div>
      </div>

    </div>
    </div>

    <div class='xircles-confirm' layer-id='confirmAction'>
      <span class='xircles-confirm-text'>Are you sure?</span><span class='xircles-confirm-action confirm-yes'><i class="material-icons">check</i></span><span class='xircles-confirm-action confirm-no'><i class="material-icons">close</i></span>
    </div>
    <div class='xircles-actions' layer-id='actions'>
      <ul></ul>
    </div>


  </div>
`
);

Layer.UI.buildAndRegisterTemplate(
  'layer-loading-indicator',
  `
  <div class='loading-spinner'>
    <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path spinner-border" cx="33" cy="33" r="28" stroke="url(#gradient)"></circle>
      <linearGradient id="gradient-spinner">
        <stop offset="50%" stop-color='#494d5b' stop-opacity=".85"></stop>
        <stop offset="65%" stop-color='#494d5b' stop-opacity=".52"></stop>
        <stop offset="100%" stop-color='#494d5b' stop-opacity="0"></stop>
      </linearGradient>
    </svg>
  </div>
`
);

const LayerReactComponents = Layer.UI.adapters.react(React, ReactDom);

export { LayerReactComponents };
export { Layer };
export { layerClient };
export default { Layer, LayerReactComponents, layerClient };
