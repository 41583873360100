import { Layer } from '../get-layer';
import { isBot, isOperator } from '../utils/user';

const MessageItemReceivedMixin = {
  methods: {
    onAfterCreate() {
      this.borderAttempts = 3;
    },
    onRender() {
      (this.getElementsByClassName('layer-message-type-view__operator')
        .length ||
        this.getElementsByClassName(
          'xircles-content-card-message-view-swipe__operator'
        ).length ||
        this.getElementsByClassName(
          'xircles-content-card-message-view__operator'
        ).length) &&
        isOperator(Layer.client.user) &&
        this.classList.add('layer-message-status-delivered-from-operator');
    },
    onRerender: function () {
      this.nodes.date.innerHTML = '';

      const sender = this.properties.item.sender;
      if (isOperator(Layer.client.user) || isOperator(sender)) {
        this.nodes.sender.classList.add('show-sender');
      }

      if (isBot(sender)) {
        this.nodes.sender.classList.add('bot');
      }

      if (!isOperator(Layer.client.user)) {
        this._setBorder();
      }
    },
    _compareDates(firstDate, secondDate) {
      return new Date(firstDate).getTime() - new Date(secondDate).getTime();
    },
    _setTopBorder() {
      const messages = document.querySelectorAll('layer-message-item-received');
      if (messages.length === 0) {
        return setTimeout(() => this._setTopBorder(), 100);
      } else {
        if (messages.length > 2) {
          const lastMessageQuestion = messages[messages.length - 2];
          const sentMessages = document.querySelectorAll(
            'layer-message-item-sent'
          );
          const lastSentMessage =
            sentMessages &&
            sentMessages.length &&
            sentMessages[sentMessages.length - 1];
          const isSentMessageInside =
            this._compareDates(
              lastMessageQuestion.item.receivedAt,
              lastSentMessage.item.receivedAt
            ) < 0;

          if (
            lastMessageQuestion &&
            lastMessageQuestion.classList.contains(
              'layer-message-item-layer-text-message-view'
            ) &&
            !isSentMessageInside
          ) {
            lastMessageQuestion.classList.add(
              'layer-list-item-middle',
              'layer-message-item__border-top'
            );
            this.classList.add('layer-message-item__border-bottom');
          }

          return;
        } else {
          return setTimeout(() => this._setTopBorder(), 100);
        }
      }
    },
    _setBorder() {
      if (
        (this.classList.contains(
          'layer-message-item-layer-choice-message-view'
        ) ||
          this.classList.contains(
            'layer-message-item-xircles-checkbox-message-view'
          )) &&
        this.offsetHeight > 0
      ) {
        this._setTopBorder();
      } else {
        if (this.borderAttempts !== 0) {
          this.borderAttempts = this.borderAttempts - 1;
          setTimeout(() => this._setBorder(), 100);
        }
      }
    },
  },
};

export default MessageItemReceivedMixin;
