// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

type Props = {
  onRequestCode: Function,
  t: Function,
};

function CodeConnfirmModalFragment({ onRequestCode, t }: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mb={2} mt={3} fontSize="fontSize">
        {t('LOGIN_SEND_YOU_CODE')}
      </Box>
      <Button outlined style={{ width: '100%' }} onClick={onRequestCode}>
        {t('LOGIN_SEND_CODE')}
      </Button>
    </React.Fragment>
  );
}

export default withTranslation()(CodeConnfirmModalFragment);
