import { layerClient } from '../get-layer';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';

// TODO: workaround bc the websocket change events don't get applied
export default function persistUser(updates) {
  const key = `layer-session-data-${layerClient.appId}`;
  const userData = localStorage.getItem(key);

  const omittedUpdates = _omitBy(updates, _isUndefined);

  const parsedUserData = userData ? JSON.parse(userData) : {};

  const updatedUserData = {
    ...parsedUserData,
    user: {
      ...parsedUserData.user,
      ...omittedUpdates,
      metadata: {
        ...parsedUserData.user.metadata,
        ...omittedUpdates.metadata,
      },
    },
  };

  localStorage.setItem(key, JSON.stringify({ ...updatedUserData }));
}
