// @flow

import * as React from 'react';

import './styles.less';

type Props = {
  children?: Object | string,
  className?: string,
  title: string,
  link: string,
  url: string,
  onInfoCardLinkClick: Function,
};

const InfoCard = (props: Props) => (
  <div className={`info-card ${props.className}`}>
    <p className="info-card__title">{props.title}</p>
    <a
      href={props.url}
      target="_blank"
      className="info-card__link"
      rel="noopener noreferrer"
      onClick={props.onInfoCardLinkClick}
    >
      {props.link}
    </a>
  </div>
);

export default InfoCard;
