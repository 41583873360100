import React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';

import { slugify } from '../../utils/common';

const handleTitleWithProduct = (title, product) =>
  title.replace(product, `<b>${product}</b>`);

const createConversationLink = (appUrl, conversationName, product) => {
  const preparedConversationName = slugify(conversationName);
  return `${appUrl}/${slugify(product)}/${preparedConversationName}`;
};

const ProductFAQ = ({ title, product, productPathname, appUrl, onClick }) => (
  <li className="product-faq" onClick={onClick}>
    <a
      rel="canonical"
      href={createConversationLink(appUrl, title, productPathname)}
      className={`product-faq__title${
        !title.includes(product) ? ' product-faq__title--labeled' : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      dangerouslySetInnerHTML={{
        __html: title.includes(product)
          ? handleTitleWithProduct(title, product)
          : title,
      }}
    ></a>
    {!title.includes(product) && <span>{product}</span>}
    <i className="material-icons">arrow_forward</i>
  </li>
);

const ProductFAQs = (props) => {
  const createOnClick = (props, questionText) => () =>
    props.createConversation(questionText, {
      product: props.productPathname,
      selectedProductPage: props.productPathname,
      documentId: props.documentId,
    });

  return (
    <div className="product-faqs">
      <div className="product-faqs__header">
        <h2>
          {props.t('COMMON_FAQ_ABOUT')} {props.productName}
        </h2>
      </div>
      <ul className="product-faqs__content">
        {props.productFaqs.map((question, index) => {
          const questionText = question.metadata.conversationName;
          const onClick = createOnClick(props, questionText);
          return (
            <ProductFAQ
              {...props}
              key={`product-faq-${index}`}
              title={questionText}
              product={props.productName}
              productPathname={props.productPathname}
              onClick={onClick}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default withTranslation()(ProductFAQs);
