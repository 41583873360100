// @flow

/**
 * GTM - Triggers
 *
 * name: Click on content menu
 * fires on: Click element matches CSS selector .contents p
 * fires on: Click element matches CSS selector #content-menu-title
 *
 * name: Click on content menu chapter
 * fires on: Click Element matches CSS selector .contents li:not(.go-top) span
 *
 * name: Click to close content menu
 * fires on: Click Element matches CSS selector button#list
 * fires on: Click Element matches CSS selector button#clear i
 * fires on: Click Element matches CSS selector #content-menu-icon-list
 *
 * name: Click to open content menu
 * fires on: Click Element matches CSS selector button#clear
 * fires on: Click Element matches CSS selector button#list i
 * fires on: Click Element matches CSS selector #content-menu-icon-clear
 *
 * name: Select active nav link
 * fires on: gtm.active-nav-link with all custom events
 */

import * as React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { isInsideFrame } from '../../../utils/common';

import './styles.less';
import './smpc-ema/styles.less';
import './smpc-mmi/styles.less';
import ReactDOM from 'react-dom';

type Props = {
  containerRef: any,
  headerRef: any,
  topHeader: string,
  slug: string,
  updateParentUrl: Function,
  isMMIBannerVisible: Boolean,
};

type State = {
  isOpen: boolean,
  isNameVisible: boolean,
  activeNavLink: string,
};

class Contents extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      isNameVisible: true,
      isAutoScroll: true,
      activeNavLink: '',
    };
    //
    this.contentsListRef = React.createRef();
    //
    this.navLinkTimer = undefined;
    this.scrollTimer = undefined;
    this.firstMenuItem = null;
  }

  onScroll = () => {
    const filteredNavLinksArray = this.props.navLinksArray.filter(
      (item) => window.pageYOffset >= item.offsetTop + 40
    );
    if (this.clickedNavlink === 'top') {
      this.setState({ activeNavLink: '' }, () => (this.clickedNavlink = ''));
    } else if (this.clickedNavlink && this.isElementClicked) {
      this.setState({ activeNavLink: this.clickedNavlink });
    } else if (filteredNavLinksArray && filteredNavLinksArray.length) {
      this.state.activeNavLink !==
        filteredNavLinksArray[filteredNavLinksArray.length - 1].innerText &&
        this.setState(
          {
            activeNavLink:
              filteredNavLinksArray[filteredNavLinksArray.length - 1].innerText,
          },
          () => (this.clickedNavlink = '')
        );
    } else {
      this.setState({ activeNavLink: '' });
    }

    if (
      this.state.isAutoScroll &&
      document.documentElement.scrollTop ===
        Math.round(this.props.anchorFromUrlCoordinates - 81)
    ) {
      window.clearTimeout(this.scrollTimer);
      this.handleScrollToContentItem();
      this.scrollTimer = setTimeout(
        () => this.setState({ isAutoScroll: false }),
        70
      );
    }
  };

  resetClick = () =>
    setTimeout(() => {
      this.isElementClicked = false;
    }, 1500);

  onWheel = (evt) => {
    // if ((evt.deltaY > 0 && !this.state.isNameVisible) || (evt.deltaY < 0 && this.state.isNameVisible)) {
    if (evt.deltaY > 0 && !this.state.isNameVisible) {
      this.setState({ isNameVisible: !this.state.isNameVisible });
    }
  };

  handleResize = () => {
    if (document.documentElement.clientWidth >= 1034 && !this.state.isOpen) {
      this.setState({ isOpen: true });
    }
  };

  componentDidMount(): void {
    if (document.body.clientWidth >= 1034) {
      this.setState({ isOpen: true });
    }

    const [firstMenuItem] = this.props.contentMenu;
    this.firstMenuItem = firstMenuItem;

    const { hash } = this.props.history.location;

    if (hash.length === 0) {
      this.setState({ isAutoScroll: false });
    }

    window.addEventListener('scroll', this.onScroll, { passive: true });
    window.addEventListener('wheel', this.onWheel, { passive: true });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { anchorFromUrlCoordinates, parentLocation } = this.props;
    const { anchorFromUrlCoordinates: oldAnchorFromUrlCoordinates } = prevProps;
    if (
      anchorFromUrlCoordinates !== oldAnchorFromUrlCoordinates &&
      !this.state.isScrolling
    ) {
      this.setState({ isScrolling: true, isNameVisible: true });

      scroll.scrollTo(anchorFromUrlCoordinates - 81, {
        delay: 500,
        duration: 1000,
        smooth: true,
      });
    }

    if (
      prevState.activeNavLink !== this.state.activeNavLink &&
      !this.state.isAutoScroll
    ) {
      const activeNavLinkContent = this.props.navLinksArray.find(
        (link) => link.textContent === this.state.activeNavLink
      );
      if (
        activeNavLinkContent &&
        activeNavLinkContent.previousElementSibling &&
        activeNavLinkContent.previousElementSibling.id
      ) {
        window.clearTimeout(this.navLinkTimer);
        this.navLinkTimer = setTimeout(
          () =>
            window.history.replaceState(
              null,
              null,
              document.location.pathname +
                '#' +
                activeNavLinkContent.previousElementSibling.id
            ),
          100
        );

        if (isInsideFrame()) {
          this.props.updateParentUrl(
            this.props.location.pathname,
            '#' + activeNavLinkContent.previousElementSibling.id,
            this.props.location.search,
            parentLocation
          );
        }

        this.handleScrollToContentItem();
      }

      document.dispatchEvent(
        new CustomEvent('tr-custom-event', {
          detail: {
            name: 'active-nav-link',
            link: this.state.activeNavLink,
            slug: this.props.slug,
          },
        })
      );
    }

    if (
      prevState.activeNavLink !== this.state.activeNavLink &&
      prevState.activeNavLink === this.firstMenuItem &&
      prevState.activeNavLink.length > 0 &&
      this.state.activeNavLink.length === 0 &&
      this.contentsListRef &&
      this.contentsListRef.current
    ) {
      this.contentsListRef.current.scrollTop = 0;
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.handleResize);
  }

  handleScrollToContentItem = () => {
    const contentsItem = document.getElementById(this.state.activeNavLink);
    if (contentsItem) {
      contentsItem.scrollIntoView();
    }
  };

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    const isOpen = this.state.isOpen;

    if ((isOpen && !domNode) || (isOpen && !domNode.contains(event.target))) {
      if (document.body.clientWidth <= 1034) {
        this.setState({
          isOpen: false,
        });
      }
    }
  };

  iconClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  isElementClicked = false;
  clickedNavlink = '';

  scrollTo(element = null) {
    clearTimeout(this.timer);
    this.clickedNavlink = '';
    this.isElementClicked = true;
    const textRef = this.props.textRef;

    let positionToScroll;
    if (element) {
      const scrollHeight = document.documentElement.scrollHeight;
      const offsetHeight = document.documentElement.offsetHeight;
      for (let i = 0; i < textRef.childNodes.length; i++) {
        if (textRef.childNodes[i].innerText === element) {
          positionToScroll = textRef.childNodes[i].offsetTop + 54;
          if (scrollHeight < positionToScroll + offsetHeight) {
            this.setState({ activeNavLink: element });
          }
        }
      }
    } else {
      positionToScroll = 0;
    }

    this.clickedNavlink = element || 'top';
    scroll.scrollTo(positionToScroll);
    this.timer = this.resetClick();
  }

  render() {
    const { isMMIBannerVisible, topHeader } = this.props;

    return (
      <div
        className={`contents${
          isMMIBannerVisible ? ' contents-with-mmi-banner' : ''
        }`}
        ref={this.props.containerRef}
      >
        <button
          data-tr-event
          id={this.state.isOpen ? 'clear' : 'list'}
          className={'contents-icon'}
          onClick={this.iconClick}
        >
          <i
            data-tr-event
            id={`content-menu-icon-${this.state.isOpen ? 'clear' : 'list'}`}
            className="material-icons"
          >
            {this.state.isOpen ? 'clear' : 'list'}
          </i>
        </button>
        <p
          id="content-menu-title"
          data-tr-event
          onClick={this.iconClick}
          style={{
            opacity: this.state.isNameVisible && !this.state.isOpen ? '1' : '0',
          }}
        >
          {this.state.activeNavLink !== '' && this.state.activeNavLink}
        </p>
        {this.state.isOpen && (
          <ul ref={this.contentsListRef}>
            <li className={'go-top'}>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  this.scrollTo();
                }}
              >
                {topHeader}
              </span>
              <button
                id="go-top-document"
                onClick={(e) => {
                  e.preventDefault();
                  this.scrollTo();
                }}
              >
                <i className="material-icons">expand_less</i>
                <i className="material-icons">expand_less</i>
              </button>
            </li>
            {this.props.contentMenu.map((item, i) => {
              return Array.isArray(item) ? (
                <ul key={item}>
                  {item.map((subitem) => (
                    <li
                      key={subitem}
                      className={
                        this.state.activeNavLink === subitem ? 'active' : null
                      }
                      id={subitem}
                    >
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          this.scrollTo(subitem);
                        }}
                      >
                        {subitem}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <li
                  key={i}
                  className={
                    this.state.activeNavLink === item ? 'active' : null
                  }
                  id={item}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      this.scrollTo(item);
                    }}
                  >
                    {item}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default withRouter(Contents);
