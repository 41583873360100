/* eslint-disable no-useless-escape */
import slugifyLib from 'slugify';
import utf8 from 'utf8';
import MobileDetect from 'mobile-detect/mobile-detect';
import DOMPurify from 'dompurify';

const IMG_WIDTH_HEIGHT_THRESHOLD = 1;
const WAIT_FOR_PROPERTY_MIN_THRESHOLD = 100;
const WAIT_FOR_PROPERTY_MAX_THRESHOLD = 10000;

export const isQuestionsRoute = (str) =>
  /^\/(questions|[a-zA-Z-]*)?$/.test(str);
export const isConversationRoute = (str) =>
  /\/questions\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/?$/.test(
    str
  ); // eslint-disable-line
export const isProductQuestionsRoute = (str) =>
  /\/product-questions\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/?$/.test(
    str
  ); // eslint-disable-line
export const isConversationModalRoute = (str) =>
  /\/questions\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/.*?$/.test(
    str
  );
export const isDocumentsRoute = (str) =>
  /\/documents\/[A-Za-z0-9]/.test(str) ||
  /\/medikamente\/.*\/fachinfo/.test(str);
export const isDocumentsModalRoute = (str) =>
  /\/documents\/[A-Za-z0-9].*?(need-to-login|login|logout|send-code|password-code|password-confirm|password-submit|login-unregister|code|password|set-password|create-password|reset-password|welcome|share|shared|something-went-wrong|unavailable-content|network-error|no-product|future-product|my-account)$/.test(
    str
  );
export const isLoginModalWindowRoute = (str) =>
  /^(.*)\/(my-account|need-to-login|login|logout|doccheck-welcome|doccheck|send-code|password-code|password-confirm|password-submit|login-unregister|code|password|set-password|create-password|reset-password|welcome)\/?.*$/.test(
    str
  );
export const isModalWindowRoute = (str) =>
  /^(.*)\/(ie-warning|my-account|need-to-login|login|doccheck-welcome|doccheck|send-code|password-code|password-confirm|password-submit|login-unregister|code|password|set-password|create-password|reset-password|welcome|share|shared|something-went-wrong|unavailable-content|network-error|too-many-requests|select-drug)\/?.*$/.test(
    str
  );
export const isVideoRoute = (str) => /\/video\/[A-Za-z0-9]/.test(str);
export const isExternalDisclaimerRoute = (str) =>
  /\/questions\/.{36}\/(external-disclaimer)/.test(str);
export const isSaveChangesRoute = (str) => /^(.*)\/save-changes\/?$/.test(str);
export const isSomethingWentWrongRoute = (str) =>
  /^(.*)\/something-went-wrong\/?$/.test(str);
export const isIEWarningRoute = (str) => /^(.*)\/ie-warning\/?$/.test(str);
export const isLoginWasRemovedRoute = (str) =>
  /^(.*)\/login-was-removed\/?$/.test(str);
export const isNetworkErrorRoute = (str) =>
  /^(.*)\/network-error\/?$/.test(str);
export const isTooManyRequestsRoute = (str) =>
  /^(.*)\/too-many-requests\/?$/.test(str);
export const isNoProductRoute = (str) => /^(.*)\/no-product\/?$/.test(str);
export const isFutureProductRoute = (str) =>
  /^(.*)\/future-product\/?$/.test(str);
export const isUnavailableContentRoute = (str) =>
  /^(.*)\/unavailable-content\/?$/.test(str);
export const isDataPrivacyRoute = (str) => /^(.*)\/data-privacy\/?$/.test(str);
export const isPrivacyNoticeRoute = (str) =>
  /^(.*)\/privacy-notice\/?$/.test(str);
export const isTermsOfUseRoute = (str) => /^(.*)\/terms-of-use\/?$/.test(str);
export const isMyAccountRoute = (str) => /^(.*)\/my-account\/?$/.test(str);
export const isDrugCatalogueRoute = (str) => /^\/medikamente\/?$/.test(str);
export const isProductRoute = (str) => /^\/medikamente\/.*$/.test(str);
export const isDeletedRequestsRoute = (str) =>
  /^\/(questions|[a-zA-Z-]*)\/deleted-requests?$/.test(str);
export const isSelectDrugRoute = (str) => /^(.*)\/select-drug?.*$/.test(str);
export const isPhoneNumber = (str) =>
  /(\(?([\d \-\)\–\+\/\(]+){9,}\)?([ .\-–\/]?)([\d]+))/.test(str); // eslint-disable-line
export const isGermanPhoneNumber = (str) =>
  /(?:(?=((0|0049|\+49)))(\+49|0049|0)\d{4,}$)/.test(str); // eslint-disable-line
export const isEmail = (str) =>
  /^((\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)|(test(\+[A-Za-z0-9-]+)?@xircles\.com))$/.test(
    str
  ); // eslint-disable-line
export const isPassword = (str) => /^[a-zA-Z0-9]{7,}$/.test(str);
export const isValidPassword = (str) =>
  /^[A-Za-z0-9!"#$%&'\]()*+,\-./:;<=>?@[\\\]^_`{|}~]{7,}$/.test(str);
export const isSecurityCode = (str) => /^[0-9]{5}$/.test(str);
export const isUUID = (str) =>
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    str
  );
export const isStoryblokUrl = (str) => /a\.storyblok\.com/.test(str);

export const getCredentialType = (phoneNumberOrEmail) =>
  isPhoneNumber(phoneNumberOrEmail) ? 'phone_number' : 'email_address';

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export const isIOS = () =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const iPad = () => ['iPad'].indexOf(navigator.platform) >= 0;

export const isIpadPro = () => {
  return (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    navigator.userAgent.indexOf('iPhone') === -1
  );
};

export const getIOSVersion = () => {
  const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);

  if (match !== undefined && match !== null) {
    const version = [
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3] || 0, 10),
    ];
    return version;
  }
  return [false];
};

export const isMobileDevice = () =>
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export const isIE = () => {
  const ua = navigator.userAgent;
  const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  return is_ie;
};

export const isFirefox = () => navigator.userAgent.indexOf('Firefox') > 0;

export const isPortraitMode = () =>
  window.orientation === 0 ||
  window.orientation === 180 ||
  (window.screen.orientation && window.screen.orientation.angle === 0);

export const isAndroid = () => /(android)/i.test(navigator.userAgent);

export const isTouchDevice = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.phone() || md.tablet();
};

export const isChromeBrowser = () => {
  const pieces = navigator.userAgent.match(
    /Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/
  );
  return {
    pieces,
    isChrome: pieces && pieces[0].slice(0, 6) === 'Chrome',
  };
};

export const isIOSSafari = () => {
  const ua = navigator.userAgent;
  var isIOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var isWebkit = !!ua.match(/WebKit/i);
  return isIOS && isWebkit && !/(Chrome|CriOS|OPiOS)/.test(ua);
};

export const isIOSChromeBrowser = () => navigator.userAgent.match('CriOS');

export const isLatestChromeVersion = () => {
  const { isChrome, pieces } = isChromeBrowser();
  const majorChromeVersion = pieces && parseInt(pieces[1], 10);
  let isLatestChrome;
  if (isChrome && majorChromeVersion) {
    isLatestChrome = majorChromeVersion >= 72;
  }
  return isLatestChrome;
};

export const isTabletDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
};

export const bytesToSize = (bytes) => {
  const sizes = ['bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const isEscButtonPressed = (e) =>
  e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;

export const isInsideFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isUtf8 = (input) => {
  let encodeError;

  try {
    utf8.encode(input);
  } catch (e) {
    encodeError = e;
  }

  return !encodeError;
};

// Don't understand this, has it something to do with Watson input?
// Because for elasticsearch is irrelevant as words are tokenized.
// In any case no idea what is exactly trying to achieve
export const isDotSlash = (input) => input.search(/.?\\|\.\//g) === -1;

export const isEmptyBytes = (input) => input.search(/\0|%./g) === -1;

export const downloadFileFromRequest = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const mouseHandler = (event, action) => {
  const evt = window.event || event;
  const button = evt.which || evt.button;
  if (button === 1) {
    // if left mouse button
    action(event);
  }
};

export const isDOMElement = (element) =>
  element instanceof Element || element instanceof HTMLDocument;

export const getOriginalPathname = (url) =>
  url.replace(
    /\/(login-unregister|logout|create-password|password|password-code|password-submit|send-code|code|doccheck-welcome|doccheck|shared|welcome)/,
    ''
  );

export const isIncludesEmail = (str) =>
  /\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+/.test(str);

export const findDiff = (first, second) => {
  let index = 0;

  for (const [i, el] of first.split('').entries()) {
    if (el !== second.charAt(i)) {
      index = i;
      break;
    }
  }

  return index;
};

export const getPathnameWithoutModal = (url) =>
  url.replace(
    /\/\b(code-confirm|ie-warning|need-to-login|login|login-unregister|logout|create-password|password|password-code|password-confirm|password-submit|send-code|code|doccheck-welcome|doccheck|shared|share|welcome|unavailable-content|select-drug|my-account|something-went-wrong)\b/,
    ''
  );

export const getImageSize = async (
  image,
  heightProperty = 'naturalHeight',
  widthProperty = 'naturalWidth'
) => {
  const height = image[heightProperty];
  const width = image[widthProperty];

  const naturalSize = {
    height,
    width,
  };

  const recursiveFunction = () =>
    new Promise((resolve) =>
      setTimeout(
        () => resolve(getImageSize(image, heightProperty, widthProperty)),
        100
      )
    );

  return height && width > 20 ? naturalSize : await recursiveFunction();
};

export const isCrawler = () =>
  /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

export const isReactSnap = () => navigator.userAgent === 'ReactSnap';

export const getEmailParam = (url) => {
  const [email] =
    url.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || [];
  return email;
};

export const getRedirectTypeParam = (url) => {
  const [redirectType] = url.match(/password-code|code/) || [];
  return redirectType;
};

export const getCodeParam = (url) => {
  const [code] = url.match(/\d+$/) || [];
  return code;
};

export const getModalWindowRoute = (str) =>
  /^(.*)\/(ie-warning|my-account|doccheck-welcome|doccheck|need-to-login|login|logout|send-code|password-code|code-confirm|password-confirm|password-submit|login-unregister|code|password|set-password|create-password|reset-password|welcome|share|shared|something-went-wrong|unavailable-content|network-error|data-privacy|privacy-notice|terms-of-use)\/?.*$/.exec(
    str
  );

export const getQuestionsRouteParam = (str) => /\/questions\/.*?$/.exec(str);

export const isEmailParam = (str) => /^\S+@\S+$/.test(str);

export const getAppUrl = () => {
  const origin = isIE() ? window.location.origin : window.origin;
  return window.appUrl ? window.appUrl : origin;
};

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s: s + '%', l: l + '%' };
};

export const generateSVG = ({ fill = 'none', height, width, viewBox, d }) => {
  const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const iconPath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );

  icon.setAttribute('fill', fill);
  icon.setAttribute('height', height);
  icon.setAttribute('width', width);
  icon.setAttribute('viewBox', viewBox);

  iconPath.setAttribute('d', d);
  icon.appendChild(iconPath);

  return icon;
};

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      // This is for browserstack support
      window.location.hostname === 'bs-local.com' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const normalizePathname = (pathname) =>
  pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;

export const capitalizeString = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

/**
 * @function slugify
 * Wrapper for slugifying a string by lowercasing and '-' separator by default
 * @param st
 * @param replacement
 * @param lower
 * @returns string
 */
export const slugify = (st, replacement = '-', lower = true) => {
  return slugifyLib(st, { lower, replacement, strict: true });
};

/**
 * @function getUUID extracts UUID from the string
 * @param string
 * @returns string[]
 * @example getUUID('/questions/86ba1cc6-93af-4608-882c-df362ef8dc16') --> ['86ba1cc6-93af-4608-882c-df362ef8dc16']
 */
export const getUUID = (string) => {
  return string.match(
    /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/gm
  );
};

export const safeDocSlugReplace = (srcString, matchText, replacement) => {
  const matchCnt = srcString.match(new RegExp(matchText, 'g')).length;
  if (matchCnt === 2) {
    // matchCnt === 2 means that this full_slug has the brandSlug and the docSlug as the same name
    // so we need to replace the brandSlug with the TEMP replacement
    // and then put it back
    let tempString = srcString.replace(new RegExp(matchText), '#TEMP#');
    tempString = tempString.replace(matchText, replacement);
    return tempString.replace('#TEMP#', matchText);
  }
  return srcString.replace(matchText, replacement);
};

export const prepareProductUrl = (
  url,
  productPathname,
  appUrl,
  isRelative = true
) => {
  const productName = url.split('/').pop();
  const selectedAppUrl = isRelative ? '' : appUrl;
  return `${selectedAppUrl}/medikamente/${productPathname}/fachinfo-${productName}`;
};

export const prepareMmiExternalUrl = (url) => {
  if (url.length > 0) {
    /* eslint-disable no-unused-vars */
    const [_fullDomainNameMatch, domainName] =
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/gim.exec(url);
    return `www.${domainName}`;
  }

  return url;
};

export const getSafeHtml = (html, disableAnyTags = false) => {
  const ALLOWED_TAGS = ['sup'];
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: disableAnyTags ? [] : ALLOWED_TAGS,
  });
};

export const isAutocompleteOrSpellcheckRequest = (url) => {
  const expectedApiArray = ['autocomplete', 'spellcheck'];

  return expectedApiArray.some((api) => url.includes(api));
};

export const isImageThresholdPassed = (image) => {
  return new Promise((resolve) => {
    image.onload = () =>
      resolve(
        [image.width, image.height].every(
          (prop) => prop > IMG_WIDTH_HEIGHT_THRESHOLD
        )
      );
  });
};

/**
 * @function unescapeHTML
 * @param {string} html
 * @returns {string}
 */
export const unescapeHTML = (html = '') => {
  const el = document.createElement('span');
  el.innerHTML = html;
  return el.innerText;
};

export const createElementFromHTML = (htmlString) => {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.childNodes;
};

export const convertStringToHtml = (input) => {
  return new DOMParser().parseFromString(input, 'text/html').body.childNodes[0];
};

export const removeRegisteredSymbol = (input) => input.replace('®', '');

export const wrapRegisteredSymbol = (input) =>
  input.replace('®', '<sup>®</sup>');

export const sortNumArray = (array) => array.sort((a, b) => a - b);

export const waitForPropertyToBeSet = (
  source,
  props,
  callback,
  startTime = Date.now()
) => {
  const [prop, ...otherProps] = props;

  if (prop.length > 0 && prop.every((key) => key in source)) {
    const [first] = prop;

    if (otherProps.length > 0) {
      console.log(`NEXT: Prop [${prop}] found`);
      setTimeout(
        waitForPropertyToBeSet.bind(null, source[first], otherProps, callback),
        WAIT_FOR_PROPERTY_MIN_THRESHOLD
      );
    } else if (otherProps.length === 0) {
      console.log(`FINISH: Prop [${prop}] found`);
      setTimeout(callback.bind(null), 0);
    }
  } else {
    if (Date.now() - startTime > WAIT_FOR_PROPERTY_MAX_THRESHOLD) {
      console.log(`TIMEOUT: Prop [${prop}] not found`);
    } else {
      console.log(`RETRY: Prop [${prop}] not found`);
      setTimeout(
        waitForPropertyToBeSet.bind(null, source, props, callback),
        WAIT_FOR_PROPERTY_MIN_THRESHOLD
      );
    }
  }
};

export const isOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

// It closes active Live-Chat session
export const closeLiveChat = () => {
  const rp = window.rp;
  const { pathname } = document.location;
  const isConversationPageCurrent = isUUID(pathname);

  try {
    if (isConversationPageCurrent && rp.chat.HeadlessChat.chatIsActive()) {
      rp.chat.HeadlessChat.endChat();
    }
  } catch (error) {}
};
