import Layer from '@layerhq/web-xdk';

import './xircles-long-copy-message-model';
import '../../mixins/LongCopyMixin';
import LongCopyMixin from '../../mixins/LongCopyMixin';
import lineClamp from 'line-clamp';
import closest from '../../polyfills/closest';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-long-copy-message-view', {
  mixins: [MessageViewMixin, LongCopyMixin],
  style: `
    
  `,
  template: `<div layer-id="copy">
                    <div>
                        <p layer-id="question"></p>
                        <i class="xircles-icons icon-send"></i>
                    </div>
                    <h6 layer-id="text" class="long-copy-text"></h6>
                </div>`,
  properties: {
    widthType: {
      get: function get() {
        return Widths.FULL;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    onRerender() {
      this.nodes.question.innerHTML = this.model.question;
      this.nodes.text.innerHTML = this.model.text;
      closest(this, '.layer-card-top').style.borderRadius = 0;
      closest(this, 'layer-message-viewer').style.width = '100%';
      closest(this, 'layer-message-viewer').style.borderRadius = 0;
      setTimeout(
        () => lineClamp(this.querySelector('[layer-id="text"]'), 2),
        0
      );
    },
  },
});
