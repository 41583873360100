// @flow
/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { withCookies } from 'react-cookie';

const DocCheckTarget = (props: Props) => {
  const { cookies } = props;

  const cookieParams = {
    path: '/',
    maxAge: 60 * 30 * 24,
    domain: location.hostname,
    //secure: true,
    sameSite: 'strict',
  };

  cookies.set('xir-dc-success', true, cookieParams);

  return <div />;
};

export default withCookies(DocCheckTarget);
