import { CONVERSATION_ACTIONS } from '../const/conversation-actions';
import AlloyFinger from 'alloyfinger';
import Layer from '@layerhq/web-xdk';
import i18next from 'i18next';

import config from '../config.json';
import { isHcpOrOperator } from '../utils/user';
import { isIE, mouseHandler } from '../utils/common';

require('alloyfinger/transformjs/transform');

const DATA_OPEN_CARD_TAG = 'data-card-open';
const DATA_SWIPE_CARD_TAG = 'data-card-swiped';
const OPEN_TRANSFORM_X_VALUE = -150;

const LinkMessageViewMixin = {
  methods: {
    _triggerHcpCheck() {
      const conversationId = this.mainComponent.conversationId;
      const url =
        this.model.url ||
        this.model.sourceUrl ||
        (this.model.source && this.model.source.url);
      this.trigger('xircles-question-send', {
        conversationId,
        text: `${i18next.t('REGISTER_NEED_HCP_CHECK')}`,
        slug: `${config.StoryblokRootFolder}/${config.HcpCheckSlug}`,
        data: {
          url: url.replace(/^\/documents\//, ''),
          title: this.model.title,
        },
      });
    },
    _updateContent: function () {
      let dbg = this.model.score;
      if (this.model.matchedTags && this.model.matchedTags.length) {
        dbg = this.model.matchedTags.join(', ');
      }

      const isVideoLink = this.model.customData.type === 'xircles-video-link';
      const [header] = this.nodes.image.childNodes;
      header.innerHTML = `${
        isVideoLink ? this.model.title : this.model.description
      } ${config.Debug ? `<sup><em>${dbg}</em></sup>` : ''}`;
      this.title = `${config.Debug ? `${dbg}` : ''}`;
    },
    onAfterCreate: function () {
      const view = this;
      this.needHcpCheck =
        this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);
      if (
        view.model.customData &&
        view.model.customData.elementType === 'single'
      ) {
        if (view.model.customData && view.model.customData.type) {
          view
            .closest('.layer-list-item')
            .classList.add(view.model.customData.type);
          if (view.model.customData.type === 'xircles-video-link') {
            this.parentElement.style.display = 'block';
            const firstExistingElement = view.querySelector('div');
            const secondExistingElement = view.querySelector('a');

            const wrapper = document.createElement('div');
            wrapper.classList.add(
              'xircles-link-message-view-swipe-wrapper',
              'xircles-video-link'
            );
            view.appendChild(wrapper);

            firstExistingElement.parentNode.insertBefore(
              wrapper,
              firstExistingElement
            );
            secondExistingElement.parentNode.insertBefore(
              wrapper,
              secondExistingElement
            );

            wrapper.appendChild(firstExistingElement);
            wrapper.appendChild(secondExistingElement);

            const icon = document.createElement('i');
            icon.classList.add('play-icon');
            icon.classList.add('material-icons');
            icon.innerText = 'play_circle_filled_white';
            wrapper.appendChild(icon);
            const description = document.createElement('h6');
            description.className = 'video-description';
            description.innerHTML = this.model.description;
            view.nodes.image.appendChild(description);
            view.messageViewer.querySelector(
              '.layer-card-body-outer'
            ).style.display = 'none';
            view.messageViewer.querySelector(
              'layer-standard-message-view-container'
            ).style.border = 'none';

            const actions = document.createElement('div');
            actions.className = 'xircles-actions';
            const actionsUL =
              '<ul>\n' +
              '            <li layer-id=\'actions-mark\'><i class="material-icons">outlined_flag</i>Mark</li>\n' +
              "            <li layer-id='actions-share' class='actions-share'><i class=\"material-icons\">share</i>Share</li>\n" +
              '          </ul>';
            actions.innerHTML = actionsUL;
            view.appendChild(actions);
          }
        } else if (!view.model.imageUrl && !view.model.description) {
          view.innerHTML = view.model.title;
        }
      } else {
        if (view.model.customData && view.model.customData.type) {
          view
            .closest('.layer-list-item')
            .classList.add(view.model.customData.type);
          if (view.model.customData.type === 'xircles-video-link') {
            const icon = document.createElement('i');
            icon.classList.add('play-icon');
            icon.classList.add('material-icons');
            icon.innerText = 'play_circle_filled_white';
            view.appendChild(icon);
            const description = document.createElement('h6');
            description.className = 'video-description';
            description.innerHTML = this.model.description;
            view.nodes.image.appendChild(description);
            view.messageViewer.querySelector(
              '.layer-card-body-outer'
            ).style.display = 'none';
            view.messageViewer.querySelector(
              'layer-standard-message-view-container'
            ).style.border = 'none';
          }
        } else if (!view.model.imageUrl && !view.model.description) {
          view.innerHTML = view.model.title;
        }
      }

      this._updateContent();

      this.messageViewer.removeClickHandler('card-click', this.messageViewer);

      this.messageViewer.addClickHandler(
        'open-link',
        this.messageViewer,
        () => {
          this.needHcpCheck
            ? this._triggerHcpCheck()
            : dispatchEvent(
                new CustomEvent('change-route', {
                  detail: {
                    url: this.querySelector('[layer-id="link"]').innerText,
                  },
                })
              );
        }
      );

      Layer.client.user.on('identities:change', () => view.onRerender());
    },
    onRerender: function () {
      this.needHcpCheck =
        this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);
      if (this.model.customData.type === 'xircles-video-link') {
        if (this.needHcpCheck) {
          const linkMessage = this.querySelector(
            '.xircles-link-message-view-swipe-wrapper'
          );
          linkMessage.classList.add('only-for-hcp');

          if (this.nodes.image.children.length < 2) {
            const hcpOnly = document.createElement('p');
            hcpOnly.classList.add('hcp-only-label');
            hcpOnly.innerHTML = `<em><br/>${i18next.t(
              'ANSWER_FOR_HCP_ONLY'
            )}</em>`;
            this.nodes.image.appendChild(hcpOnly);
          }
        } else {
          const linkMessage = this.querySelector(
            '.xircles-link-message-view-swipe-wrapper'
          );
          linkMessage.classList.contains('only-for-hcp') &&
            linkMessage.classList.remove('only-for-hcp');
          //
          const [hcpOnlyLabel] =
            document.getElementsByClassName('hcp-only-label');
          hcpOnlyLabel &&
            hcpOnlyLabel.parentNode &&
            hcpOnlyLabel.parentNode.removeChild(hcpOnlyLabel);
        }

        setTimeout(() => {
          if (this.messageViewer) {
            this.messageViewer.style.maxWidth = '235px';
            this.messageViewer.style.minWidth = '235px';
            this.messageViewer.style.height = '105px';
          }
        }, 0);
      }
    },
    onRender: function () {
      if (
        this.model.customData &&
        this.model.customData.elementType === 'single'
      ) {
        this.innerNode = this.getElementsByClassName(
          'xircles-link-message-view-swipe-wrapper'
        )[0];
        this.actions = this.getElementsByClassName('xircles-actions')[0];
        {
          const getInnerNodeTransformValue = () => {
            this.actions.style.zIndex = 0;
            return this.innerNode.style.transform
              ? Number(
                  this.innerNode.style.transform.split('(')[1].split('p')[0]
                )
              : 0;
          };

          const getConversationId = () => {
            const layerItemId = this.getAttribute('layer-item-id');
            const conversationId = layerItemId.replace('conversations_', '');
            return conversationId;
          };

          const shareConversation = () => {
            const conversationId = getConversationId();
            window.dispatchEvent(
              new CustomEvent(CONVERSATION_ACTIONS.share, {
                detail: { conversationId },
              })
            );
          };

          const isCardOpen = () =>
            this.innerNode.hasAttribute(DATA_OPEN_CARD_TAG);
          const setCardOpen = () =>
            this.innerNode.setAttribute(DATA_OPEN_CARD_TAG, '');
          const removeCardOpen = (conversation = this) =>
            conversation.innerNode.removeAttribute(DATA_OPEN_CARD_TAG);

          const isCardSwipe = () =>
            this.innerNode.hasAttribute(DATA_SWIPE_CARD_TAG);
          const setCardSwipe = () =>
            this.innerNode.setAttribute(DATA_SWIPE_CARD_TAG, '');
          const removeCardSwipe = () =>
            this.innerNode.removeAttribute(DATA_SWIPE_CARD_TAG);

          let startPosition = 0;
          // const getInnerNodeTransformValue = () => this.upperBlockRef.current && this.upperBlockRef.current.translateX || 0;
          // const getTitleToggleStatus = () => this.iconRef.current && this.iconRef.current.style.display !== 'none';

          if ('ontouchstart' in document.documentElement) {
            const windowEventHandler = new AlloyFinger(window, {});

            const openActionPanel = () => {
              this.innerNode.translateX = OPEN_TRANSFORM_X_VALUE;
              windowEventHandler.on('touchEnd', closeHeaderActionEvent);
            };

            const closeActionPanel = () => {
              this.innerNode.translateX = 0;
              windowEventHandler.off('touchEnd', closeHeaderActionEvent);
            };

            const closeHeaderActionEvent = (event) => {
              closeActionPanel();
            };

            window.Transform(this.innerNode);

            new AlloyFinger(this.innerNode, {
              touchStart: function (event) {
                startPosition = this.translateX;
              },
              tap: function (event) {
                // event.stopPropagation();
                // event.preventDefault();
              },

              pressMove: function (event) {
                let x = this.translateX + event.deltaX;
                if (x > 0) {
                  x = 0;
                } else if (x < OPEN_TRANSFORM_X_VALUE) {
                  x = OPEN_TRANSFORM_X_VALUE;
                }
                this.translateX = x;
              },

              touchEnd: function (event) {
                let x = this.translateX || 0;
                const swipe = Math.abs(startPosition - x) > 30;

                if (x !== startPosition) {
                  event.stopPropagation();
                  event.preventDefault();
                }

                if (
                  (startPosition === OPEN_TRANSFORM_X_VALUE && swipe) ||
                  (startPosition === 0 && !swipe) ||
                  (x === startPosition && x === OPEN_TRANSFORM_X_VALUE)
                ) {
                  closeActionPanel();
                } else if (
                  (startPosition === OPEN_TRANSFORM_X_VALUE && !swipe) ||
                  (startPosition === 0 && swipe)
                ) {
                  openActionPanel();
                }
              },
            });
          } else {
            const openCard = () => {
              window.dispatchEvent(new Event('mousedown'));
              this.innerNode.style.transform = `translateX(${OPEN_TRANSFORM_X_VALUE}px)`;
              setCardOpen();
              window.addEventListener('mousedown', closeCard);
            };

            const closeCard = () => {
              removeCardOpen();
              this.innerNode.style.transform = 'translateX(0)';
              window.removeEventListener('mousedown', closeCard);
            };

            let startPosition = 0;

            let prevX = 0;
            const mouseMove = (event) => {
              let movementX;
              // IE doesn't support movementX property in MouseEvent object,
              // so wee need to replicate it's behaviour by taking the delta between subsequent screenX values.
              if (isIE()) {
                movementX = prevX ? event.screenX - prevX : 0;
                prevX = event.screenX;
              } else {
                movementX = event.movementX;
              }
              let x = movementX + getInnerNodeTransformValue();

              if (x > 0) {
                x = 0;
              } else if (x < OPEN_TRANSFORM_X_VALUE) {
                x = OPEN_TRANSFORM_X_VALUE;
              }

              this.innerNode.style.transform = `translateX(${x}px)`;
            };

            const mouseUp = (evt) => {
              evt.stopPropagation();
              let x = getInnerNodeTransformValue();
              Math.abs(startPosition - x) > 5
                ? setCardSwipe()
                : removeCardSwipe();
              x < OPEN_TRANSFORM_X_VALUE / 2 ? openCard() : closeCard();

              document.removeEventListener('mousemove', mouseMove);
              document.removeEventListener('mouseup', mouseUp);
            };

            const swipe = (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              startPosition = getInnerNodeTransformValue();

              const removeSwipe = (evt) => {
                removeCardSwipe();
                window.removeEventListener('click', removeSwipe);
              };
              window.addEventListener('click', removeSwipe);

              document.addEventListener('mousemove', mouseMove);
              document.addEventListener('mouseup', mouseUp);
            };
            this.addEventListener('mousedown', (e) => mouseHandler(e, swipe));

            const conversationItemClick = (evt) => {
              if (isCardOpen() || isCardSwipe()) {
                evt.preventDefault();
                evt.stopPropagation();
                isCardSwipe() ? removeCardSwipe() : closeCard();
              }
            };
            this.addEventListener('click', conversationItemClick);

            const shareAction = () => shareConversation();
            this.getElementsByClassName('actions-share')[0].addEventListener(
              'click',
              shareAction
            );

            this.getElementsByClassName('actions-share')[0].addEventListener(
              'click',
              (evt) => evt.stopPropagation()
            );
            this.getElementsByClassName('actions-share')[0].addEventListener(
              'mousedown',
              (evt) => evt.stopPropagation()
            );
          }
        }
      }
    },
  },
};

export default LinkMessageViewMixin;
