import React from 'react';
import './styles.less';

const AboutCard = ({
  title,
  content,
  listTitle,
  listItems,
  additionalContent,
}) => (
  <div className="about-card">
    <div className="about-card__description-wrapper">
      <h3 className="about-card__description-title">{title}</h3>
      <p className="about-card__description-content">{content}</p>
      {listTitle && (
        <p className="about-card__description-content about-card__list-title">
          {listTitle}
        </p>
      )}
      {listItems && (
        <ul
          className="about-card__list"
          dangerouslySetInnerHTML={{ __html: listItems }}
        />
      )}
      {additionalContent && (
        <p className="about-card__description-content">{additionalContent}</p>
      )}
    </div>
    <div className="about-card__image-wrapper">
      <img alt={title} className="about-card__image" />
      <span className="about-card__image-caption"></span>
    </div>
  </div>
);

export default AboutCard;
