import * as React from 'react';

import './styles.less';

const MMIBanner = (props) => (
  <div
    className={`mmi-banner ${props.isFixedPosition ? 'mmi-banner--fixed' : ''}`}
  >
    <p
      className="mmi-banner__content"
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  </div>
);

export default MMIBanner;
