// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isUndefined from 'lodash/isUndefined';
import _values from 'lodash/values';

import config from '../../../config.json';

import MaterialIcon from '@material/react-material-icon';

import IconButton from '../../../material-components/IconButton';
import LoginModalFragment from '../LoginModalFragment';
import MyAccountModalFragment from '../MyAccountModalFragment';
import LogoutModalFragment from '../LogoutModalFragment';
import LoginUnregisterModalFragment from '../LoginUnregisterModalFragment';
import SendCodeModalFragment from '../SendCodeModalFragment';
import PasswordModalFragment from '../PasswordModalFragment';
import PasswordConfirmModalFragment from '../PasswordConfirmModalFragment';
import CodeConfirmModalFragment from '../CodeConfirmModalFragment';
import ResetPasswordModalFragment from '../ResetPasswordModalFragment';
import WelcomeModalFragment from '../WelcomeModalFragment';
import SuccessModalFragment from '../SuccessModalFragment';
import CodeModalFragment from '../CodeModalFragment';
import AuthWrapper from '../AuthWrapper';
import DocCheckModalFragment from '../DocCheckModalFragment';
import DocCheckWelcomeModalFragment from '../DocCheckWelcomeModalFragment';
import {
  getCodeParam,
  getCredentialType,
  getEmailParam,
  getModalWindowRoute,
  getPathnameWithoutModal,
  getRedirectTypeParam,
  isEmail,
  isEmailParam,
  isEscButtonPressed,
  isLoginModalWindowRoute,
  isMyAccountRoute,
  isIOS,
  isPhoneNumber,
  isDocumentsModalRoute,
  isSelectDrugRoute,
} from '../../../utils/common';
import { CHOICE_ACTION_EVENTS } from '../../../const/choice-actions';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '../../../material-components/Dialog';
import CreatePasswordSubmitModalFragment from '../CreatePasswordSubmitModalFragment';
import PasswordCodeModalFragment from '../PasswordCodeModalFragment';
import { WELCOME_MODAL_TYPES } from '../../../const/welcome-modal-types';
import { VERIFICATION_ACTIONS } from '../../../const/verification-actions';
import { USER_STATUSES } from '../../../const/status/user-status';
import LoadingSpinner from '../../LoadingSpinner';
import ErrorMessage from '../ErrorMessage';
import persistUser from '../../../common/persistUser';
import { checkUserStatus, wrapCatch } from '../../../utils/api';
import doccheckImg from '../../../images/doccheck.svg';

import './styles.less';
import NeedToLoginModalFragment from '../NeedToLoginModalFragment/NeedToLoginModalFragment';

type Props = {
  location: any,
  isOpen: boolean,
  onToggle: Function,
  onUserCheck: Function,
  onCodeSend: Function,
  onCodeVerify: Function,
  onLogin: Function,
  onLogout: Function,
  onDocCheckLogin: Function,
  onUpdateUser: Function,
  onGoBack: Function | null,
  error: string | null,
  t: Function,
  resetError: Function,
  validatePhoneNumberOrEmail: Function,
  validateEmail: Function,
  validatePhoneNumber: Function,
  comparePasswords: Function,
  validatePassword: Function,
  validateSecurityCode: Function,
};

type State = {
  phoneNumberOrEmail: string,
  securityCode: string,
  username: string,
  passwordNew: string,
  passwordRepeat: string,
  errorMessage: string,
  isGoingBack: boolean,
};

class LoginModal extends React.Component<Props, State> {
  state = {
    isAutoSubmitApplied: false,
    phoneNumberOrEmail: '',
    securityCode: '',
    dcUsername: '',
    username: '',
    password: '',
    passwordNew: '',
    passwordRepeat: '',
    isGoingBack: false,
    isEmailChangeEnabled: false,
    isPhoneNumberChangeEnabled: false,
    isSaveAccountChangesEnabled: false,
    isSubFlow: false,
  };

  successEvent: string;
  canceledEvent: string;

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.isOpen) {
      const phoneNumberOrEmail = _get(
        nextProps.location,
        'state.detail.phoneNumberOrEmail'
      );

      const codeFromPath = getCodeParam(nextProps.location.pathname);
      const emailFromPath = getEmailParam(nextProps.location.pathname);

      const email = _has(nextProps.location, 'state.detail.email')
        ? _get(nextProps.location, 'state.detail.email')
        : emailFromPath;
      const code = _has(nextProps.location, 'state.detail.code')
        ? _get(nextProps.location, 'state.detail.code')
        : codeFromPath;
      const isAutoSubmit =
        _get(nextProps.location, 'state.detail.isAutoSubmit') ||
        (emailFromPath && codeFromPath);

      if (phoneNumberOrEmail) {
        return { phoneNumberOrEmail };
      }

      if (nextProps.error) {
        return { errorMessage: nextProps.error };
      }

      if (email && code && !nextState.isAutoSubmit) {
        return {
          isAutoSubmit,
          phoneNumberOrEmail: email,
          securityCode: code,
        };
      }

      if (
        nextProps.layerClient.user &&
        nextProps.isLoggedIn &&
        !nextState.user
      ) {
        return {
          user: {
            ...nextProps.layerClient.user,
            ...nextProps.layerClient.user.metadata,
            notification_target: _get(
              nextProps.layerClient.user,
              'metadata.notification_target',
              nextProps.layerClient.user &&
                nextProps.layerClient.user.emailAddress &&
                nextProps.layerClient.user.emailAddress.length > 0
                ? 'email_address'
                : 'phone_number'
            ),
            emailAddress: _get(nextProps.layerClient.user, 'emailAddress', ''),
            phoneNumber: _get(nextProps.layerClient.user, 'phoneNumber', ''),
          },
        };
      }
    }

    return null;
  }

  async componentDidUpdate(prevProps) {
    if (this.props.newConversationId !== prevProps.newConversationId) {
      this.props.history.replace(`/questions/${this.props.newConversationId}`);
    }

    if (this.state.isAutoSubmit && !this.state.isAutoSubmitApplied) {
      this.handleFormAutoSubmit();
    }

    if (
      isLoginModalWindowRoute(this.props.location.pathname) &&
      (this.props.location.pathname !== prevProps.location.pathname ||
        _isUndefined(this.state.title))
    ) {
      const title = await this.getTitle();
      this.setState({ title });
    }

    if (
      isLoginModalWindowRoute(this.props.location.pathname) &&
      this.props.location.pathname !== prevProps.location.pathname &&
      this.props.location.pathname.includes('my-account')
    ) {
      this.handleSelectOptions();
      this.handleAccountErrors(this.props.accountErrors);
    }
  }

  handleFormAutoSubmit = () => {
    this.setState({ isAutoSubmitApplied: true });

    const submitButton = document.querySelector('[data-keystroke="true"]');

    if (submitButton) {
      submitButton.click();
    }
  };

  handleNotificationsChannelChange = ({ target }) => {
    if (target.getAttribute('disabled') === null) {
      this.handleAccountChange(
        'notification_target',
        target.getAttribute('data-value')
      );
    }
  };

  handleSelectOptions = () => {
    const [menu] = document.getElementsByClassName('mdc-menu');
    if (menu) {
      const menuClone = menu.cloneNode(true);
      const [select] = document.getElementsByClassName('select-wrapper');
      if (select) {
        const [list] = menuClone.getElementsByClassName('mdc-list');
        list.addEventListener('click', this.handleNotificationsChannelChange);
        select.appendChild(menuClone);
      }
    }
  };

  handleAccountErrors = (accountErrors) => {
    if (Object.keys(accountErrors).length !== 0) {
      for (const [key, value] of Object.entries(accountErrors)) {
        if (value === false) {
          const element = document.getElementById(`text-field-${key}`);
          element && element.classList.add('mdc-text-field--invalid');
        }
      }
    }
  };

  handleSaveAccountChanges = (state, name, value) => {
    const disableFields = ['emailAddress', 'phoneNumber'];

    const oppositeFields = {
      emailAddress: 'phoneNumber',
      phoneNumber: 'emailAddress',
    };

    if (disableFields.includes(name)) {
      const oppositeField = oppositeFields[name];
      return state.user[oppositeField].length > 0 && value.length === 0;
    }

    return true;
  };

  handleAccountChange = (name, value) =>
    this.setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        [name]: value.trim(),
      },
      isSaveAccountChangesEnabled: this.handleSaveAccountChanges(
        prevState,
        name,
        value
      ),
    }));

  sliceCode = (code) => {
    let clearedCode = code.replace(/\s/g, '').replace(/[^0-9]/g, '');
    if (clearedCode.length > 5) {
      return clearedCode.slice(0, 5);
    } else {
      return clearedCode;
    }
  };

  getModalType = () => {
    const modalType = _get(this.props.location, 'state.detail.modalType');

    if (!modalType) {
      const { pathname } = this.props.location;
      const redirectType = getRedirectTypeParam(pathname);
      return redirectType === 'code' ? 'login' : 'createPassword';
    }

    return modalType;
  };

  getDynamicTitle = async () => {
    const { pathname } = this.props.location;

    const modalType = this.getModalType() || 'login';
    const email = getEmailParam(pathname);
    const redirectType = getRedirectTypeParam(pathname);

    const dynamicTitleByModalType = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: 'LOGIN_RESET_PASSWORD_TITLE',
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: 'LOGIN_CREATE_PASSWORD_TITLE',
      [WELCOME_MODAL_TYPES.RESET_EMAIL]: 'LOGIN_CONFIRM_EMAIL_ADDRESS',
      [WELCOME_MODAL_TYPES.RESET_PHONE_NUMBER]: 'LOGIN_CONFIRM_MOBILE_NUMBER',
      [WELCOME_MODAL_TYPES.LOGIN]: 'LOGIN_TITLE',
      [WELCOME_MODAL_TYPES.REGISTER]: 'REGISTER_TITLE',
    };

    const dynamicTitleByUser = {
      [USER_STATUSES.UNKNOWN]:
        dynamicTitleByModalType[WELCOME_MODAL_TYPES.REGISTER],
      [USER_STATUSES.KNOWN]:
        redirectType === 'code'
          ? dynamicTitleByModalType[WELCOME_MODAL_TYPES.LOGIN]
          : dynamicTitleByModalType[WELCOME_MODAL_TYPES.CREATE_PASSWORD],
      [USER_STATUSES.PASSWORD]:
        dynamicTitleByModalType[WELCOME_MODAL_TYPES.RESET_PASSWORD],
    };

    if (email) {
      const { data: userStatus } = await wrapCatch(
        checkUserStatus({ query: email })
      );
      return this.props.t(dynamicTitleByUser[userStatus.data]);
    } else {
      return this.props.t(dynamicTitleByModalType[modalType]);
    }
  };

  getCodeSubmitButtonTitle = () => {
    const modalType = this.getModalType();

    const buttonTitle = {
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: 'LOGIN_NEXT_BUTTON',
      [WELCOME_MODAL_TYPES.LOGIN]: 'LOGIN_PASSWORD_LOGIN',
      [WELCOME_MODAL_TYPES.REGISTER]: 'COMMON_SUBMIT',
    };

    return this.props.t(
      buttonTitle[modalType] || buttonTitle[WELCOME_MODAL_TYPES.CREATE_PASSWORD]
    );
  };

  getTitle = async () => {
    const route = getModalWindowRoute(this.props.location.pathname);

    switch (route.pop()) {
      case 'login':
      case 'login-unregister':
      case 'send-code':
      case 'password':
        return this.props.t('LOGIN_TITLE');
      case 'code':
      case 'code-confirm':
      case 'create-password':
      case 'password-code':
      case 'password-submit':
      case 'password-confirm':
      case 'success':
      case 'welcome':
        return await this.getDynamicTitle();
      case 'doccheck':
      case 'doccheck-welcome':
        return this.props.t('LOGIN_DOCCHECK_TITLE');
      case 'need-to-login':
        return this.props.t('LOGIN_NEED_TO_LOGIN');
      case 'my-account':
        return this.props.t('LOGIN_MY_ACCOUNT');
      case 'logout':
        return config.RegistrationEnabled
          ? this.props.t('LOGIN_MY_ACCOUNT')
          : this.props.t('LOGOUT_DOCCHECK_TITLE');

      default:
        return '';
    }
  };

  getGreetingMessage = (username, welcomeTitle) => {
    const greetings = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: `${this.props.t(
        'LOGIN_CREATE_PASSWORD_HELLO'
      )}`,
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: `${this.props.t(
        'LOGIN_CREATE_PASSWORD_HELLO'
      )}`,
      [WELCOME_MODAL_TYPES.LOGIN]: `${this.props.t('LOGIN_WELCOME_HELLO', {
        username,
      })}`,
      [WELCOME_MODAL_TYPES.REGISTER]: `${this.props.t(
        'REGISTER_WELCOME_HELLO',
        { username }
      )}`,
    };

    return greetings[welcomeTitle];
  };

  getWelcomeBackMessage = (welcomeTitle) => {
    const messages = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: `${this.props.t(
        'LOGIN_WELCOME_BACK_CREATE_PASSWORD'
      )}`,
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: `${this.props.t(
        'LOGIN_WELCOME_BACK_CREATE_PASSWORD'
      )}`,
      [WELCOME_MODAL_TYPES.LOGIN]: `${this.props.t('LOGIN_WELCOME_BACK')}`,
      [WELCOME_MODAL_TYPES.REGISTER]: '',
    };

    return messages[welcomeTitle];
  };

  getSuccessMessage = (successTitle) => {
    const messages = {
      [WELCOME_MODAL_TYPES.RESET_EMAIL]: `${this.props.t(
        'LOGIN_CHANGE_EMAIL_SUCCESS'
      )}`,
      [WELCOME_MODAL_TYPES.RESET_PHONE_NUMBER]: `${this.props.t(
        'LOGIN_CHANGE_PHONE_NUMBER_SUCCESS'
      )}`,
    };

    return messages[successTitle];
  };

  handleSignUp = () => {
    const signUpMessage = this.props.t('LOGIN_I_WANT_TO_REGISTER');
    return this.props.createConversation(signUpMessage, {
      product: _get(this.props.product, 'id'),
      productName: _get(this.props.product, 'name'),
    });
  };

  handleRenderLoginFragment = ({ history }) => {
    const { phoneNumberOrEmail } = this.state;
    const {
      onUserCheck,
      validatePhoneNumberOrEmail,
      location: { hash },
    } = this.props;
    const loginState = this.props.loginState === 'loading';

    this.successEvent = CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.login_cancel;

    return (
      <AuthWrapper {...this.props} isAllowedWithoutAuth>
        <div>
          {loginState && <LoadingSpinner />}
          <LoginModalFragment
            phoneNumberOrEmail={phoneNumberOrEmail}
            onPhoneNumberOrEmailChange={(value) =>
              this.setState({ phoneNumberOrEmail: value })
            }
            onNext={() => {
              validatePhoneNumberOrEmail(phoneNumberOrEmail.trim()) &&
                wrapCatch(
                  onUserCheck({
                    query: phoneNumberOrEmail.trim(),
                  }).then(({ data }) => {
                    const knownNextRoute = config.TwoFactorAuthenticationEnabled
                      ? '/create-password'
                      : '/send-code';

                    const nextRoute = {
                      [USER_STATUSES.UNKNOWN]: '/login-unregister',
                      [USER_STATUSES.KNOWN]: knownNextRoute,
                      [USER_STATUSES.PASSWORD]: '/password',
                    };

                    const selectedRoute = nextRoute[data.data];
                    history.push({
                      pathname: this.path + selectedRoute,
                      hash,
                    });
                  })
                );
            }}
            onSignUp={this.handleSignUp}
          />
        </div>
      </AuthWrapper>
    );
  };

  handleRenderNeedToLoginFragment = ({ history }) => {
    return (
      <AuthWrapper {...this.props} isAllowedWithoutAuth>
        <NeedToLoginModalFragment
          onLogin={() => history.replace(this.path + '/login')}
          onRegister={this.handleSignUp}
        />
      </AuthWrapper>
    );
  };

  handleRenderLoginUnregisterFragment = ({ history }) => {
    const { phoneNumberOrEmail } = this.state;

    this.successEvent = CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.login_cancel;

    return (
      <AuthWrapper {...this.props}>
        <LoginUnregisterModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          onSignUp={this.handleSignUp}
        />
      </AuthWrapper>
    );
  };

  mapUserAccount = (user) => ({
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    disease_areas: user.disease_areas,
    zip_code: user.zip_code,
    city: user.city,
    institution: user.institution,
    notification_target: user.notification_target,
  });

  handleSaveUserChanges = ({ detail: user }) => {
    const { layerClient, onUpdateUser } = this.props;

    const data = [
      {
        operation: 'set',
        property: 'display_name',
        value: `${user.title} ${user.firstName} ${user.lastName}`,
      },
      { operation: 'set', property: 'metadata.title', value: user.title },
      {
        operation: 'set',
        property: 'metadata.disease_areas',
        value: user.disease_areas,
      },
      { operation: 'set', property: 'first_name', value: user.firstName },
      { operation: 'set', property: 'last_name', value: user.lastName },
      { operation: 'set', property: 'metadata.zip_code', value: user.zip_code },
      { operation: 'set', property: 'metadata.city', value: user.city },
      {
        operation: 'set',
        property: 'metadata.institution',
        value: user.institution,
      },
      {
        operation: 'set',
        property: 'metadata.notification_target',
        value: user.notification_target,
      },
    ];

    if (layerClient.userId) {
      onUpdateUser({
        userId: layerClient.userId,
        data,
      })
        .then(() => {
          persistUser({
            metadata: {
              display_name: `${user.title} ${user.firstName} ${user.lastName}`,
              notification_target: user.notification_target,
              title: user.title,
              disease_areas: user.disease_areas,
              institution: user.institution,
              zip_code: user.zip_code,
              city: user.city,
            },
            first_name: user.firstName,
            last_name: user.lastName,
          });

          this.setState({ isSaveAccountChangesEnabled: false });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  handleScrollCorrectionIOSDevice = () => {
    if (isIOS()) {
      const [dialog] = document.getElementsByClassName('mdc-dialog');
      dialog.scrollTop = 0;
    }
  };

  handleRenderMyAccount = ({ history }) => {
    const {
      accountErrors,
      location: { hash },
      layerClient,
      onAccountErrors,
      onCodeSend,
      onUserCheck,
      validateEmail,
      validatePhoneNumber,
      validateUser,
    } = this.props;

    return (
      <AuthWrapper {...this.props} isAuthRequired>
        <MyAccountModalFragment
          account={this.state.user}
          layerUser={layerClient.user}
          errors={accountErrors}
          isSaveAccountChangesEnabled={this.state.isSaveAccountChangesEnabled}
          isEmailChangeEnabled={this.state.isEmailChangeEnabled}
          onEnableEmailChange={() => {
            const query = this.getCredentialsFromLayerUser(
              layerClient.user,
              undefined
            );

            const passwordConfrimFlow = {
              pathname: this.path + '/password-confirm',
              hash,
              state: {
                detail: {
                  modalType: WELCOME_MODAL_TYPES.RESET_EMAIL,
                  fieldToEnable: 'isEmailChangeEnabled',
                },
              },
            };

            const codeConfrimFlow = {
              pathname: this.path + '/code-confirm',
              hash,
              state: {
                detail: {
                  modalType: WELCOME_MODAL_TYPES.RESET_EMAIL,
                  fieldToEnable: 'isEmailChangeEnabled',
                  credentialType: 'email_address',
                },
              },
            };

            wrapCatch(
              onUserCheck({ query }).then(({ data }) => {
                this.handleScrollCorrectionIOSDevice();

                if (this.state.isSaveAccountChangesEnabled) {
                  const isUserValid = validateUser(
                    this.state.user,
                    this.state.isEmailChangeEnabled,
                    this.state.isPhoneNumberChangeEnabled
                  );

                  const saveChangesRedirect = () =>
                    history.push({
                      pathname: this.path + '/save-changes',
                      hash,
                      state: {
                        detail: {
                          isUserValid,
                          backUrl: this.path + '/my-account',
                          saveChangesData: this.mapUserAccount(this.state.user),
                          saveChangesEvent: 'save-user-account',
                          nextFlow:
                            data.data === USER_STATUSES.PASSWORD
                              ? passwordConfrimFlow
                              : codeConfrimFlow,
                        },
                      },
                    });

                  this.onHide(saveChangesRedirect);
                } else {
                  if (data.data === USER_STATUSES.PASSWORD) {
                    history.push(passwordConfrimFlow);
                  } else {
                    history.push(codeConfrimFlow);
                  }
                }
              })
            );
          }}
          onEmailChange={() => {
            const credentialsFromLayerUser = this.getCredentialsFromLayerUser(
              layerClient.user,
              this.state.user.emailAddress
            );

            validateEmail(this.state.user.emailAddress) &&
              wrapCatch(
                onCodeSend({
                  user_id: this.props.userId,
                  target_url: '/questions/password-code',
                  credential: {
                    type: getCredentialType(credentialsFromLayerUser),
                    value: credentialsFromLayerUser,
                  },
                  update_credential: {
                    type: 'email_address',
                    value: this.state.user.emailAddress,
                  },
                  action: VERIFICATION_ACTIONS.UPDATE_PHONE_EMAIL,
                }).then(() => {
                  history.replace({
                    pathname: this.path + '/password-code',
                    hash,
                    state: {
                      detail: {
                        actionType: VERIFICATION_ACTIONS.UPDATE_PHONE_EMAIL,
                        modalType: WELCOME_MODAL_TYPES.RESET_EMAIL,
                        updatedPhoneNumberOrEmail: this.state.user.emailAddress,
                      },
                    },
                  });

                  this.setState({ isEmailChangeEnabled: false });
                })
              );
          }}
          isPhoneNumberChangeEnabled={this.state.isPhoneNumberChangeEnabled}
          onEnablePhoneNumberChange={() => {
            const query = this.getCredentialsFromLayerUser(
              layerClient.user,
              undefined
            );

            const passwordConfrimFlow = {
              pathname: this.path + '/password-confirm',
              hash,
              state: {
                detail: {
                  modalType: WELCOME_MODAL_TYPES.RESET_PHONE_NUMBER,
                  fieldToEnable: 'isPhoneNumberChangeEnabled',
                },
              },
            };

            const codeConfrimFlow = {
              pathname: this.path + '/code-confirm',
              hash,
              state: {
                detail: {
                  modalType: WELCOME_MODAL_TYPES.RESET_PHONE_NUMBER,
                  fieldToEnable: 'isPhoneNumberChangeEnabled',
                  credentialType: 'phone_number',
                },
              },
            };

            wrapCatch(
              onUserCheck({ query }).then(({ data }) => {
                this.handleScrollCorrectionIOSDevice();

                if (this.state.isSaveAccountChangesEnabled) {
                  const isUserValid = validateUser(
                    this.state.user,
                    this.state.isEmailChangeEnabled,
                    this.state.isPhoneNumberChangeEnabled
                  );

                  const saveChangesRedirect = () =>
                    history.push({
                      pathname: this.path + '/save-changes',
                      hash,
                      state: {
                        detail: {
                          isUserValid,
                          backUrl: this.path + '/my-account',
                          saveChangesData: this.mapUserAccount(this.state.user),
                          saveChangesEvent: 'save-user-account',
                          nextFlow:
                            data.data === USER_STATUSES.PASSWORD
                              ? passwordConfrimFlow
                              : codeConfrimFlow,
                        },
                      },
                    });

                  this.onHide(saveChangesRedirect);
                } else {
                  if (data.data === USER_STATUSES.PASSWORD) {
                    history.push(passwordConfrimFlow);
                  } else {
                    history.push(codeConfrimFlow);
                  }
                }
              })
            );
          }}
          onPhoneNumberChange={() => {
            const credentialsFromLayerUser = this.getCredentialsFromLayerUser(
              layerClient.user,
              this.state.user.phoneNumber
            );

            validatePhoneNumber(this.state.user.phoneNumber) &&
              wrapCatch(
                onCodeSend({
                  user_id: this.props.userId,
                  target_url: '/questions/password-code',
                  credential: {
                    type: getCredentialType(credentialsFromLayerUser),
                    value: credentialsFromLayerUser,
                  },
                  update_credential: {
                    type: 'phone_number',
                    value: this.state.user.phoneNumber,
                  },
                  action: VERIFICATION_ACTIONS.UPDATE_PHONE_EMAIL,
                }).then(() => {
                  history.replace({
                    pathname: this.path + '/password-code',
                    hash,
                    state: {
                      detail: {
                        actionType: VERIFICATION_ACTIONS.UPDATE_PHONE_EMAIL,
                        modalType: WELCOME_MODAL_TYPES.RESET_PHONE_NUMBER,
                        updatedPhoneNumberOrEmail: this.state.user.phoneNumber,
                      },
                    },
                  });

                  this.setState({ isPhoneNumberChangeEnabled: false });
                })
              );
          }}
          onAccountChange={(name, value) => {
            onAccountErrors(name, value);
            this.handleAccountChange(name, value);
          }}
          onUpdateAcccount={(e) => {
            e.preventDefault();

            const isUserValid = validateUser(
              this.state.user,
              this.state.isEmailChangeEnabled,
              this.state.isPhoneNumberChangeEnabled
            );

            if (isUserValid) {
              const loginType =
                this.state.user.emailAddress.length === 0
                  ? 'EMAIL'
                  : this.state.user.phoneNumber.length === 0
                  ? 'NUMBER'
                  : undefined;
              this.onClose(e, loginType, false);
            }
          }}
          onResetPassword={() => {
            this.handleScrollCorrectionIOSDevice();

            const resetPasswordFlow = {
              pathname: this.path + '/create-password',
              hash,
              state: {
                detail: { modalType: WELCOME_MODAL_TYPES.RESET_PASSWORD },
              },
            };

            if (this.state.isSaveAccountChangesEnabled) {
              const isUserValid = validateUser(
                this.state.user,
                this.state.isEmailChangeEnabled,
                this.state.isPhoneNumberChangeEnabled
              );

              const saveChangesRedirect = () =>
                history.push({
                  pathname: this.path + '/save-changes',
                  hash,
                  state: {
                    detail: {
                      isUserValid,
                      backUrl: this.path + '/my-account',
                      saveChangesData: this.mapUserAccount(this.state.user),
                      saveChangesEvent: 'save-user-account',
                      nextFlow: resetPasswordFlow,
                    },
                  },
                });

              this.onHide(saveChangesRedirect);
            } else {
              history.push(resetPasswordFlow);
            }
          }}
          onLogout={(e) => {
            e.preventDefault();
            this.handleScrollCorrectionIOSDevice();
            const hash = this.props.location.hash || '';
            history.replace(this.path + '/logout' + hash);
          }}
          onCancel={(e) => {
            this.onClose(e);
          }}
        />
      </AuthWrapper>
    );
  };

  handleRenderLogoutFragment = ({ history }) => {
    return (
      <LogoutModalFragment
        onLogout={() => {
          this.props.onLogout();
          history.goBack();
        }}
        onCancel={() => {
          history.goBack();
        }}
      />
    );
  };

  handleUpdateUser = (onUpdateUser, layerClient, history) => {
    const metadata = {
      is_hcp: 'true', // yes, string
    };

    onUpdateUser({
      userId: layerClient.userId,
      data: [
        {
          operation: 'set',
          property: 'metadata.is_hcp',
          value: metadata.is_hcp,
        },
      ],
    })
      .then(() => {
        persistUser({ metadata });
        history.replace(this.path + '/doccheck-welcome');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          layerClient.trigger('challenge');
          layerClient.once('authenticated', () => {
            if (layerClient.userId) {
              this.handleUpdateUser(onUpdateUser, layerClient, history);
            }
          });
        }
      });
  };

  handleRenderDocCheckFragment = ({ history }) => {
    const { dcUsername, password } = this.state;
    const { onDocCheckLogin, onUpdateUser, layerClient } = this.props;
    const loginState = this.props.loginState === 'loading';

    this.successEvent = CHOICE_ACTION_EVENTS.doccheck_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.doccheck_cancel;

    return (
      <div>
        {loginState && <LoadingSpinner />}
        <DocCheckModalFragment
          username={dcUsername}
          password={password}
          onUsernameChange={(value) => this.setState({ dcUsername: value })}
          onPasswordChange={(value) => this.setState({ password: value })}
          onNext={() => {
            onDocCheckLogin({
              username: this.state.dcUsername.trim(),
              password: this.state.password.trim(),
            })
              .then((response) => {
                if (response.data.data === 'ok') {
                  this.setState({
                    username: this.state.dcUsername,
                  });

                  if (layerClient.userId) {
                    this.handleUpdateUser(onUpdateUser, layerClient, history);
                  }
                } else {
                  this.setState({
                    loginState: 'error',
                    errorMessage: this.props.t(
                      'ERROR_WRONG_CREDENTIALS_SUPPLIED'
                    ),
                  });
                }
              })
              .catch((err) => {
                this.setState({
                  loginState: 'error',
                  loginError: err,
                });
              });
          }}
        />
      </div>
    );
  };

  handleRenderDocCheckWelcomeFragment = ({ history }) => {
    const { username } = this.state;

    return (
      <DocCheckWelcomeModalFragment
        fullName={username}
        onAcknowledge={(e) => {
          this.setState(
            {
              password: '',
            },
            () => {
              this.onClose(e);
            }
          );
        }}
      />
    );
  };

  handleRenderSendCodeFragment = ({ history }) => {
    const { phoneNumberOrEmail } = this.state;
    const {
      onCodeSend,
      location: { hash },
    } = this.props;
    const loginState = this.props.loginState === 'loading';
    const createPasswordLink = this.props.t(
      'LOGIN_SEND_CODE_CREATE_A_PASSWORD'
    );

    return (
      <AuthWrapper {...this.props}>
        <div>
          {loginState && <LoadingSpinner />}
          <SendCodeModalFragment
            loginState={this.props.loginState}
            phoneNumberOrEmail={phoneNumberOrEmail}
            onRequestCode={() => {
              wrapCatch(
                onCodeSend({
                  user_id: this.props.userId,
                  target_url: '/questions/code',
                  credential: {
                    type: getCredentialType(phoneNumberOrEmail),
                    value: phoneNumberOrEmail,
                  },
                  action: VERIFICATION_ACTIONS.LOGIN,
                }).then(() => {
                  history.push({
                    pathname: this.path + '/code',
                    hash,
                    state: { detail: { modalType: WELCOME_MODAL_TYPES.LOGIN } },
                  });
                })
              );
            }}
            onCreatePassword={() =>
              history.replace({
                pathname: this.path + '/create-password',
                hash,
                state: {
                  detail: { modalType: WELCOME_MODAL_TYPES.CREATE_PASSWORD },
                },
              })
            }
            createPasswordLink={createPasswordLink}
          />
        </div>
      </AuthWrapper>
    );
  };

  handleRenderCreatePasswordFragment = ({ history }) => {
    const { phoneNumberOrEmail } = this.state;
    const {
      onCodeSend,
      location: { hash },
      layerClient: { user },
    } = this.props;
    const loginState = this.props.loginState === 'loading';

    const modalType = this.getModalType();
    const phoneNumberOrEmailUpdated = !phoneNumberOrEmail
      ? this.getCredentialsFromLayerUser(
          user,
          user.metadata.notification_target
        )
      : phoneNumberOrEmail;

    return (
      <AuthWrapper {...this.props}>
        <div>
          {loginState && <LoadingSpinner />}
          <ResetPasswordModalFragment
            phoneNumberOrEmail={phoneNumberOrEmailUpdated}
            onRequestCode={() => {
              wrapCatch(
                onCodeSend({
                  user_id: this.props.userId,
                  target_url: '/questions/password-code',
                  credential: {
                    type: getCredentialType(phoneNumberOrEmailUpdated),
                    value: phoneNumberOrEmailUpdated,
                  },
                  action: VERIFICATION_ACTIONS.RESET_PASSWORD,
                }).then(() =>
                  history.replace({
                    pathname: this.path + '/password-code',
                    hash,
                    state: {
                      detail: {
                        modalType,
                        updatedPhoneNumberOrEmail: phoneNumberOrEmailUpdated,
                      },
                    },
                  })
                )
              );
            }}
          />
        </div>
      </AuthWrapper>
    );
  };

  getCredentialsFromLayerUser = (
    { emailAddress, phoneNumber },
    newTypeCredentials
  ) => {
    const credentials = {
      [isEmailParam(newTypeCredentials)]: {
        value: emailAddress,
        alternativeValue: phoneNumber,
      },
      [isPhoneNumber(newTypeCredentials)]: {
        value: phoneNumber,
        alternativeValue: emailAddress,
      },
      [newTypeCredentials === 'email_address']: {
        value: emailAddress,
        alternativeValue: phoneNumber,
      },
      [newTypeCredentials === 'phone_number']: {
        value: phoneNumber,
        alternativeValue: emailAddress,
      },
    };

    if (credentials[true] && newTypeCredentials) {
      return credentials[true].value || credentials[true].alternativeValue;
    } else {
      return emailAddress || phoneNumber;
    }
  };

  handleRenderPasswordCodeFragment = ({ history }) => {
    const { securityCode, phoneNumberOrEmail } = this.state;
    const {
      onCodeSend,
      onCodeVerify,
      onUpdatePhoneEmail,
      validateSecurityCode,
      location: { hash },
      layerClient: { user },
    } = this.props;
    const loginState = this.props.loginState === 'loading';
    const actionType = _get(history.location, 'state.detail.actionType');
    const updatedPhoneNumberOrEmail = _get(
      history.location,
      'state.detail.updatedPhoneNumberOrEmail'
    );

    const modalType = this.getModalType();
    const submitButtonTitle = this.getCodeSubmitButtonTitle();
    const phoneNumberOrEmailUpdated = !phoneNumberOrEmail
      ? this.getCredentialsFromLayerUser(user, updatedPhoneNumberOrEmail)
      : phoneNumberOrEmail;

    const userUpdates = {
      [isEmail(updatedPhoneNumberOrEmail) ? 'email_address' : 'phone_number']:
        updatedPhoneNumberOrEmail,
    };

    return (
      <div>
        {loginState && <LoadingSpinner />}
        <PasswordCodeModalFragment
          phoneNumberOrEmail={updatedPhoneNumberOrEmail}
          code={securityCode}
          submitButtonTitle={submitButtonTitle}
          onCodeChange={(value) =>
            this.setState({ securityCode: this.sliceCode(value) })
          }
          onNext={async () => {
            const response =
              validateSecurityCode(securityCode) &&
              (await onCodeVerify({
                credential: {
                  type: getCredentialType(phoneNumberOrEmailUpdated),
                  value: phoneNumberOrEmailUpdated,
                },
                verification_code: securityCode,
              }).catch(() => this.setState({ securityCode: '' })));

            if (response) {
              const { username, password_code, update_code } = response;

              this.setState({ username, securityCode: '' }, async () => {
                if (password_code) {
                  history.replace({
                    pathname: this.path + '/password-submit',
                    hash,
                    state: { detail: { modalType, password_code } },
                  });
                } else {
                  persistUser(userUpdates);
                  await wrapCatch(
                    onUpdatePhoneEmail({
                      credential: {
                        type: getCredentialType(phoneNumberOrEmailUpdated),
                        value: phoneNumberOrEmailUpdated,
                      },
                      update_credential: {
                        type: getCredentialType(updatedPhoneNumberOrEmail),
                        value: updatedPhoneNumberOrEmail,
                      },
                      update_code,
                    }).then(() => {
                      history.replace({
                        pathname: this.path + '/success',
                        hash,
                        state: { detail: { modalType } },
                      });
                    })
                  );
                }
              });
            }
          }}
          onRequestCode={() => {
            wrapCatch(
              onCodeSend({
                user_id: this.props.userId,
                target_url: '/questions/password-code',
                credential: {
                  type: getCredentialType(phoneNumberOrEmailUpdated),
                  value: phoneNumberOrEmailUpdated,
                },
                update_credential: {
                  type: getCredentialType(updatedPhoneNumberOrEmail),
                  value: updatedPhoneNumberOrEmail,
                },
                action: actionType,
              })
            );
          }}
        />
      </div>
    );
  };

  handleRenderCodeFragment = ({ history }) => {
    const { securityCode, phoneNumberOrEmail } = this.state;
    const {
      onCodeSend,
      onCodeVerify,
      validateSecurityCode,
      location: { hash },
      layerClient: { user },
    } = this.props;
    const loginState = this.props.loginState === 'loading';

    const submitButtonTitle = this.getCodeSubmitButtonTitle();
    const modalType = this.getModalType();
    const fieldToEnable = _get(
      this.props.location,
      'state.detail.fieldToEnable'
    );
    const credentialType = _get(
      this.props.location,
      'state.detail.credentialType'
    );
    const isRegister = modalType === WELCOME_MODAL_TYPES.REGISTER;

    const phoneNumberOrEmailUpdated = !phoneNumberOrEmail
      ? this.getCredentialsFromLayerUser(user, credentialType)
      : phoneNumberOrEmail;

    if (isRegister) {
      this.successEvent = CHOICE_ACTION_EVENTS.confirm_register_cancel;
      this.canceledEvent = CHOICE_ACTION_EVENTS.confirm_register_cancel;
    }

    return (
      <div>
        {loginState && <LoadingSpinner />}
        <CodeModalFragment
          phoneNumberOrEmail={phoneNumberOrEmailUpdated}
          code={securityCode}
          submitButtonTitle={submitButtonTitle}
          onCodeChange={(value) =>
            this.setState({ securityCode: this.sliceCode(value) })
          }
          onRequestCode={() => {
            wrapCatch(
              onCodeSend({
                user_id: this.props.userId,
                target_url: '/questions/code',
                credential: {
                  type: getCredentialType(phoneNumberOrEmailUpdated),
                  value: phoneNumberOrEmailUpdated,
                },
                action: VERIFICATION_ACTIONS.REGISTER,
              })
            );
          }}
          onLogin={() => {
            validateSecurityCode(securityCode) &&
              onCodeVerify({
                credential: {
                  type: getCredentialType(phoneNumberOrEmailUpdated),
                  value: phoneNumberOrEmailUpdated,
                },
                verification_code: securityCode,
              })
                .then(({ username }) =>
                  this.setState({ username, securityCode: '' }, () => {
                    if (fieldToEnable) {
                      this.setState({ [fieldToEnable]: true }, () =>
                        history.replace(this.path + '/my-account')
                      );
                    } else {
                      history.replace({
                        pathname: this.path + '/welcome',
                        hash,
                        state: { detail: { modalType } },
                      });
                    }
                  })
                )
                .catch(() => this.setState({ securityCode: '' }));
          }}
        />
      </div>
    );
  };

  handleRenderPasswordFragment = ({ history }) => {
    const { phoneNumberOrEmail, password } = this.state;
    const {
      onAuthenticate,
      onLogin,
      validatePassword,
      location: { hash },
    } = this.props;
    const loginState = this.props.loginState === 'loading';
    const resetPasswordLink = this.props.t('LOGIN_PASSWORD_FORGOTTEN');

    return (
      <AuthWrapper {...this.props}>
        <div>
          {loginState && <LoadingSpinner />}
          <PasswordModalFragment
            phoneNumberOrEmail={phoneNumberOrEmail}
            password={password}
            onPasswordChange={(value) => this.setState({ password: value })}
            onLogin={async () => {
              const isPasswordValid = validatePassword(
                password.trim(),
                'passwordFragment'
              );

              if (isPasswordValid) {
                if (config.TwoFactorAuthenticationEnabled) {
                  onAuthenticate({
                    credential: {
                      type: getCredentialType(phoneNumberOrEmail),
                      value: phoneNumberOrEmail.trim(),
                    },
                    password: password.trim(),
                  })
                    .then(() => {
                      const sendCodeFlow = {
                        pathname: this.path + '/code',
                        hash,
                        state: {
                          detail: {
                            modalType: WELCOME_MODAL_TYPES.LOGIN,
                            credentialType:
                              getCredentialType(phoneNumberOrEmail),
                          },
                        },
                      };

                      history.push(sendCodeFlow);
                    })
                    .catch(() => this.setState({ password: '' }));
                } else {
                  onLogin({
                    credential: {
                      type: getCredentialType(phoneNumberOrEmail),
                      value: phoneNumberOrEmail.trim(),
                    },
                    password: password.trim(),
                  })
                    .then(({ username }) => {
                      this.setState({ username }, () =>
                        history.replace({
                          pathname: this.path + '/welcome',
                          hash,
                          state: {
                            detail: { modalType: WELCOME_MODAL_TYPES.LOGIN },
                          },
                        })
                      );
                    })
                    .catch(() => this.setState({ password: '' }));
                }
              }
            }}
            onResetPassword={() =>
              history.push({
                pathname: this.path + '/create-password',
                hash,
                state: {
                  detail: { modalType: WELCOME_MODAL_TYPES.RESET_PASSWORD },
                },
              })
            }
            resetPasswordLink={resetPasswordLink}
          />
        </div>
      </AuthWrapper>
    );
  };

  handleRenderPasswordSubmitFragment = ({ history }) => {
    const { phoneNumberOrEmail, passwordNew, passwordRepeat } = this.state;
    const {
      comparePasswords,
      onUpsertPassword,
      validatePassword,
      location: { hash },
      layerClient: { user },
    } = this.props;
    const loginState = this.props.loginState === 'loading';

    const modalType = this.getModalType();
    const password_code = _get(
      this.props.location,
      'state.detail.password_code'
    );
    const phoneNumberOrEmailUpdated = !phoneNumberOrEmail
      ? user.emailAddress || user.phoneNumber
      : phoneNumberOrEmail;

    return (
      <AuthWrapper {...this.props}>
        <div>
          {loginState && <LoadingSpinner />}
          <CreatePasswordSubmitModalFragment
            phoneNumberOrEmail={phoneNumberOrEmailUpdated}
            passwordNew={passwordNew}
            passwordRepeat={passwordRepeat}
            onPasswordNewChange={(value) =>
              this.setState({ passwordNew: value })
            }
            onPasswordRepeatChange={(value) =>
              this.setState({ passwordRepeat: value })
            }
            onLogin={() => {
              validatePassword(passwordNew.trim()) &&
                comparePasswords(passwordNew.trim(), passwordRepeat.trim()) &&
                onUpsertPassword({
                  credential: {
                    type: getCredentialType(phoneNumberOrEmailUpdated),
                    value: phoneNumberOrEmailUpdated,
                  },
                  password_code,
                  password: passwordNew.trim(),
                })
                  .then(({ data: { username } }) =>
                    this.setState({ username }, () =>
                      history.replace({
                        pathname: this.path + '/welcome',
                        hash,
                        state: { detail: { modalType } },
                      })
                    )
                  )
                  .catch(() =>
                    this.setState({ passwordNew: '', passwordRepeat: '' })
                  );
            }}
          />
        </div>
      </AuthWrapper>
    );
  };

  handleRenderPasswordConfirmFragment = ({ history }) => {
    const { password } = this.state;
    const {
      onPasswordCheck,
      layerClient,
      location: { hash },
      validatePassword,
    } = this.props;
    const loginState = this.props.loginState === 'loading';

    const fieldToEnable = _get(history.location, 'state.detail.fieldToEnable');

    const credentialsFromLayerUser = this.getCredentialsFromLayerUser(
      layerClient.user,
      undefined
    );

    return (
      <div>
        {loginState && <LoadingSpinner />}
        <PasswordConfirmModalFragment
          password={password}
          onPasswordChange={(password) => this.setState({ password })}
          onPasswordConfirm={() =>
            validatePassword(password.trim(), 'passwordFragment') &&
            onPasswordCheck({
              credential: {
                type: getCredentialType(credentialsFromLayerUser),
                value: credentialsFromLayerUser,
              },
              password: password.trim(),
            })
              .then(() =>
                this.setState({ [fieldToEnable]: true, password: '' }, () =>
                  history.replace(this.path + '/my-account')
                )
              )
              .catch(() => this.setState({ password: '' }))
          }
          onPasswordReset={() =>
            this.setState({ isSubFlow: true }, () =>
              history.push({
                pathname: this.path + '/create-password',
                hash,
                state: {
                  detail: { modalType: WELCOME_MODAL_TYPES.RESET_PASSWORD },
                },
              })
            )
          }
        />
      </div>
    );
  };

  handleRenderCodeConfirmFragment = ({ history }) => {
    const {
      layerClient,
      location: { hash },
      onCodeSend,
    } = this.props;
    const loginState = this.props.loginState === 'loading';
    const modalType = this.getModalType();
    const fieldToEnable = _get(
      this.props.location,
      'state.detail.fieldToEnable'
    );
    const credentialType = _get(
      this.props.location,
      'state.detail.credentialType'
    );

    const credentialsFromLayerUser = this.getCredentialsFromLayerUser(
      layerClient.user,
      credentialType
    );

    return (
      <div>
        {loginState && <LoadingSpinner />}
        <CodeConfirmModalFragment
          onRequestCode={() => {
            wrapCatch(
              onCodeSend({
                user_id: this.props.userId,
                target_url: '/questions/code',
                credential: {
                  type: getCredentialType(credentialsFromLayerUser),
                  value: credentialsFromLayerUser,
                },
                action: VERIFICATION_ACTIONS.LOGIN,
              }).then(() => {
                history.push({
                  pathname: this.path + '/code',
                  hash,
                  state: {
                    detail: {
                      modalType,
                      fieldToEnable,
                      credentialType,
                    },
                  },
                });
              })
            );
          }}
        />
      </div>
    );
  };

  handleRenderWelcomeFragment = ({ history }) => {
    const { username } = this.state;
    const modalType = this.getModalType() || WELCOME_MODAL_TYPES.LOGIN;
    const isRegister = modalType === WELCOME_MODAL_TYPES.REGISTER;

    const greetingMessage = this.getGreetingMessage(username, modalType);
    const welcomeBackMessage = this.getWelcomeBackMessage(modalType);
    const acknowledgeButton = this.props.t('LOGIN_WELCOME_OK');

    this.successEvent = isRegister
      ? CHOICE_ACTION_EVENTS.confirm_register_success
      : CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = isRegister
      ? CHOICE_ACTION_EVENTS.confirm_register_success
      : CHOICE_ACTION_EVENTS.login_success;

    return (
      <AuthWrapper {...this.props}>
        <WelcomeModalFragment
          isRegister={isRegister}
          greetingMessage={greetingMessage}
          welcomeBackMessage={welcomeBackMessage}
          acknowledgeButton={acknowledgeButton}
          onAcknowledge={(e) => {
            this.setState(
              {
                securityCode: '',
                password: '',
              },
              () => {
                this.onClose(e);
              }
            );
          }}
        />
      </AuthWrapper>
    );
  };

  handleRenderSuccessFragment = () => {
    const modalType = this.getModalType();
    const successMessage = this.getSuccessMessage(modalType);
    const acknowledgeButton = this.props.t('LOGIN_WELCOME_OK');

    return (
      <AuthWrapper {...this.props}>
        <SuccessModalFragment
          successMessage={successMessage}
          acknowledgeButton={acknowledgeButton}
          onAcknowledge={this.onClose}
        />
      </AuthWrapper>
    );
  };

  renderBackButton() {
    const { onGoBack } = this.props;
    if (!onGoBack) {
      return null;
    }

    return (
      <div style={{ position: 'absolute', top: '-9px', left: '0px' }}>
        <IconButton
          id="back-login"
          size="small"
          className="mdc-theme--primary backBtn"
          onClick={() =>
            this.setState({ isGoingBack: true }, () => {
              // back transition takes 400ms, so wait 800ms to be save,  even on ms edge
              setTimeout(() => this.setState({ isGoingBack: false }), 800);
              onGoBack();
            })
          }
        >
          <MaterialIcon icon="arrow_back" />
        </IconButton>
      </div>
    );
  }

  renderCloseButton() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '-11px',
          right: '0px',
          color: 'xSlateLighter',
        }}
      >
        <IconButton
          id="close-login"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  onEnterErrorMessage = () => {
    this.setState({ errorMessage: this.props.error });
  };

  onExitedErrorMessage = () => {
    this.setState({ errorMessage: null });
  };

  renderError() {
    const { error, resetError } = this.props;
    const { errorMessage } = this.state;

    return (
      <ErrorMessage
        error={error}
        errorMessage={errorMessage}
        resetError={resetError}
        onEnterErrorMessage={this.onEnterErrorMessage}
        onExitedErrorMessage={this.onExitedErrorMessage}
      />
    );
  }

  clearState = () =>
    this.setState({
      username: '',
      user: undefined,
      isEmailChangeEnabled: false,
      isPhoneNumberChangeEnabled: false,
      isSaveAccountChangesEnabled: false,
      isSubFlow: false,
    });

  onClose = (ev, loginType) => {
    const {
      history,
      location: { hash },
      onToggle,
      validateUser,
    } = this.props;

    if (this.state.isSaveAccountChangesEnabled) {
      const isUserValid = validateUser(
        this.state.user,
        this.state.isEmailChangeEnabled,
        this.state.isPhoneNumberChangeEnabled
      );

      const saveChangesRedirect = () =>
        history.push({
          pathname: this.path + '/save-changes',
          hash,
          state: {
            detail: {
              isUserValid,
              backUrl: this.path,
              saveChangesData: this.mapUserAccount(this.state.user),
              saveChangesEvent: 'save-user-account',
              nextFlow: this.path,
            },
          },
        });

      return this.onHide(saveChangesRedirect);
    }

    if (this.state.username) {
      this.successEvent && window.dispatchEvent(new Event(this.successEvent));
    } else {
      this.canceledEvent && window.dispatchEvent(new Event(this.canceledEvent));
    }

    this.successEvent = null;
    this.canceledEvent = null;

    if (this.node && !this.state.isSubFlow) {
      this.node.classList.remove('mdc-dialog--open');
    }

    this.clearState();
    return ev && onToggle(ev, this.props.location, loginType);
  };

  onHide = (redirect) => {
    const { resetAccountErrors } = this.props;

    if (this.node) {
      this.node.classList.remove('mdc-dialog--open');
    }
    setTimeout(() => {
      redirect();
      resetAccountErrors();
      this.clearState();
    }, 100);
  };

  isDoccheck = (title) => title === 'DocCheck-Login';

  keyDown = (event) => {
    if (isEscButtonPressed(event)) {
      event.preventDefault();
      this.props.isOpen && this.onClose(event);
    }
  };

  async componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener(
      'click',
      (event) =>
        this.props.isOpen && !this.isMyAccountRoute && this.onClose(event)
    );
    this.node.addEventListener('keydown', this.keyDown, true);
    window.addEventListener('save-user-account', this.handleSaveUserChanges);

    if (this.props.location.pathname.includes('my-account')) {
      setTimeout(() => this.handleSelectOptions(), 500);
    }

    if (
      isLoginModalWindowRoute(this.props.location.pathname) &&
      _isUndefined(this.state.title)
    ) {
      const title = await this.getTitle();
      this.setState({ title });
    }

    window.addEventListener('captcha-failed', this.handleCaptchaFailed);
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener('keydown', this.keyDown);
    window.removeEventListener('save-user-account', this.handleSaveUserChanges);
    window.removeEventListener('captcha-failed', this.handleCaptchaFailed);
  }

  handleCaptchaFailed = (e) => this.props.isOpen && this.onClose(e);

  needsPageAnimation = () => !this.props.prevLocation.includes('/my-account');

  getBasePath = (match) => {
    if (isSelectDrugRoute(match.url)) {
      return;
    }

    if (
      match.params.productName &&
      !match.params.documentPath &&
      !match.url.includes('fachinfo')
    ) {
      if (match.url.includes('medikamente')) {
        /* eslint-disable no-unused-vars */
        const modalRoute = getModalWindowRoute(match.url);

        if (modalRoute) {
          const [_fullPath, baseModalPath] = modalRoute;
          return baseModalPath;
        }

        return match.url;
      }

      return `/${match.params.productName}`;
    }

    const { conversationId, ...restParams } = match.params;
    const params = _values(restParams);

    let basePath = '';
    if (isDocumentsModalRoute(match.url)) {
      /* eslint-disable no-unused-vars */
      const [_fullPath, baseModalPath] = getModalWindowRoute(match.url);
      basePath = baseModalPath;
    } else {
      basePath = match.url.includes('medikamente')
        ? getPathnameWithoutModal(match.url)
        : params.reduce((acc, cur) => acc.replace('/' + cur, ''), match.url);
    }

    const clearPath = basePath.replace(/(\/password-code|\/code)/, '');
    return clearPath || '';
  };

  render() {
    const { isOpen } = this.props;

    this.path = this.props.location.pathname
      .split('/')
      .filter((p) => p !== '' && !isEmailParam(p) && !Number.isInteger(+p))
      .slice(0, -1)
      .map((p) => `/${p}`)
      .join('');
    this.isMyAccountRoute = isMyAccountRoute(this.props.location.pathname);
    const isDoccheckVisible = this.isDoccheck(this.state.title);

    let key = this.props.location.key;
    if (this.needsPageAnimation()) {
      this.lastLocationKey = this.props.location.key;
    } else {
      key = this.lastLocationKey;
    }

    return (
      <Dialog
        className={`xircles-login-dialog ${
          this.isMyAccountRoute ? 'xircles-my-account-dialog' : ''
        }`}
        open={isOpen && this.state.title}
        scrimClickAction={this.isMyAccountRoute && ''}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {this.renderError()}
          {this.renderBackButton()}
          {this.state.title}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          {!!isDoccheckVisible && (
            <img
              className="doccheck-doc-image"
              src={doccheckImg}
              alt={'DocCheck'}
            />
          )}
          <div className={`my-wrapper${this.state.isGoingBack ? ' back' : ''}`}>
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={key}
                timeout={{ enter: 400, exit: 400 }}
                classNames="fade"
              >
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route
                      path={[
                        '/questions/:conversationId?/*',
                        '/questions/deleted-requests/*',
                        '/medikamente/katalog/:pagination/:subpagination?/*',
                        '/medikamente/:productName/:documentPath/*',
                        '/medikamente/:productName/*',
                        '/medikamente/*',
                        '/documents/*',
                        '/:productName/*',
                        '/:productName/deleted-requests/*',
                        '/*',
                      ]}
                      render={({ match }) => {
                        const basePath = this.getBasePath(match);
                        return (
                          <Switch>
                            <Route
                              exact
                              path={basePath + '/login'}
                              render={this.handleRenderLoginFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/need-to-login'}
                              render={this.handleRenderNeedToLoginFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/login-unregister'}
                              render={this.handleRenderLoginUnregisterFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/my-account'}
                              render={this.handleRenderMyAccount}
                            />
                            <Route
                              exact
                              path={basePath + '/logout'}
                              render={this.handleRenderLogoutFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/send-code'}
                              render={this.handleRenderSendCodeFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/password-code/:email?/:code?'}
                              render={this.handleRenderPasswordCodeFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/create-password'}
                              render={this.handleRenderCreatePasswordFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/password-submit'}
                              render={this.handleRenderPasswordSubmitFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/password-confirm'}
                              render={this.handleRenderPasswordConfirmFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/code-confirm'}
                              render={this.handleRenderCodeConfirmFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/code/:email?/:code?'}
                              render={this.handleRenderCodeFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/password'}
                              render={this.handleRenderPasswordFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/welcome'}
                              render={this.handleRenderWelcomeFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/success'}
                              render={this.handleRenderSuccessFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/doccheck-welcome'}
                              render={this.handleRenderDocCheckWelcomeFragment}
                            />
                            <Route
                              exact
                              path={basePath + '/doccheck'}
                              render={this.handleRenderDocCheckFragment}
                            />
                          </Switch>
                        );
                      }}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(LoginModal));
