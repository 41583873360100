import { sessionKey, sessionStoreKey } from './persistSession';

export default function loadSession() {
  const session = localStorage.getItem(sessionKey);
  return session && JSON.parse(session);
}

export function loadSessionStore() {
  const sessionStore = localStorage.getItem(sessionStoreKey);
  return sessionStore && JSON.parse(sessionStore);
}

export function loadFromSession(name) {
  const sessionStore = loadSessionStore();
  return sessionStore && sessionStore[name];
}
