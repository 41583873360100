// @flow

import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import config from '../../config.json';
import _get from 'lodash/get';

import './styles.less';
import scrolldown from '../../images/scrolldown.gif';
import openGraphImage from '../../images/openGraphImage.png';

import MedicationCard from '../MedicationCard';
import ProductCard from '../ProductCard';
import DrugsCategory from '../DrugsCategory';
import BrandFooter from '../BrandFooter';
import MenuContainer from '../../containers/MenuContainer';
import { Layer, LayerReactComponents } from '../../get-layer';
import { getDrugsCatalogue, wrapCatch } from '../../utils/api';
import { isCrawler, isReactSnap } from '../../utils/common';
import { saveInSession } from '../../common/persistSession';
import { loadFromSession } from '../../common/loadSession';
import XIRCLES_STATUSES from '../../const/status/xircles-status';
import { START_PAGE_MODES } from '../../const/start-page-modes';
import BrandHeader from '../BrandHeader';
import InfoCard from '../InfoCard';
import _isBoolean from 'lodash/isBoolean';
import pkjson from '../../../package.json';

const { ConversationList } = LayerReactComponents;

const { uuid } = Layer.Utils;

type Props = {};

type State = {};

class StartPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      pageContentSettings: undefined,
      isScrollDownShown: false,
    };
  }

  t = this.props.t;
  i18n = this.props.i18n;
  scrollThreshold = 15;
  resizeThreshold = 700;

  isInfoCardsVisible = _get(
    this.props.themeSettings,
    'isInfoCardsVisible',
    false
  );
  infos = _get(this.props.themeSettings, 'infos', []);

  setIsScrollDownShown = (event, condition) => {
    if (window[event] > condition) {
      this.state.isScrollDownShown &&
        this.setState({ isScrollDownShown: false });
    } else {
      !this.state.isScrollDownShown &&
        this.setState({ isScrollDownShown: true });
    }
  };

  getChangeTheWayGetInfo = () => {
    const translatedString = this.t('COMMON_CHANGE_THE_WAY_GET_INFO_1');

    return translatedString.replace(
      /\*\*(.+?)\*\*/gm,
      (_match, contents) => `<span>${contents}</span>`
    );
  };

  pageContentSettings = {
    [START_PAGE_MODES.SINGLE_PRODUCT]: {
      headerTitle: '',
      welcomeMessages: [
        {
          id: 'welcome-1',
          content: 'QUESTION_HELLO_MESSAGE',
        },
        {
          id: 'welcome-2',
          content: 'QUESTION_MAIN_MESSAGE',
        },
      ],
      getPageContent: (
        filterConversations,
        customizeConversationList,
        onConversationSelected
      ) => (
        <Fragment>
          <h3 className="start-page__inquiries-medical-service">
            {this.t('COMMON_INQUIRIES_MEDICAL_SERVICE')}
          </h3>
          <ConversationList
            filter={filterConversations}
            replaceableContent={customizeConversationList}
            onConversationSelected={(e) => onConversationSelected(e)}
          />
          <h3 className="start-page__further-information">
            {this.t('COMMON_FURTHER_INFORMATION')}
          </h3>
          <div className="start-page__info-cards">
            {this.isInfoCardsVisible &&
              this.infos.map((info) => (
                <InfoCard
                  key={info.id}
                  title={info.title}
                  link={info.link.url}
                  url={info.url.url}
                  onInfoCardLinkClick={(e) => {
                    if (
                      info.url.url &&
                      info.url.url.includes(window.location.origin)
                    ) {
                      e.preventDefault();
                      this.props.history.push({
                        pathname: info.url.url.replace(
                          window.location.origin,
                          ''
                        ),
                        state: {
                          detail: {
                            isFachInfoLink: true,
                          },
                        },
                      });
                    }
                  }}
                />
              ))}
          </div>
        </Fragment>
      ),
    },
    [START_PAGE_MODES.MULTI_PRODUCTS]: {
      headerTitle: '',
      welcomeMessages: [
        {
          id: 'welcome-1',
          content: 'QUESTION_HELLO_MESSAGE',
        },
        {
          id: 'welcome-2',
          content: 'QUESTION_FIND_ANSWER_MESSAGE',
        },
        {
          id: 'welcome-3',
          type: 'data-privacy',
          content: 'QUESTION_DATA_PRIVACY_MESSAGE',
          className: 'welcome-message__data-privacy',
          replaceableContent: {
            id: 'data-protection-link',
            key: 'dataProtection',
            content: `<a id='data-protection-link' target='_blank' href='${this.t(
              'QUESTION_DATA_PROTECTION_LINK'
            )}'>${this.t('COMMON_DATA_PROTECTION')}</a>`,
          },
        },
        {
          id: 'welcome-4',
          type: 'data-privacy',
          wrapperContainer: true,
          content: 'QUESTION_ACCEPT_DATA_PRIVACY_MESSAGE',
          className: 'welcome-message__data-privacy-check',
          replaceableContent: {
            id: 'data-privacy-link',
            key: 'dataPrivacy',
            content: `<a id='data-privacy-link' target='_blank' href='${this.t(
              'QUESTION_DATA_PRIVACY_LINK'
            )}'>${this.t('COMMON_ANSWER_MY_REQUEST')}</a>`,
          },
          childComponent: {
            id: 'accept-data-privacy',
            content:
              '<input type="checkbox" data-tr-event="true" id="accept-data-privacy" name="accept-data-privacy"></input>',
          },
        },
        {
          id: 'welcome-5',
          type: 'general-terms',
          wrapperContainer: true,
          content: 'QUESTION_ACCEPT_GENERAL_TERMS_MESSAGE',
          className: 'welcome-message__general-terms-check',
          replaceableContent: {
            id: 'general-terms-link',
            key: 'generalTerms',
            content: `<a id='general-terms-link' href='${
              window.origin
            }/terms-of-use'>${this.t('COMMON_GENERAL_TERMS')}</a>`,
          },
          childComponent: {
            id: 'accept-general-terms',
            content:
              '<input type="checkbox" data-tr-event="true" id="accept-general-terms" name="accept-general-terms"></input>',
          },
        },
      ],
      getPageContent: (
        filterConversations,
        customizeConversationList,
        onConversationSelected,
        products
      ) => {
        const hasCategoryInfo =
          config.EnableCatalogueCategoryWhenAvailable &&
          products.some(
            (product) => (product.categories_translationkeys || []).length > 0
          );
        return (
          <Fragment>
            <Trans>
              <h3 className="start-page__inquiries-medical-service">
                {this.t('COMMON_INQUIRIES_MEDICAL_SERVICE')}
              </h3>
              <p className="start-page__medical-services-text">
                {this.t('COMMON_INQUIRIES_MEDICAL_SERVICE_TEXT')}
              </p>
            </Trans>
            {!isReactSnap() && (
              <ConversationList
                filter={filterConversations}
                replaceableContent={customizeConversationList}
                onConversationSelected={(e) => onConversationSelected(e)}
              />
            )}
            <h3 className="start-page__available-products">
              {this.t('COMMON_AVAILABLE_PRODUCTS')}
            </h3>
            <div
              className={`start-page__scroll-down-container_${
                this.state.isScrollDownShown ? 'show' : 'hide'
              }`}
            >
              <img
                className="start-page__scroll-down-img"
                src={scrolldown}
                alt="scrolldown"
              />
            </div>
            {!hasCategoryInfo ? (
              <div className="start-page__products">
                {products.map((product, index) => (
                  <ProductCard
                    size="small"
                    id={`product-${index}`}
                    key={`product-${index}`}
                    name={product.brand_name}
                    appUrl={this.props.appUrl}
                    brand_slug={product.brand_slug}
                    companiesNum={product.num_companies}
                    documentsNum={product.num_documents}
                    compoundName={product.molecule_names.join(', ')}
                    isManufactureVisible={false}
                    onProductCardClick={this.handleProductCardClick}
                    isLinkClickAllowed={isReactSnap() || isCrawler()}
                  />
                ))}
              </div>
            ) : (
              <DrugsCategory
                appUrl={this.props.appUrl}
                products={products}
                isManufactureVisible={false}
                onProductCardClick={this.handleProductCardClick}
              />
            )}
          </Fragment>
        );
      },
    },
    [START_PAGE_MODES.ALL_PRODUCTS]: {
      headerTitle: undefined,
      welcomeMessages: [
        {
          id: 'welcome-1',
          content: 'QUESTION_HELLO_MESSAGE',
        },
        {
          id: 'welcome-2',
          content: 'QUESTION_MAIN_MESSAGE',
        },
        {
          id: 'welcome-3',
          content: 'QUESTION_ADDITIONAL_MESSAGE',
        },
      ],
      getPageContent: () => (
        <Fragment>
          <h1 className="start-page__change-the-way">
            <p
              dangerouslySetInnerHTML={{
                __html: this.getChangeTheWayGetInfo(
                  this.t('COMMON_CHANGE_THE_WAY_GET_INFO_1')
                ),
              }}
            ></p>
            {this.t('COMMON_CHANGE_THE_WAY_GET_INFO_2')}
          </h1>
          <h2 className="start-page__intelligent-search">
            {this.t('COMMON_INTELLIGENT_SEARCH')}
          </h2>
          <div className="start-page__medical-links">
            <p className="start-page__medical-services">
              {this.t('COMMON_MEDICAL_SERVICES')}
            </p>
            <p className="start-page__medical-services-links">{`${this.t(
              'COMMON_SERVICES_MEET'
            )}.`}</p>
            {this.i18n.exists('COMMON_SERVICES_EXTRA') && (
              <span className="start-page__medical-services-extra">{` ${this.t(
                'COMMON_SERVICES_EXTRA'
              )}`}</span>
            )}
          </div>
          <div className="start-page__medication-cards">
            <MedicationCard
              key="medication-card-0"
              title={this.t('ABOUT_MEDICATION_CARD_TITLE_1')}
              link={this.t('ABOUT_MEDICATION_CARD_LINK_1', {
                origin: window.location.origin,
              })}
            />
            <MedicationCard
              key="medication-card-1"
              title={this.t('ABOUT_MEDICATION_CARD_TITLE_2')}
              link={this.t('ABOUT_MEDICATION_CARD_LINK_2')}
            />
            <MedicationCard
              key="medication-card-2"
              title={this.t('ABOUT_MEDICATION_CARD_TITLE_3')}
              link={this.t('ABOUT_MEDICATION_CARD_LINK_3')}
            />
          </div>
          <div className="start-page__service-content">
            <div className="start-page__service-image-wrapper">
              <span className="start-page__service-image"></span>
            </div>
            <div className="start-page__service-description-wrapper">
              <div className="start-page__service-description">
                <h2 className="start-page__service-explained">
                  {this.t('COMMON_SERVICE_EXPLAINED')}
                </h2>
                <p className="start-page__service-paragraph">
                  {this.t('COMMON_RELEVANT_DRUG_INFORMATION')}
                </p>
                <p className="start-page__service-paragraph">
                  {this.t('COMMON_EXPAND_RANGE_SERVICES')}
                </p>
                <a
                  className="start-page__service-learn-more"
                  href={this.t('COMMON_LEARN_MORE_LINK')}
                >{`${this.t('COMMON_LEARN_MORE')} »`}</a>
              </div>
            </div>
          </div>
        </Fragment>
      ),
    },
  };

  async componentDidMount() {
    if (window.innerHeight <= this.resizeThreshold) {
      this.setState({ isScrollDownShown: true });
    }
    window.addEventListener('scroll', () =>
      this.setIsScrollDownShown('scrollY', this.scrollThreshold)
    );
    window.addEventListener('resize', () =>
      this.setIsScrollDownShown('innerHeight', this.resizeThreshold)
    );

    this.handleAddKnowledgeGraph();

    const startPageMode = _get(
      this.props.themeSettings,
      'startPageMode',
      START_PAGE_MODES.ALL_PRODUCTS
    );
    const pageContentSettings = _get(this.pageContentSettings, startPageMode);

    if (startPageMode === START_PAGE_MODES.MULTI_PRODUCTS) {
      const {
        data: { drugs: products },
      } = await wrapCatch(getDrugsCatalogue(null, null, true));
      this.setState({ products });
    }

    const welcomeMessages = _get(pageContentSettings, 'welcomeMessages', []);

    const isDataPrivacyAgreedFromSession = loadFromSession(
      'is_data_privacy_agreed'
    );
    const isDataPrivacyAgreed = _isBoolean(isDataPrivacyAgreedFromSession)
      ? isDataPrivacyAgreedFromSession
      : false;

    const isTermsOfUseAgreedFromSession = loadFromSession(
      'is_terms_of_use_agreed'
    );
    const isTermsOfUseAgreed = _isBoolean(isTermsOfUseAgreedFromSession)
      ? isTermsOfUseAgreedFromSession
      : false;

    this.setState(
      {
        pageContentSettings,
        isDataPrivacyMode: welcomeMessages.some(
          (welcomeMessage) => welcomeMessage.type === 'data-privacy'
        ),
        isDataPrivacyAgreed,
        isTermsOfUseAgreed,
      },
      () => {
        const acceptDataPrivacy = document.getElementById(
          'accept-data-privacy'
        );

        if (acceptDataPrivacy) {
          acceptDataPrivacy.addEventListener('change', () => {
            const nextDataPrivacyAgreed = !this.state.isDataPrivacyAgreed;

            saveInSession({ is_data_privacy_agreed: nextDataPrivacyAgreed });
            this.setState({ isDataPrivacyAgreed: nextDataPrivacyAgreed });
          });

          if (isDataPrivacyAgreed) {
            acceptDataPrivacy.checked = isDataPrivacyAgreed;
          }
        }

        const acceptGeneralTerms = document.getElementById(
          'accept-general-terms'
        );

        if (acceptGeneralTerms) {
          acceptGeneralTerms.addEventListener('change', () => {
            const nextTermsOfUseAgreed = !this.state.isTermsOfUseAgreed;

            saveInSession({ is_terms_of_use_agreed: nextTermsOfUseAgreed });
            this.setState({ isTermsOfUseAgreed: nextTermsOfUseAgreed });
          });

          if (isTermsOfUseAgreed) {
            acceptGeneralTerms.checked = isTermsOfUseAgreed;
          }
        }

        const generalTermsLink = document.getElementById('general-terms-link');

        if (generalTermsLink) {
          generalTermsLink.addEventListener('click', (e) => {
            e.preventDefault();
            this.props.history.push('/terms-of-use');
          });
        }

        window.addEventListener('logged-out', () =>
          this.setState(
            { isDataPrivacyAgreed: false, isTermsOfUseAgreed: false },
            () => {
              if (acceptDataPrivacy) acceptDataPrivacy.checked = false;
              if (acceptGeneralTerms) acceptGeneralTerms.checked = false;
            }
          )
        );
      }
    );

    if (!isReactSnap()) {
      setTimeout(() => this.handleShowConversationList(), 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.newConversationId !== prevProps.newConversationId) {
      this.props.history.push({
        pathname: `/questions/${this.props.newConversationId}`,
        state: {
          detail: {
            corrections: this.props.corrections,
            originalText: this.props.originalText,
            prevLocation: this.props.history.location.pathname,
          },
        },
      });
    }
    if (this.state.isScrollDownShown !== prevState.isScrollDownShown) {
      if (window.innerHeight > this.resizeThreshold) {
        this.setState({ isScrollDownShown: false });
      }
    }
  }

  componentWillUnmount() {
    this.handleRemoveKnowledgeGraph();
    window.removeEventListener('scroll', this.setIsScrollDownShown);
    window.removeEventListener('resize', this.setIsScrollDownShown);
  }

  handleProductCardClick = (productName) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          pathname: `/medikamente/${productName}`,
          name: 'select-drug',
          drug: productName,
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          question: '-',
          text: '-',
          id: '-',
        },
      })
    );

    window.dispatchEvent(
      new CustomEvent('select-drug', { detail: { selectedProduct: null } })
    );
    this.props.history.push(`/medikamente/${productName}`);
  };

  handleShowConversationList = () => {
    const [layerConversationList] = document.getElementsByTagName(
      'layer-conversation-list'
    );
    layerConversationList &&
      layerConversationList.setAttribute(
        'style',
        'display: block; opacity: 1;'
      );
  };

  filterConversations = (conversation) => {
    return (
      conversation &&
      [XIRCLES_STATUSES.FORWARDED, XIRCLES_STATUSES.FORWARD_REQUESTED].includes(
        conversation.metadata.xircles_status
      )
    );
  };

  onConversationSelected = (evt) => {
    const conversation = evt.detail.item.toObject();
    this.props.history.push(`/questions/${uuid(conversation.id)}`);
  };

  handleAddKnowledgeGraph = () => {
    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: `${config.XirclesUrl}`,
      publisher: {
        '@type': 'Organization',
        name: 'Xircle AI',
        description: this.t('COMMON_DESCRIPTION'),
        legalName: 'Xircle AG',
        logo: {
          '@type': 'ImageObject',
          url: `${config.XirclesUrl}${openGraphImage}`,
        },
      },
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  handleRemoveKnowledgeGraph = () => {
    const knowledgeGraph = document.querySelector(
      'script[type="application/ld+json"]'
    );
    return (
      knowledgeGraph &&
      knowledgeGraph.parentNode &&
      knowledgeGraph.parentNode.removeChild(knowledgeGraph)
    );
  };

  handlePostQuestion = (question, corrections, originalText) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          question,
          name: 'search-submit',
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          drug: '-',
          text: '-',
          id: '-',
        },
      })
    );

    const { parentDocumentId, parentDocumentBrandSlug } = this.props;
    this.props.createConversation(
      question,
      {
        goBackPath: this.props.history.location.pathname,
        documentId: parentDocumentId,
        product: parentDocumentBrandSlug,
      },
      corrections,
      originalText
    );
  };

  renderPageContent = (
    pageContentSettings,
    filterConversations,
    customizeConversationList,
    onConversationSelected,
    products
  ) => {
    const getPageContent = _get(pageContentSettings, 'getPageContent');

    return getPageContent
      ? getPageContent(
          filterConversations,
          customizeConversationList,
          onConversationSelected,
          products
        )
      : undefined;
  };

  getWelcomeMessage = (
    content,
    replaceableContent,
    childComponent,
    wrapperContainer
  ) => {
    const welcomeMessage = replaceableContent
      ? this.t(content, {
          [replaceableContent.key]: replaceableContent.content,
        })
      : this.t(content);

    const welcomeMessageWithWrapperContainer = wrapperContainer
      ? `<span>${welcomeMessage}</span>`
      : welcomeMessage;

    const welcomeMessageWithChildComponent = childComponent
      ? `${childComponent.content}${welcomeMessageWithWrapperContainer}`
      : welcomeMessageWithWrapperContainer;

    return welcomeMessageWithChildComponent;
  };

  render() {
    return (
      <div className="start-page">
        <BrandHeader
          {...this.props}
          className={this.state.isDataPrivacyMode && 'data-privacy-mode'}
          isDataPrivacyMode={this.state.isDataPrivacyMode}
          isDataPrivacyAgreed={this.state.isDataPrivacyAgreed}
          isTermsOfUseAgreed={this.state.isTermsOfUseAgreed}
          onPostQuestion={this.handlePostQuestion}
        >
          <div className="brand-header__welcome">
            {_get(this.state.pageContentSettings, 'welcomeMessages', []).map(
              ({
                id,
                content,
                childComponent,
                className,
                replaceableContent,
                wrapperContainer,
              }) => (
                <p
                  key={id}
                  className={`brand-header__welcome-message ${
                    className ? className : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: this.getWelcomeMessage(
                      content,
                      replaceableContent,
                      childComponent,
                      wrapperContainer
                    ),
                  }}
                />
              )
            )}
          </div>
        </BrandHeader>
        <div className="start-page__content">
          {this.renderPageContent(
            this.state.pageContentSettings,
            this.filterConversations,
            this.props.customizeConversationList,
            this.onConversationSelected,
            this.state.products
          )}
        </div>
        {_get(this.props.themeSettings, 'isMenuVisible', false) && (
          <MenuContainer />
        )}
        {_get(this.props.themeSettings, 'isFooterVisible', false) && (
          <BrandFooter />
        )}
        {pkjson.version_number && pkjson.release_date && (
          <span className="release-metadata">
            v{pkjson.version_number} - {pkjson.release_date}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(StartPage));
