import Layer from '@layerhq/web-xdk';

const MessageListAfterMixin = {
  methods: {
    onRerender: {
      mode: Layer.UI.registerComponent.MODES.AFTER,
      conditional: function onCanRerender() {
        return Boolean(this.query);
      },
      value: function value() {
        const evt =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : {};

        if (!this.query.isDestroyed && evt.type === 'insert') {
          const item = document.createElement('div');
          item.classList.add('layer-message-item');
          const indi = document.createElement('div');
          indi.classList.add('xircles-typing-indicator');
          if (
            document.querySelector(
              'layer-typing-indicator.layer-typing-occuring'
            )
          ) {
            indi.classList.add('layer-typing-occuring');
          }
          indi.innerHTML = '<span></span><span></span><span></span>';
          item.appendChild(indi);
          this.appendChild(item);
        }
      },
    },
  },
};

export default MessageListAfterMixin;
