import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';

import './styles.less';

const FutureProductFragment = ({ t, onClose }) => (
  <Fragment>
    <p className="future-product__message">{t('MODAL_FUTURE_PRODUCT')}</p>
    <Button outlined style={{ width: '100%' }} onClick={onClose}>
      {t('COMMON_OK')}
    </Button>
  </Fragment>
);

export default withTranslation()(FutureProductFragment);
