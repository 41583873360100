// @flow

import * as React from 'react';

import './styles.less';

type Props = {
  children?: Object | string,
  className?: string,
  title: string,
  link: string,
  url: string,
};

const DiseaseCard = (props: Props) => (
  <div className={`content-panel__disease-card ${props.className}`}>
    <p className="disease-card__title">{props.title}</p>
    <a
      href={props.url}
      target="_blank"
      className="disease-card__link"
      rel="noopener noreferrer"
    >
      {props.link}
    </a>
  </div>
);

export default DiseaseCard;
