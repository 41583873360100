import React from 'react';

import config from '../config.json';
import { Layer } from '../get-layer';

const { uuid } = Layer.Utils;

function conversationManager(Component) {
  return class A extends React.Component {
    state = {
      conversationId: undefined,
      conversationMetadata: undefined,
    };

    prepareConversationAndSendMessage = (
      message,
      metadata,
      bot,
      corrections,
      originalText
    ) => {
      const { layerClient } = this.props;

      const conversation = layerClient.createConversation({
        participants: [layerClient.user, bot],
        distinct: false,
        metadata: {
          conversationName: message,
          stepBackNumber: metadata.stepBackNumber,
          ...metadata,
        },
      });

      const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
        'XirclesQuestionModel'
      );
      const model = new XirclesQuestionModel({
        text: message,
        customData: metadata,
      });
      model.send({ conversation });

      this.setState({
        conversationId: uuid(conversation.id),
        conversationMetadata: conversation.metadata,
        originalText,
        corrections,
      });
    };

    prepareBot = (message, metadata, corrections, originalText) => {
      const { layerClient } = this.props;
      const bot = layerClient.getIdentity(config.LayerBotID, true);

      if (bot.isLoading) {
        bot.on('identities:loaded', () => {
          this.prepareConversationAndSendMessage(
            message,
            metadata,
            bot,
            corrections,
            originalText
          );
        });
      } else {
        this.prepareConversationAndSendMessage(
          message,
          metadata,
          bot,
          corrections,
          originalText
        );
      }
    };

    createConversation = (message, metadata, corrections, originalText) => {
      const { layerClient } = this.props;

      if (layerClient.isReady) {
        this.prepareBot(message, metadata, corrections, originalText);
      } else {
        layerClient.once('ready', () => {
          this.prepareBot(message, metadata, corrections, originalText);
        });
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          corrections={this.state.corrections}
          originalText={this.state.originalText}
          newConversationId={this.state.conversationId}
          newConversationMetadata={this.state.conversationMetadata}
          createConversation={this.createConversation}
        />
      );
    }
  };
}

export default conversationManager;
