// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { getAppUrl } from '../../utils/common';
import config from '../../config.json';

import './styles.less';

type Props = {
  children?: Object | string,
  className?: string,
  title: Array,
  subtitle: string,
  images: Array,
};

const prepareExternalLink = (url) =>
  `${getAppUrl()}/documents/${config.Language}-${config.Language}/${url}`;
const prepareInternalLink = (url) =>
  `/documents/${config.Language}-${config.Language}/${url}`;

const getSubtitleLink = (props) => {
  if (props.subtitleLink && props.subtitleLink.cached_url) {
    const externalLink = prepareExternalLink(props.subtitleLink.cached_url);
    const internalLink = prepareInternalLink(props.subtitleLink.cached_url);

    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          props.history.push(internalLink);
        }}
        href={externalLink}
        className="medicine-card__link"
        key="open-subtitle-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.subtitle}
      </a>
    );
  }

  return <p className="medicine-card__subtitle">{props.subtitle}</p>;
};

const getTitleLink = (props, title, i) => {
  if (title.component === 'medicine_link') {
    const externalLink = prepareExternalLink(title.download.cached_url);
    const internalLink = prepareInternalLink(title.download.cached_url);

    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          props.history.push(internalLink);
        }}
        href={externalLink}
        className="medicine-card__link-title"
        key="open-subtitle-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {title.name}
      </a>
    );
  }

  return (
    <p className="medicine-card__title" key={'medicine-card-title-' + i}>
      {title.text}
    </p>
  );
};

const MedicineCard = (props: Props) => (
  <div className={props.className}>
    <div className={'medicine-card__image-container'}>
      {props.images.map((image, i) => (
        <img
          className="medicine-card__image"
          src={image.src.filename}
          alt={image.alt}
          key={i}
        />
      ))}
    </div>
    {props.title.map((title, i) => getTitleLink(props, title, i))}
    {getSubtitleLink(props)}
  </div>
);

export default withRouter(MedicineCard);
