// @flow
/**
 * This UI Component manages the Layer Conversations.
 *
 * It provides a Conversation List on the left, and a Conversation View on the right.
 *
 * It also uses an Identity List via the EditCOnversationDialog.js file to create new Conversations.
 */

import React, { Component, Fragment } from 'react';

import { layerClient, LayerReactComponents, Layer } from '../../get-layer';
import from from 'array.from';
import { withTranslation } from 'react-i18next';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import Suggestions from '../Suggestions/index';
import BigButtonBar from '../BigButtonBar/index';
import ContentPanel from '../ContentPanel/index';
import ScrollPaneWrapper from './ScrollPaneWrapper/index';
import BrandFooter from '../BrandFooter';
import Footer from '../Footer';
import ContentEdit from '../ContentEdit';
import lineClamp from 'line-clamp';

import './styles.less';
import HeaderContainer from '../../containers/HeaderContainer';
import HeaderOperatorContainer from '../../containers/HeaderOperatorContainer';
import MenuContainer from '../../containers/MenuContainer';
import HelmetWrapper from '../HelmetWrapper';
import config from '../../config.json';

import Filter from '../Filter/index';
import { isHcp, isOperator } from '../../utils/user';
import XIRCLES_STATUSES from '../../const/status/xircles-status';
import { QUERY_TYPES } from '../../const/query-types';
import {
  getAllDictionaries,
  getSuggestionsByAutocomplete,
  getSuggestionsByTags,
  spellCheck,
  getConversationsCount,
  wrapCatch,
} from '../../utils/api';
import {
  debounce,
  getAppUrl,
  iPad,
  isCrawler,
  isDeletedRequestsRoute,
  isDOMElement,
  isDotSlash,
  isEmptyBytes,
  isFirefox,
  isInsideFrame,
  isIE,
  isIOS,
  isIpadPro,
  isMobileDevice,
  isTabletDevice,
  isUtf8,
  isConversationRoute,
  isQuestionsRoute,
  isDocumentsRoute,
  isModalWindowRoute,
  isProductQuestionsRoute,
  slugify,
} from '../../utils/common';
import delayUnmounting from '../../hocs/delayUnmounting';
import { SUGGEST_MODES } from '../../const/suggest-modes';
import { NAVIGATION_TYPE } from '../../const/navigation-type';
import { SPELL_CHECK_MODES } from '../../const/spell-check-modes';
import { START_PAGE_MODES } from '../../const/start-page-modes';
import xirclesStatuses from '../../const/status/xircles-status';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import _random from 'lodash/random';

const { uuid } = Layer.Utils;

// Extract the Layer XDK UI Components that are to be used in this Project
const { ConversationList } = LayerReactComponents;

const { ARCHIVED } = xirclesStatuses;

type Props = {
  history: any,
  location: any,
  match: any,
  firstTime: boolean,
  onLogout: Function,
  t: Function,
  userId: string,
};

type State = {
  conversation: any,
  conversationName: string,
  isLoaded: boolean,
  conversationId: string,
  showEditConversationDialog: boolean,
  isSuggestionsShown: boolean,
  helpList: Array<any>,
  shortCutList: Array<any>,
  suggestionList: Array<string>,
  editConversationId: string,
  sortBy: string,
  isPublicListVisible: boolean,
};

const ContentPanelDelayed = delayUnmounting(ContentPanel);

class QuestionPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      conversationId: '',
      conversation: null,
      conversationName: '',
      isLoading: false,
      isLoaded: false,
      showEditConversationDialog: false,
      isSuggestionsShown: false,
      helpList: [],
      shortCutList: [],
      suggestionList: [],
      tags: [],
      dictionaries: [],
      products: [],
      editConversationId: '',
      text: '',
      bigButtonBarVisible: isMobileDevice(),
      sortBy: '',
      key: false,
      focusRequired: true,
      isPublicListVisible: false,
      isPageVisible: false,
      isFetchingConversationCount: true,
      conversationsCount: 0,
    };
    this.titleRef = React.createRef();
    this.parentTitleRef = React.createRef();

    this.textareaRef = React.createRef();
    // always displaying
    this.mainMessage = React.createRef();
    // displaying only on first enter
    this.additionalMessage = React.createRef();
    // for dividing this.mainMessage and this.additionalMessage
    this.contentEditable = React.createRef();
    this.welcome = React.createRef();
    this.header = React.createRef();
    this.bigButtonBar = React.createRef();
    this.question = React.createRef();
    this.lineBreak = React.createRef();
    this.headerBackground = React.createRef();
    this.scrollPane = React.createRef();
    this.leftPanelFixed = false;
    this.suggestionsElem = null;
    this.conversationList = null;
    //
    this.headerAnimation = null;
    this.headerDisplayAnimation = null;
    this.questionMoveAnimation = null;
    this.questionHeightAnimation = null;
    this.questionFixedAnimation = null;
    this.recognizedTags = [];
    this.autoCompleteSearchLength = 0;
    this.firstChatBubbleHeight = 0;
    this.isBlurred = false;
    this.isMobileModeActive = false;
    //
    this.key = '';
    //
    this.openAnimationDelayRequired = true;

    this.updateConversationsCount();
  }

  t = this.props.t;
  isIpad = iPad() || isIpadPro();
  isIOS = isIOS();
  isMobileDevice = isMobileDevice();
  isMediumScreenFirst;

  // Handle reauthentication
  async componentDidMount() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }, 400);

    const startPageMode = _get(
      this.props.themeSettings,
      'startPageMode',
      START_PAGE_MODES.ALL_PRODUCTS
    );

    if (
      !isOperator(Layer.client.user) &&
      startPageMode !== 'x' /* START_PAGE_MODES.SINGLE_PRODUCT */
    ) {
      return this.props.history.replace('/');
    }

    const { data: dictionaries } = await wrapCatch(getAllDictionaries());
    this.setState({ dictionaries: this.createDictionary(dictionaries) });

    const products = this.createProducts(dictionaries);
    const product = this.getProduct(products);

    if (
      !product &&
      !this.props.history.location.pathname.includes('/questions')
    ) {
      return this.props.history.replace('/');
    }

    const productName = `${(product && product.brand_name) || 'Dupilumab'}`;

    this.setState(
      {
        product,
        products,
        productName,
        productCompoundComponent: product && product.compound_name,
        isPageVisible: true,
      },
      () => {
        layerClient.product = product || false;
        this.props.onProductChange(this.state.product);

        if (this.state.product) {
          this.handleShowConversationListWithoutDelay();
          this.handleAddKnowledgeGraph(this.state.product);
        } else {
          this.handleShowConversationList();
        }
      }
    );

    if (
      this.props.prevLocation === '/' ||
      isQuestionsRoute(this.props.prevLocation) ||
      isConversationRoute(this.props.prevLocation) ||
      isDocumentsRoute(this.props.prevLocation) ||
      isProductQuestionsRoute(this.props.prevLocation) ||
      isModalWindowRoute(this.props.prevLocation)
    ) {
      const [contentPanel] = document.getElementsByClassName('content-panel');
      contentPanel && contentPanel.setAttribute('style', 'opacity: 1;');
    }

    const welcomeMessage = this.generateRandomWelcomeMessage(
      this.props.firstTime,
      productName
    );
    this.setState({ welcomeMessage });

    if (config.FeatureShortcutsEnabled) {
      const { shortCutList, helpList } = this.prepareBigButtonBar();
      this.setState({ shortCutList, helpList });
    }
    const conversationName = _get(
      this.props.location,
      'state.detail.conversationName'
    );

    if (conversationName) {
      this.setState({ text: conversationName }, () =>
        this.focusOnTextAreaDelayed()
      );
    }

    this.leftPanel = document.querySelector('.left-panel');
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
    window.addEventListener('scroll', this.onScroll, { passive: true });
    window.addEventListener('click', this.onClick);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('close-help-list', this.closeHelpList);
    window.addEventListener(
      'archive-conversation',
      this.handleArchiveConversation
    );
    // should happen always to corellate w/ onBlur
    this.setState({ tags: _get(dictionaries, `tags[${config.Language}]`, []) });
    this.key = this.state.sortBy;
    this.setState({
      bigButtonBarHeight:
        this.bigButtonBar.current && this.bigButtonBar.current.offsetHeight,
    });

    if (isDeletedRequestsRoute(this.props.location.pathname)) {
      this.handleCollapseAnimationQuestion(false);
    } else {
      setTimeout(() => this.updateQuestionHeight(welcomeMessage), 400);
    }

    if (this.props.operatorFilterSelected) {
      this.changeSortBy(this.props.operatorFilterSelected);
    }
  }

  componentWillUnmount() {
    this.handleRemoveKnowledgeGraph();
    clearAllBodyScrollLocks();
    this.clearAnimationTimers();

    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('close-help-list', this.closeHelpList);
    window.removeEventListener(
      'archive-conversation',
      this.handleArchiveConversation
    );

    if (this.props.isInsideWidget && !isMobileDevice() && !isTabletDevice()) {
      const [scrollPane] = document.getElementsByClassName('scrollPane');
      scrollPane &&
        scrollPane.removeEventListener('scroll', this.handleScrollPaneControls);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.history.location.pathname ===
      nextProps.history.location.pathname
    );
  }

  updateConversationsCount = async () => {
    const { sortBy } = this.state;
    this.setState({ isFetchingConversationCount: true });
    const count = await wrapCatch(getConversationsCount(sortBy));
    const newState = {
      isFetchingConversationCount: false,
    };
    if (this.state.sortBy === sortBy) {
      // the user can quickly change the sortBy, so we need to check if the new sortBy is still the same as the old one
      newState.conversationsCount = count;
    }
    this.setState(newState);
  };

  handleAddKnowledgeGraph = (product) => {
    const { brand_name, compound_name } = product;

    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'MedicalWebPage',
      audience: 'https://schema.org/Clinician',
      description: `${brand_name}${
        compound_name ? `(${compound_name})` : ''
      } • ${this.t('COMMON_TITLE_CLAIM')}`,
      url: `${config.XirclesUrl}/medikamente/${slugify(brand_name)}`,
      drug: [
        {
          '@type': 'Drug',
          proprietaryName: brand_name,
          activeIngredient: compound_name,
        },
      ],
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  handleRemoveKnowledgeGraph = () => {
    const knowledgeGraph = document.querySelector(
      'script[type="application/ld+json"]'
    );
    return (
      knowledgeGraph &&
      knowledgeGraph.parentNode &&
      knowledgeGraph.parentNode.removeChild(knowledgeGraph)
    );
  };

  createProducts = (source) => {
    const dictionaryMap = ['products.de', 'products.en'];

    return dictionaryMap.reduce((acc, cur) => {
      const dictionary = _get(source, cur, []);
      return [...acc, ...dictionary];
    }, []);
  };

  getProduct = (products) => {
    if (this.props.match.params.productName) {
      const productName = this.props.match.params.productName;
      const matchedProduct = products.find(
        (product) => slugify(product.brand_name) === productName
      );
      return matchedProduct;
    }

    return undefined;
  };

  createDictionary = (source) => {
    const dictionaryMap = [
      {
        path: 'autocompletes.en',
        lang: 'en',
      },
      {
        path: 'tags.en',
        lang: 'en',
      },
      {
        path: 'autocompletes.de',
        lang: 'de',
      },
      {
        path: 'tags.de',
        lang: 'de',
      },
    ];

    return dictionaryMap.reduce((acc, cur) => {
      const dictionary = _get(source, cur.path);
      const preparedDictionary = dictionary.map((current) => ({
        value: current,
        lang: cur.lang,
      }));
      return [...acc, preparedDictionary];
    }, []);
  };

  handleProductLandingClick = (productId) => {
    const product = [...this.state.products].find(
      (product) => product.id === productId
    );

    this.setState(
      {
        product,
        productName: `(${product && product.brand_name})`,
        productCompoundComponent: product && product.compound_name,
      },
      () => {
        layerClient.product = product || false;
        this.props.onProductNameChange(this.state.productName);
      }
    );
  };

  handleShowConversationListWithoutDelay = () => {
    if (isDeletedRequestsRoute(this.props.history.location.pathname)) {
      const [layerConversationList] = document.getElementsByTagName(
        'layer-conversation-list'
      );
      layerConversationList.setAttribute('style', 'opacity: 1;');
    } else {
      const questionTitle = document.querySelector(
        '.question-title:last-of-type'
      );
      questionTitle &&
        questionTitle.setAttribute('style', 'display: block; opacity: 1;');

      if (this.props.match.params.productName) {
        const [productQuestionsList] = document.getElementsByClassName(
          'product-questions-list'
        );
        productQuestionsList &&
          productQuestionsList.setAttribute(
            'style',
            'display: block; opacity: 1;'
          );
      } else {
        const layerConversationList = [
          ...document.getElementsByTagName('layer-conversation-list'),
        ];
        if (layerConversationList.length > 0) {
          layerConversationList.forEach((conversationList) => {
            conversationList.setAttribute(
              'style',
              'display: block; opacity: 1;'
            );
          });
        }
      }
    }
  };

  handleShowConversationList = () =>
    setTimeout(() => this.handleShowConversationListWithoutDelay(), 200);

  updateQuestionHeight = (welcomeMessage) => {
    const [tinyScreenWidth, smallScreenWidth, mediumScreenWidth] = [
      356, 360, 768,
    ];

    if (document.documentElement.clientWidth < mediumScreenWidth) {
      this.isMediumScreenFirst = true;
      const questionLengthLineBreaks = {
        [tinyScreenWidth]: 34,
        [smallScreenWidth]: 38,
        [mediumScreenWidth]: 42,
      };

      const screenWidth = [
        tinyScreenWidth,
        smallScreenWidth,
        mediumScreenWidth,
      ].find((width) => document.documentElement.clientWidth < width);

      const questionLengthLineBreak = questionLengthLineBreaks[screenWidth];

      if (questionLengthLineBreak < welcomeMessage.length) {
        this.question &&
          this.question.classList &&
          this.question.classList.add('question--first-time');
        this.scrollPane &&
          this.scrollPane.current.classList &&
          this.scrollPane.current.classList.add('scrollPane--first-time');
      } else {
        this.question &&
          this.question.classList &&
          this.question.classList.remove('question--first-time');
        this.scrollPane &&
          this.scrollPane.current &&
          this.scrollPane.current.classList.remove('scrollPane--first-time');
      }
    } else {
      this.isMediumScreenFirst = false;
    }

    setTimeout(() => {
      if (!isDeletedRequestsRoute(this.props.location.pathname)) {
        this.refreshQuestionHeight();
      }
    }, 0);
  };

  prepareBigButtonBar = () => {
    const t = this.t;
    const AMOUNT_OF_BUTTONS_AND_LISTS = 9;

    const { shortCutList, helpList } = Array.apply(null, {
      length: AMOUNT_OF_BUTTONS_AND_LISTS,
    }).reduce(
      ({ shortCutList, helpList }, _value, index) => {
        const itemNum = index + 1;
        return {
          shortCutList:
            t(`HELP_BUTTON_${itemNum}_QUESTION`) !==
            `HELP_BUTTON_${itemNum}_QUESTION`
              ? shortCutList.concat({
                  text: t(`HELP_BUTTON_${itemNum}_SHORT_TEXT`),
                  className: 'short-cut',
                  onClick: () =>
                    this.clickShortCut(
                      t(`HELP_BUTTON_${itemNum}_QUESTION`) ||
                        t(`HELP_BUTTON_${itemNum}_SHORT_TEXT`)
                    ),
                })
              : shortCutList,
          helpList:
            t(`HELP_LIST_${itemNum}_QUESTION`) !==
            `HELP_LIST_${itemNum}_QUESTION`
              ? helpList.concat({
                  question: t(`HELP_LIST_${itemNum}_QUESTION`),
                  text:
                    t(`HELP_LIST_${itemNum}_SHORT_TEXT`) ||
                    t(`HELP_LIST_${itemNum}_QUESTION`),
                })
              : helpList,
        };
      },
      {
        shortCutList: [],
        helpList: [],
      }
    );

    const shortCutListWithHelpList = [
      ...shortCutList,
      {
        text: t('SUGGESTIONS_MORE_REQUESTS'),
        className: 'short-cut short-cut__more',
        onClick: this.showSuggestions,
      },
    ];

    return {
      shortCutList: shortCutListWithHelpList,
      helpList,
    };
  };

  /**
   * Now that we have a Conversation, setup event handlers on it to detect when its loaded,
   * and when its `metadata` property has changed; metadata changes typically mean a Conversation Name change
   * that needs to be rerendered.
   *
   * Note: This works off of `this.conversation` as its input, and this value may be `null`
   */
  setupConversation(con) {
    const conversation = con || this.state.conversation;

    // If the conversation is still loading, wait for it to finish, and then set isLoaded to true
    if (conversation && conversation.isLoading) {
      conversation.once('conversations:loaded', () => {
        this.setState({
          isLoaded: true,
        });
      });
    }

    // Setup our inital state
    this.setState({
      conversationId: conversation ? uuid(conversation.id) : '',
      conversation,
      isLoaded: conversation && !conversation.isLoading,
    });
  }

  normalizeText = (text) =>
    text.replace(new RegExp('<em>(.*?)</em>', 'gi'), (_match, p1) => p1); // eslint-disable-line

  postSuggestion = (text) => {
    const prevText = this.state.text;
    this.setState({ text: this.normalizeText(text) }, () => {
      this.hideSuggestions(prevText);
      this.postQuestion();
    });
  };

  handleCloseSuggestionsClick = () => {
    this.hideSuggestions();
  };

  startConversation = (text, corrections) => {
    if (layerClient.isReady) {
      this.createConversationAndSendMessageToBot(text, corrections);
    } else {
      layerClient.once('ready', () => {
        this.createConversationAndSendMessageToBot(text, corrections);
      });
      layerClient.once('challenge', () => {
        this.setState({ isLoading: false });
      });
    }
  };

  applyCorrectionsToSearchRequest = (text, corrections) => {
    return corrections.reduce(
      (acc, cur) => acc.replace(cur.token, cur.suggestion),
      text
    );
  };

  mapCorrections = (source) => {
    return Object.keys(source).reduce((acc, key) => {
      const corrections = source[key];

      if (Array.isArray(corrections)) {
        const autocorrections = corrections.map((current) => ({
          ...current,
          token: key,
        }));
        return [...acc, ...autocorrections];
      }

      return acc;
    }, []);
  };

  postQuestion = async (isAutocorrectRequired = false) => {
    this.setState({ isLoading: true });

    this.removeCorrectionsWrapper();

    if (isAutocorrectRequired) {
      const product = this.state.product ? this.state.product.id : undefined;

      const { data } = await wrapCatch(
        spellCheck({
          text: this.state.text,
          mode: [SPELL_CHECK_MODES.AUTOCORRECT],
          product,
        })
      );

      if (!_isEmpty(data.corrections)) {
        const mappedCorrections = this.mapCorrections(data.corrections);
        const requestWithCorrections = this.applyCorrectionsToSearchRequest(
          this.state.text,
          mappedCorrections
        );
        this.startConversation(requestWithCorrections, mappedCorrections);
      } else {
        this.startConversation(this.state.text);
      }
    } else {
      this.startConversation(this.state.text);
    }
  };

  getQuestionPageHeaderHeight = (text = '') => {
    const linesCount = isFirefox() ? 4 : 3;

    this.parentTitleRef.current.setAttribute(
      'style',
      'width:100vw; max-height: 84px; font-size: 24px; line-height: 28px; margin: 0 36px 0 20px;'
    );
    if (text) {
      this.titleRef.current.innerHTML = text;
    } else {
      this.titleRef.current.innerHTML = this.state.text;
    }

    this.titleRef.current.setAttribute(
      'style',
      'margin: 0; white-space: pre-line;'
    );
    lineClamp(this.titleRef.current, linesCount);
    const titleHeight = this.parentTitleRef.current.clientHeight + 70;
    this.parentTitleRef.current.setAttribute('style', 'display: none');

    const questionTitlesList = [
      ...document.getElementsByClassName('question-title'),
    ];
    if (questionTitlesList.length > 0) {
      questionTitlesList.forEach((questionTitle) => {
        questionTitle.setAttribute('style', 'display: block; opacity: 0;');
      });
    }

    //questionFixed fixed
    const [leftPanel] = document.getElementsByClassName('left-panel');

    let topMargin = 0;
    if (
      !leftPanel.classList.contains('fixed') &&
      !leftPanel.classList.contains('questionFixed')
    ) {
      const [questionElement] = document.getElementsByClassName('question');
      const delta = this.state.product ? 63 : 28;
      (questionElement &&
        questionElement.classList.contains('question-mobile-view-fixed')) ||
      this.isIOS
        ? (topMargin = 0)
        : (topMargin = window.scrollY - delta);
    } else if (
      leftPanel.classList.contains('questionFixed') &&
      !leftPanel.classList.contains('fixed')
    ) {
      topMargin = 172;
    } else if (
      leftPanel.classList.contains('questionFixed') &&
      leftPanel.classList.contains('fixed')
    ) {
      topMargin = 126;
    }

    const isDeletedRequestsRoute =
      this.props.history.location.pathname.includes('deleted-requests');

    if (!isOperator(Layer.client.user)) {
      if (isDeletedRequestsRoute) {
        isDOMElement(this.question) &&
          this.question.setAttribute(
            'style',
            `height: ${
              titleHeight + topMargin
            }px; transition: height 400ms 200ms ease-in-out`
          );
      } else {
        this.question.setAttribute(
          'style',
          `height: ${
            titleHeight + topMargin
          }px; transition: height 400ms 200ms ease-in-out`
        );
      }
    }
  };

  createConversationAndSendMessageToBot = (text, corrections) => {
    this.getQuestionPageHeaderHeight();

    const bot = layerClient.getIdentity(config.LayerBotID, true);
    if (bot.isLoading) {
      bot.on('identities:loaded', () =>
        this._createConversationAndSendMessage(text, corrections, bot)
      );
    } else {
      this._createConversationAndSendMessage(text, corrections, bot);
    }
  };

  _createConversationAndSendMessage = (text, corrections, bot) => {
    this.setState(
      {
        conversation: layerClient.createConversation({
          participants: [layerClient.user, bot],
          distinct: false,
          metadata: {
            conversationName: text,
            product: this.state.product ? this.state.product.id : undefined,
            productName: this.state.product
              ? this.state.product.brand_name
              : undefined,
          },
        }),
      },
      () => {
        // but now
        const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass(
          'XirclesQuestionModel'
        );
        const model = new XirclesQuestionModel({
          text,
          customData: {
            product: this.state.product ? this.state.product.id : undefined,
          },
        });
        model.send({ conversation: this.state.conversation });
        this.onCreateConversation(this.state.conversation, true, corrections);
      }
    );
  };

  /**
   * Whenever a conversation is selected in the Conversation List, navigate to that Conversation.
   * This will cause `render` to be called, and the new Conversation ID to be passed to the Conversation View.
   */
  onConversationSelected(evt: CustomEvent) {
    if (!evt.detail.item || this.state.startState || isCrawler()) return;

    const questionTitlesList = [
      ...document.getElementsByClassName('question-title'),
    ];
    if (questionTitlesList.length > 0) {
      questionTitlesList.forEach((questionTitle) => {
        if (questionTitle.hasAttribute('style')) {
          questionTitle.setAttribute(
            'style',
            'transition: none; display: block; opacity: 0;'
          );
        }
      });
    }

    let component = evt.target.querySelector(
      `[layer-item-id = "conversations_${evt.detail.item.id.replace(
        'layer:///conversations/',
        ''
      )}"]`
    );

    if (component !== null) {
      component.nodes.innerNode.style.transform =
        'perspective(500px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)';

      let marginTop = 10,
        marginLeft;
      this.isMediumScreenFirst ? (marginLeft = 0) : (marginLeft = 5);

      component.setAttribute(
        'style',
        `top: ${component.getBoundingClientRect().top - marginTop}px;
     left: ${
       component.getBoundingClientRect().left - marginLeft
     }px; max-width: none;
     `
      );

      component.style.position = 'fixed';
      setTimeout(() => {
        component.setAttribute(
          'style',
          'position: fixed; top: 0; left: 0; max-width: none;'
        );
      }, 0);
      this.getQuestionPageHeaderHeight(component.nodes.lastMessage.innerHTML);
    }

    const conversation = evt.detail.item.toObject();
    if (conversation.metadata && conversation.metadata.isPublic) {
      this.startConversation(conversation.metadata.conversationName);
    } else {
      document.dispatchEvent(
        new CustomEvent('tr-custom-event', {
          detail: {
            name: 'active-conversation',
            conversationId: uuid(conversation.id),
          },
        })
      );
      this.props.history.push(`/questions/${uuid(conversation.id)}`);
    }
  }

  showSuggestionsAnimation = () => {
    if (isMobileDevice() && !isTabletDevice() && !this.props.isIOS12) {
      disableBodyScroll(document.getElementById('suggestions'), {
        reserveScrollBarGap: true,
      });
    }

    setTimeout(() => {
      if (this.suggestionsElem) {
        this.suggestionsElem.getElementsByTagName(
          'header'
        )[0].style.transitionDelay = '';
        const ul = this.suggestionsElem.getElementsByTagName('ul')[0];
        const height = this.suggestionsElem.offsetHeight - 20;
        this.suggestionsElem.style.height = 0;
        from(ul.getElementsByTagName('li')).forEach(
          (li, i) => (li.style.transitionDelay = i * 100 + 300 + 'ms')
        );
        this.suggestionsElem.className =
          this.suggestionsElem.className + ' move';
        this.headerBackground.current.setAttribute('style', 'z-index: 1');
        this.suggestionsElem.style.transition =
          'opacity 200ms ease-in-out, height 200ms 200ms ease-in-out';

        setTimeout(() => {
          this.suggestionsElem.style.height =
            isMobileDevice() &&
            !this.isIpad &&
            !(this.props.isIOS12 && isInsideFrame())
              ? window.innerHeight - 20 - 5 - 5 + 'px'
              : height + 'px';

          setTimeout(() => {
            if (this.suggestionsElem) {
              this.suggestionsElem.className =
                this.suggestionsElem.className + ' open';
            }
          }, 1000);
        }, 10);
      }
    }, 0);
  };

  showSuggestions = () => {
    if (window.parentIFrame) {
      window.parentIFrame.sendMessage({
        id: 'toggle-close-button',
        content: { isHidden: true },
      });
    }

    setTimeout(
      () =>
        this.suggestionsElem &&
        this.suggestionsElem.classList.toggle(
          'suggestions--fixed',
          isMobileDevice() &&
            !this.isIpad &&
            !(this.props.isIOS12 && isInsideFrame())
        ),
      0
    );

    this.setState({ isSuggestionsShown: true }, () => {
      this.showSuggestionsAnimation();

      if (this.state.text) {
        this.focusOnTextAreaDelayed();
      }
    });
  };

  applyStylesToHideSuggestions = () => {
    if (
      this.suggestionsElem &&
      this.suggestionsElem.classList.contains('open')
    ) {
      this.suggestionsElem.classList.remove('open');
      this.suggestionsElem.classList.remove('move');
    }
  };

  hideSuggestions = (prevText) => {
    if (window.parentIFrame) {
      window.parentIFrame.sendMessage({
        id: 'toggle-close-button',
        content: { isHidden: false },
      });
    }

    if (isMobileDevice() && !isTabletDevice() && !this.props.isIOS12) {
      clearAllBodyScrollLocks();
    }

    this.setState({ isSuggestionsShown: false }, () => {
      this.applyStylesToHideSuggestions();

      if (this.state.text) {
        this.focusOnTextAreaDelayed();
      }

      if (_isEmpty(prevText) && !_isEmpty(this.state.text)) {
        this.focusOnTextArea();
      }
    });
  };

  hideSuggestionsWithoutFocusSetting = () => {
    this.setState({ isSuggestionsShown: false }, () =>
      this.applyStylesToHideSuggestions()
    );
  };

  handleAnimationQuestionInput = () => {
    this.header.current &&
      this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current &&
      this.welcome.current.setAttribute('style', 'opacity: 0;');
    this.bigButtonBar.current &&
      this.bigButtonBar.current.setAttribute('style', 'display: none;');

    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add(
        'question-mobile-view-transition-header'
      );
      this.header.current.setAttribute(
        'style',
        `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`
      );

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.welcome.current.setAttribute(
        'style',
        `height: ${welcomeHeight}px; opacity: 0;`
      );

      this.headerAnimation = setTimeout(() => {
        this.header.current.setAttribute(
          'style',
          'height: 0px; min-height: 0px; opacity: 0;'
        );
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        const top = window.pageYOffset;
        this.question.setAttribute(
          'style',
          `margin-top: ${top}px; height: 119px`
        );
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.textareaRef.current.removeAttribute('style');
        if (isIOS()) {
          this.question.classList.remove('question-mobile-view-transition');
          this.question.classList.add('question-mobile-view-relative');
        } else {
          this.question.classList.add('question-mobile-view-fixed');
          const questionHeight = this.question.offsetHeight;
          this.scrollPane.current.setAttribute(
            'style',
            `padding-top: ${questionHeight}px`
          );
        }
        window.scrollTo(0, 0);
      }, 400);
    }, 200);
  };

  handleAnimationQuestionInputQuestionFixed = () => {
    this.header.current &&
      this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current &&
      this.welcome.current.setAttribute('style', 'opacity: 0;');
    this.bigButtonBar.current &&
      this.bigButtonBar.current.setAttribute('style', 'display: none;');

    this.contentEditable.current &&
      this.contentEditable.current.setAttribute('style', 'top: 69px;');

    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add(
        'question-mobile-view-transition-header'
      );
      this.header.current.setAttribute(
        'style',
        `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`
      );

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.contentEditable.current.classList.add(
        'question-mobile-view-transition'
      );
      this.welcome.current.setAttribute(
        'style',
        `height: ${welcomeHeight}px; opacity: 0;`
      );

      this.headerAnimation = setTimeout(() => {
        this.contentEditable.current.setAttribute('style', 'top: 0px;');
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
        this.header.current.setAttribute(
          'style',
          'height: 0px; min-height: 0px; opacity: 0;'
        );
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        const top = window.pageYOffset;
        this.question.setAttribute(
          'style',
          `margin-top: ${top}px; height: 110px`
        );
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.question.classList.remove('question-mobile-view-transition');
        this.question.classList.add('question-mobile-view-fixed');

        const questionHeight = this.question.offsetHeight;
        this.scrollPane.current.setAttribute(
          'style',
          `padding-top: ${questionHeight}px`
        );

        window.scrollTo(0, 0);
        setTimeout(
          () => this.textareaRef.current.removeAttribute('style'),
          100
        );
      }, 400);
    }, 200);
  };

  handleAnimationQuestionInputFixed = () => {
    this.header.current &&
      this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current &&
      this.welcome.current.setAttribute('style', 'opacity: 0;');
    this.bigButtonBar.current &&
      this.bigButtonBar.current.setAttribute('style', 'display: none;');

    this.contentEditable.current &&
      this.contentEditable.current.setAttribute('style', 'top: 69px;');

    if (!this.bigButtonBar.current && !this.state.product) {
      this.question && this.question.setAttribute('style', 'top: -120px');
    }

    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add(
        'question-mobile-view-transition-header'
      );
      this.header.current.setAttribute(
        'style',
        `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`
      );

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.contentEditable.current.classList.add(
        'question-mobile-view-transition'
      );
      this.welcome.current.setAttribute(
        'style',
        `height: ${welcomeHeight}px; opacity: 0;`
      );

      this.headerAnimation = setTimeout(() => {
        this.header.current.setAttribute(
          'style',
          'height: 0px; min-height: 0px; opacity: 0;'
        );
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
        this.contentEditable.current.setAttribute('style', 'top: 0px;');
        this.question.setAttribute('style', 'top: 0px; height: 110px');
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }, 0);

        this.scrollPane.current.setAttribute('style', 'padding-top: 110px');
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.textareaRef.current.removeAttribute('style');
        this.question.classList.add('question-mobile-view-fixed');
      }, 400);
    }, 200);
  };

  handleContentEditFocusOnMobile = () => {
    if (!this.isBlurred && !this.isMobileModeActive) {
      if (!this.isIpad) {
        this.setState({ focusRequired: true });
        this.isMobileModeActive = true;
        this.clearAnimationTimers();

        this.question &&
          this.question.classList.remove('question-change-url-transition');

        if (
          !this.leftPanel.classList.contains('questionFixed') &&
          !this.leftPanel.classList.contains('fixed')
        ) {
          this.handleAnimationQuestionInput();
        }

        if (
          this.leftPanel.classList.contains('questionFixed') &&
          !this.leftPanel.classList.contains('fixed')
        ) {
          this.handleAnimationQuestionInputQuestionFixed();
        }

        if (this.leftPanel.classList.contains('fixed')) {
          this.handleAnimationQuestionInputFixed();
        }

        this.scrollPane.current.addEventListener(
          'click',
          this.handleContentEditBlur
        );
      }

      const [layerConversationList] = document.getElementsByTagName(
        'layer-conversation-list'
      );
      layerConversationList.classList.add('layer-conversation-list--disabled');
    } else {
      this.textareaRef.current.removeAttribute('style');
    }
  };

  clearAnimationTimers = () => {
    clearTimeout(this.questionMoveAnimation);
    clearTimeout(this.headerAnimation);
    clearTimeout(this.headerDisplayAnimation);
    clearTimeout(this.questionHeightAnimation);
    clearTimeout(this.questionFixedAnimation);
  };

  isDocumentHidden = () => document.hidden || document.webkitHidden;

  handleContentEditBlur = () => {
    const { text: prevText } = this.state;

    setTimeout(() => {
      const { text } = this.state;

      if (
        !this.isDocumentHidden() &&
        isMobileDevice() &&
        !this.isIpad &&
        !this.props.isIOS12 &&
        _isEqual(prevText, text)
      ) {
        this.setState({ focusRequired: false });
        this.isMobileModeActive = false;
        this.setState({ text: text.trim() });

        this.removeCorrectionsWrapper();
        this.clearAnimationTimers();

        const [layerConversationList] = document.getElementsByTagName(
          'layer-conversation-list'
        );
        layerConversationList.classList.remove(
          'layer-conversation-list--disabled'
        );

        this.scrollPane.current.removeEventListener(
          'click',
          this.handleContentEditBlur
        );

        this.lineBreak.current &&
          this.lineBreak.current.removeAttribute('style');
        this.additionalMessage.current &&
          this.additionalMessage.current.removeAttribute('style');
        this.leftPanel.classList.remove('questionFixed');

        this.leftPanelFixed = false;
        this.leftPanel.classList.remove('fixed');

        if (!isDeletedRequestsRoute(this.props.location.pathname)) {
          this.question.removeAttribute('style');
        }

        this.question.classList.remove('question-mobile-view-transition');
        this.question.classList.remove('question-mobile-view-relative');
        this.question.classList.remove('question-mobile-view-fixed');
        this.question.classList.remove('question-mobile-view');

        this.contentEditable.current.removeAttribute('style');
        this.contentEditable.current.classList.remove(
          'question-mobile-view-transition'
        );

        this.header.current && this.header.current.removeAttribute('style');
        this.header.current &&
          this.header.current.classList.remove(
            'question-mobile-view-transition-header'
          );
        this.welcome.current && this.welcome.current.removeAttribute('style');
        this.welcome.current &&
          this.welcome.current.classList.remove(
            'question-mobile-view-transition'
          );

        this.scrollPane.current &&
          this.scrollPane.current.removeAttribute('style');
        this.bigButtonBar.current &&
          this.bigButtonBar.current.removeAttribute('style');

        window.scrollTo(0, 0);

        if (!isDeletedRequestsRoute(this.props.location.pathname)) {
          this.refreshQuestionHeight();
        }
      }
    }, 150);
  };

  handleContentEditFocus = () => {
    if (this.props.isInsideWidget && window.parentIFrame) {
      const [widgetBackButton] = document.getElementsByClassName(
        'widget__back-button'
      );
      widgetBackButton &&
        widgetBackButton.classList.remove('widget__back-button--hidden');
      window.parentIFrame.sendMessage({
        id: 'open-widget',
        height: document.body.clientHeight,
      });
    }

    if (
      isMobileDevice() &&
      !isTabletDevice() &&
      !this.isIpad &&
      !this.props.isIOS12
    ) {
      this.handleContentEditFocusOnMobile();
    } else {
      this.setState({ bigButtonBarVisible: true });
    }
  };

  /**
   * Whenever properties change, determine if the Conversation ID has changed, and if so:
   *
   * * Unsubscribe to all events from the prior conversation
   * * Call setupConversation() with the new conversation
   */
  componentWillReceiveProps(props: Props) {
    if (
      !props.match.params.welcomeId &&
      this.props.match.params.conversationId !==
        props.match.params.conversationId
    ) {
      const conversationId = props.match.params.conversationId;
      const newConversation = conversationId
        ? layerClient.getConversation(conversationId)
        : null;
      if (this.state.conversation)
        this.state.conversation.off(null, null, this);
      this.setState({ conversation: newConversation });
      this.setupConversation();
    } else if (this.props.userId !== props.userId) {
      // use setTimeout to wait until animation ends in LoginModal component to prevent animation freeze while ConversationList is force updating
      setTimeout(() => {
        this.key = this.props.userId;
        this.setState({ key: !this.state.key, isPublicListVisible: false });
        this.handleShowConversationList();

        if (
          isQuestionsRoute(props.location.pathname) ||
          isDeletedRequestsRoute(props.location.pathname)
        ) {
          const [contentPanel] =
            document.getElementsByClassName('content-panel');
          contentPanel && contentPanel.setAttribute('style', 'opacity: 1;');
        }
      }, 600);
    } else if (
      isOperator(Layer.client.user) &&
      (this.props.location.pathname.includes('deleted-requests') ||
        this.props.history.location.pathname.includes('deleted-requests'))
    ) {
      this.props.history.push('/questions');
    } else if (
      // change key to force update ConversationList only if we go from question page to deleted-requests and vice versa
      (this.props.location.pathname !== props.location.pathname ||
        this.props.history.location.pathname !==
          this.props.location.pathname) &&
      !this.props.location.pathname.includes('deleted-requests/') &&
      !this.props.history.location.pathname.includes('deleted-requests/') &&
      !isConversationRoute(props.history.location.pathname) &&
      (this.props.location.pathname.includes('deleted-requests') ||
        this.props.history.location.pathname.includes('deleted-requests') ||
        (props.location.pathname.includes('deleted-requests') &&
          this.props.location.pathname === '/questions'))
    ) {
      setTimeout(() => {
        if (this.key.includes('/deleted-requests')) {
          this.key = '';
        } else {
          this.key = this.props.location.pathname;
        }
        this.setState({ key: !this.state.key, isPublicListVisible: false });
        this.handleShowConversationList();
      }, 600);
    }

    if (this.props.location.pathname !== props.location.pathname) {
      this.handleAnimationQuestionLocationChange(
        this.props.location.pathname,
        props.location.pathname
      );
    }

    const [scrollPane] = document.getElementsByClassName('scrollPane');

    if (
      this.props.isInsideWidget !== props.isInsideWidget &&
      !isMobileDevice() &&
      !isTabletDevice()
    ) {
      const [layerConversationList] = document.getElementsByTagName(
        'layer-conversation-list'
      );

      setTimeout(() => {
        const [scrollBack] = document.getElementsByClassName('scroll-back');
        scrollBack && scrollBack.classList.add('hide-scroll-button');

        if (
          scrollPane &&
          layerConversationList &&
          layerConversationList.offsetWidth < scrollPane.offsetWidth
        ) {
          const [scrollNext] = document.getElementsByClassName('scroll-next');
          scrollNext && scrollNext.classList.add('hide-scroll-button');
        }
      }, 1000);

      scrollPane &&
        scrollPane.addEventListener('scroll', this.handleScrollPaneControls, {
          passive: true,
        });

      Layer.client.on('conversations:change', (e) => {
        const [changes] = e.changes;

        if (
          changes &&
          scrollPane &&
          layerConversationList &&
          changes.property === 'metadata' &&
          changes.newValue &&
          changes.newValue.xircles_status === ARCHIVED
        ) {
          this.handleScrollPaneControls({ target: scrollPane });
        }
      });
    }
    if (
      this.props.isLoggedIn !== props.isLoggedIn &&
      props.isInsideWidget &&
      !isMobileDevice() &&
      !isTabletDevice()
    ) {
      setTimeout(() => {
        this.handleScrollPaneControls({ target: scrollPane });
      }, 1000);
    }
    if (
      this.props.location.pathname !== props.location.pathname &&
      props.isInsideWidget &&
      !isMobileDevice() &&
      !isTabletDevice()
    ) {
      setTimeout(() => {
        this.handleScrollPaneControls({ target: scrollPane });
      }, 200);
    }
  }

  handleCollapseAnimationQuestion = (withAnimation = true) => {
    const { location, navigationType } = this.props;

    if (!isDeletedRequestsRoute(location.pathname)) {
      this.openAnimationDelayRequired = false;
    }

    const wasReload =
      (this.checkIsPageReloaded() ||
        navigationType === NAVIGATION_TYPE.TYPE_RELOAD) &&
      this.openAnimationDelayRequired;
    if (wasReload && this.leftPanel) {
      this.leftPanel.classList.add('component--invisible');
    }

    const [contentPanel] = document.getElementsByClassName('content-panel');
    contentPanel && contentPanel.setAttribute('style', 'opacity: 0;');

    const [layerConversationList] = document.getElementsByTagName(
      'layer-conversation-list'
    );
    layerConversationList &&
      layerConversationList.setAttribute('style', 'opacity: 0;');

    const [productQuestionsList] = document.getElementsByClassName(
      'product-questions-list'
    );
    productQuestionsList &&
      productQuestionsList.setAttribute('style', 'display: none;');

    const questionTitlesList = [
      ...document.getElementsByClassName('question-title'),
    ];
    if (questionTitlesList.length > 0) {
      questionTitlesList.forEach((questionTitle) => {
        questionTitle.setAttribute('style', 'opacity: 0;');
      });
    }

    const [deletedRequests] = document.getElementsByClassName(
      'deleted-requests__content'
    );

    if (this.question) {
      this.question.classList.add('question--invisible');
      if (withAnimation) {
        this.question.classList.add('question-change-url-transition');
        deletedRequests &&
          deletedRequests.classList.add('deleted-requests__content--animated');
      }
    }

    setTimeout(() => {
      if (this.question) {
        this.question.setAttribute('style', 'height: 47px;');
        this.question.classList.add('question--collapsed');
      }

      const delay = withAnimation ? 600 : 0;

      setTimeout(() => {
        const [deletedRequests] = document.getElementsByClassName(
          'deleted-requests__content'
        );
        deletedRequests && deletedRequests.setAttribute('style', 'opacity: 1;');

        this.contentEditable.current &&
          this.contentEditable.current.classList.add('component--hidden');
        this.bigButtonBar.current &&
          this.bigButtonBar.current.classList.add('component--hidden');

        if (!withAnimation) {
          this.question &&
            this.question.classList.add('question-change-url-transition');
          deletedRequests &&
            deletedRequests.classList.add(
              'deleted-requests__content--animated'
            );
        }

        if (questionTitlesList.length > 0) {
          questionTitlesList.forEach((questionTitle) => {
            questionTitle.setAttribute('style', 'opacity: 0; display: none;');
          });
        }
      }, delay);

      setTimeout(() => {
        if (wasReload && this.leftPanel) {
          this.leftPanel.classList.remove('component--invisible');
        }
      }, 700);
    }, 0);
  };

  handleOpenAnimationQuestion = () => {
    const { navigationType } = this.props;

    const [layerConversationList] = document.getElementsByTagName(
      'layer-conversation-list'
    );
    layerConversationList.setAttribute('style', 'opacity: 0;');

    const [deletedRequests] = document.getElementsByClassName(
      'deleted-requests__content'
    );
    deletedRequests && deletedRequests.removeAttribute('style');

    if (this.question) {
      this.question.classList.remove('question--collapsed');

      if (this.contentEditable.current) {
        this.contentEditable.current.classList.remove('component--hidden');
        this.contentEditable.current.classList.add('component--invisible');
      }

      if (this.bigButtonBar.current) {
        this.bigButtonBar.current.classList.remove('component--hidden');
        this.bigButtonBar.current.classList.add('component--invisible');
      }

      const { delayRefresh, delayShowItems } =
        (this.checkIsPageReloaded() ||
          navigationType === NAVIGATION_TYPE.TYPE_RELOAD) &&
        this.openAnimationDelayRequired
          ? { delayRefresh: 650, delayShowItems: 1200 }
          : { delayRefresh: 0, delayShowItems: 600 };

      this.openAnimationDelayRequired = false;

      setTimeout(() => this.refreshQuestionHeight(), delayRefresh);

      setTimeout(() => {
        this.question.classList.remove('question--invisible');
        this.contentEditable.current &&
          this.contentEditable.current.classList.remove('component--invisible');
        this.bigButtonBar.current &&
          this.bigButtonBar.current.classList.remove('component--invisible');

        setTimeout(() => {
          const questionTitlesList = [
            ...document.getElementsByClassName('question-title'),
          ];
          if (questionTitlesList.length > 0) {
            questionTitlesList.forEach((questionTitle) => {
              questionTitle.setAttribute('style', 'opacity: 1;');
            });
          }

          const [contentPanel] =
            document.getElementsByClassName('content-panel');
          contentPanel && contentPanel.setAttribute('style', 'opacity: 1;');
        }, 200);
      }, delayShowItems);
    }
  };

  normalizePathname = (pathname) => {
    return pathname[pathname.length - 1] === '/'
      ? pathname.slice(0, -1)
      : pathname;
  };

  handleAnimationQuestionLocationChange = (prevLocation, curLocation) => {
    const animationMethod = {
      [isQuestionsRoute(prevLocation) && isDeletedRequestsRoute(curLocation)]:
        this.handleCollapseAnimationQuestion, // eslint-disable-line
      [isDeletedRequestsRoute(prevLocation) && isQuestionsRoute(curLocation)]:
        this.handleOpenAnimationQuestion, // eslint-disable-line
    };

    const selectedMethod = animationMethod[true];
    return selectedMethod && selectedMethod();
  };

  changeSortBy = (value: string) => {
    this.props.handleOperatorFilterSelection(value);

    const { sortBy } = this.state;
    if (value !== sortBy) {
      this.key = value;
      this.setState({ sortBy: value }, this.updateConversationsCount);
      this.handleShowConversationList();
    }
  };

  handleShowPublicConversationList = (conversation: any) => {
    if (conversation.metadata.isPublic) {
      this.setState({ isPublicListVisible: true }, () => {
        const [publicConversations] = document.getElementsByClassName(
          'public-conversations'
        );
        publicConversations &&
          publicConversations.setAttribute(
            'style',
            'display:block; opacity: 1;'
          );
      });
    }
  };

  handleArchiveConversation = () =>
    setTimeout(() => {
      if (this.props.match.params.productName) {
        const [layerEmptyList] =
          document.getElementsByClassName('layer-empty-list');
        if (layerEmptyList && layerEmptyList.style.display === '') {
          const questionTitle = document.querySelector(
            '.question-title:first-of-type'
          );
          questionTitle && questionTitle.removeAttribute('style');

          const [layerConversationList] = document.getElementsByTagName(
            'layer-conversation-list'
          );
          layerConversationList &&
            layerConversationList.removeAttribute('style');
        }
      }
    }, 750);

  handleShowCommonConversationList = (conversation: any) => {
    if (
      conversation &&
      conversation.metadata.xircles_status !== XIRCLES_STATUSES.ARCHIVED &&
      conversation.metadata.xircles_status !== XIRCLES_STATUSES.DELETED &&
      !conversation.metadata.isPublic &&
      (conversation.metadata.product === this.state.product.id ||
        _isUndefined(conversation.metadata.product))
    ) {
      const questionTitle = document.querySelector(
        '.question-title:first-of-type'
      );
      questionTitle &&
        questionTitle.setAttribute('style', 'display: block; opacity: 1;');

      const [layerConversationList] = document.getElementsByTagName(
        'layer-conversation-list'
      );
      layerConversationList &&
        layerConversationList.setAttribute(
          'style',
          'display:block; opacity: 1;'
        );
    }
  };

  filterConversations = (conversation: any) => {
    if (!this.state.isPublicListVisible && !isOperator(Layer.client.user)) {
      this.handleShowPublicConversationList(conversation);
    }

    if (
      this.props.match.params.productName &&
      !isConversationRoute(this.props.history.location.pathname)
    ) {
      this.handleShowCommonConversationList(conversation);
    }

    if (isOperator(Layer.client.user)) {
      const { sortBy } = this.state;
      if (!sortBy.length) {
        return conversation && !conversation.metadata.isPublic;
      } else if (
        sortBy === 'pending' ||
        sortBy === 'reported' ||
        sortBy === 'cleared'
      ) {
        return (
          conversation &&
          conversation.metadata.pv_status === sortBy &&
          !conversation.metadata.isPublic
        );
      } else {
        return (
          conversation &&
          conversation.metadata.xircles_status === sortBy &&
          !conversation.metadata.isPublic
        );
      }
    } else if (
      this.props.location.pathname.includes('/deleted-requests') ||
      this.props.location.pathname.includes('/deleted-requests/welcome')
    ) {
      if (this.state.product) {
        return (
          conversation &&
          conversation.metadata.xircles_status === XIRCLES_STATUSES.ARCHIVED &&
          !conversation.metadata.isPublic &&
          (conversation.metadata.product === this.state.product.id ||
            _isUndefined(conversation.metadata.product))
        );
      } else {
        return (
          conversation &&
          conversation.metadata.xircles_status === XIRCLES_STATUSES.ARCHIVED &&
          !conversation.metadata.isPublic
        );
      }
    } else {
      if (this.state.product) {
        return (
          conversation &&
          conversation.metadata.xircles_status !== XIRCLES_STATUSES.ARCHIVED &&
          conversation.metadata.xircles_status !== XIRCLES_STATUSES.DELETED &&
          !conversation.metadata.isPublic &&
          (conversation.metadata.product === this.state.product.id ||
            _isUndefined(conversation.metadata.product))
        );
      } else {
        return (
          conversation &&
          conversation.metadata.xircles_status !== XIRCLES_STATUSES.ARCHIVED &&
          conversation.metadata.xircles_status !== XIRCLES_STATUSES.DELETED &&
          !conversation.metadata.isPublic
        );
      }
    }
  };

  filterPublicConversations = (conversation: any) =>
    conversation &&
    conversation.metadata.isPublic &&
    !conversation.metadata.isSeo &&
    !conversation.metadata.isMenu;

  filterProductConversations = (conversation: any, productId: string) =>
    conversation &&
    conversation.metadata.isSeo &&
    conversation.metadata.product === productId;

  /**
   * Once the EditConversationDialog reports back that the Conversation has been created,
   * update our state and our URL
   */
  onCreateConversation = (
    conversation: any,
    redirect: boolean = true,
    corrections
  ) => {
    this.setState({
      conversationId: uuid(conversation.id),
      showEditConversationDialog: false,
      editConversationId: '',
    });
    redirect &&
      this.props.history.push({
        pathname: `/questions/${uuid(conversation.id)}`,
        state: { detail: { corrections, originalText: this.state.text } },
      });
  };

  /**
   * Logout of the client and navigate to the Login page
   */
  logout = (e) => {
    e.preventDefault();
    const { onLogout } = this.props;
    onLogout();
  };

  /**
   * Navigate HCP to show deleted requests
   */
  showDeletedRequests = (e) => {
    e.preventDefault();

    this.props.onToggleMenu();
    this.props.history.push(this.props.location.pathname + '/deleted-requests');
  };

  isTextSelectionMouseEvent = () => {
    const { type } = window.getSelection();
    return type === 'Range';
  };

  removeCorrectionsWrapper() {
    const [correctionsWrapper] = document.getElementsByClassName(
      'corrections-wrapper'
    );

    if (correctionsWrapper) {
      correctionsWrapper.remove();
    }
  }

  onClick = (e) => {
    const id = _get(e, 'target.id', undefined);
    const textSelectionEvent = this.isTextSelectionMouseEvent();
    const target = e.target;
    const isCloseSuggestionsButton =
      target.classList.contains('mdc-icon-button') ||
      target.classList.contains('material-icons');

    if (
      !id.includes('shortcut') &&
      !id.includes('correction-item') &&
      !textSelectionEvent &&
      (!isMobileDevice() || this.isIpad) &&
      !this.state.isSuggestionsShown &&
      !isCloseSuggestionsButton
    ) {
      this.removeCorrectionsWrapper();
      this.setState({ bigButtonBarVisible: false });
    }

    if (
      !target.classList.contains('short-cut__more') &&
      target.className !== 'suggestions' &&
      this.state.isSuggestionsShown
    ) {
      this.handleCloseSuggestionsClick();
    }
  };

  onResize = () => {
    this.removeCorrectionsWrapper();

    if (!isDeletedRequestsRoute(this.props.location.pathname)) {
      this.updateQuestionHeight(this.state.welcomeMessage);
    }

    if (this.props.isInsideWidget && !isMobileDevice() && !isTabletDevice()) {
      const [scrollPane] = document.getElementsByClassName('scrollPane');
      scrollPane && this.handleScrollPaneControls({ target: scrollPane });
    }
  };

  closeHelpList = () => {
    if (this.state.isSuggestionsShown) {
      this.setState({ isSuggestionsShown: false });
    }
  };

  onFocus = () => {
    if (this.isBlurred) {
      setTimeout(() => {
        this.textareaRef.current &&
          this.textareaRef.current.removeAttribute('style');
        this.isBlurred = false;
      }, 0);
    }
  };

  onBlur = () => {
    if (isTabletDevice()) {
      const input = document.activeElement;
      if (input) {
        input.blur();
      }
    }

    if (this.isDocumentHidden()) {
      this.isBlurred = true;
    }
  };

  onScroll = () => {
    const isDeletedRequests = isDeletedRequestsRoute(
      this.props.location.pathname
    );

    if (
      this.question.classList &&
      !this.question.classList.contains('question-mobile-view') &&
      !isDeletedRequests &&
      !isOperator(Layer.client.user)
    ) {
      const top = window.pageYOffset;
      const largeScreenWidth = 1035;
      const mediumScreenWidth = 768;
      const smallScreenWidth = 376;
      const tinyScreenWidth = 361;
      const superTinyScreenWidth = 321;
      const oldFixedBigButtonBarHeight =
        this.bigButtonBarHeight >= 181 ? 73 : 44;
      if (this.bigButtonBarHeight && this.bigButtonBarHeight !== 0) {
        this.difference = this.bigButtonBarHeight - oldFixedBigButtonBarHeight;
      }

      const firstMessageTwoLinesScreenWidth = 434;

      if (this.state.product) {
        const [login] = document.getElementsByClassName('login');
        const [menuButton] = document.getElementsByClassName('menu-button');
        const [menuTitle] = document.getElementsByClassName('menu');

        if (document.documentElement.clientWidth >= largeScreenWidth) {
          if (top >= 69) {
            if (login) {
              login.style.position = 'fixed';
            }

            menuButton.style.position = 'fixed';
            menuTitle.style.position = 'fixed';
          } else {
            login && login.removeAttribute('style');
            menuButton.removeAttribute('style');
            menuTitle.removeAttribute('style');
          }

          const [productBackButton] = document.getElementsByClassName(
            'product-title__back-button'
          );

          if (productBackButton) {
            if (top >= 85) {
              productBackButton.classList.add(
                'product-title__back-button--fixed'
              );
            } else {
              productBackButton.classList.remove(
                'product-title__back-button--fixed'
              );
            }
          }
        }
      }

      let questionFixed, fixedTop;
      if (document.documentElement.clientWidth >= largeScreenWidth) {
        if (this.state.product) {
          questionFixed = 121;
          fixedTop = 205;
        } else {
          questionFixed = 128;
          fixedTop = this.bigButtonBarLines === 1 ? 220 : 250;
        }
      } else if (document.documentElement.clientWidth >= mediumScreenWidth) {
        if (this.state.product) {
          questionFixed = 102;
          fixedTop = 185;
        } else {
          questionFixed = 108;
          fixedTop = this.bigButtonBarLines === 1 ? 197 : 230;
        }
      } else if (document.documentElement.clientWidth >= smallScreenWidth) {
        if (this.state.product) {
          questionFixed = 128;
          fixedTop = 185;
        } else {
          questionFixed = 104;
          fixedTop = 230;

          if (this.bigButtonBarHeight && this.difference && !isMobileDevice()) {
            fixedTop += this.difference - 30;
          } else if (
            this.bigButtonBarHeight &&
            this.difference &&
            isMobileDevice()
          ) {
            if (this.bigButtonBarLines === 2) {
              fixedTop = 225;
            } else if (this.bigButtonBarLines === 3) {
              fixedTop = 263;
            } else {
              fixedTop += this.difference - 10;
            }
          }
        }
      } else if (document.documentElement.clientWidth >= tinyScreenWidth) {
        if (this.state.product) {
          questionFixed = 128;
          fixedTop = 185;
        } else {
          questionFixed = 84;
          fixedTop = 272;

          if (isMobileDevice()) {
            fixedTop =
              this.bigButtonBarLines === 2
                ? 225
                : this.bigButtonBarLines === 3
                ? 265
                : 297;
          }
        }
      } else if (document.documentElement.clientWidth >= superTinyScreenWidth) {
        if (this.state.product) {
          questionFixed = 128;
          fixedTop = 213;
        } else {
          questionFixed = 84;
          fixedTop = 310;

          if (isMobileDevice()) {
            fixedTop = this.bigButtonBarLines === 3 ? 255 : 340;
          }
        }
      } else {
        if (this.state.product) {
          questionFixed = 128;
          fixedTop = 213;
        } else {
          questionFixed = 84;
          fixedTop = 330;

          if (isMobileDevice()) {
            fixedTop = this.bigButtonBarLines === 3 ? 260 : 335;
          }
        }
      }

      if (
        this.additionalMessage.current &&
        this.additionalMessage.current.clientHeight > 0
      ) {
        this.firstChatBubbleHeight =
          document.documentElement.clientWidth < smallScreenWidth
            ? 31
            : this.additionalMessage.current.clientHeight;
      }

      const chatBubbleMargin = 5;

      if (this.props.firstTime && !this.state.product) {
        const firstChatBubbleHeight =
          document.documentElement.clientWidth <=
          firstMessageTwoLinesScreenWidth
            ? 51
            : 31;
        questionFixed += firstChatBubbleHeight + chatBubbleMargin;
      } else {
        questionFixed += this.firstChatBubbleHeight + chatBubbleMargin;
      }

      if (top >= questionFixed) {
        this.mainMessage.current &&
          this.mainMessage.current.removeAttribute('style');
        this.lineBreak.current &&
          this.lineBreak.current.setAttribute('style', 'display: none;');
        this.additionalMessage.current &&
          this.additionalMessage.current.setAttribute(
            'style',
            'display: none;'
          );
        this.leftPanel.classList.add('questionFixed');

        if (!this.state.product) {
          if (this.bigButtonBarHeight && isMobileDevice()) {
            const topQuestionFixed = 126 + this.difference;
            if (top >= topQuestionFixed) {
              const updatedTopQuestionFixed = isInsideFrame()
                ? topQuestionFixed - 30 - 7
                : topQuestionFixed - 30;
              this.question.style.top = `-${updatedTopQuestionFixed}px`;
            }
          }
          if (this.bigButtonBarHeight && this.bigButtonBarLines === 1) {
            this.question.style.top = '-92px';
          }
        }
      } else {
        this.mainMessage.current &&
          this.mainMessage.current.setAttribute('style', 'margin-top: 5px');
        this.lineBreak.current &&
          this.lineBreak.current.removeAttribute('style');
        this.additionalMessage.current &&
          this.additionalMessage.current.removeAttribute('style');
        this.leftPanel.classList.remove('questionFixed');
        this.refreshQuestionHeight();
        this.question.style.top = null;
      }
      if (top >= fixedTop && !this.leftPanelFixed) {
        const questionHeight = this.question && this.question.style.height;
        this.scrollPane.current &&
          this.scrollPane.current.setAttribute(
            'style',
            `padding-top: ${questionHeight} !important;`
          );
        this.leftPanelFixed = true;
        this.leftPanel.classList.toggle('fixed');
      } else if (top < fixedTop && this.leftPanelFixed) {
        this.scrollPane.current &&
          this.scrollPane.current.setAttribute('style', 'padding-top: 0;');
        this.leftPanelFixed = false;
        this.leftPanel.classList.toggle('fixed');
      }
    }
  };

  handleScrollPaneControls = ({ target }) => {
    const [layerConversationList] = document.getElementsByTagName(
      'layer-conversation-list'
    );

    const [scrollNext] = document.getElementsByClassName('scroll-next');
    const [scrollBack] = document.getElementsByClassName('scroll-back');

    const maxScroll = target.scrollWidth - target.clientWidth;

    const scrollPaneControls = [
      {
        condition: target.scrollLeft === 0,
        callback: () => scrollBack.classList.add('hide-scroll-button'),
      },
      {
        condition: target.scrollLeft > 0,
        callback: () => scrollBack.classList.remove('hide-scroll-button'),
      },
      {
        condition: target.scrollLeft === maxScroll,
        callback: () => scrollNext.classList.add('hide-scroll-button'),
      },
      {
        condition: target.scrollLeft < maxScroll,
        callback: () => scrollNext.classList.remove('hide-scroll-button'),
      },
      {
        condition: layerConversationList.offsetWidth < target.offsetWidth,
        callback: () => scrollNext.classList.add('hide-scroll-button'),
      },
    ];

    return scrollPaneControls
      .filter((current) => !!current.condition)
      .map((current) => current.callback());
  };

  prepareSuggestionsByAutocomplete = ({ data: { by_title, by_tags } }) => {
    this.setState({
      suggestionList: [
        ...by_title.map((current) => ({ title: current })),
        ...by_tags,
      ],
    });
  };

  prepareSuggestionsByTags = ({ data: { suggestions } }) => {
    const preparedSuggestions = suggestions.reduce(
      (acc, title) => (acc.indexOf(title) === -1 ? acc.concat(title) : acc),
      []
    );

    if (!_isEmpty(this.recognizedTags)) {
      this.setState({ suggestionList: preparedSuggestions });
    }
  };

  handleSuggestionsByAutocomplete(title) {
    const minLengthStartSearch = 3;
    const titleLength = title ? title.trim().length : 0;
    const isSearch =
      title &&
      title.length >= minLengthStartSearch &&
      this.autoCompleteSearchLength !== titleLength;

    this.autoCompleteSearchLength = titleLength;
    const product = this.state.product ? this.state.product.id : undefined;
    return (
      isSearch &&
      wrapCatch(
        getSuggestionsByAutocomplete(title, product).then((data) =>
          this.prepareSuggestionsByAutocomplete(data)
        )
      )
    );
  }

  handleSuggestionsByTag(title) {
    const tags = this.state.tags.filter(
      (tag) => title.toLowerCase().indexOf(tag.toLowerCase()) >= 0
    );
    const isSearchSuggestionsByTag =
      !_isEmpty(tags) && this.recognizedTags.length !== tags.length;

    this.recognizedTags = [...tags];

    if (_isEmpty(this.recognizedTags)) {
      this.setState({ suggestionList: [] });
    }

    const product = this.state.product ? this.state.product.id : undefined;
    return (
      isSearchSuggestionsByTag &&
      wrapCatch(
        getSuggestionsByTags(tags, product).then((data) =>
          this.prepareSuggestionsByTags(data)
        )
      )
    );
  }

  handleGetSuggestions(title) {
    const { SuggestMode } = config;
    const suggest_mode = SuggestMode;

    return suggest_mode === SUGGEST_MODES.AUTOCOMPLETE
      ? this.handleSuggestionsByAutocomplete(title)
      : this.handleSuggestionsByTag(title);
  }

  handleChange = (event: any) => {
    const { FeatureAutosuggestEnabled } = config;
    this.state.isSuggestionsShown && this.hideSuggestionsWithoutFocusSetting();
    const value = _get(event, 'target.value', '');

    if (FeatureAutosuggestEnabled)
      debounce(this.handleGetSuggestions(value), 1000);
    // Firefox has a bag in ContentEditable - it auto removes white spaces in the end of words,
    // so for firefox we replace white spaces with '&nbsp;'
    this.setState({
      text: isFirefox()
        ? value.slice(0, 400).replace(/ /gm, '&nbsp;')
        : value.slice(0, 400),
    });
  };

  clickShortCut = (text) => {
    this.focusOnTextArea();
    this.removeCorrectionsWrapper();

    if (isMobileDevice() && !isTabletDevice() && !this.isIpad) {
      if (!_isEmpty(this.state.text)) {
        this.setState({ text: '' });
      }

      setTimeout(() => this.setState({ text }), 600);
    } else {
      this.setState({ text });
    }
  };

  focusOnTextArea = () => this.textareaRef.current.focus();

  focusOnTextAreaDelayed = () =>
    setTimeout(() => {
      const el = document.getElementById('editable');
      const [childNodes] = el.childNodes;

      const range = document.createRange();
      const selection = window.getSelection();

      range.setStart(childNodes, childNodes.childNodes.length);
      range.collapse(true);

      selection.removeAllRanges();
      selection.addRange(range);

      el.focus();
    }, 0);

  blurOnTextArea = () => this.textareaRef.current.blur();

  handleContentEditClick = (e) => {
    window.dispatchEvent(
      new CustomEvent('tr-event', { detail: { target: e.target } })
    );
    this.setState({ bigButtonBarVisible: true });
    // to avoid refocusing
    e.stopPropagation();
  };

  handleKeyDown = (e) => {
    if (this.validateSubmit()) {
      if (e.keyCode === 13) {
        this.blurOnTextArea();
        this.postQuestion(true);
      }
    }
  };

  validateSubmit = () => {
    let lineWithoutSpaces = this.state.text.replace(/\s/g, '');

    const conditions = [
      isUtf8(lineWithoutSpaces),
      lineWithoutSpaces.length > 2,
      isDotSlash(lineWithoutSpaces),
      isEmptyBytes(lineWithoutSpaces),
    ];

    const [firstConditionResult] = conditions;
    const isInputValid = conditions.every(
      (condition) => condition === firstConditionResult
    );

    return isInputValid;
  };

  checkIsPageReloaded = () => {
    const navigationType = _get(window, 'performance.navigation.type');
    return navigationType === NAVIGATION_TYPE.TYPE_RELOAD;
  };

  generateRandomWelcomeMessage = (isFirstTime, productName) => {
    const t = this.t;
    const numberOfQuestions = 5;

    const index = _random(1, numberOfQuestions);
    const randomQuestion = `QUESTION_WELCOME_LONG_${index}`;
    const defaultQuestion = 'QUESTION_WELCOME_LONG';

    const isPageReloaded = this.checkIsPageReloaded();

    const selectedQuestion =
      isFirstTime && !isPageReloaded ? defaultQuestion : randomQuestion;

    return t(selectedQuestion, { productName });
  };

  refreshQuestionHeight = () => {
    var [bigButtonBarHeight, welcomeHeight, contentEditableHeight] = [
      this.bigButtonBar.current && this.bigButtonBar.current.offsetHeight,
      this.welcome.current && this.welcome.current.offsetHeight,
      this.contentEditable.current && this.contentEditable.current.offsetHeight,
    ];
    const [leftPanel] = document.getElementsByClassName('left-panel');

    const isPrevDeletedRequest = isDeletedRequestsRoute(
      this.props.prevLocation
    );

    if (!isPrevDeletedRequest || this.bigButtonBarHeight === undefined) {
      this.bigButtonBarHeight = bigButtonBarHeight;
      if (this.bigButtonBar.current) {
        this.bigButtonBarLines = Math.round(
          this.bigButtonBar.current.offsetHeight / 37
        );
      }
    }

    if (!this.welcomeHeight || welcomeHeight !== this.welcomeHeight) {
      this.welcomeHeight = welcomeHeight;
    }

    if (!contentEditableHeight && isIE()) {
      const [contentEditable] =
        document.getElementsByClassName('content-editable');
      if (contentEditable && contentEditable.currentStyle.height) {
        contentEditableHeight = +contentEditable.currentStyle.height.replace(
          'px',
          ''
        );
      }
    }

    if (
      this.welcomeHeight &&
      contentEditableHeight &&
      leftPanel.classList.length <= 1
    ) {
      //42 - it's a question padding-top and padding bottom (14px and 1414px) plus content-editable margin-top 14px
      if (this.bigButtonBarHeight && !this.state.product) {
        this.question.style.height = `${
          this.bigButtonBarHeight +
          this.welcomeHeight +
          contentEditableHeight +
          42
        }px`;
      } else {
        this.question.style.height = `${
          this.welcomeHeight + contentEditableHeight + 42
        }px`;
      }
    }
  };

  sideScroll = (
    element,
    target,
    scrollNext,
    scrollBack,
    scrollDirection,
    maxScroll,
    speed,
    distance,
    step
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (scrollDirection === 'next') {
        element.scrollLeft += step;
      } else {
        element.scrollLeft -= step;
      }

      scrollNext.classList.remove('hide-scroll-button');
      scrollBack.classList.remove('hide-scroll-button');

      if (
        (scrollDirection === 'next' && element.scrollLeft >= maxScroll) ||
        (scrollDirection === 'back' && element.scrollLeft <= maxScroll)
      ) {
        element.scrollLeft = maxScroll;
        target.classList.add('hide-scroll-button');
      }

      scrollAmount += step;

      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  handleScrollClick = (direction) => {
    const [scrollPane] = document.getElementsByClassName('scrollPane');
    const maxScroll =
      direction === 'next'
        ? scrollPane.scrollWidth - scrollPane.clientWidth
        : 0;

    const [target] =
      direction === 'next'
        ? document.getElementsByClassName('scroll-next')
        : document.getElementsByClassName('scroll-back');

    const [scrollNext] = document.getElementsByClassName('scroll-next');
    const [scrollBack] = document.getElementsByClassName('scroll-back');

    this.sideScroll(
      scrollPane,
      target,
      scrollNext,
      scrollBack,
      direction,
      maxScroll,
      10,
      150,
      10
    );
  };

  handleWidgetGoBackClick = (e) => {
    e.preventDefault();

    if (window.parentIFrame) {
      const [widgetBackButton] = document.getElementsByClassName(
        'widget__back-button'
      );
      widgetBackButton &&
        widgetBackButton.classList.add('widget__back-button--hidden');

      window.parentIFrame.sendMessage({ id: 'collapse-widget' });
    }
  };

  createPredefinedAnswerLink = (pathname) => {
    const appUrl = getAppUrl();
    return `${appUrl}${pathname}`;
  };

  hadleClickPredefinedAnswer = (e, text) => {
    e.preventDefault();

    if (!isCrawler()) {
      this.props.onToggleMenu();
      this.startConversation(text);
    }
  };

  renderLeftPanel() {
    const t = this.t;

    const isContentPanelVisible = _get(
      this.props.themeSettings,
      'isContentPanelVisible',
      false
    );
    const isDeletedRequestsButtonVisible = _get(
      this.props.themeSettings,
      'isDeletedRequestsButtonVisible',
      false
    );
    const isEmailServiceMenuVisible = _get(
      this.props.themeSettings,
      'isEmailServiceMenuVisible',
      false
    );
    const isPredefinedAnswersMenuVisible = _get(
      this.props.themeSettings,
      'isPredefinedAnswersMenuVisible',
      false
    );
    const isInfoCardsVisible = _get(
      this.props.themeSettings,
      'isInfoCardsVisible',
      false
    );

    // const isOperator = this.state.isOperator
    // props for Menu component
    const buttonAccountList = [
      {
        id: 'account-info-button',
        text: `${t('MENU_ABOUT_XIRCLES')}`,
        href: `${t('MENU_ABOUT_XIRCLES_URL')}`,
        iconClassName: 'material-icons',
        iconName: 'info',
        className: 'menu-account-button',
        target: '_top',
      },
      isDeletedRequestsButtonVisible &&
        !isOperator(Layer.client.user) && {
          id: 'account-deleted-requests-button',
          text: `${t('MENU_DELETED_REQUESTS')}`,
          iconClassName: 'material-icons',
          iconName: 'delete',
          onClick: this.showDeletedRequests,
          className: 'menu-account-button',
        },
      isPredefinedAnswersMenuVisible && {
        id: 'account-predefined-answers',
        title: `${t('MENU_PREDEFINED_ANSWERS')}`,
        items: [
          {
            id: 'about-dupixent',
            text: `${t('MENU_ABOUT_DUPIXENT')}`,
            iconClassName: 'material-icons',
            url: '/questions/047c6dae-6b42-47e3-a623-3e4ab6442858',
            onClick: this.hadleClickPredefinedAnswer,
            createLink: this.createPredefinedAnswerLink,
          },
          {
            id: 'indications',
            text: `${t('MENU_INDICATIONS')}`,
            iconClassName: 'material-icons',
            url: '/questions/39e9b3a6-4902-46f2-9b7f-8f571ce5c54c',
            onClick: this.hadleClickPredefinedAnswer,
            createLink: this.createPredefinedAnswerLink,
          },
          {
            id: 'adverse-events',
            text: `${t('MENU_ADVERSE_EVENTS')}`,
            iconClassName: 'material-icons',
            url: '/questions/938ba678-305c-40a9-a740-cc40d1b90f2b',
            onClick: this.hadleClickPredefinedAnswer,
            createLink: this.createPredefinedAnswerLink,
          },
        ],
      },
      isEmailServiceMenuVisible && {
        id: 'account-email-service',
        text: `${t('MENU_EMAIL_SERVICE')}`,
        href: `${t('MENU_EMAIL_SERVICE_LINK')}`,
        target: '_blank',
        iconClassName: 'material-icons email-service',
        className: 'menu-account-button',
      },
      {
        id: 'account-send-feedback-button',
        text: `${t('MENU_SEND_FEEDBACK')}`,
        href: `mailto:${t('MENU_SEND_FEEDBACK_URL')}`,
        iconClassName: 'material-icons',
        iconName: 'email',
        className: 'menu-account-button',
      },
      {
        id: 'account-logout-button',
        text: `${config.Debug ? 'Debug: ' : ''}${t('MENU_LOGOUT')}`,
        iconClassName: 'material-icons',
        iconName: 'power_settings_new',
        onClick: this.logout,
        className: 'menu-account-button',
      },
    ];

    // props for Menu component
    // eslint-disable-next-line
    const buttonContentList = [
      {
        text: `${t('MENU_IMPRINT')}`,
        href: 'imprint',
        className: 'menu-content-button',
      },
      {
        text: `${t('MENU_DATA_PRIVACY_STATEMENT')}`,
        href: 'data-privacy',
        className: 'menu-content-button',
      },
      {
        text: `${t('MENU_TERMS_AND_CONDITIONS')}`,
        href: 'terms',
        className: 'menu-content-button',
      },
    ];

    const isDeletedRequests = isDeletedRequestsRoute(
      this.props.location.pathname
    );
    const isConversationNext = isConversationRoute(
      this.props.history.location.pathname
    );

    return (
      <Fragment>
        <div className={'title-fake'} ref={this.parentTitleRef}>
          <p ref={this.titleRef}></p>
        </div>
        {!isOperator(Layer.client.user) ? (
          <div className="left-panel">
            <HeaderContainer
              ref={this.header}
              isIOS12={this.props.isIOS12}
              isInsideWidget={this.props.isInsideWidget}
              themeSettings={this.props.themeSettings}
              isSuggestionsShown={this.state.isSuggestionsShown}
              pathname={this.props.location.pathname}
              titleProps={
                <h1>
                  {t('COMMON_HEADER_PRODUCT_NAME', {
                    productName: this.state.productName,
                  })}
                </h1>
              }
              product={this.state.product}
              productName={this.state.productName}
              productCompoundComponent={this.state.productCompoundComponent}
              onWidgetGoBackClick={this.handleWidgetGoBackClick}
              normalizePathname={this.normalizePathname}
              onLogout={() => this.props.history.push()}
            />

            <MenuContainer buttonAccountList={buttonAccountList} />

            <div
              className="header-background"
              ref={this.headerBackground}
              style={{
                boxShadow: '0 4px 4px -2px rgba(0, 0, 0, 0.19)',
                zIndex: 1,
              }}
            >
              {isDeletedRequests && (
                <div className="deleted-requests__wrapper">
                  <div className="deleted-requests__content">
                    <button
                      id="arrow-back-questions"
                      data-tr-event
                      className="deleted-requests__back-button"
                      onClick={() => this.props.history.goBack()}
                    >
                      <i className="material-icons">arrow_back</i>
                    </button>
                    <h3 className="deleted-requests__subtitle">
                      {t('MENU_DELETED_REQUESTS')}
                    </h3>
                  </div>
                </div>
              )}
              <div
                className={`question ${
                  this.state.product ? 'question-product' : ''
                }`}
                ref={(r) => {
                  r &&
                    (this.suggestionsElem =
                      r.getElementsByClassName('suggestions')[0]);
                  r && setTimeout(() => r.classList.add('open'));
                  r && (this.question = r);
                }}
              >
                <div ref={this.welcome} className="welcome">
                  <div className="logo">
                    <span></span>
                  </div>
                  <div className="text" ref={this.additionalMessage}>
                    {this.state.welcomeMessage}
                  </div>
                  <br ref={this.lineBreak} />
                  <div ref={this.mainMessage} className="text">
                    {t('QUESTION_WELCOME_SHORT')}
                  </div>
                </div>

                <ContentEdit
                  contentEditableRef={this.contentEditable}
                  tags={this.state.tags}
                  dictionaries={this.state.dictionaries}
                  suggestions={this.state.suggestionList}
                  value={this.state.text}
                  send={() => this.postQuestion(true)}
                  showSubmit={this.validateSubmit()}
                  onChange={this.handleChange}
                  onFocus={this.handleContentEditFocus}
                  onClick={this.handleContentEditClick}
                  onBlur={this.handleContentEditBlur}
                  onKeyDown={this.handleKeyDown}
                  className="questionInput"
                  placeholder={`${t('QUESTION_PLACEHOLDER')}`}
                  innerRef={this.textareaRef}
                  onSuggestionSelect={this.postSuggestion}
                  suggest_mode={config.SuggestMode}
                  isInsideWidget={this.props.isInsideWidget}
                  isIOS12={this.props.isIOS12}
                  isMobileDevice={this.isMobileDevice}
                  isIpad={this.isIpad}
                  isLoading={this.state.isLoading}
                  focusRequired={this.state.focusRequired}
                  product={this.state.product}
                >
                  {this.state.isSuggestionsShown && (
                    <Suggestions
                      isSuggestionsShown={this.state.isSuggestionsShown}
                      headerText={`${t('SUGGESTIONS_HEADER_TEXT')}`}
                      helpList={this.state.helpList}
                      onCloseSuggestionsClick={this.handleCloseSuggestionsClick}
                      onSuggestionClick={this.postSuggestion}
                    />
                  )}
                </ContentEdit>

                {!this.state.product && (
                  <BigButtonBar
                    innerRef={this.bigButtonBar}
                    buttons={this.state.shortCutList}
                    className={
                      this.state.bigButtonBarVisible
                        ? 'bigButtonBarVisible'
                        : ''
                    }
                    messageTranslation={
                      config.FeatureShortcutsEnabled
                        ? t('SUGGESTIONS_TRY_THIS')
                        : ''
                    }
                  />
                )}
              </div>
            </div>

            {this.props.isInsideWidget &&
            !isMobileDevice() &&
            !isTabletDevice() ? (
              <ScrollPaneWrapper
                handleScrollBack={() => this.handleScrollClick('back')}
                handleScrollNext={() => this.handleScrollClick('next')}
              >
                <div ref={this.scrollPane} className="scrollPane">
                  <ConversationList
                    key={this.key} // force update after user change
                    filter={this.filterConversations}
                    replaceableContent={this.props.customizeConversationList}
                    onConversationSelected={(e) =>
                      this.onConversationSelected(e)
                    }
                  />
                </div>
              </ScrollPaneWrapper>
            ) : (
              <div
                ref={this.scrollPane}
                className={`scrollPane  ${
                  isDeletedRequests ? 'scrollPane__deleted-requests' : ''
                }`}
              >
                {this.state.product && (
                  <h2 className="question-title">
                    {this.t('COMMON_MY_QUESTIONS')}
                  </h2>
                )}
                <ConversationList
                  key={this.key} // force update after user change
                  filter={this.filterConversations}
                  _queryModel={QUERY_TYPES.PRODUCT_QUESTIONS}
                  replaceableContent={this.props.customizeConversationList}
                  onConversationSelected={(e) => this.onConversationSelected(e)}
                />
                {this.state.product && (
                  <h2 className="question-title">
                    {this.t('COMMON_PRODUCT_QUESTIONS')}
                  </h2>
                )}
                {this.state.product && (
                  <ConversationList
                    className="product-questions-list"
                    filter={(conversation) =>
                      this.filterProductConversations(
                        conversation,
                        _get(this.state, 'product.id')
                      )
                    }
                    _queryModel={QUERY_TYPES.PRODUCT_QUESTIONS}
                    replaceableContent={this.props.customizeConversationList}
                    onConversationSelected={(e) =>
                      this.onConversationSelected(e)
                    }
                  />
                )}
                {isContentPanelVisible && !isConversationNext && (
                  <ContentPanelDelayed
                    appUrl={this.props.appUrl}
                    delayTime={200}
                    isMounted={!isDeletedRequests}
                    filterPublicList={this.filterPublicConversations}
                    isPublicListVisible={this.state.isPublicListVisible}
                    isDiseaseCardsVisible={isHcp(Layer.client.user)}
                    isInfoCardsVisible={isInfoCardsVisible}
                    themeSettings={this.props.themeSettings}
                    customizeConversationList={
                      this.props.customizeConversationList
                    }
                    onConversationSelected={(e) =>
                      this.onConversationSelected(e)
                    }
                  />
                )}
                {!isInsideFrame() &&
                  !isDeletedRequests &&
                  this.state.product && (
                    <BrandFooter isProduct={this.state.product} />
                  )}
                {!isInsideFrame() &&
                  !isDeletedRequests &&
                  !this.state.product && <Footer />}
              </div>
            )}
          </div>
        ) : (
          <div className="left-panel">
            <HeaderOperatorContainer
              themeSettings={this.props.themeSettings}
              titleProps={<h2>{t('COMMON_OPERATOR_ASSISTENT')}</h2>}
              onLogout={() => this.props.history.push()}
              isOperator={isOperator(Layer.client.user)}
            />

            <MenuContainer buttonAccountList={buttonAccountList} />

            <Filter
              value={this.props.operatorFilterSelected}
              changeSortBy={this.changeSortBy}
            />

            <div className="counter-wrapper">
              <span id="counter">
                {`${t('COMMON_CONVERSATION_COUNT')}: `}
                {this.state.isFetchingConversationCount
                  ? '...'
                  : this.state.conversationsCount}
              </span>
            </div>

            {this.props.isInsideWidget &&
            !isMobileDevice() &&
            !isTabletDevice() ? (
              <ScrollPaneWrapper
                handleScrollBack={() => this.handleScrollClick('back')}
                handleScrollNext={() => this.handleScrollClick('next')}
              >
                <div
                  className={`scrollPane ${
                    isOperator(Layer.client.user) ? 'scrollPane--operator' : ''
                  }`}
                >
                  <ConversationList
                    key={this.key} // force update after sortBy or userId change in operator mode
                    filter={this.filterConversations}
                    onConversationSelected={(e) =>
                      this.onConversationSelected(e)
                    }
                  />
                </div>
              </ScrollPaneWrapper>
            ) : (
              <div className={'scrollPane scrollPane--operator'}>
                <ConversationList
                  key={this.key} // force update after sortBy or userId change in operator mode
                  filter={this.filterConversations}
                  onConversationSelected={(e) => this.onConversationSelected(e)}
                />
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    // Setup the CSS Classes for the root element
    let rootClasses = 'questionPage';
    if (this.state.conversationId || this.state.showEditConversationDialog)
      rootClasses += ' has-conversation';
    if (isMobileDevice()) rootClasses += ' mobile-view';
    if (isFirefox()) rootClasses += ' firefox';
    if (isIE()) rootClasses += ' ie';
    if (isDeletedRequestsRoute(this.props.location.pathname))
      rootClasses += ' deleted-requests';
    if (this.state.product && !isOperator(Layer.client.user))
      rootClasses += ' productPage';

    return (
      <div className={rootClasses}>
        <HelmetWrapper
          product={this.state.product}
          location={this.props.location.pathname}
        />
        {this.state.isPageVisible && this.renderLeftPanel()}
      </div>
    );
  }
}

export default withTranslation()(QuestionPage);
