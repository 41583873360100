/* eslint-disable no-restricted-globals */
import Layer from '@layerhq/web-xdk';
import Cookies from 'universal-cookie';

import settings from '@layerhq/web-xdk/settings';

import './doccheck-message-model';

import extendBaseView from '../xircles-choice-base-view/xircles-choice-message-base-view';

import config from '../../config.json';

const cookies = new Cookies();

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

let win = null;

registerComponent(
  'xircles-doccheck-message-view',
  extendBaseView({
    mixins: [MessageViewMixin],
    template: `
    <div layer-id='label' class='layer-choice-message-view-label'></div>
    <div layer-id='choices' class='layer-choice-message-view-choices'></div>
  `,
    style: `
  xircles-doccheck-message-view .layer-choice-message-view-choices {
    display: flex;
    flex-direction: column;
  }
  `,
    methods: {
      onAfterCreate() {
        this.nodes.label.innerText = this.model.label;
        this.model.choices.forEach((choice) => {
          this.createElement('layer-action-button', {
            text: choice.text,
            tooltip: choice.tooltip,
            event:
              choice.id === 'true'
                ? 'xircles-doccheck-select'
                : 'layer-choice-select',
            data: { id: choice.id },
            // icon: choice.icon,
            parentNode: this.nodes.choices,
          });
        });
      },

      onDestroy() {
        win && win.close();
      },

      runAction({ event, data }) {
        const cookieParams = {
          path: '/',
          maxAge: 60 * 30 * 24,
          domain: location.hostname,
          //secure: true,
          sameSite: 'strict',
        };

        const cleanUp = () => {
          win && win.close();
          cookies.remove('xir-dc-success', cookieParams);
          cookies.remove('xir-dc', cookieParams);
        };

        if (event === 'xircles-doccheck-select') {
          if (!this.model.isSelectionEnabled()) return;

          const conversationId = this.messageViewer.message.conversationId;

          const windowParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
          width=467,height=253,left=100,top=100`;

          cookies.set('xir-dc', conversationId, cookieParams);

          cookies.remove('xir-dc-success', cookieParams);

          win = window.open(config.DocCheckUrl, 'doccheck', windowParams);

          const interval = setInterval(() => {
            const success =
              cookies.get('xir-dc-success') &&
              cookies.get('xir-dc') === conversationId;

            if (success || (win && win.closed)) {
              clearInterval(interval);

              const selection = success ? data : { id: 'false' };
              this.runAction({ event: 'layer-choice-select', data: selection });
              this.trigger('layer-choice-select', selection);

              cleanUp();
            }
          }, 100);
        } else if (event === 'layer-choice-select') {
          if (!this.model.isSelectionEnabled()) return;
          this.onChoiceSelect(data);

          const rootPart = this.model.message.getPartsMatchingAttribute({
            role: 'root',
          })[0];
          const rootModel = settings.client.getMessageTypeModel(rootPart.id);
          this.trigger(this.model.responseName, {
            model: this.model,
            data: this.model,
            rootModel,
          });
          cleanUp();
        }
      },
    },
  })
);
