import Layer from '@layerhq/web-xdk';
import _ from 'lodash';

import { SERVICE_NAMES } from '../../const';

import './control-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

registerComponent('xircles-control-message-view', {
  mixins: [MessageViewMixin],
  style: `
    .xircles-control-message-view {
        display: none;
    }
  `,
  template: '',
  methods: {
    onAfterCreate() {
      const view = this;
      const model = view.properties.model;
      const recipientStatus =
        model.message.recipientStatus[Layer.client.user.id];
      if (
        model.serviceName !== SERVICE_NAMES.NAME_CLIENT &&
        (recipientStatus === 'sent' ||
          Layer.client.user.metadata['user_type'] === 'operator') &&
        !model.checked
      ) {
        const detail = model.data || {};
        detail.serviceName = model.serviceName;
        detail.user = model.message.sender;
        detail.conversationId = this.messageViewer.message.conversationId;
        view.trigger(`xircles-${_.kebabCase(model.eventName)}`, detail);
        model.checked = true;
      }
    },
  },
});
