export default {
  'Show all': '',
  'Forward requested': 'forward_requested',
  'Not Finished': 'not_finished',
  'PV pending': 'pending',
  Forwarded: 'forwarded',
  'Answer delivered': 'answer_delivered',
  Closed: 'closed',
  Helpful: 'helpful',
  'PV reported': 'reported',
  'PV Cleared': 'cleared',
  Deleted: 'deleted',
  'Expert Positive': 'expert_positive',
  'Expert Negative': 'expert_negative',
};
