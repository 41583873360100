// @flow
import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import PullDownItem from '../PullDownItem';
import './style.less';
import IconButton from '../../material-components/IconButton';
import i18next from 'i18next';

type Props = {
  suggestions: Array<string>,
  onClose: Function,
  onItemClick: Function,
  preventBubblingEvent: Function,
};

function PullDown({
  suggestions,
  onClose,
  onItemClick,
  preventBubblingEvent,
}: Props) {
  return (
    <div>
      <div
        className="pull-down__overlay"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose(e);
        }}
      />
      {suggestions.length > 0 && (
        <div className="pull-down">
          <span className="pull-down__title">
            {i18next.t('SUGGESTIONS_DO_YOU_MEAN')}
          </span>
          <div className="pull-down__button">
            <IconButton
              id="pull-down-close"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose(e);
              }}
            >
              <MaterialIcon icon="clear" />
            </IconButton>
          </div>
          <div className="pull-down__suggestions">
            {suggestions.map((sugg, index) => (
              <PullDownItem
                suggestion={sugg}
                key={sugg + index}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onItemClick(typeof sugg === 'object' ? sugg.title : sugg);
                }}
                preventBubblingEvent={preventBubblingEvent}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PullDown;
