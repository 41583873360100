import PV_STATUS from '../../const/status/pv-status';
import PUBLIC_PV_STATUS from '../../const/status/public-pv-status';

export default (status) => {
  switch (status) {
    case PV_STATUS.PENDING:
      return PUBLIC_PV_STATUS.PV_PENDING;
    case PV_STATUS.REPORTED:
      return PUBLIC_PV_STATUS.PV_REPORTED;
    case PV_STATUS.CLEARED:
      return PUBLIC_PV_STATUS.PV_CLEARED;
    default:
      return status;
    //TODO throw an error
  }
};
