/**
 * GTM - Triggers
 *
 * name: Click on remove card QP
 * fires on: Click element matches CSS selector [layer-id="actions-delete"] i
 *
 * name: Click on share card QP
 * fires on: Click element matches CSS selector [layer-id="actions-share"] i
 */

import Layer from '@layerhq/web-xdk';

import './xircles-content-card-message-model';
import lineClamp from 'line-clamp';
import closest from '../../polyfills/closest';
import AlloyFinger from 'alloyfinger';
import { CONVERSATION_ACTIONS } from '../../const/conversation-actions';
import i18next from 'i18next';

import config from '../../config.json';
import { isHcpOrOperator } from '../../utils/user';
import { mouseHandler } from '../../utils/common';

require('alloyfinger/transformjs/transform');

const DATA_OPEN_CARD_TAG = 'data-card-open';
const DATA_SWIPE_CARD_TAG = 'data-card-swiped';
const OPEN_TRANSFORM_X_VALUE = -150;

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-content-card-message-view-swipe', {
  mixins: [MessageViewMixin],
  template: `
      <div class="xircles-content-card-message-view-swipe-wrapper">
        <div class='layer-list-item' layer-id='innerNode'>
          <h6 layer-id='header'></h6>
          <p layer-id='text'></p>
          <a layer-id='link'></a>
          <i class="xircles-icons icon-send"></i>
        </div>
        <div class='xircles-actions' layer-id='actions'>
          <ul>
            <!--<li layer-id='actions-delete'><i class="material-icons">delete</i>Delete</li>-->
            <li layer-id='actions-mark'><i class="material-icons">outlined_flag</i>Mark</li>
            <li layer-id='actions-share'><i class="material-icons">share</i>Share</li>
          </ul>
        </div>
      </div>
`,
  properties: {
    widthType: {
      get: function get() {
        return Widths.FULL;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    onCreate() {
      this.innerNode = this.getElementsByClassName('layer-list-item')[0];
      this.actions = this.getElementsByClassName('xircles-actions')[0];
      {
        const getInnerNodeTransformValue = () => {
          this.actions.style.zIndex = 0;
          return this.innerNode.style.transform
            ? Number(this.innerNode.style.transform.split('(')[1].split('p')[0])
            : 0;
        };

        const getConversationId = () => {
          const layerItemId = this.getAttribute('layer-item-id');
          const conversationId = layerItemId.replace('conversations_', '');
          return conversationId;
        };

        const shareConversation = () => {
          const conversationId = getConversationId();
          window.dispatchEvent(
            new CustomEvent(CONVERSATION_ACTIONS.share, {
              detail: { conversationId },
            })
          );
        };

        const isCardOpen = () =>
          this.innerNode.hasAttribute(DATA_OPEN_CARD_TAG);
        const setCardOpen = () =>
          this.innerNode.setAttribute(DATA_OPEN_CARD_TAG, '');
        const removeCardOpen = (conversation = this) =>
          conversation.innerNode.removeAttribute(DATA_OPEN_CARD_TAG);

        const isCardSwipe = () =>
          this.innerNode.hasAttribute(DATA_SWIPE_CARD_TAG);
        const setCardSwipe = () =>
          this.innerNode.setAttribute(DATA_SWIPE_CARD_TAG, '');
        const removeCardSwipe = () =>
          this.innerNode.removeAttribute(DATA_SWIPE_CARD_TAG);

        let startPosition = 0;
        if ('ontouchstart' in document.documentElement) {
          const windowEventHandler = new AlloyFinger(window, {});

          const openActionPanel = () => {
            this.innerNode.translateX = OPEN_TRANSFORM_X_VALUE;
            windowEventHandler.on('touchEnd', closeHeaderActionEvent);
          };

          const closeActionPanel = () => {
            this.innerNode.translateX = 0;
            windowEventHandler.off('touchEnd', closeHeaderActionEvent);
          };

          const closeHeaderActionEvent = (event) => {
            closeActionPanel();
          };

          window.Transform(this.innerNode);

          new AlloyFinger(this.innerNode, {
            touchStart: function (event) {
              startPosition = this.translateX;
            },
            tap: function (event) {
              // event.stopPropagation();
              // event.preventDefault();
            },

            pressMove: function (event) {
              let x = this.translateX + event.deltaX;
              if (x > 0) {
                x = 0;
              } else if (x < OPEN_TRANSFORM_X_VALUE) {
                x = OPEN_TRANSFORM_X_VALUE;
              }
              this.translateX = x;
            },

            touchEnd: function (event) {
              let x = this.translateX || 0;
              const swipe = Math.abs(startPosition - x) > 30;

              if (x !== startPosition) {
                event.stopPropagation();
                event.preventDefault();
              }

              if (
                (startPosition === OPEN_TRANSFORM_X_VALUE && swipe) ||
                (startPosition === 0 && !swipe) ||
                (x === startPosition && x === OPEN_TRANSFORM_X_VALUE)
              ) {
                closeActionPanel();
              } else if (
                (startPosition === OPEN_TRANSFORM_X_VALUE && !swipe) ||
                (startPosition === 0 && swipe)
              ) {
                openActionPanel();
              }
            },
          });
        } else {
          const openCard = () => {
            window.dispatchEvent(new Event('mousedown'));
            this.innerNode.style.transform = `translateX(${OPEN_TRANSFORM_X_VALUE}px)`;
            setCardOpen();
            window.addEventListener('mousedown', closeCard);
          };

          const closeCard = () => {
            removeCardOpen();
            this.innerNode.style.transform = 'translateX(0)';
            window.removeEventListener('mousedown', closeCard);
          };

          let startPosition = 0;

          const mouseMove = (evt) => {
            let x = evt.movementX + getInnerNodeTransformValue();

            if (x > 0) {
              x = 0;
            } else if (x < OPEN_TRANSFORM_X_VALUE) {
              x = OPEN_TRANSFORM_X_VALUE;
            }

            this.innerNode.style.transform = `translateX(${x}px)`;
          };

          const mouseUp = (evt) => {
            evt.stopPropagation();
            let x = getInnerNodeTransformValue();
            Math.abs(startPosition - x) > 5
              ? setCardSwipe()
              : removeCardSwipe();
            x < OPEN_TRANSFORM_X_VALUE / 2 ? openCard() : closeCard();

            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
          };

          const swipe = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            startPosition = getInnerNodeTransformValue();

            const removeSwipe = (evt) => {
              removeCardSwipe();
              window.removeEventListener('click', removeSwipe);
            };
            window.addEventListener('click', removeSwipe);

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
          };

          this.addEventListener('mousedown', (e) => mouseHandler(e, swipe));

          const conversationItemClick = (evt) => {
            if (isCardOpen() || isCardSwipe()) {
              evt.preventDefault();
              evt.stopPropagation();
              isCardSwipe() ? removeCardSwipe() : closeCard();
            }
          };
          this.addEventListener('click', conversationItemClick);

          const shareAction = () => shareConversation();
          this.nodes.actions
            .querySelector('[layer-id="actions-share"]')
            .addEventListener('click', shareAction);

          this.nodes.actions.addEventListener('click', (evt) =>
            evt.stopPropagation()
          );
          this.nodes.actions.addEventListener('mousedown', (evt) =>
            evt.stopPropagation()
          );
        }
      }
    },
    _triggerHcpCheck() {
      const conversationId = this.mainComponent.conversationId;
      this.trigger('xircles-question-send', {
        conversationId,
        text: `${i18next.t('REGISTER_NEED_HCP_CHECK')}`,
        slug: `${config.StoryblokRootFolder}/${config.HcpCheckSlug}`,
        data: {
          url: this.model.url.replace(/^\/documents\//, ''),
          title: this.model.title,
        },
      });
    },
    onRerender() {
      this.needHcpCheck =
        this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);

      this.classList.toggle('only-for-hcp', this.needHcpCheck);
      this.nodes.header.innerHTML = this.model.header;
      this.nodes.text.innerHTML = this.needHcpCheck
        ? `<em><br/>${i18next.t('ANSWER_FOR_HCP_ONLY')}<br/><br/></em>`
        : this.model.text;
      this.nodes.link.innerHTML = this.model.linkText;

      setTimeout(() => {
        const card = closest(this, '.xircles-content-card-message-view-swipe');
        card.style.minWidth = '235px';
        card.style.maxWidth = '235px';
        lineClamp(this.querySelector('[layer-id="text"]'), 3);
      }, 0);

      this.model.customData &&
        this.model.customData.isOperator &&
        this.classList.add('xircles-content-card-message-view-swipe__operator');
    },
    onAfterCreate() {
      const view = this;
      const card = closest(view, '.xircles-content-card-message-view-swipe');
      card.addClickHandler('open-link', card, () => {
        view.needHcpCheck
          ? view._triggerHcpCheck()
          : dispatchEvent(
              new CustomEvent('change-route', {
                detail: { url: view.model.url },
              })
            );
      });
      card.style.minWidth = '235px';
      card.style.maxWidth = '235px';
      card.style.height = '105px';
      Layer.client.user.on('identities:change', () => view.onRerender());
    },
    onSelection(isSelected) {
      const view = this;
      if (isSelected)
        window.onpopstate = (e) => {
          view.isSelected = false;
        };
    },
  },
});
