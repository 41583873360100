export const isBot = (identity) =>
  identity.metadata.user_type === 'bot' ||
  identity.metadata.identity_type === 'bot';

export const isUser = (identity) =>
  (identity.metadata && identity.metadata.user_type === 'user') ||
  !identity.displayName ||
  identity.isMine;

export const isOperator = (identity) =>
  identity &&
  (identity.metadata.user_type === 'operator' ||
    identity.displayName === 'Mr Operator User' ||
    identity.displayName === 'QA Operator');

export const isExpert = (identity) =>
  identity && identity.metadata && identity.metadata.user_type === 'expert';

export const isAnonymous = (identity) =>
  identity.metadata.user_status === 'anonymous';

export const isNotConfirmed = (identity) =>
  identity.metadata.user_status !== 'confirmed';

export const isHcp = (identity) => {
  const { search } = window.location;
  const params = search.slice(search.indexOf('?') + 1).split('&');
  const shQuery = params.find((param) => param.includes('is_hcp'));
  return (
    (identity && identity.metadata.is_hcp === 'true') ||
    (shQuery && shQuery.replace('is_hcp=', '') === 'true')
  );
};

export const isHcpOrOperator = (identity) =>
  isHcp(identity) || isOperator(identity);
