// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import './styles.less';
import TextField from '../../material-components/TextField';
import Box from '../../material-components/Box/Box';
import Button from '../../material-components/Button';
import AutoFocusInput from '../AutoFocusInput';
import { Input } from '@material/react-text-field/dist/index';
import { isInsideFrame, isIOSSafari } from '../../utils/common';
import pkjson from '../../../package.json';
import { checkIsBrowserValid } from '../../utils/browserValidation';
import config from '../../config.json';

type State = {
  isValidBrowser: Boolean,
  emailValue: String,
  passwordValue: String,
  error: String,
};

class SplashScreenPage extends Component<State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isValidBrowser: false,
      emailValue: '',
      passwordValue: '',
      error: undefined,
    };
  }

  cookieParams = {
    path: '/',
    maxAge: 60 * 30 * 24,
  };

  onEmailChange = (value) => this.setState({ emailValue: value });
  onPasswordChange = (value) => this.setState({ passwordValue: value });

  onStartTestingClick = (loginTestData) => {
    const { emailValue, passwordValue } = this.state;

    const email = emailValue ? emailValue.trim() : loginTestData.email || '';

    const password = passwordValue
      ? passwordValue.trim()
      : loginTestData.password || '';

    const { cookies } = this.props;

    if (!email.length) {
      this.setState({
        error: `${this.props.t('ERROR_DOCCHECK_WRONG_CREDENTIALS')}`,
      });
    } else if (!password.length) {
      this.setState({
        error: `${this.props.t('ERROR_AUTHENTICATION_MISSING_PASSWORD')}`,
      });
    } else if (
      password !== config.SplashPassword ||
      email !== config.SplashUser
    ) {
      this.setState({
        error: `${this.props.t('ERROR_AUTHENTICATION_WRONG_CREDENTIALS')}`,
      });
    } else {
      cookies.set(
        'xir-test-login-success',
        { email, password },
        this.cookieParams
      );
      this.props.history.push({
        pathname: '/',
        state: { detail: { isSuccessLogin: true } },
      });
    }
  };

  clearError = () =>
    this.state.error && this.state.error.length && this.setState({ error: '' });

  componentDidMount() {
    if (isInsideFrame()) {
      const [body] = document.getElementsByTagName('body');
      body.classList.add('default-background');
    }

    const { cookies } = this.props;
    const loginTestData = cookies.get('xir-test-login-success');

    const isValidBrowser = checkIsBrowserValid();
    if (isValidBrowser) {
      this.setState({ isValidBrowser });
    }

    if (loginTestData) {
      this.onStartTestingClick(loginTestData);
    }
  }

  componentWillUnmount() {
    if (isInsideFrame()) {
      const [body] = document.getElementsByTagName('body');
      body.classList.remove('default-background');
    }
  }

  render() {
    const { t } = this.props;
    const { isValidBrowser, emailValue, passwordValue, error } = this.state;

    return (
      <div
        className={`splash-screen${
          isIOSSafari() ? ' splash-screen--fixed' : ''
        }`}
      >
        <div className={'splash-screen__container'}>
          <p>{t('SPLASH_WELCOME_TO_PREVIEW')}</p>
          {pkjson.version_number && pkjson.release_date && (
            <p>
              v{pkjson.version_number} - {pkjson.release_date}
            </p>
          )}
          {isValidBrowser ? (
            <div className={'splash-screen__container--actual-version-chrome'}>
              <TextField dense outlined>
                <AutoFocusInput
                  id="email"
                  name="email"
                  type="email"
                  placeholder={`${t('SPLASH_LOGIN_PLACEHOLDER')}`}
                  value={emailValue}
                  onChange={(e) => this.onEmailChange(e.target.value)}
                  onFocus={this.clearError}
                />
              </TextField>
              <TextField dense outlined style={{ marginTop: '15px' }}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder={`${t('SPLASH_PASSWORD_PLACEHOLDER')}`}
                  value={passwordValue}
                  onChange={(e) => this.onPasswordChange(e.target.value)}
                  onFocus={this.clearError}
                />
              </TextField>
              {error && (
                <span
                  className={
                    'splash-screen__container--actual-version-chrome__error'
                  }
                >
                  {error}
                </span>
              )}
              <Box mt={2}>
                <Button
                  data-keystroke
                  outlined
                  className="splash-screen__test-button"
                  onClick={this.onStartTestingClick}
                >
                  {t('SPLASH_START_TESTING')}
                </Button>
              </Box>
            </div>
          ) : (
            <div className={'splash-screen__container--outdated-chrome'}>
              <p>{t('SPLASH_PLEASE_USE_LATEST_CHROME_VERSION')}</p>
            </div>
          )}
          <img className="app-logo" alt="Application Logo" />
        </div>
      </div>
    );
  }
}

export default withCookies(withTranslation()(SplashScreenPage));
