// @flow

/**
 *      Header is component with animation logic for Answer Page
 *
 *      onClickArrow    - prop for coming back to prev page, function, required
 *      question        - string, which will be at header
 *
 */

/**
 * GTM - Triggers
 *
 * name: Click on action menu AP
 *
 * name: Click on remove card AP
 * fires on: Click Element matches CSS selector #actions-delete
 * fires on: Click Element matches CSS selector li#actions-delete
 * fires on: Click Element matches CSS selector i#actions-delete-icon
 *
 * name: Click on share card AP
 * fires on: Click Element matches CSS selector #actions-share
 * fires on: Click Element matches CSS selector li#actions-share
 * fires on: Click Element matches CSS selector i#actions-share-icon
 */

import 'alloyfinger/transformjs/transform';
import * as React from 'react';
import getStatusClass from '../../../common/status/getStatusClass';
import publicStatuses from '../../../const/status/public-status';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import './styles.less';
import { isIE, isInsideFrame, isIOS } from '../../../utils/common';
import config from '../../../config.json';

type Props = {
  onClickArrow: Function,
  question: string,
  status: string,
  operatorStatusFirst: string,
  operatorStatusSecond: string,
  t: Function,
  onClickArrow: Function,
  onClickShare: Function,
  onClickDelete: Function,
  onClickRestore: Function,
  onClickForwardToMedinfo: Function,
  onClickClearPV: Function,
  onClickCloseRequest: Function,
  onClickDeleteRequest: Function,
  handleDeliverAnswerClick: Function,
  onArchiveRequest: Function,
  question: string,
  isUserOperator: boolean,
  isUserHCP: boolean,
  onClickTakeOver: Function,
};

type State = {
  opened: boolean,
  openedConfirmDeletion: boolean,
  headerSwiped: boolean,
};

const { ANSWER_DELIVERED, CLOSED, IN_PROGRESS, RESOLVED } = publicStatuses;

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.titleRef = React.createRef();
    this.parentTitleRef = React.createRef();
    this.upperBlockRef = React.createRef();
    this.headerRef = React.createRef();
    this.arrowBackRef = React.createRef();
    this.operatorButtonBar = React.createRef();
    this.buttonTakeOver = React.createRef();
    this.buttonCLearPV = React.createRef();
    this.buttonForwardToMedinfo = React.createRef();
    this.buttonDeliverAnswer = React.createRef();
    this.buttonClose = React.createRef();
    this.buttonDelete = React.createRef();
    this.defaultSize = 0;
    this.initialUpperBlockHeight = 0;
    this.isUpperBlockHeightUpdated = false;
    this.state = {
      opened: false,
      openedConfirmDeletion: false,
      headerSwiped: false,
    };
    this.timerIdFirst = null;
    this.timerIdSecond = null;
  }

  isIOSInsideFrame = isIOS() && isInsideFrame();

  componentDidMount(): void {
    isIE() &&
      this.titleRef.current.setAttribute('style', 'line-height: 1.19em');

    if (this.titleRef.current && this.props.question) {
      this.titleRef.current.innerHTML = this.props.question;
      this.timerIdFirst = setTimeout(
        () => this.handleAskedQuestionHeight(this.titleRef.current),
        0
      );
    }

    const corrections = _get(this.props.location, 'state.detail.corrections');

    if (!_isEmpty(corrections)) {
      const originalText = _get(
        this.props.location,
        'state.detail.originalText',
        ''
      ).replace(/&nbsp;/gm, ' ');
      this.setState({ originalText });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.question !== nextProps.question) {
      this.titleRef.current.innerHTML = nextProps.question;
      this.timerIdSecond = setTimeout(
        () => this.handleAskedQuestionHeight(this.titleRef.current),
        0
      );
    }
  }

  componentWillUnmount() {
    this.timerIdFirst && clearTimeout(this.timerIdFirst);
    this.timerIdSecond && clearTimeout(this.timerIdSecond);
  }

  handleAskedQuestionHeight = (titleElement) => {
    const threeLinesQuestionHeight = 84;

    if (titleElement.clientHeight === threeLinesQuestionHeight) {
      this.upperBlockRef.current.classList.add('long-question');
      this.headerRef.current.parentNode.parentNode.classList.add(
        'long-question'
      );
    }
  };

  handleShareClick = (e, question) => {
    window.dispatchEvent(
      new CustomEvent('tr-event', { detail: { target: e.target } })
    );
    this.props.onClickShare(question);
  };

  handleForwardToMedinfoClick = () => {
    this.props.onClickForwardToMedinfo();
  };

  handleClearPVClick = () => {
    this.props.onClickClearPV();
  };

  handleCloseRequestClick = () => {
    this.props.onClickCloseRequest();
  };

  handleDeleteRequestClick = () => {
    this.props.onClickDeleteRequest();
  };

  handleDeliverAnswerClick = () => {
    this.props.onClickDeliverAnswer();
  };

  getStatusClassName = () =>
    `xircles-status ${i18next.t(getStatusClass(this.props.status)[1])}`;

  render() {
    const {
      t,
      isUserOperator,
      status,
      operatorStatusFirst,
      operatorStatusSecond,
      isTakeOverActive,
      onClickTakeOver,
      onClickArrow,
      isLoggedIn,
      onDisplayName,
      displayName,
      onLogin,
      onToggleMenu,
      onLogoClick,
      onCorrectionClick,
    } = this.props;

    const isStatusBadgeVisible = [
      ANSWER_DELIVERED,
      CLOSED,
      IN_PROGRESS,
      RESOLVED,
    ].some((item) => item === status);

    return (
      <div
        className={isUserOperator ? 'operator' : ''}
        ref={this.headerRef}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div ref={this.operatorButtonBar} className="operator-button-bar">
          <div className="operator-button-bar__wrapper">
            <button
              onClick={onClickTakeOver}
              ref={this.buttonTakeOver}
              className={`${isTakeOverActive ? 'button-active' : ''}`}
            >
              {`${
                isTakeOverActive
                  ? 'Leave Conversation'
                  : t('OPERATOR_TAKE_OVER')
              }`}
            </button>
            <button ref={this.buttonCLearPV} onClick={this.handleClearPVClick}>
              {t('OPERATOR_CLEAR_PV')}
            </button>
            <button
              ref={this.buttonForwardToMedinfo}
              onClick={this.handleForwardToMedinfoClick}
            >
              {t('OPERATOR_FORWARD_TO_MEDINFO')}
            </button>
            <button
              ref={this.buttonDeliverAnswer}
              onClick={this.handleDeliverAnswerClick}
            >
              {t('OPERATOR_DELIVER_ANSWER')}
            </button>
            <button
              ref={this.buttonClose}
              onClick={this.handleCloseRequestClick}
            >
              {t('OPERATOR_CLOSE')}
            </button>
            <button
              ref={this.buttonDelete}
              onClick={this.handleDeleteRequestClick}
            >
              {t('OPERATOR_DELETE')}
            </button>
          </div>
        </div>
        <div
          ref={this.upperBlockRef}
          className="panel-header conversation-header"
        >
          <img
            alt="Xircle"
            data-tr-event
            id="xircle-logo"
            className="xircles-logo"
            onClick={onLogoClick}
          />
          <div className="login">
            {config.RegistrationEnabled &&
              (isLoggedIn ? (
                <h6 onClick={onDisplayName}>{displayName}</h6>
              ) : (
                <h6 data-tr-event onClick={onLogin}>
                  {i18next.t('COMMON_MENU_LOGIN')}
                </h6>
              ))}
            {_get(this.props.themeSettings, 'isMenuVisible', false) && (
              <button id="menu-button" data-tr-event onClick={onToggleMenu}>
                <i data-tr-event className="material-icons menu-icon">
                  menu
                </i>
              </button>
            )}
          </div>
          <div className={`${isUserOperator ? 'header-operator' : ''}`}>
            <div
              className={`${isUserOperator ? 'operator-status__wrapper' : ''}`}
            >
              <div
                className={
                  'xircles-operator-status xircles-operator status__first'
                }
              >
                {operatorStatusFirst}
              </div>
              <div
                className={
                  'xircles-operator-status xircles-operator status__second'
                }
              >
                {operatorStatusSecond}
              </div>
              {(isUserOperator || isStatusBadgeVisible) && (
                <div
                  className={`${this.getStatusClassName()} xircles-operator-status`}
                >
                  {i18next.t(getStatusClass(status)[0])}
                </div>
              )}
            </div>
            <span className="back-button-dummy" />
            <div
              className={`title ${
                this.state.originalText ? 'title-with-correction' : ''
              }`}
              ref={this.parentTitleRef}
            >
              <h1 ref={this.titleRef}>{''}</h1>
              {this.state.originalText && (
                <div className="correction">
                  <p className="correction__label">
                    {i18next.t('COMMON_CORRECTED')}
                  </p>
                  <p
                    className="correction__content"
                    onClick={() => onCorrectionClick(this.state.originalText)}
                  >
                    {this.state.originalText}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="back-button">
            <button
              id="back-button"
              onClick={onClickArrow}
              ref={this.arrowBackRef}
            >
              <i className="material-icons">arrow_back</i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
