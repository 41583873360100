/* eslint-disable react/jsx-no-target-blank */
// @flow

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';

import BrandFooter from '../BrandFooter';
import BrandHeader from '../BrandHeader';
import HelmetWrapper from '../HelmetWrapper';
import conversationManager from '../../hocs/conversationManager';
import ProductXircleNews from '../ProductXircleNews';
import {
  removeRegisteredSymbol,
  slugify,
  wrapRegisteredSymbol,
} from '../../utils/common';
import config from '../../config.json';

import './styles.less';

type Props = {};

type State = {};

class ProductXirclePage extends Component<Props, State> {
  componentDidMount() {
    const { brandName, moleculesString, pxInfo } = this.props;

    if (this.props.history.location.pathname.includes('medikamente')) {
      this.props.history.replace(
        `/${slugify(pxInfo.page_data.company_name)}/${slugify(brandName)}`
      );
    }

    this.handleAddKnowledgeGraph(brandName, moleculesString);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.newConversationId !== prevProps.newConversationId &&
      this.props.newConversationMetadata !== prevProps.newConversationMetadata
    ) {
      this.props.history.push({
        pathname: `/questions/${this.props.newConversationId}`,
        state: {
          detail: {
            corrections: this.props.corrections,
            originalText: this.props.originalText,
            pxColor: this.props.newConversationMetadata.isPX
              ? {
                  primaryColor: this.props.pxInfo.page_data.primary_color,
                  onBackgroundHighlightColor:
                    this.props.pxInfo.page_data.on_background_highlight_color,
                  onBackgroundHighlightTextColor:
                    this.props.pxInfo.page_data
                      .on_background_highlight_text_color,
                }
              : undefined,
            prevLocation: this.props.history.location.pathname,
          },
        },
      });
    }
  }

  handlePostQuestion = (question, corrections, originalText) => {
    let metadata = {};
    const questionLowerCase = question.toLowerCase();
    const questionBrandName = this.props.brands
      .sort((a, b) => a.localeCompare(b))
      .find((brand) => questionLowerCase.includes(brand.toLowerCase()));

    if (questionBrandName && questionBrandName !== this.props.brandName) {
      metadata = {
        product: questionBrandName.toLowerCase(),
        selectedProductPage: questionBrandName.toLowerCase(),
      };
    }

    if (
      (questionBrandName && questionBrandName === this.props.brandName) ||
      !questionBrandName
    ) {
      metadata = {
        isPX: true,
        is_px: true,
        product: this.props.brandName.toLowerCase(),
        selectedProductPage: this.props.brandName.toLowerCase(),
      };
    }

    this.props.createConversation(
      question,
      {
        goBackPath: this.props.history.location.pathname,
        stepBackNumber: -2,
        ...metadata,
      },
      corrections,
      originalText
    );
  };

  handleAddKnowledgeGraph = (brandName, moleculesString) => {
    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'MedicalWebPage',
      audience: 'https://schema.org/Clinician',
      description: `${brandName} • ${this.props.t('COMMON_TITLE_CLAIM')}`,
      url: `${config.XirclesUrl}/medikamente/${slugify(brandName)}`,
      drug: [
        {
          '@type': 'Drug',
          proprietaryName: brandName,
          activeIngredient: moleculesString,
        },
      ],
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  render() {
    const { location, t, pxInfo } = this.props;

    return (
      <Fragment>
        <HelmetWrapper
          documentTitle={pxInfo.page_data.page_title}
          description={pxInfo.page_data.page_description}
          location={location.pathname}
        />
        <div className="product-xircle-page">
          <section className="product-xircle-page__drug-intro">
            <h1
              className="product-xircle-page__brand-name"
              style={{ color: pxInfo.page_data.primary_color }}
            >
              <span>
                {`${removeRegisteredSymbol(pxInfo.drug_intro.brand_name)}`}
                <sup>®</sup>
                {' Xircle'}
              </span>
              <span className="product-xircle-page__compound-name">
                {pxInfo.drug_intro.compound_name}
              </span>
            </h1>
            <div className="product-xircle-page__drug">
              <div className="product-xircle-page__drug-card">
                <ul className="product-xircle-page__dosage-forms">
                  {pxInfo.drug_intro.dosage_forms.map((dosageForm, key) => (
                    <li
                      key={`dosage-form-${key}`}
                      dangerouslySetInnerHTML={{
                        __html: wrapRegisteredSymbol(dosageForm.text),
                      }}
                    />
                  ))}
                </ul>
                <ul className="product-xircle-page__safety_hints">
                  {pxInfo.drug_intro.safety_hint_monitoring && (
                    <li>
                      <svg
                        className="product-xircle-page__safety_hints_image"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="24"
                          height="24"
                          rx="12"
                          fill={pxInfo.page_data.on_background_highlight_color}
                        />
                        <path
                          d="M17.131 8H6.87a1 1 0 0 0-.833 1.555l5.132 7.697a1 1 0 0 0 1.664 0l5.131-7.697A1 1 0 0 0 17.131 8z"
                          fill="#0F0F12"
                        />
                      </svg>
                      {t('PRODUCT_XIRCLE_HINT_MONITORING')}
                    </li>
                  )}
                  {pxInfo.drug_intro.safety_hint_prescription_only && (
                    <li>
                      <svg
                        className="product-xircle-page__safety_hints_image"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="24"
                          height="24"
                          rx="12"
                          fill={pxInfo.page_data.on_background_highlight_color}
                        />
                        <path
                          d="M6.5 8h2.828c.58 0 1.078.087 1.495.26.42.174.743.431.969.771.225.34.338.759.338 1.255 0 .407-.07.756-.208 1.047a1.96 1.96 0 0 1-.578.724 3 3 0 0 1-.87.459l-.495.26H7.521l-.01-1.219h1.828c.274 0 .501-.048.682-.146a.956.956 0 0 0 .406-.406c.094-.173.14-.375.14-.604 0-.243-.044-.453-.135-.63a.914.914 0 0 0-.411-.406c-.184-.094-.415-.141-.693-.141H8.063v6.36H6.5V8zm4.24 7.583-1.73-3.38 1.652-.01 1.75 3.317v.073H10.74zM14.448 9.948l.932 1.713.958-1.713h1.6l-1.594 2.755 1.666 2.88h-1.604l-1.015-1.812-1.016 1.812h-1.604l1.666-2.88-1.593-2.755h1.604z"
                          fill="#000"
                        />
                      </svg>
                      {t('PRODUCT_XIRCLE_HINT_PRESCRIPTION')}
                    </li>
                  )}
                  {pxInfo.drug_intro.safety_hint_pregnancy && (
                    <li>
                      <svg
                        className="product-xircle-page__safety_hints_image"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="24"
                          height="24"
                          rx="12"
                          fill={pxInfo.page_data.on_background_highlight_color}
                        />
                        <path
                          d="M14.5 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
                          fill="#0F0F12"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.826 13.735a.996.996 0 0 1-.414-.176l-2.75-2a1 1 0 0 1-.256-1.346l.844.537-.843-.537v-.001l.002-.002.004-.007.017-.026a17.41 17.41 0 0 1 .279-.42c.183-.268.437-.629.719-.993.277-.36.601-.748.924-1.057.16-.153.345-.31.543-.435.178-.112.481-.272.855-.272H11.75a1 1 0 0 1 .588.191L11.75 8c.588-.809.588-.809.589-.808h.001l.003.003.009.006.028.022a13.26 13.26 0 0 1 .453.359c.293.242.691.59 1.124 1.023.85.85 1.898 2.09 2.472 3.524.332.83.34 1.61.073 2.296-.256.66-.726 1.128-1.164 1.446-.44.32-.904.53-1.242.658-.14.053-.265.095-.365.125l-.73 3.846v.5a1 1 0 1 1-2 0v-.5c0-2.928-.002-4.829-.175-6.765zm-.33-2.588a52.71 52.71 0 0 0-.257-1.449 17.47 17.47 0 0 0-.618.813l.876.636z"
                          fill="#0F0F12"
                        />
                      </svg>
                      {t('PRODUCT_XIRCLE_HINT_PREGNANCY')}
                    </li>
                  )}
                  {pxInfo.drug_intro.safety_hint_lactation && (
                    <li>
                      <svg
                        className="product-xircle-page__safety_hints_image"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="24"
                          height="24"
                          rx="12"
                          fill={pxInfo.page_data.on_background_highlight_color}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.533 10.684a1.25 1.25 0 0 0-1.317-.667L8.59 11.104a1.75 1.75 0 0 0-1.277.934l-2.177 4.287a1.25 1.25 0 0 0 .102 1.3l2.865 3.95a1.25 1.25 0 1 0 2.024-1.469l-.844-1.164c.06-.29.25-.55.537-.691l1.615-.796-1.615-.795a1 1 0 0 1-.559-.897v-1.974a1 1 0 0 1 2 0v1.352l2.88 1.417a1 1 0 0 1 .309 1.559l.01-.003-.626.864a1.25 1.25 0 1 0 2.023 1.468l2.865-3.949a1.25 1.25 0 0 0 .103-1.3l-2.292-4.513zM17 14.25a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0z"
                          fill="#0F0F12"
                        />
                        <path
                          d="M15.5 5.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                          fill="#0F0F12"
                        />
                      </svg>
                      {t('PRODUCT_XIRCLE_HINT_LACTATION')}
                    </li>
                  )}
                </ul>
                <ul className="product-xircle-page__links">
                  {pxInfo.drug_intro.drug_intro_links.map(
                    (drugIntroLink, index) => (
                      <li key={`drug-info-link-${index}`}>
                        <a
                          target="_blank"
                          rel="canonical"
                          href={drugIntroLink.Url}
                          dangerouslySetInnerHTML={{
                            __html: `
                            ${wrapRegisteredSymbol(drugIntroLink.Text)}
                            <i
                            style="color: ${pxInfo.page_data.primary_color}"
                            class="material-icons product-xircle-page__link_icon"
                          >
                            chevron_right
                          </i
                          `,
                          }}
                        />
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="product-xircle-page__drug-card">
                <Slider
                  dots
                  infinite
                  speed={400}
                  arrows={false}
                  slidesToShow={1}
                  slidesToScroll={1}
                >
                  {pxInfo.drug_intro.drug_intro_images.map(
                    (drugIntroImage, index) => (
                      <img
                        key={`drug-intro-image-${index}`}
                        alt=""
                        src={drugIntroImage.image.filename}
                      />
                    )
                  )}
                </Slider>
              </div>
            </div>
            <p className="product-xircle-page__drug-intro-text">
              {pxInfo.drug_intro.drug_intro_text}
            </p>
          </section>
          <BrandHeader
            {...this.props}
            isPX
            isLoginAllowed={false}
            isLogoVisible={false}
            isMenuVisible={false}
            className="product-xircle-page__header"
            onPostQuestion={this.handlePostQuestion}
          >
            <div className="brand-header__welcome">
              <p className="brand-header__welcome-message">
                {t('PRODUCT_XIRCLE_HELLO_MESSAGE', {
                  product: removeRegisteredSymbol(pxInfo.drug_intro.brand_name),
                })}
                <sup>®</sup>
                {' Xircle'}
              </p>
              <p className="brand-header__welcome-message">
                {t('PRODUCT_XIRCLE_MAIN_MESSAGE', {
                  product: removeRegisteredSymbol(pxInfo.drug_intro.brand_name),
                })}
                <sup>®</sup>
              </p>
            </div>
          </BrandHeader>
          {pxInfo.announcement.announcement_title.length > 0 && (
            <section
              className="product-xircle-page__announcement"
              style={{
                backgroundColor: pxInfo.page_data.on_background_highlight_color,
              }}
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: wrapRegisteredSymbol(
                    pxInfo.announcement.announcement_title
                  ),
                }}
              />
              <p
                className="product-xircle-page__announcement-text"
                dangerouslySetInnerHTML={{
                  __html: wrapRegisteredSymbol(
                    pxInfo.announcement.announcement_text
                  ),
                }}
              />
              <a
                target="_blank"
                rel="canonical"
                href={pxInfo.announcement.announcement_link_url}
                style={{
                  color: pxInfo.page_data.on_background_highlight_text_color,
                }}
              >
                <button
                  type="button"
                  className="product-xircle-page__announcement-button"
                  dangerouslySetInnerHTML={{
                    __html: wrapRegisteredSymbol(
                      pxInfo.announcement.announcement_link_text
                    ),
                  }}
                />
              </a>
            </section>
          )}
          {pxInfo.content.content_section_title.length > 0 && (
            <section className="product-xircle-page__content">
              <h2 className="product-xircle-page__content_title">
                {pxInfo.content.content_section_title}
              </h2>
              <div className="product-xircle-page__contents">
                {pxInfo.content.content.map((content, index) => (
                  <div
                    key={`content-item-${index}`}
                    style={{
                      backgroundColor: content.is_risk_information
                        ? pxInfo.page_data.on_background_highlight_color
                        : 'white',
                    }}
                    className="product-xircle-page__content-item"
                  >
                    <h4>{content.content_title}</h4>
                    <div className="product-xircle-page__content-links">
                      {content.content_links.map((contentLink, index) => (
                        <a
                          target="_blank"
                          rel="canonical"
                          key={`content-link-${index}`}
                          href={contentLink.Url}
                        >
                          <span>{contentLink.Text}</span>
                          <i
                            style={{ color: pxInfo.page_data.primary_color }}
                            className="material-icons product-xircle-page__link_icon"
                          >
                            chevron_right
                          </i>
                        </a>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
          {pxInfo.news.news_categories.length > 0 && (
            <section className="product-xircle-page__news">
              <h2 className="product-xircle-page__news_title">
                {removeRegisteredSymbol(pxInfo.news.news_section_title)}
                <sup>®</sup>
              </h2>
              <div className="product-xircle-page__news_categories">
                {pxInfo.news.news_categories
                  .filter((news_category) =>
                    news_category.news.some(({ news_title }) => news_title)
                  )
                  .map((news_category, index) => (
                    <ProductXircleNews
                      key={`product-xircle-news-${index}`}
                      newsCategory={news_category}
                      pxInfo={pxInfo}
                      index={index}
                    />
                  ))}
              </div>
            </section>
          )}
          <BrandFooter />
        </div>
      </Fragment>
    );
  }
}

const ProductXirclePageWithConversationManager =
  conversationManager(ProductXirclePage);

export default withTranslation()(
  withRouter(ProductXirclePageWithConversationManager)
);
