import Layer from '@layerhq/web-xdk';

import i18next from 'i18next';
import './reference-message-model';

import config from '../../config.json';
import { isHcpOrOperator } from '../../utils/user';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const processText = Layer.UI.handlers.text.processText;

registerComponent('xircles-reference-message-view', {
  mixins: [MessageViewMixin],
  template: `
<div class="wrapper">
    <div layer-id="header" class="header">
      <span layer-id="breadcrumb"></span>
      <span layer-id="title"></span>
    </div>
    <button layer-id="open" class="button-more"><span></span><i class="xircles-icons icon-send"></i></button>
    </div>
  `,
  methods: {
    _goToUrl(view, url) {
      if (view.clicked) return;
      view.clicked = true;
      const selectedUrl = url || view.model.url;
      const conversationId = view.mainComponent.conversationId;
      if (this.needHcpCheck) {
        view.trigger('xircles-question-send', {
          conversationId,
          text: `${i18next.t('REGISTER_NEED_HCP_CHECK')}`,
          slug: `${config.StoryblokRootFolder}/${config.HcpCheckSlug}`,
          data: {
            url: selectedUrl.replace(/^\/documents\//, ''),
            title: view.model.title,
          },
        });
      } else {
        view.trigger('xircles-goto-url', {
          conversationId,
          title: view.model.title,
          url: selectedUrl,
          externalDisclaimer: view.model.externalDisclaimer,
        });
      }
    },
    _onTouchStart(evt, view) {
      view.pageYTouchStart = evt.pageY || evt.touches[0].pageY;
    },
    _onTouchEnd(evt, view) {
      view.pageYTouchEnd = evt.changedTouches[0].pageY;

      if (Math.abs(view.pageYTouchStart - view.pageYTouchEnd) < 10) {
        view._goToUrl(this);
      }
    },
    _updateContent() {
      this.nodes.title.innerHTML = processText(
        `${this.model.title.replace('®', '&#174;')}`
      );
      this.nodes.open.getElementsByTagName('span')[0].innerText =
        i18next.t('COMMON_OPEN');
    },
    _handleReferenceMessageClick() {
      this.model.url &&
        this.nodes.header.addEventListener('click', () => this._goToUrl(this));
      this.model.url &&
        this.nodes.open.addEventListener('click', () => this._goToUrl(this));

      if (
        this.model.url &&
        'ontouchstart' in document.documentElement &&
        'ontouchend' in document.documentElement
      ) {
        this.nodes.header.addEventListener(
          'touchstart',
          (e) => this._onTouchStart(e, this),
          { passive: true }
        );
        this.nodes.header.addEventListener(
          'touchend',
          (e) => this._onTouchEnd(e, this),
          { passive: true }
        );

        this.nodes.open.addEventListener(
          'touchstart',
          (e) => this._onTouchStart(e, this),
          { passive: true }
        );
        this.nodes.open.addEventListener(
          'touchend',
          (e) => this._onTouchEnd(e, this),
          { passive: true }
        );
      }
    },
    _createBreadcrumb() {
      if (this.model.breadcrumbs[0]) {
        this.nodes.breadcrumb.innerText = `${this.model.breadcrumbs[0].title}`;
      }
    },
    onAfterCreate() {
      this.needHcpCheck =
        this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);
      const view = this;
      view._createBreadcrumb();
      view._updateContent();
      view._handleReferenceMessageClick();
      Layer.client.user.on('identities:change', () => view._updateContent());
    },
    onRerender() {
      this._updateContent();
    },
  },
});
