// @flow

import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import config from '../../config.json';
import _get from 'lodash/get';
import _isBoolean from 'lodash/isBoolean';
import './styles.less';

import { getDrugInfos, getProductFaqs, wrapCatch } from '../../utils/api';
import { START_PAGE_MODES } from '../../const/start-page-modes';
import { loadFromSession } from '../../common/loadSession';
import { saveInSession } from '../../common/persistSession';
import {
  getAppUrl,
  getPathnameWithoutModal,
  isModalWindowRoute,
  isReactSnap,
  prepareProductUrl,
  removeRegisteredSymbol,
  slugify,
} from '../../utils/common';
import Navigation from '../Navigation';
import BrandFooter from '../BrandFooter';
import BrandHeader from '../BrandHeader';
import MenuContainer from '../../containers/MenuContainer';
import ProductOverview from '../ProductOverview';
import ProductFAQs from '../ProductFAQs';
import conversationManager from '../../hocs/conversationManager';
import LoadingSpinner from '../LoadingSpinner';
import HelmetWrapper from '../HelmetWrapper';

type Props = {};

type State = {
  searchInput: String,
  productName: String,
  productCompoundComponent: String,
  isPageVisible: Boolean,
};

class ProductPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      documentId: undefined,
      productName: '',
      productCompoundComponent: '',
      productFaqs: [],
      selectedProduct: undefined,
      isPageVisible: false,
      productInfoIndex: -1,
    };

    this.appUrl = getAppUrl();
  }

  t = this.props.t;
  isGeneralTermsHasHandler = false;
  isAcceptDataPrivacyHasHandler = false;
  isAcceptGeneralTermsHasHandler = false;

  async componentDidMount() {
    const startPageMode = _get(
      this.props.themeSettings,
      'startPageMode',
      START_PAGE_MODES.ALL_PRODUCTS
    );

    const isDataPrivacyAgreedFromSession = loadFromSession(
      'is_data_privacy_agreed'
    );
    const isDataPrivacyAgreed = _isBoolean(isDataPrivacyAgreedFromSession)
      ? isDataPrivacyAgreedFromSession
      : false;

    const isTermsOfUseAgreedFromSession = loadFromSession(
      'is_terms_of_use_agreed'
    );
    const isTermsOfUseAgreed = _isBoolean(isTermsOfUseAgreedFromSession)
      ? isTermsOfUseAgreedFromSession
      : false;

    this.setState({
      isMultiProductMode: startPageMode === START_PAGE_MODES.MULTI_PRODUCTS,
      isDataPrivacyAgreed,
      isTermsOfUseAgreed,
    });

    if (startPageMode === START_PAGE_MODES.SINGLE_PRODUCT) {
      this.props.history.push({
        pathname: '/',
      });
      return;
    }

    const brand_slug = getPathnameWithoutModal(
      this.props.history.location.pathname
    )
      .split('/')
      .pop();

    if (brand_slug) {
      const response = await getDrugInfos(brand_slug).catch(() =>
        this.props.history.push('/')
      );

      if (!this.props.history.location.pathname.includes('medikamente')) {
        this.props.history.replace(`/medikamente/${brand_slug}`);
      }

      const documents = _get(response, 'data.documents', []);
      const molecules = (_get(response, 'data.molecules', []) || []).join(', ');

      if (documents.length > 0) {
        const productInfoIndex = this.props.documentId
          ? documents.findIndex(
              (document) => document.id === this.props.documentId
            )
          : -1;
        const selectedProduct =
          productInfoIndex < 0
            ? null
            : this.getDrugProperty(documents, productInfoIndex, 'slug');
        const safeProductInfoIndex =
          productInfoIndex < 0 ? 0 : productInfoIndex;
        const brandName = _get(response, 'data.brand');

        this.setState(
          {
            productInfoIndex,
            documents: documents
              .filter(
                ({ title, url }) =>
                  title !== 'N/A' || !url.includes('undefined')
              )
              .map((current) => ({
                id: current.id,
                productUrl: prepareProductUrl(
                  current.url,
                  brand_slug,
                  this.appUrl,
                  false
                ),
                productSnippet: current.snippet,
                productTitle: current.title,
                productRelativeUrl: prepareProductUrl(
                  current.url,
                  brand_slug,
                  this.appUrl
                ),
                productManufacturer: current.company.name,
              })),
            productPathname: brand_slug,
            brandName,
            documentId: this.getDrugProperty(
              documents,
              safeProductInfoIndex,
              'id'
            ),
            productName: this.getDrugProperty(
              documents,
              safeProductInfoIndex,
              'name'
            ),
            pxInfo: response.data.px_info,
            productCompoundComponent: molecules,
            selectedProduct,
            selectedProductTitle: this.getDrugProperty(
              documents,
              safeProductInfoIndex,
              'title'
            ),
            isPageVisible: true,
          },
          () => {
            this.handleAddKnowledgeGraph(brandName, molecules);
          }
        );
      } else {
        this.props.history.push('/');
      }

      if (config.FeartureFaqEnabled) {
        this.handleProductFaqs(brand_slug);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.newConversationId !== prevProps.newConversationId) {
      this.props.history.push({
        pathname: `/questions/${this.props.newConversationId}`,
        state: {
          detail: {
            corrections: this.props.corrections,
            originalText: this.props.originalText,
            prevLocation: this.props.history.location.pathname,
          },
        },
      });
    }

    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      isModalWindowRoute(prevProps.location.pathname)
    ) {
      const selectedProduct = _get(this.props, 'selectedProduct.slug');

      if (
        selectedProduct &&
        (this.state.productInfoIndex < 0 ||
          selectedProduct !== this.state.selectedProduct)
      ) {
        const productInfoIndex = this.state.productInfo.findIndex(
          (drug) => drug.slug === selectedProduct
        );

        if (productInfoIndex !== -1) {
          this.setState({
            productInfoIndex,
            selectedProduct,
            selectedProductTitle: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'title'
            ),
            documentId: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'id'
            ),
            productName: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'name'
            ),
            productSnippet: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'snippet'
            ),
            productTitle: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'title'
            ),
            productUrl: prepareProductUrl(
              this.getDrugProperty(
                this.state.productInfo,
                productInfoIndex,
                'url'
              ),
              this.state.productPathname.toLowerCase(),
              this.appUrl,
              false
            ),
            productRelativeUrl: prepareProductUrl(
              this.getDrugProperty(
                this.state.productInfo,
                productInfoIndex,
                'url'
              ),
              this.state.productPathname.toLowerCase(),
              this.appUrl
            ),
            productManufacturer: this.getDrugProperty(
              this.state.productInfo,
              productInfoIndex,
              'company.name'
            ),
          });
        }
      }
    }

    if (!this.isGeneralTermsHasHandler) {
      const generalTermsLink = document.getElementById('general-terms-link');

      if (generalTermsLink && typeof generalTermsLink.onclick !== 'function') {
        this.isGeneralTermsHasHandler = true;
        generalTermsLink.onclick = this.handleGeneralTermsLinkClick;
      }
    }

    if (!this.isAcceptDataPrivacyHasHandler) {
      const acceptDataPrivacy = document.getElementById('accept-data-privacy');

      if (
        acceptDataPrivacy &&
        typeof acceptDataPrivacy.onchange !== 'function'
      ) {
        this.isAcceptDataPrivacyHasHandler = true;

        if (this.state.isDataPrivacyAgreed) {
          acceptDataPrivacy.checked = this.state.isDataPrivacyAgreed;
        }

        acceptDataPrivacy.onchange = this.handleAcceptDataPrivacyChange;

        window.addEventListener('logged-out', () =>
          this.setState(
            { isDataPrivacyAgreed: false },
            () => (acceptDataPrivacy.checked = false)
          )
        );
      }
    }

    if (!this.isAcceptGeneralTermsHasHandler) {
      const acceptGeneralTerms = document.getElementById(
        'accept-general-terms'
      );

      if (
        acceptGeneralTerms &&
        typeof acceptGeneralTerms.onchange !== 'function'
      ) {
        this.isAcceptGeneralTermsHasHandler = true;

        if (this.state.isTermsOfUseAgreed) {
          acceptGeneralTerms.checked = this.state.isTermsOfUseAgreed;
        }

        acceptGeneralTerms.onchange = this.handleAcceptGeneralTermsChange;

        window.addEventListener('logged-out', () =>
          this.setState(
            { isTermsOfUseAgreed: false },
            () => (acceptGeneralTerms.checked = false)
          )
        );
      }
    }
  }

  componentWillUnmount() {
    this.handleRemoveKnowledgeGraph();
  }

  handleProductFaqs = async (brand_slug) => {
    const { sessionToken } = this.props.layerClient;

    if (sessionToken.length > 0) {
      const result = await wrapCatch(getProductFaqs(brand_slug));
      if (result) {
        this.setState({ productFaqs: result.data });
      }
    } else {
      return setTimeout(() => this.handleProductFaqs(brand_slug), 1000);
    }
  };

  handleAddKnowledgeGraph = (brandName, moleculesString) => {
    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'MedicalWebPage',
      audience: 'https://schema.org/Clinician',
      description: `${brandName} • ${this.t('COMMON_TITLE_CLAIM')}`,
      url: `${config.XirclesUrl}/medikamente/${slugify(brandName)}`,
      drug: [
        {
          '@type': 'Drug',
          proprietaryName: brandName,
          activeIngredient: moleculesString,
        },
      ],
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  handleRemoveKnowledgeGraph = () => {
    const knowledgeGraph = document.querySelector(
      'script[type="application/ld+json"]'
    );
    return (
      knowledgeGraph &&
      knowledgeGraph.parentNode &&
      knowledgeGraph.parentNode.removeChild(knowledgeGraph)
    );
  };

  handleProductOverviewLinkClick = (
    pathname,
    isProductXircle = false,
    isPXDocument = false
  ) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          pathname,
          name: 'select-drug-info',
          drug: this.state.brandName,
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          question: '-',
          text: '-',
          id: '-',
        },
      })
    );

    const { pxInfo } = this.state;

    this.props.history.push({
      pathname,
      state: {
        detail: {
          isFachInfoLink: true,
          isProductXircle,
          pxColor:
            pxInfo && (isProductXircle || isPXDocument)
              ? {
                  primaryColor: _get(pxInfo, 'page_data.primary_color'),
                  onBackgroundHighlightColor: _get(
                    pxInfo,
                    'page_data.on_background_highlight_color'
                  ),
                  onBackgroundHighlightTextColor: _get(
                    pxInfo.page_data,
                    'on_background_highlight_text_color'
                  ),
                }
              : undefined,
        },
      },
    });
  };

  getDrugProperty = (drug, i = 0, path) => _get(drug, `[${i}].${path}`);

  handlePostQuestion = (question, corrections, originalText) => {
    document.dispatchEvent(
      new CustomEvent('tr-custom-event', {
        detail: {
          question,
          name: 'search-submit',
          drug: this.state.brandName,
          location: window.location.href,
          conversationId: '-',
          elementId: '-',
          suggestion: '-',
          text: '-',
          id: '-',
        },
      })
    );

    const product = this.props.location.pathname.replace('/medikamente/', '');
    this.props.createConversation(
      question,
      {
        goBackPath: this.props.history.location.pathname,
        product,
        selectedProductPage: product,
      },
      corrections,
      originalText
    );
  };

  handleLogoClick = () => this.props.history.push('/');

  handleGeneralTermsLinkClick = (e) => {
    e.preventDefault();
    this.props.history.push(
      `/medikamente/${this.state.productPathname}/terms-of-use`
    );
  };

  handleAcceptDataPrivacyChange = () => {
    const nextDataPrivacyAgreed = !this.state.isDataPrivacyAgreed;

    saveInSession({ is_data_privacy_agreed: nextDataPrivacyAgreed });
    this.setState({ isDataPrivacyAgreed: nextDataPrivacyAgreed });
  };

  handleAcceptGeneralTermsChange = () => {
    const nextTermsOfUseAgreed = !this.state.isTermsOfUseAgreed;

    saveInSession({ is_terms_of_use_agreed: nextTermsOfUseAgreed });
    this.setState({ isTermsOfUseAgreed: nextTermsOfUseAgreed });
  };

  render() {
    const {
      documentId,
      documents,
      isPageVisible,
      productCompoundComponent,
      productPathname,
      brandName,
      isMultiProductMode,
      isDataPrivacyAgreed,
      isTermsOfUseAgreed,
      pxInfo,
    } = this.state;

    return (
      <div className="product-page">
        {isPageVisible && (
          <Fragment>
            <HelmetWrapper
              product={brandName}
              location={this.props.location.pathname}
            />
            <BrandHeader
              {...this.props}
              className="product-page__header"
              documentId={documentId}
              productName={productPathname}
              isDataPrivacyMode={isMultiProductMode}
              isDataPrivacyAgreed={isDataPrivacyAgreed}
              isTermsOfUseAgreed={isTermsOfUseAgreed}
              onPostQuestion={this.handlePostQuestion}
              onLogoClick={this.handleLogoClick}
              brandName={brandName}
            >
              <h1 className="brand-header__select-area">
                <span className="brand-header__compound-component">
                  {productCompoundComponent}
                </span>
                <span className="brand-header__show-drug">{brandName}</span>
              </h1>
              {isMultiProductMode && (
                <div className="brand-header__welcome">
                  <p
                    className="brand-header__welcome-message welcome-message__data-privacy"
                    dangerouslySetInnerHTML={{
                      __html: this.t('QUESTION_DATA_PRIVACY_MESSAGE', {
                        dataProtection: `<a id='data-protection-link' target='_blank' href='${this.t(
                          'QUESTION_DATA_PROTECTION_LINK'
                        )}'>${this.t('COMMON_DATA_PROTECTION')}</a>`,
                      }),
                    }}
                  />
                  <p
                    className="brand-header__welcome-message welcome-message__data-privacy-check"
                    dangerouslySetInnerHTML={{
                      __html: `
									<input type="checkbox" data-tr-event="true" id="accept-data-privacy" name="accept-data-privacy" />
									<span>${this.t('QUESTION_ACCEPT_DATA_PRIVACY_MESSAGE', {
                    dataPrivacy: `<a id='data-privacy-link' target='_blank' href='${this.t(
                      'QUESTION_DATA_PRIVACY_LINK'
                    )}'>${this.t('COMMON_ANSWER_MY_REQUEST')}</a>`,
                  })}</span>
								`,
                    }}
                  />
                  <p
                    className="brand-header__welcome-message welcome-message__general-terms-check"
                    dangerouslySetInnerHTML={{
                      __html: `
									<input type="checkbox" data-tr-event="true" id="accept-general-terms" name="accept-general-terms" />
									<span>${this.t('QUESTION_ACCEPT_GENERAL_TERMS_MESSAGE', {
                    generalTerms: `<a id='general-terms-link' href='${
                      window.origin
                    }/terms-of-use'>${this.t('COMMON_GENERAL_TERMS')}</a>`,
                  })}</span>
								`,
                    }}
                  />
                </div>
              )}
            </BrandHeader>
            <div className="product-page__content">
              <Navigation
                links={[
                  {
                    url: '/',
                    title: this.t('COMMON_START_PAGE'),
                  },
                  {
                    url: '/medikamente/katalog/1',
                    title: this.t('COMMON_MEDICATIONS'),
                  },
                  {
                    url: `/medikamente/${productPathname}`,
                    title: brandName,
                  },
                ]}
              />
              {pxInfo && (
                <div
                  className="px-info-wrapper"
                  style={{
                    backgroundColor:
                      pxInfo.page_data.on_background_highlight_color,
                  }}
                >
                  <p
                    className="px-info-wrapper__title"
                    style={{ color: pxInfo.page_data.primary_color }}
                  >
                    {this.t('PRODUCT_XIRCLE_AVAILABLE')}
                    <i className="material-icons">info</i>
                  </p>
                  <div className="px-info">
                    <ProductOverview
                      withBackground
                      isProductXircle
                      pxInfo={pxInfo}
                      key="product-overview-xircle"
                      productUrl={`${this.appUrl}/${slugify(
                        pxInfo.page_data.company_name
                      )}/${slugify(
                        removeRegisteredSymbol(pxInfo.drug_intro.brand_name)
                      )}`}
                      productRelativeUrl={`/${slugify(
                        pxInfo.page_data.company_name
                      )}/${slugify(
                        removeRegisteredSymbol(pxInfo.drug_intro.brand_name)
                      )}`}
                      isMultiProduct={false}
                      productSnippet={pxInfo.drug_intro.drug_intro_text}
                      productManufacturer={pxInfo.page_data.company_name}
                      productCompoundComponent={productCompoundComponent}
                      linkTitle={
                        <span>
                          {removeRegisteredSymbol(pxInfo.page_data.page_title)}
                          <sup>®</sup>
                          {' Xircle »'}
                        </span>
                      }
                      productTitle={`${pxInfo.page_data.page_title} Xircle`}
                      onProductOverviewLinkClick={
                        this.handleProductOverviewLinkClick
                      }
                    />
                    {documents
                      .filter((doc) =>
                        pxInfo
                          ? pxInfo.drug_intro.mmi_documents.includes(doc.id)
                          : doc
                      )
                      .map(
                        (
                          {
                            productUrl,
                            productSnippet,
                            productTitle,
                            productRelativeUrl,
                            productManufacturer,
                          },
                          index
                        ) => (
                          <ProductOverview
                            pxInfo={pxInfo}
                            isPXDocument
                            key={`product-overview-${index}`}
                            productUrl={productUrl}
                            productSnippet={productSnippet}
                            productTitle={productTitle}
                            productRelativeUrl={productRelativeUrl}
                            productManufacturer={productManufacturer}
                            isMultiProduct={isMultiProductMode}
                            linkTitle={this.t(
                              'COMMON_SHORT_SPECIALIST_INFORMATION'
                            )}
                            productCompoundComponent={productCompoundComponent}
                            onProductOverviewLinkClick={
                              this.handleProductOverviewLinkClick
                            }
                          />
                        )
                      )}
                  </div>
                </div>
              )}
              <div className="product-overview">
                {documents
                  .filter((doc) =>
                    pxInfo
                      ? !pxInfo.drug_intro.mmi_documents.includes(doc.id)
                      : doc
                  )
                  .map(
                    (
                      {
                        productUrl,
                        productSnippet,
                        productTitle,
                        productRelativeUrl,
                        productManufacturer,
                      },
                      index
                    ) => (
                      <ProductOverview
                        key={`product-overview-${index}`}
                        productUrl={productUrl}
                        productSnippet={productSnippet}
                        productTitle={productTitle}
                        productRelativeUrl={productRelativeUrl}
                        productManufacturer={productManufacturer}
                        isMultiProduct={isMultiProductMode}
                        linkTitle={this.t(
                          'COMMON_SHORT_SPECIALIST_INFORMATION'
                        )}
                        productCompoundComponent={productCompoundComponent}
                        onProductOverviewLinkClick={
                          this.handleProductOverviewLinkClick
                        }
                      />
                    )
                  )}
              </div>
              {config.FeartureFaqEnabled &&
                this.state.productFaqs.length > 0 &&
                !isReactSnap() && (
                  <ProductFAQs
                    {...this.props}
                    documentId={this.state.documentId}
                    productFaqs={this.state.productFaqs}
                    productName={brandName}
                    productPathname={productPathname}
                    productCompoundComponent={productCompoundComponent}
                  />
                )}
            </div>
            {_get(this.props.themeSettings, 'isMenuVisible', false) && (
              <MenuContainer />
            )}
            {_get(this.props.themeSettings, 'isFooterVisible', false) && (
              <BrandFooter />
            )}
          </Fragment>
        )}
        {!isPageVisible && (
          <LoadingSpinner className="loading-spinner__product-page" />
        )}
      </div>
    );
  }
}

const ProductPageWithConversationManager = conversationManager(ProductPage);

export default withTranslation()(
  withRouter(ProductPageWithConversationManager)
);
