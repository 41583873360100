// @flow

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

function ForwardConversationModalFragment({ onSubmit, onCancel, t }: Props) {
  return (
    <Fragment>
      <Box textAlign="center">{t('MODAL_SUBMIT_CONVERSATION_TO_MEDINFO')}</Box>
      <Box mt={4}>
        <Button outlined style={{ width: '100%' }} onClick={onSubmit}>
          {t('COMMON_SEND')}
        </Button>
      </Box>
      <Box mt={1}>
        <Button
          outlined
          style={{ width: '100%' }}
          color="secondary"
          onClick={onCancel}
        >
          {t('COMMON_CANCEL')}
        </Button>
      </Box>
    </Fragment>
  );
}

export default withTranslation()(ForwardConversationModalFragment);
