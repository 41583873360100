import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesResultsMessageModel extends MessageTypeModel {
  generateParts(callback) {
    const that = this;

    const body = this.initBodyWithMetadata([]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });

    if (this.results.length === 0) {
      callback([this.part]);
    } else {
      const parts = [this.part];
      this.results.forEach((result, i) => {
        that.addChildModel(result, 'results', (newParts) => {
          newParts.forEach((p) => parts.push(p));
        });
      });
      callback(parts);
    }
  }

  parseModelChildParts({ changes, isEdit }) {
    super.parseModelChildParts({ changes, isEdit });

    this.results = this.getModelsByRole('results');
  }
}

XirclesResultsMessageModel.prototype.results = null;

XirclesResultsMessageModel.MIMEType = 'application/xircles.results+json';
XirclesResultsMessageModel.SummaryTemplate = '${textFound}'; // eslint-disable-line no-template-curly-in-string
XirclesResultsMessageModel.LabelSingular = 'Results';
XirclesResultsMessageModel.messageRenderer = 'xircles-results-message-view';

Root.initClass.apply(XirclesResultsMessageModel, [
  XirclesResultsMessageModel,
  'XirclesResultsMessageModel',
]);
Client.registerMessageTypeModelClass(
  XirclesResultsMessageModel,
  'XirclesResultsMessageModel'
);

export default XirclesResultsMessageModel;
