import Layer from '@layerhq/web-xdk';
import { MDCSwitch } from '@material/switch';
import i18next from 'i18next';

import './checkbox-message-model';

import extendBaseView from '../xircles-choice-base-view/xircles-choice-message-base-view';
import textHandlers from '@layerhq/web-xdk/ui/handlers/text/text-handlers';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;

registerComponent(
  'xircles-checkbox-message-view',
  extendBaseView({
    mixins: [MessageViewMixin],
    template: `
    <div class='layer-choice-message-view-choices'>
      <div>
        <div layer-id='choices'></div>
        <div layer-id='label' class='layer-choice-message-view-label'></div>
      </div>
    </div>
  `,
    style: `
    xircles-checkbox-message-view .layer-choice-message-view-choices {
      display: flex;
      flex-direction: column;
      padding: 19px;
    }
    `,
    methods: {
      onAfterCreate() {
        const view = this;
        let choiceMessageID = view.closest('layer-message-item-received');
        if (!choiceMessageID) {
          choiceMessageID = view.closest('layer-message-item-sent');
        }
        choiceMessageID = choiceMessageID
          .getAttribute('layer-item-id')
          .split('_')[1];
        this.nodes.label.innerHTML = textHandlers.processText(view.model.label);
        const listItem = view.closest('.layer-list-item');
        const sendSelection = () => {
          this._removeBorder();
          view.trigger('xircles-choice-send', {
            id: 'Ok',
            text: `${i18next.t('LOGIN_CHOISE_SEND')}`,
            conversationId: view.messageViewer.message.conversationId,
            customData: { choiceMessageID, isMultiSelect: true },
          });
          view.nodes.sendButton.classList.add('xircles-hide');
          listItem.classList.add('xircles-hide');
        };
        view.model.choices.forEach((choice) => {
          let id = `${view.model.id}-${choice.id}`;
          let checkbox = view.createElement('div', {
            event: 'layer-choice-select',
            data: { id: choice.id },
            parentNode: view.nodes.choices,
          });
          checkbox.innerHTML = `
          <div class="mdc-switch">
            <div class="mdc-switch__track"></div>
            <div class="mdc-switch__thumb-underlay">
              <div class="mdc-switch__thumb">
                <input type="checkbox" id="${id}" class="mdc-switch__native-control" role="switch">
              </div>
            </div>
          </div>
          <label for="${id}">${choice.text}</label>
        `;
          new MDCSwitch(checkbox.querySelector('.mdc-switch'));
          checkbox
            .querySelector('.mdc-switch__native-control')
            .addEventListener('change', () => {
              view.runAction(checkbox);
              const ids = view.model.selectedAnswer.split(',');
              const allChecked = ids.length === view.model.choices.length;
              if (allChecked) {
                view.nodes.sendButton.classList.remove('xircles-disabled');
                view.nodes.sendButton.addEventListener('click', sendSelection);
              } else {
                view.nodes.sendButton.classList.add('xircles-disabled');
                view.nodes.sendButton.removeEventListener(
                  'click',
                  sendSelection
                );
              }
            });
        });
        const div = document.createElement('div');
        view.appendChild(div);
        div.innerHTML =
          '<button class="layer-button xircles-disabled" layer-id="button" tab-index="0" title="OK"></button>';
        view.nodes.sendButton = div.firstChild;
        view.nodes.sendButton.innerHTML = 'Ok';
      },
      _removeBorder() {
        const [itemTop] = document.getElementsByClassName(
          'layer-message-item__border-top'
        );
        itemTop && itemTop.classList.remove('layer-message-item__border-top');
        const [itemBottom] = document.getElementsByClassName(
          'layer-message-item__border-bottom'
        );
        itemBottom &&
          itemBottom.classList.remove('layer-message-item__border-bottom');
        const [itemSides] = document.getElementsByClassName(
          'layer-message-item__border-sides'
        );
        itemSides &&
          itemSides.classList.remove('layer-message-item__border-sides');
      },
    },
  })
);
