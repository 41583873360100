/* eslint-disable */
// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { isOverflown, wrapRegisteredSymbol } from '../../utils/common';

import './styles.less';

type Props = {};

type State = {};

class ProductXircleNews extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.categoryNewsRef = React.createRef();

    this.state = {
      isNewsSectionExpanded: false,
      isOverflownContent: false,
    };
  }

  componentDidMount() {
    this.handleOverflownContent();
  }

  handleOverflownContent = () => {
    if (
      (isOverflown(this.categoryNewsRef.current) ||
        (window.innerWidth <= 768 &&
          this.props.newsCategory.news.length > 2)) &&
      !this.state.isOverflownContent
    ) {
      this.setState({ isOverflownContent: true });
    }
  };

  handleExpandNewsSection = (e) => {
    e.preventDefault();
    this.setState({ isNewsSectionExpanded: !this.state.isNewsSectionExpanded });
  };

  render() {
    const { newsCategory, pxInfo, index, t } = this.props;
    const { isNewsSectionExpanded, isOverflownContent } = this.state;

    return (
      <div
        className="product-xircle-page__news_category"
        key={`news-category-${index}`}
      >
        <h3 className="product-xircle-page__news_subtitle">
          {newsCategory.category_name}
        </h3>
        <div
          ref={this.categoryNewsRef}
          className={`product-xircle-page__news_category-news${
            isNewsSectionExpanded
              ? ' product-xircle-page__news_category-news--expanded'
              : ''
          }`}
        >
          {newsCategory.news.map((news, index) =>
            news.news_title ? (
              <div
                key={`news-${newsCategory.category_name}-${index}`}
                className="product-xircle-page__news_category-item"
              >
                <div>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: wrapRegisteredSymbol(news.news_title),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: wrapRegisteredSymbol(news.news_text),
                    }}
                  />
                </div>
                <a
                  target="_blank"
                  rel="canonical"
                  href={news.news_link_url}
                  style={{
                    color: pxInfo.page_data.on_background_highlight_text_color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: wrapRegisteredSymbol(news.news_link_text),
                  }}
                />
              </div>
            ) : null
          )}
        </div>
        {isOverflownContent && (
          <a
            style={{
              color: pxInfo.page_data.on_background_highlight_text_color,
            }}
            onClick={this.handleExpandNewsSection}
            className="product-xircle-page__show-all"
          >
            {t(
              isNewsSectionExpanded
                ? 'PRODUCT_XIRCLE_HIDE_ALL'
                : 'PRODUCT_XIRCLE_SHOW_ALL'
            )}
          </a>
        )}
      </div>
    );
  }
}

export default withTranslation()(ProductXircleNews);
