import { Layer } from '../../get-layer';

const registerComponent = Layer.UI.registerComponent;

registerComponent('xircles-message-view-container', {
  template: "<div layer-id='UIContainer' class='layer-card-top'></div>",
  style: `
    xircles-message-view-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `,
  properties: {
    /**
     * The Layer.Core.MessageTypeModel whose data is rendered here.
     *
     * @property {Layer.Core.MessageTypeModel} model
     */
    model: {},

    /**
     * The Layer.UI.messages.MessageViewMixin that is wrapped by this UI Component.
     *
     * @property {Layer.UI.messages.MessageViewMixin} ui
     */
    ui: {
      set: function set() {
        while (this.nodes.UIContainer.firstChild) {
          this.nodes.UIContainer.removeChild(this.nodes.UIContainer.firstChild);
        }
        if (this.properties.ui)
          this.nodes.UIContainer.appendChild(this.properties.ui);
      },
    },
  },
});
