/**
 * GTM - Trigger
 *
 * name: Click on scroll down
 * fires on: Click element matches CSS selector button.show-more-button i
 * fires on: Click element matches CSS selector button.show-more-button
 * fires on: Click element matches CSS selector i.show-more-button-icon
 */

import Layer from '@layerhq/web-xdk';

import { isOperator } from '../utils/user';
import animatedScrollTo from '../utils/animated-scroll';
import { isAndroid, isModalWindowRoute } from '../utils/common';

const MessageListBeforeMixin = {
  methods: {
    onCreate: function () {
      window.addEventListener(
        'toogle-result-message',
        this._myCheckVisibility.bind(this)
      );
      this.addEventListener('scroll', this._myCheckVisibility.bind(this), {
        passive: true,
      });
      window.addEventListener('resize', this._myCheckVisibility.bind(this), {
        passive: true,
      });
      // used to detect if we adding/paging messages on top or bottom (new arrivals instead scrolling to old ones)
      this.maxPosition = 0;
      // view can be scrolled to that position w/o hiding potentially unread messages
      this.maxScrollToPosition = 0;
      // this.timeoutRef = 0;
      // flag to mark that self scroll maximum was reached
      this.overflow = false;
      // to determine scroll direction
      this.lastScrollTop = 0;
      // is revealing messages on the bottom
      this.isScrollingDown = true;
      // by default we disable auto scroll to bottom, when we first time create a conversation
      this.isScrollDisabled = true;
      // is the list of the messages was srolled manually
      this.wasScrolled = false;
      // the number of the items in the message list
      this.childMessageListCount = 0;
      // override via monkey patch
      this.orig_animatedScrollTo = function (position) {
        var _this3 = this;

        var animateSpeed =
          arguments.length > 1 && arguments[1] !== undefined
            ? arguments[1]
            : 200;
        var animateCallback = arguments[2];

        if (position === this.scrollTop) return;
        this.properties.isSelfScrolling = true;
        if (this.properties.cancelAnimatedScroll)
          this.properties.cancelAnimatedScroll();
        var cancel = (this.properties.cancelAnimatedScroll = animatedScrollTo(
          this,
          position,
          animateSpeed,
          function () {
            // Wait for any onScroll events to trigger before we clear isSelfScrolling and procede
            setTimeout(function () {
              if (cancel !== _this3.properties.cancelAnimatedScroll) return;
              _this3.properties.cancelAnimatedScroll = null;

              _this3.properties.isSelfScrolling = false;
              _this3._checkVisibility();
              if (animateCallback) animateCallback();
            }, 100);
          }
        ));
      };
      // override via monkey patch
      this.animatedScrollTo = function (position) {
        if (this.isScrollDisabled) {
          return;
        }

        const nextScrollPosition = this.maxScrollToPosition
          ? Math.min(this.maxScrollToPosition, position)
          : position;
        if (this.scrollTop < nextScrollPosition) {
          this.orig_animatedScrollTo(nextScrollPosition);
        }
        if (
          nextScrollPosition > 0 &&
          nextScrollPosition === this.maxScrollToPosition
        ) {
          this.overflow = true;
        }
      };
    },
    onPagedDataDone: function (isDoneSizingContent) {
      this.childMessageListCount = this.childElementCount;
      isDoneSizingContent && this.isScrollingDown && this._revealAll();
    },
    onRerender: {
      mode: Layer.UI.registerComponent.MODES.BEFORE,
      conditional: function onCanRerender() {
        return Boolean(this.query);
      },
      value: function value() {
        const evt =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : {};
        if (!this.query.isDestroyed && evt.type === 'insert') {
          const indi = this.querySelector('.xircles-typing-indicator');
          indi && indi.remove();
        }

        // wait one cycle for adding item DOM
        setTimeout(() => {
          if (isOperator(Layer.client.user)) {
            const layerItems = this.getElementsByTagName(
              'layer-message-item-received'
            );
            const layerItemsSent = this.getElementsByTagName(
              'layer-message-item-sent'
            );
            if (layerItems) {
              for (let i = 0; i < layerItems.length; i++) {
                layerItems[i].getElementsByClassName(
                  'layer-carousel-message-view'
                ).length &&
                  layerItems[i].classList.add(
                    'layer-message-status-delivered-from-operator'
                  );
              }
            }

            if (layerItemsSent) {
              for (let i = 0; i < layerItemsSent.length; i++) {
                layerItemsSent[i].getElementsByClassName(
                  'layer-message-type-view__operator'
                ).length &&
                  layerItemsSent[i].classList.add(
                    'layer-message-status-delivered-from-operator'
                  );

                layerItemsSent[i].getElementsByClassName(
                  'layer-message-item-main'
                ).length &&
                  layerItemsSent[i]
                    .getElementsByClassName('layer-message-item-main')[0]
                    .classList.add('layer-message-item-main__operator');
              }
            }
          }
        });
      },
    },
    onGenerateListItem: function (widget) {
      if (this.childElementCount > this.childMessageListCount) {
        this.isScrollDisabled = false;
      }
      // we adding/paging messages on bottom?
      if (this.maxPosition < widget.properties.item.position) {
        this.maxPosition = widget.properties.item.position;
        if (
          widget.tagName.endsWith('-SENT') ||
          widget.tagName.endsWith('-STATUS')
        ) {
          // reveal and update maxScrollToPosition after user entered message
          this._revealAll(false, widget);
        } else {
          if (!widget.properties.item.isRead) {
            // wait one cycle for adding item DOM so we can get its height
            setTimeout(() => {
              // mark new arrived message
              widget.classList.add('new');
              if (this._isVisible(widget)) {
                this._revealAll(true, widget);
              } else {
                widget.classList.add('hidden');
                // if new message is not visible, hide input bar, show show-more

                const [layerComposeBar] =
                  document.getElementsByTagName('layer-compose-bar');
                layerComposeBar &&
                  layerComposeBar.classList.add(
                    'layer-compose-bar__border-top'
                  );

                this.showMore.classList.remove('xircles-hide');
                this.trigger('xircles-set-keyboard', {
                  visibility: 'hidden',
                  conversation: this.conversation,
                });
              }
            });
          }
        }
      }
    },
    _setMaxScrollInitialPosition: function () {
      const header = document.getElementsByClassName('conversation-header')[0]
        ? document.getElementsByClassName('conversation-header')[0].clientHeight
        : 98;

      this.maxScrollToPosition = this.scrollHeight - header;
    },
    _setMaxScrollPosition: function (widget) {
      setTimeout(() => {
        const header = document.getElementsByClassName('conversation-header')[0]
          ? document.getElementsByClassName('conversation-header')[0]
              .clientHeight
          : 98;
        if (widget && this.scrollHeight === this.clientHeight) {
          const r = widget.getBoundingClientRect();
          this.maxScrollToPosition = r.y || 0 - 10 - header;
        } else {
          // add chat height as offset as soon as page is full and scrolling has started
          // const scrollStartedOffset = (this.scrollHeight <= this.clientHeight) ? this.clientHeight : 0;
          // this.maxScrollToPosition = this.scrollHeight - (scrollStartedOffset + header);
          this.maxScrollToPosition = this.scrollHeight - header;
          if (this.maxScrollToPosition < 0) {
            this.maxScrollToPosition = 0;
          }
        }
        // console.log('SET this.maxScrollToPosition: ' + this.maxScrollToPosition);
      });
    },
    _myCheckVisibility: function () {
      if (isAndroid() && isModalWindowRoute(window.location.pathname)) {
        return;
      }
      this.isScrollDisabled = false;
      this.wasScrolled = true;
      this.isScrollingDown = this.scrollTop > this.lastScrollTop;
      this.lastScrollTop = this.scrollTop;
      const children = Array.prototype.slice.call(this.childNodes);
      children
        .filter((item) => item.classList.contains('hidden'))
        .forEach((child) => {
          if (this._isVisible(child)) {
            child.classList.remove('hidden');
            // update maxScrollToPosition after hidden message was manually revealed
            if (!this.properties.isSelfScrolling) {
              // when self scroll maximum reached there is a timing issue in Layer.UI.components.MessageListPanel.List
              // which leads to false negative this.properties.isSelfScrolling
              // usage of this.overflow is a workaround to prevent accidentally updating MaxScrollPosition
              if (this.overflow) {
                this.overflow = false;
              } else {
                this._updateMaxScrollPosition();
              }
            }
          }
        }, this);
      // if there is nothing to show anymore, hide shore-more button and show input bar
      const bar = document.querySelector('layer-compose-bar');
      const [layerComposeBar] =
        document.getElementsByTagName('layer-compose-bar');
      if (
        this.scrollTop +
          this.clientHeight +
          (bar && bar.classList.contains('xircles-hide') ? 0 : 93) <
        this.scrollHeight
      ) {
        layerComposeBar &&
          layerComposeBar.classList &&
          layerComposeBar.classList.add('layer-compose-bar__border-top');
        this.showMore.classList.remove('xircles-hide');
        this.trigger('xircles-set-keyboard', {
          visibility: 'hidden',
          conversation: this.conversation,
        });
      } else {
        layerComposeBar &&
          layerComposeBar.classList &&
          layerComposeBar.classList.remove('layer-compose-bar__border-top');
        this.showMore.classList.add('xircles-hide');
        this.trigger('xircles-set-keyboard', {
          visibility: 'visible',
          conversation: this.conversation,
        });
      }
    },
    _isVisible: function (child) {
      // taken from MessageList._shouldMarkAsRead
      const errorMarginBottom = 10;
      const errorMarginTop = 10;
      const topVisiblePixel = this.scrollTop;
      const bottomVisiblePixel = this.scrollTop + this.clientHeight;
      const childTopVisiblePixel = child.offsetTop - this.offsetTop;
      const childBottomVisiblePixel = childTopVisiblePixel + child.clientHeight;
      const isTooBig = child.clientHeight + 50 > this.clientHeight;

      const isChildTopVisible =
        childTopVisiblePixel + errorMarginTop >= topVisiblePixel &&
        childTopVisiblePixel < bottomVisiblePixel;
      const isChildBottomVisible =
        childBottomVisiblePixel <= bottomVisiblePixel + errorMarginBottom &&
        childTopVisiblePixel < bottomVisiblePixel;
      return (
        isChildTopVisible ||
        (isTooBig && (isChildBottomVisible || isChildTopVisible))
      );
    },
    _updateMaxScrollPosition: function () {
      const header = document.getElementsByClassName('conversation-header')[0]
        ? document.getElementsByClassName('conversation-header')[0].clientHeight
        : 98;
      // add chat height as offset as soon as page is full and scrolling has started
      this.maxScrollToPosition = this.scrollTop + this.clientHeight - header;
      // console.log('UPD this.maxScrollToPosition: ' + this.maxScrollToPosition);
    },
    _revealAll: function (doNotUpdateMaxScrollPos, widget) {
      const news = Array.prototype.slice.call(
        document.querySelectorAll(
          'layer-message-item-received.new:not(.hidden)'
        )
      );
      news.forEach((n) => n.classList.remove('new'));

      !doNotUpdateMaxScrollPos && this._setMaxScrollPosition(widget);
      this.scrollToBottom(50);
    },
    _appendShowMoreButton(div) {
      const [layerComposeBar] =
        document.getElementsByTagName('layer-compose-bar');

      if (!layerComposeBar) {
        return setTimeout(() => this._appendShowMoreButton(div), 500);
      } else {
        return layerComposeBar.appendChild(div);
      }
    },
    onAfterCreate: function () {
      const div = document.createElement('div');
      this.showMore = div;
      const button = document.createElement('button');
      button.classList.add('show-more-button');
      button.setAttribute('data-tr-event', true);
      div.classList.add('layer-message-item');
      div.classList.add('show-more');
      div.classList.add('xircles-hide');

      // TODO: translate
      button.innerHTML =
        '<i data-tr-event="true" class="material-icons show-more-button-icon">keyboard_arrow_down</i>';
      button.addEventListener('click', () => {
        if (this.isScrollDisabled || !this.wasScrolled) {
          this.isScrollDisabled = false;
          this.wasScrolled = true;
          this._setMaxScrollInitialPosition();
        }
        this._revealAll(false);
      });
      div.appendChild(button);
      this._appendShowMoreButton(div);
      this._revealAll(true);
    },
    onDestroy: function () {
      window.removeEventListener(
        'toogle-result-message',
        this._myCheckVisibility.bind(this)
      );
      window.removeEventListener('resize', this._myCheckVisibility.bind(this), {
        passive: true,
      });
    },
  },
};

export default MessageListBeforeMixin;
