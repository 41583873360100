import Layer from '@layerhq/web-xdk';
import './results-message-model';
import _get from 'lodash/get';
import { isOperator } from '../../utils/user';
import closestComponent from '../../polyfills/closestComponent';

import { layerClient } from '../../get-layer';

import i18next from 'i18next';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

const RESULT_MESSAGE_LIMIT = 8;
const ANSWER_HEIGHT = 550;
const WAIT_FOR_ANSWER_TIMEOUT_MS = 10000;
const WAIT_FOR_ANSWER_INTERVAL_MS = 100;

let isAnalyticsEventTriggered = false;

registerComponent('xircles-results-message-view', {
  mixins: [MessageViewMixin],
  style: `
    layer-text-message-view {
      display: block;
    }
    .layer-root-viewer.layer-text-message-view > * > .layer-card-top {
      display: block;
    }
    .xircles .layer-choice-message-view.xircles-button-center {
      text-align: center;
      margin-top: 20px;
    }
    .layer-answer-height-limit {
      height: 450px;
      overflow: hidden;
      -webkit-mask-image: -webkit-gradient(linear, left 63%, left bottom, 
        from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }
  `,
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata
          ? Widths.FLEX
          : Widths.ANY;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
    selectedDocumentId: {
      value: null,
      set: function (value) {},
    },
  },
  methods: {
    render({ isFirstRendering = true } = {}) {
      const view = this;

      // TODO: it's temporary fix. We need to know why this becomes null
      // it becomes null when coming back to the result page from the document(Fachinfo) page
      // Search result -> click block to enter document -> back to the result: becomes null
      if (!view.messageViewer) return;

      const conversationId = view.messageViewer.message.conversationId;
      const conversation = layerClient.getConversation(conversationId);
      // this value can be overwritten by Watson's product detection
      const product = _get(conversation, 'metadata.product');
      /* still needed when we check always for documentId mismatch below?
      const selectedProductPage = _get(
        conversation,
        'metadata.selectedProductPage'
      );*/
      if (!this.properties.selectedDocumentId) {
        this.properties.selectedDocumentId = _get(
          conversation,
          'metadata.documentId'
        );
      }
      let documentId = this.properties.selectedDocumentId;
      // We may have already the document, for example in unfied search
      // In this case, we don't want to force a different document as it's done below
      const messageList = closestComponent(view, 'layer-message-list');
      const currDocumentId = messageList.documentId;
      if (
        isFirstRendering &&
        documentId &&
        !currDocumentId /* Always check for documentId mismatch see DEV-974
        &&
        selectedProductPage &&
        product !== selectedProductPage*/
      ) {
        let isSelectedDocIdInResults = false;
        // if the selected documentId is not found in the block results, we clear it and replace it with the first one
        // because it's likely the user searched other products in a product page.
        // e.g searching "dosierung Voltaren" in "Certican" product page
        for (const result of view.model.results) {
          if (
            result.blocks.length > 0 &&
            result.blocks[0].documentId.toString() === documentId.toString()
          ) {
            isSelectedDocIdInResults = true;
            break;
          }
        }
        if (!isSelectedDocIdInResults) {
          documentId = null;
        }
      }

      // Make sure we fall back to unified document if available
      documentId = documentId || currDocumentId;
      if (!documentId) {
        // if the product(documentId) is not set, we need to get the first documentId as a selected product
        view.model.results.forEach((result) => {
          if (
            !documentId &&
            result.blocks.length > 0 &&
            result.blocks[0].documentId
          ) {
            documentId = result.blocks[0].documentId;
          }
        });
      }
      if (documentId) {
        documentId = documentId.toString();
      }

      let blocksResults = [];
      if (documentId) {
        blocksResults = view.model.results.filter(
          (result) =>
            result.blocks.length > 0 &&
            result.blocks[0].documentId === documentId
        );
      } else {
        blocksResults = view.model.results.filter(
          (result) => result.blocks.length > 0
        );
      }

      const isUnifiedSearch =
        view.model.customData && view.model.customData.isUnifiedSearch;
      // We want to perform some filtering only in case of unified search
      if (isUnifiedSearch) {
        // On async requests this seems to be rendered in parallel or something
        // So no DOM for this component at this point yet
        // Trick by accessing elements up in hierarchy, as done for the drug selector
        messageList.snippets = messageList.snippets || [];
        // Set here current document, we need to check it for further cleanup
        messageList.documentId = documentId;
        // Remove duplicated results blocks from here
        // The reason: We could have full-text search showing blocks we already got from headlines
        // Filter blocks with snippets containing existing snippets or the other way around
        // We can do this more complex if we want to fine tune in the future, but its probably enough
        const snippetTexts = messageList.snippets.map((sp) => {
          const spEl = document.createElement('div');
          spEl.innerHTML = sp;
          return spEl.innerText;
        });
        blocksResults = blocksResults.reduce((acc, res) => {
          const blocks = res.blocks.filter((bl) => {
            const blEl = document.createElement('div');
            blEl.innerHTML = bl.snippet;
            const blText = blEl.innerText;
            return (
              !messageList.snippets.some(
                (sp) => sp.includes(bl.snippet) || bl.snippet.includes(sp)
              ) &&
              !snippetTexts.some(
                (spt) => spt.includes(blText) || blText.includes(spt)
              )
            );
          });
          // Also discard results with no blocks left
          return blocks.length > 0 ? acc.concat(res) : acc;
        }, []);
        // Track blocks into this one so it does not get lost next renderings
        messageList.snippets = messageList.snippets.concat(
          blocksResults.reduce(
            (acc, res) => acc.concat(res.blocks.map((bl) => bl.snippet)),
            []
          )
        );
      }

      const hasBlockResults = blocksResults.length > 0;
      if (documentId && product) {
        // messages can arrive pretty close, so checking the DOM is not fast enough
        // to prevent multiple drug selectors
        this.properties.selectedDocumentId = parseInt(documentId, 10);
        const messageList = closestComponent(view, 'layer-message-list');
        if (!messageList.hasDrugSelector) {
          messageList.hasDrugSelector = true;
          const docIdsMapWithMatch = view.model.results.reduce(
            (acc, result) => {
              if (result.blocks.length) {
                const docId = result.blocks[0].documentId.toString();
                acc[docId] = true;
              }
              return acc;
            },
            {}
          );
          if (!isOperator(Layer.client.user)) {
            view.createElement('xircles-select-drug-view', {
              model: {
                documentId,
                product,
                parts: [],
                on: () => {},
                expandOnCreate: !hasBlockResults,
                docIdsMapWithMatch,
              },
              parentNode: this,
            });
          }
        }
      }

      this.blockElements.forEach((elem) => {
        this.removeChild(elem);
      });
      this.blockElements = [];

      const checkNothingFoundHeadlineMatch = (
        time = WAIT_FOR_ANSWER_TIMEOUT_MS
      ) => {
        const headlineMatchResultElm = document.querySelectorAll(
          'xircles-results-message-view'
        )[0];
        // wait for headline match result to deal with nothing found
        if (!headlineMatchResultElm) {
          return setTimeout(
            () =>
              checkNothingFoundHeadlineMatch(
                time - WAIT_FOR_ANSWER_INTERVAL_MS
              ),
            WAIT_FOR_ANSWER_INTERVAL_MS
          );
        }
        const nothingFoundHM = !headlineMatchResultElm.blockElements.length;
        if (!isUnifiedSearch || nothingFoundHM) {
          const textElem = this.addSimpleChatBubble(
            view,
            i18next.t('QUESTION_ANSWERS_NOT_FOUND_SELECT_ANOTHER')
          );
          this.blockElements.push(textElem);
        }
        this.setVisibleMessageChats(false, isUnifiedSearch && !nothingFoundHM);
      };

      if (hasBlockResults) {
        if (!isAnalyticsEventTriggered) {
          isAnalyticsEventTriggered = true;
        }
        blocksResults.forEach((result, i) => {
          if (i === RESULT_MESSAGE_LIMIT) {
            const btnElm = this.addXirclesButton(
              view,
              'showMore',
              i18next.t('RESULTS_SHOW_MORE')
            );
            this.blockElements.push(btnElm);
            btnElm.addEventListener(
              'ontouchstart' in document.documentElement
                ? 'touchstart'
                : 'click',
              () => {
                btnElm.classList.add('xircles-hide');
                const hidden = view.querySelectorAll(
                  'xircles-blocks-result-message-view.xircles-hide'
                );
                for (let j = 0; j < hidden.length; j++) {
                  hidden[j].classList.remove('xircles-hide');
                }
              }
            );
          }
          const elem = view.createElement(
            'xircles-blocks-result-message-view',
            { model: result, parentNode: this, conversationId }
          );

          if (i >= RESULT_MESSAGE_LIMIT) {
            elem.classList.add('xircles-hide');
          }
          this.blockElements.push(elem);
        });
        this.setVisibleMessageChats(true);
      } else {
        checkNothingFoundHeadlineMatch();
      }

      if (isUnifiedSearch) {
        const answerMessageContainer = document.getElementsByTagName(
          'layer-standard-message-view-container'
        );
        const layerMessageList = document.querySelector('layer-message-list');
        const createButtons = () => {
          const moreBtnElm = this.addXirclesButton(
            view,
            'showMore',
            i18next.t('RESULTS_SHOW_MORE')
          );
          const lessBtnElm = this.addXirclesButton(
            view,
            'lessMore',
            i18next.t('RESULTS_SHOW_LESS')
          );
          this.blockElements.push(moreBtnElm, lessBtnElm);
          return {
            moreBtnElm,
            lessBtnElm,
          };
        };

        // also observe empty search results to deal with partly nothing founds
        const observerFunc = function (
          mutations,
          obs,
          time = WAIT_FOR_ANSWER_TIMEOUT_MS
        ) {
          if (
            document.contains(
              answerMessageContainer[1] && answerMessageContainer[2]
            )
          ) {
            // wait for full text search result to not to deal with nothing found
            if (
              time > 0 &&
              document.querySelectorAll('xircles-results-message-view').length <
                2
            ) {
              return setTimeout(
                () => observerFunc(time - WAIT_FOR_ANSWER_INTERVAL_MS),
                WAIT_FOR_ANSWER_INTERVAL_MS
              );
            }
            const fullTextSearchResultElm = document.querySelectorAll(
              'xircles-results-message-view'
            )[1];
            const nothingFoundFTS =
              fullTextSearchResultElm &&
              !fullTextSearchResultElm.blockElements.length;
            const actualAnswerHeight =
              answerMessageContainer[1].offsetHeight +
              answerMessageContainer[2].offsetHeight;
            if (!nothingFoundFTS && actualAnswerHeight >= ANSWER_HEIGHT) {
              const { moreBtnElm, lessBtnElm } = createButtons();
              const answerMessageBlock =
                answerMessageContainer[2].nodes.UIContainer.querySelector(
                  '.block-result-message'
                );
              answerMessageBlock.classList.add('layer-answer-height-limit');
              lessBtnElm.classList.add('xircles-hide');

              moreBtnElm.addEventListener(
                'ontouchstart' in document.documentElement
                  ? 'touchstart'
                  : 'click',
                () => {
                  moreBtnElm.classList.add('xircles-hide');
                  answerMessageBlock.classList.remove(
                    'layer-answer-height-limit'
                  );
                  lessBtnElm.classList.remove('xircles-hide');
                }
              );

              lessBtnElm.addEventListener(
                'ontouchstart' in document.documentElement
                  ? 'touchstart'
                  : 'click',
                () => {
                  lessBtnElm.classList.add('xircles-hide');
                  answerMessageBlock.classList.add('layer-answer-height-limit');
                  moreBtnElm.classList.remove('xircles-hide');
                }
              );
            }
            observer.disconnect();
          }
        };
        const observer = new MutationObserver(observerFunc);
        this.observer = observer;

        observer.observe(layerMessageList, {
          attributes: false,
          childList: true,
          characterData: false,
          subtree: true,
        });
      }
    },
    addXirclesButton(view, id, title) {
      const div = document.createElement('layer-action-button');
      div.classList.add('layer-choice-message-view');
      div.classList.add('xircles-button-center');
      view.appendChild(div);
      div.innerHTML = `<button class="layer-button" layer-id="button-${id}" tab-index="0" title="${title}."></button>`;
      view.nodes[id] = div.firstChild;
      view.nodes[id].innerHTML = title;
      return div;
    },
    addSimpleChatBubble(view, text) {
      const div = document.createElement('span');
      div.classList.add('layer-message-type-view');
      div.classList.add('simple-chat-bubble');
      div.innerHTML = text;
      view.appendChild(div);
      return div;
    },
    setVisibleMessageChats(isVisible, isUnifiedSearch) {
      // Unfortunately, this element is not available in DOM yet, so we need to use setTimeout
      setTimeout(() => {
        const messageItems = Array.from(
          document.getElementsByTagName('layer-text-message-view')
        );
        if (isUnifiedSearch) {
          // keep top msg always visible
          messageItems.shift();
        }
        messageItems.forEach((item) => {
          item.hidden = !isVisible;
        });
      }, 0);
    },
    onAfterCreate() {
      this.blockElements = [];

      const resultsMessageSource = _get(this, 'model.customData.source', '');
      const conversationId = this.model.message.conversationId;

      const conversation = layerClient.getConversation(conversationId);
      const question = _get(conversation, 'metadata.conversationName', '');
      const drug = _get(conversation, 'metadata.product');

      if (resultsMessageSource !== 'full-text-search') {
        document.dispatchEvent(
          new CustomEvent('tr-custom-event', {
            detail: {
              drug,
              question,
              conversationId,
              name: 'answer-result',
              location: window.location.href,
              elementId: '-',
              suggestion: '-',
              text: '-',
              id: '-',
            },
          })
        );
      } else {
        document.dispatchEvent(
          new CustomEvent('tr-custom-event', {
            detail: {
              conversationId,
              question,
              name: 'full-text-search-result',
            },
          })
        );
      }

      window.addEventListener('select-drug', this.handleSelectDrug(this));
      this.render();
    },
    onDestroy() {
      this.observer && this.observer.disconnect();
      window.removeEventListener('select-drug', this.handleSelectDrug(this));
    },
    handleSelectDrug: (widget) => (e) => {
      const documentId = _get(e, 'detail.selectedProduct.id');
      // Cleanup snippets used for unified search duplicated blocks
      const messageList = closestComponent(widget, 'layer-message-list');
      // Only do it if on a new document from durg slector (or new navigation)
      if (messageList.documentId !== `${documentId}`) {
        messageList.snippets = [];
      }
      if (widget.properties.selectedDocumentId !== documentId) {
        widget.properties.selectedDocumentId = documentId;
        widget.render({ isFirstRendering: false });
      }
    },
  },
});
