export const HTTP_STATUSES = {
  NOT_FOUND: {
    code: 404,
    camelCaseMessage: 'notFound',
  },
  TOO_MANY_REQUESTS: {
    code: 429,
    camelCaseMessage: 'tooManyRequests',
  },
};
