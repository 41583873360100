import React from 'react';
import { withTranslation } from 'react-i18next';

import conversationManager from '../../hocs/conversationManager';
import './styles.less';

const TEST_KEYWORD = '@test';

class AutoSuggest extends React.Component<Props, State> {
  componentWillReceiveProps(nextProps) {
    if (this.props.newConversationId !== nextProps.newConversationId) {
      const conversationDetail = this.props.pxInfo
        ? {
            prevLocation: this.props.history.location.pathname,
            pxColor: {
              primaryColor: this.props.pxInfo.page_data.primary_color,
              onBackgroundHighlightColor:
                this.props.pxInfo.page_data.on_background_highlight_color,
              onBackgroundHighlightTextColor:
                this.props.pxInfo.page_data.on_background_highlight_text_color,
            },
          }
        : {
            prevLocation: this.props.history.location.pathname,
          };

      this.props.history.push({
        pathname: `/questions/${nextProps.newConversationId}`,
        state: {
          detail: conversationDetail,
        },
      });
    }
  }

  handleSuggestionTitleContent = (title) => title.replace(/<[^>]*>?/gm, '');

  // do not use this function if it is needed to display compound tag/s
  removeTaggedData = (data) => data.replace(/<tagged>[\s\S]*<\/tagged>/g, '');

  getSuggestionValue = (target) => {
    const suggestion = target && target.getAttribute('value');
    return suggestion || this.getSuggestionValue(target.parentNode);
  };

  getSuggestionWithTestKeyword = (searchInput, suggestion, keyword) => {
    const searchInputParts = searchInput.split(' ');
    const keywordPart = searchInputParts.find((part) =>
      part.toLowerCase().includes(keyword)
    );

    return `${keywordPart} ${suggestion}`;
  };

  handleSuggestionClick = (e) => {
    if (
      e.target.tagName === 'UL' ||
      e.target.nodeName === 'PLACEHOLDER' ||
      Array.from(e.target.childNodes).find((c) => c.nodeName === 'PLACEHOLDER')
    ) {
      return;
    }
    this.props.onLoading();

    const suggestion = this.getSuggestionValue(e.target);

    if (suggestion) {
      const message = this.props.searchInput
        .toLowerCase()
        .includes(TEST_KEYWORD)
        ? this.getSuggestionWithTestKeyword(
            this.props.searchInput,
            suggestion,
            TEST_KEYWORD
          )
        : suggestion;

      document.dispatchEvent(
        new CustomEvent('tr-custom-event', {
          detail: {
            suggestion,
            name: 'autosuggest-submit',
            drug: this.props.brandName,
            location: window.location.href,
            conversationId: '-',
            elementId: '-',
            question: message,
            text: '-',
            id: '-',
          },
        })
      );
      this.props.createConversation(message, {
        isPX: this.props.isPX,
        goBackPath: this.props.history.location.pathname,
        product: this.props.productName,
        selectedProductPage: this.props.productName,
        documentId: this.props.documentId,
      });
    }
  };

  handleOpenDocCheck = (e) => {
    e.preventDefault();
    let { pathname } = this.props.history.location;
    if (pathname.endsWith('/')) pathname = pathname.slice(0, -1);
    this.props.history.push(pathname + '/doccheck');
  };

  prepareSuggestionTitle = (title, pxInfo) => {
    const regex = new RegExp(/<drug>(.*)<\/drug>/gm);

    if (pxInfo) {
      const result = regex.exec(title);

      if (result) {
        const [, drugContent] = result;
        return title.replace(
          regex,
          `<drug style="background-color: ${pxInfo.page_data.on_background_highlight_color}">${drugContent}</drug>`
        );
      }

      return title;
    }

    return title;
  };

  render() {
    const {
      t,
      brandName,
      searchInput,
      minLengthStartSearch,
      suggestions,
      isAutoSuggestEnabled,
      pxInfo,
    } = this.props;

    const showSuggestions =
      searchInput.length >= minLengthStartSearch &&
      suggestions.length > 0 &&
      isAutoSuggestEnabled;
    const firstExampleCustomized = brandName
      ? t('COMMON_AUTOSUGGEST_EXAMPLE_1').replace('{{productName}}', brandName)
      : t('COMMON_AUTOSUGGEST_EXAMPLE_1').replace(
          '{{productName}}',
          t('COMMON_DEMO_BRAND_1')
        );
    const secondExampleCustomized = brandName
      ? t('COMMON_AUTOSUGGEST_EXAMPLE_2').replace('{{productName}}', brandName)
      : t('COMMON_AUTOSUGGEST_EXAMPLE_2').replace(
          '{{productName}}',
          t('COMMON_DEMO_BRAND_2')
        );

    return (
      <div className="auto-suggest">
        {!showSuggestions && (
          <ul className="auto-suggest__example">
            <li>{t('COMMON_SEARCH_SPECIFIC_INFORMATION')}</li>
            <li className="auto-suggest__question-example">
              {firstExampleCustomized}
            </li>
            <li className="auto-suggest__ask-question">
              {t('COMMON_YOU_MAY_ASK_A_QUESTION')}
            </li>
            <li className="auto-suggest__question-example">
              {secondExampleCustomized}
            </li>
            {!isAutoSuggestEnabled && (
              <li className="auto-suggest__doc-check">
                <span>{t('COMMON_AUTOSUGGEST_TO_ENABLE_TEXT')}</span>
                <button onClick={this.handleOpenDocCheck}>
                  {t('COMMON_AUTOSUGGEST_TO_ENABLE_BUTTON')}
                </button>
              </li>
            )}
          </ul>
        )}
        {showSuggestions && (
          <ul
            id="auto-suggest-search-results"
            className="auto-suggest__search-results"
            onClick={this.handleSuggestionClick}
          >
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  key={`suggestion-${index}`}
                  className={`auto-suggest__search-result ${
                    suggestion.title.includes('<placeholder>') && 'disabled'
                  }`}
                  value={this.handleSuggestionTitleContent(
                    this.removeTaggedData(suggestion.title)
                  )}
                  dangerouslySetInnerHTML={{
                    __html: this.prepareSuggestionTitle(
                      suggestion.title,
                      pxInfo
                    ),
                  }}
                ></li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

const AutoSuggestWithConversationManager = conversationManager(AutoSuggest);
export default withTranslation()(AutoSuggestWithConversationManager);
