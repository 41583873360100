import Layer from '@layerhq/web-xdk';

import './xircles-content-card-message-model';
import lineClamp from 'line-clamp';
import closest from '../../polyfills/closest';
import i18next from 'i18next';

import config from '../../config.json';
import { isHcpOrOperator } from '../../utils/user';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-content-card-message-view', {
  mixins: [MessageViewMixin],
  template: `<div>
                   <h6 layer-id='header'></h6>
                   <p layer-id='text'></p>
                   <a layer-id='link'></a>
                   <i class="xircles-icons icon-send" />
                </div>`,
  properties: {
    widthType: {
      get: function get() {
        return Widths.FULL;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    _triggerHcpCheck() {
      const conversationId = this.mainComponent.conversationId;
      this.trigger('xircles-question-send', {
        conversationId,
        text: `${i18next.t('REGISTER_NEED_HCP_CHECK')}`,
        slug: `${config.StoryblokRootFolder}/${config.HcpCheckSlug}`,
        data: {
          url: this.model.url.replace(/^\/documents\//, ''),
          title: this.model.title,
        },
      });
    },
    _openHcpCheckModal() {
      window.dispatchEvent(
        new CustomEvent('change-route', {
          detail: {
            url: window.location.pathname + '/doccheck',
          },
        })
      );
    },
    _updateContent() {
      let dbg = this.model.score;
      if (this.model.matchedTags && this.model.matchedTags.length) {
        dbg = this.model.matchedTags.join(', ');
      }

      this.nodes.header.innerHTML = `${this.model.text} ${
        config.Debug ? `<sup><em>${dbg}</em></sup>` : ''
      }`;
      this.title = `${config.Debug ? `${dbg}` : ''}`;
    },
    onRerender() {
      this.needHcpCheck =
        this.model.onlyForHcp && !isHcpOrOperator(Layer.client.user);

      this.classList.toggle('only-for-hcp', this.needHcpCheck);
      this.nodes.header.innerHTML = this.model.text;
      this.nodes.text.innerHTML = this.needHcpCheck
        ? `<em><br/>${i18next.t('ANSWER_FOR_HCP_ONLY')}<br/><br/></em>`
        : this.model.text;
      this.nodes.link.innerHTML = this.needHcpCheck ? '' : this.model.linkText;

      this._updateContent();

      setTimeout(() => {
        const card = closest(this, '.xircles-content-card-message-view');
        card.style.minWidth = '235px';
        card.style.maxWidth = '235px';
        lineClamp(this.querySelector('[layer-id="text"]'), 3);
      }, 0);

      this.model.customData &&
        this.model.customData.isOperator &&
        this.classList.add('xircles-content-card-message-view__operator');
    },
    onAfterCreate() {
      const view = this;
      view._updateContent();
      const card = closest(view, '.xircles-content-card-message-view');
      card.addClickHandler('open-link', card, () => {
        // _triggerHcpCheck() was the old approach with using chat flow
        // this._triggerHcpCheck(v.model)
        if (view.needHcpCheck) {
          this._openHcpCheckModal();
        } else {
          dispatchEvent(
            new CustomEvent('change-route', {
              detail: { url: view.model.url },
            })
          );
        }
      });
      card.style.minWidth = '235px';
      card.style.maxWidth = '235px';
      card.style.height = '105px';
      Layer.client.user.on('identities:change', () => view.onRerender());
    },
  },
});
