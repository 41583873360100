import INTERNAL_STATUS from '../../const/status/internal-status';
import INTERNAL_OPERATOR_STATUS from '../../const/status/internal-operator-status';

export default (status) => {
  switch (status) {
    case INTERNAL_STATUS.NOT_FINISHED:
      return INTERNAL_OPERATOR_STATUS.NOT_FINISHED;
    case INTERNAL_STATUS.REFRAMED:
      return INTERNAL_OPERATOR_STATUS.REFRAMED;
    case INTERNAL_STATUS.FORWARD_REQUESTED:
      return INTERNAL_OPERATOR_STATUS.FORWARD_REQUESTED;
    case INTERNAL_STATUS.FORWARDED:
      return INTERNAL_OPERATOR_STATUS.FORWARDED;
    case INTERNAL_STATUS.TAKEOVER:
      return INTERNAL_OPERATOR_STATUS.TAKEOVER;
    case INTERNAL_STATUS.ANSWER_DELIVERED:
      return INTERNAL_OPERATOR_STATUS.ANSWER_DELIVERED;
    case INTERNAL_STATUS.HELPFUL:
      return INTERNAL_OPERATOR_STATUS.HELPFUL;
    case INTERNAL_STATUS.CLOSED:
      return INTERNAL_OPERATOR_STATUS.CLOSED;
    case INTERNAL_STATUS.DELETED:
      return INTERNAL_OPERATOR_STATUS.DELETED;
    default:
      return status;
    //TODO throw an error
  }
};
