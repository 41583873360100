// @flow
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/**
 * Root level of the React application.
 *
 * For a typical application, this would contain access to a lot of your own UI components, one or more of which might contain Layer XDK Components.
 */
import React from 'react';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import themeSettings from './polyfills';
import DocCheckTarget from './components/DocCheck/DocCheckTarget';
import Video from './components/Video';
import LongCopy from './components/LongCopy/index';
import LayerContext from './components/LayerContext';
import CommonContext from './components/CommonContext';
import HelmetWrapper from './components/HelmetWrapper';
import MMIBanner from './components/MMIBanner';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _get from 'lodash/get';

import './App.less';
import conversationManager from './hocs/conversationManager';
import ProductPageSwitch from './components/ProductPageSwitch';
import QuestionPageContainer from './containers/QuestionPageContainer';
import ProductPageContainer from './containers/ProductPageContainer';
import LoginModalContainer from './containers/LoginModalContainer';
import ShareModalContainer from './containers/ShareModalContainer';
import OperatorModalContainer from './containers/OperatorModalContainer';
import InfoModalContainer from './containers/InfoModalContainer';
import SelectModalContainer from './containers/SelectModalContainer';
import SplashScreenPage from './components/SplashScreen/index';
import BrowserCheck from './components/BrowserCheck/index';
import StartPage from './containers/StartPageContainer';
import DeviceOrientation, { Orientation } from './hocs/orientation';
import {
  convertStringToHtml,
  getAppUrl,
  getCodeParam,
  getEmailParam,
  getIOSVersion,
  getOriginalPathname,
  getPathnameWithoutModal,
  getRedirectTypeParam,
  isConversationRoute,
  isConversationModalRoute,
  isCrawler,
  isDeletedRequestsRoute,
  isDocumentsRoute,
  isDocumentsModalRoute,
  isDrugCatalogueRoute,
  isUUID,
  isIE,
  isIncludesEmail,
  isInsideFrame,
  isIOS,
  isMobileDevice,
  isModalWindowRoute,
  isQuestionsRoute,
  isReactSnap,
  normalizePathname,
  slugify,
  prepareMmiExternalUrl,
  unescapeHTML,
} from './utils/common';
import { isOperator, isExpert, isHcp } from './utils/user';
import {
  checkUserStatus,
  getAllDictionaries,
  getDrugInfos,
  updateSession,
  getIdentityById,
  wrapCatch,
  updateUser,
} from './utils/api';
import { USER_STATUSES } from './const/status/user-status';
import { CONVERSATION_ACTIONS } from './const/conversation-actions';
import XirclesStatus from './const/status/xircles-status';
import { ENTER_KEYSTROKE_PATHS } from './const/enter-keystroke-paths';
import { WELCOME_MODAL_TYPES } from './const/welcome-modal-types';
import NeedToLoginModalContainer from './containers/NeedToLoginModalContainer';
import config from './config.json';
import DocumentPageContainer from './containers/DocumentPageContainer';
import AboutPageContainer from './containers/AboutPageContainer';
import DrugsCataloguePageContainer from './containers/DrugsCataloguePageContainer';
import persistUser from './common/persistUser';
import { Layer } from './get-layer';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTime: true,
      pxColor: undefined,
      appUrl: isIE() ? window.location.origin : window.origin,
      navigationType: undefined,
      prevLocation: '/',
      prevConversation: undefined,
      isInsideIframe: false,
      isInsideWidget: false,
      mmiBannerDate: '',
      mmiExternalUrl: '',
      hasDocumentConversation: false,
      isMMIBannerMessageVisible: false,
      isTriggerGtmEventsFromApp: false,
      isLoaded: isCrawler() || isReactSnap(),
    };
  }

  t = this.props.t;

  isIOS12 = false;
  KEY_CODE_ENTER = 13;
  parentLocation = undefined;
  redirectShareModal = undefined;
  isSuccessLogin = false;
  expertFeedbackStatus = [
    XirclesStatus.EXPERT_POSITIVE,
    XirclesStatus.EXPERT_NEGATIVE,
  ];
  gtmReportEvents = ['accept-data-privacy', 'accept-general-terms'];

  getHashParam = (url) => {
    const [hash] =
      url.match(
        /#[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
      ) || [];
    return hash;
  };

  // We can't use history.push at layer mixin so customEvent "change-route" was created (see LinkMessageViewMixin)
  changeRoute = (evt) => {
    if (!window.navigator.onLine) {
      return;
    }

    if (
      evt.detail.url.substring(0, 4) === 'http' ||
      evt.detail.url.substring(0, 4) === 'blob'
    ) {
      const win = window.open(evt.detail.url, '_blank');
      win.focus();
    } else {
      const hash = this.getHashParam(evt.detail.url);
      const preparedPathname = hash
        ? evt.detail.url.replace(hash, '')
        : evt.detail.url;

      const [, , path] = preparedPathname.split('/');

      const redirectMethod = evt.detail.type
        ? this.props.history[evt.detail.type]
        : !evt.detail.type &&
          !isDocumentsRoute(preparedPathname) &&
          this.state.brands.findIndex((brand) => slugify(brand) === path) > 0
        ? this.props.history.replace
        : this.props.history.push;

      redirectMethod({
        pathname: preparedPathname,
        hash,
        state: { detail: evt.detail.state },
      });
    }
  };

  sendOperatorViewMessageIframe = () => {
    if (isOperator(this.props.layerClient.user) && window.parentIFrame) {
      window.parentIFrame.sendMessage({ id: 'not-embed-view' });
    }
  };

  closeWelcomeModal = () => {
    if (this.redirectShareModal && this.redirectShareModal.isRedirect) {
      const { conversationId, hash, title, shareUrl } = this.redirectShareModal;

      this.props.history.replace({
        pathname: shareUrl,
        hash,
        state: { detail: { conversationId, title } },
      });
    } else {
      if (this.props.history.length === 1) {
        this.props.history.push('/');
      } else {
        const path =
          getPathnameWithoutModal(this.props.history.location.pathname) || '/';
        this.props.history.replace({
          pathname: path,
          hash: this.props.history.location.hash,
        });
      }
    }

    this.sendOperatorViewMessageIframe();
  };

  handleRedirectShareModal = ({ detail }) =>
    (this.redirectShareModal = { ...detail });

  handleOperatorFilterSelection = (operatorFilterSelected) =>
    this.setState({ operatorFilterSelected });

  showShareConversation = (evt) => {
    const { conversationId, title } = evt.detail;
    this.props.history.push({
      pathname: window.location.pathname + '/conversation/share',
      state: { detail: { conversationId, title } },
    });
  };

  isAuthenticated;
  isOnlyEmbeddedAccessible = isReactSnap()
    ? false
    : config.OnlyEmbeddedAccessible;
  passwordProtected = isReactSnap() ? false : config.PasswordProtected;

  componentWillMount(): void {
    this.isAuthenticated = this.props.cookies.get('xir-test-login-success');

    const [version] = getIOSVersion();
    if (version && version === 12) {
      this.isIOS12 = true;
    }

    this.props.history.listen(({ pathname, hash, search }) => {
      if (
        this.state.isMMIBannerMessageVisible &&
        !isConversationRoute(pathname) &&
        !isConversationModalRoute(pathname) &&
        !isDocumentsRoute(pathname) &&
        !isDocumentsModalRoute(pathname)
      ) {
        this.setState({
          mmiBannerDate: '',
          mmiExternalUrl: '',
          isMMIBannerMessageVisible: false,
          hasDocumentConversation: false,
        });
      }

      if (window.dataLayer) {
        window.dataLayer.push({ event: 'pageview' });
      }

      if ('parentIFrame' in window) {
        if (
          !isModalWindowRoute(this.state.prevLocation) &&
          isMobileDevice() &&
          isInsideFrame()
        ) {
          window.parentIFrame.autoResize(false);
        }

        if (this.parentLocation) {
          const redirectPath = this.updateParentUrl(
            pathname,
            hash,
            search,
            this.parentLocation
          );

          if (search.length) {
            this.handleChildSearchParams(search, redirectPath);
            this.handleReleaseIdParam({ search });
          }
        }

        if (this.parentHeight && !isMobileDevice()) {
          this.updateBodyHeight(pathname);
        }
      }

      if (
        isQuestionsRoute(pathname) &&
        isConversationRoute(this.state.prevLocation)
      ) {
        window.timeLeaveAnswerPage = new Date();
      }
    });

    if (isInsideFrame()) {
      const [body] = document.getElementsByTagName('body');
      body && body.classList.add('hide-scrollbar');
    }
  }

  handleEnterKeystrokePopUps = (keyCode) => {
    if (keyCode === this.KEY_CODE_ENTER) {
      const path = this.props.location.pathname.split('/').pop();

      if (_includes(ENTER_KEYSTROKE_PATHS, path)) {
        const submitButton = document.querySelector('[data-keystroke="true"]');

        if (submitButton) {
          submitButton.focus();
        }
      }
    }
  };

  handleKeydown = ({ keyCode }) => {
    this.handleEnterKeystrokePopUps(keyCode);
  };

  handleMessage = (event) => {
    if (event.data.length > 0 && event.data.includes('[iFrameSizer]message:')) {
      this.sendOperatorViewMessageIframe();
      this.handleIFrameMessages(event.data);
    }
  };

  handleAppClick = () => {
    if (
      window.parentIFrame &&
      !isConversationRoute(this.props.location.pathname) &&
      !isDocumentsRoute(this.props.location.pathname)
    ) {
      const [widgetBackButton] = document.getElementsByClassName(
        'widget__back-button'
      );
      widgetBackButton &&
        widgetBackButton.classList.remove('widget__back-button--hidden');

      if (document.body) {
        const height =
          this.state.isInsideWidget && !isMobileDevice()
            ? 612
            : this.parentHeight;
        document.body.style.height = height + 'px';
      }

      clearAllBodyScrollLocks();
      window.parentIFrame.sendMessage({
        id: 'open-widget',
        height: document.body.clientHeight,
      });
    }
  };

  handleContentEditableClick = (target) => {
    return target.parentNode &&
      target.parentNode.getAttribute('id') === 'editable'
      ? target.parentNode
      : target;
  };

  isParentTracked = (target) => {
    return (
      target.parentNode &&
      target.parentNode.dataset &&
      target.parentNode.dataset.trEvent
    );
  };

  getElementId = (target) => {
    const id = target.getAttribute('id');
    return !id ? target.parentNode && target.parentNode.getAttribute('id') : id;
  };

  handleClickTrackingEvent = (event) => {
    const target =
      event.type === 'tr-event' ? event.detail.target : event.target;
    const isTrackedEvent = target && target.getAttribute('data-tr-event');

    if (
      (isTrackedEvent ||
        this.isParentTracked(target) ||
        event.type === 'tr-event' ||
        (event.toElement && event.toElement.localName === 'textarea') ||
        (event.target && event.target.localName === 'textarea')) &&
      target &&
      window.parentIFrame
    ) {
      const updatedTarget = this.handleContentEditableClick(target);
      const id = this.getElementId(updatedTarget);

      const { target: originalTarget, ...restDetails } = event.detail;

      const updatedDetails = this.gtmReportEvents.includes(id)
        ? {
            ...restDetails,
            conversationId: '-',
            suggestion: '-',
            question: '-',
            name: '-',
            text: '-',
            drug: '-',
            id: '-',
          }
        : {
            ...restDetails,
          };

      const message = {
        id: 'tr-event-click',
        content: {
          event: 'gtm.click',
          target: updatedTarget.outerHTML,
          detail: {
            id,
            location: window.location.href,
            ...updatedDetails,
          },
        },
      };

      if (this.state.isTriggerGtmEventsFromApp) {
        message.content.detail.parentLocation = window.location.href;
        message.content.detail.timestamp = new Date().toISOString();
        message.content.detail.eventId = Layer.Utils.generateUUID();

        window.dataLayer.push({
          detail: message.content.detail,
          event: message.content.event,
          'gtm.element': convertStringToHtml(message.content.target),
          'gtm.detail': undefined,
        });
      } else {
        window.parentIFrame.sendMessage(message);
      }
    }
  };

  handleScrollIframeVersion = () => {
    window.parentIFrame.sendMessage({
      id: 'scroll-event',
      content: { pageYOffset: window.pageYOffset },
    });
  };

  getEventValue = (detail) =>
    detail.model.actionModels.find(
      (model) => model.data.id === detail.data.id
    ) || {};

  handleCustomTrackingEvent = ({ type, name, detail }) => {
    const updatedType =
      type === 'content-editable-blur'
        ? 'blur'
        : type === 'tr-custom-event'
        ? name
        : type;
    const updatedDetail =
      type === 'xircles-choice-reframe' || type === 'xircles-choice-cancel'
        ? { ...this.getEventValue(detail) }
        : detail;

    const message = {
      id: 'tr-event-custom',
      content: { type: updatedType, detail: updatedDetail },
    };

    if (this.state.isTriggerGtmEventsFromApp) {
      const eventType = message.content.type || message.content.detail.name;
      window.dataLayer.push({ 'gtm.detail': undefined, detail: undefined });
      message.content.detail.parentLocation = window.location.href;
      message.content.detail.timestamp = new Date().toISOString();
      message.content.detail.eventId = Layer.Utils.generateUUID();
      document.dispatchEvent(
        new CustomEvent(`${eventType}-app`, { detail: message.content.detail })
      );
    } else {
      window.parentIFrame.sendMessage(message);
    }
  };

  getLocationSource = (location) => {
    const { hash, href, search } = location;

    const selectedLocationSource = [
      {
        type: 'hash',
        value: hash,
      },
      {
        type: 'search',
        value: search,
      },
      {
        type: 'href',
        value: href,
      },
    ].find((current) =>
      current.type === 'hash'
        ? !_isEmpty(current.value) && !isUUID(current.value)
        : !_isEmpty(current.value)
    );

    const { value: locationSource } = selectedLocationSource;
    return locationSource;
  };

  getPathnameFromLocation = (location) => {
    const locationSource = this.getLocationSource(location);

    const pathname = {
      [true]: '',
      [locationSource.includes('?xrcl=')]: locationSource.replace('?xrcl=', ''),
      [locationSource.includes('#')]: locationSource.replace('#', ''),
      [locationSource.includes('/xrcl')]: locationSource.substring(
        locationSource.indexOf('/xrcl') + '/xrcl'.length,
        locationSource.length
      ),
      [locationSource.includes('/questions')]: locationSource.substring(
        locationSource.indexOf('/questions'),
        locationSource.length
      ),
    };

    return pathname[true];
  };

  isConfirmationLink = (pathname) => {
    return pathname.includes('code') && isIncludesEmail(pathname);
  };

  handleConfirmationLink = (pathname) => {
    const email = getEmailParam(pathname);
    const code = getCodeParam(pathname);
    const redirectType = getRedirectTypeParam(pathname);

    return {
      url: `/questions/${redirectType}`,
      redirectType,
      email,
      code,
    };
  };

  handleIFrameMessages = (message) => {
    const parsedMessage = JSON.parse(
      message.replace('[iFrameSizer]message:', '')
    );

    const handleInitFrame = async ({ content }) => {
      const {
        isServiceWidget,
        parentHeight,
        parentLocation,
        navigationType,
        embedAttributes,
        isProductWidget,
        isValidGtmId,
        product: productParam,
      } = content;

      if (!isValidGtmId && config.GtmContainerId.match(/[A-Z0-9]{7}/)) {
        const tagManagerArgs = {
          gtmId: `GTM-${config.GtmContainerId}`,
        };

        TagManager.initialize(tagManagerArgs);
        this.setState({ isTriggerGtmEventsFromApp: true });
      }

      if (productParam && this.state.brands) {
        const productParamLowerCase = productParam.toLowerCase();
        const isProductExists = this.state.brands.find(
          (brand) => brand.toLowerCase() === productParamLowerCase
        );

        if (isProductExists) {
          this.props.history.push(
            `/medikamente/${slugify(productParamLowerCase)}`
          );
        }
      }

      this.parentLocation = parentLocation;
      this.parentHeight = parentHeight;

      if (embedAttributes && embedAttributes.hasOwnProperty('data-product')) {
        const product = unescapeHTML(_get(embedAttributes, 'data-product', ''));
        if (!_isEmpty(product)) {
          const { data } = await wrapCatch(getDrugInfos(product));
          const defaultDocument = _get(data, 'documents[0]');

          if (defaultDocument) {
            this.setState({
              parentDocumentId: defaultDocument.id,
              parentDocumentBrandSlug: slugify(defaultDocument.brand),
            });
          }
        }
      }

      this.setState(
        {
          isInsideIframe: true,
          appUrl: isProductWidget
            ? getAppUrl()
            : parentLocation.origin + parentLocation.pathname + '?xrcl=',
          navigationType,
        },
        () => {
          window.appUrl = this.state.appUrl;
          window.addEventListener('click', this.handleClickTrackingEvent);
          window.addEventListener('scroll', this.handleScrollIframeVersion);
          window.addEventListener('tr-event', this.handleClickTrackingEvent);
          window.addEventListener(
            'xircles-choice-reframe',
            this.handleCustomTrackingEvent
          );
          window.addEventListener(
            'xircles-choice-cancel',
            this.handleCustomTrackingEvent
          );
          //
          document.addEventListener(
            'tr-custom-event',
            this.handleCustomTrackingEvent
          );
          document.addEventListener(
            'xircles-choice-send-track',
            this.handleCustomTrackingEvent
          );
        }
      );

      if (document.body && isMobileDevice() && !isProductWidget) {
        document.body.style.height = content.parentHeight + 'px';

        if (this.isIOS12) {
          const [wrapper] = document.getElementsByClassName('wrapper');
          if (wrapper) {
            wrapper.style.height = content.parentHeight + 'px';
          }
        }
      }

      if (document.body && isServiceWidget) {
        document.body.classList.add('service-widget');
      }

      const pathname = this.getPathnameFromLocation(this.parentLocation);

      const loadingIndicatorDelay = isProductWidget ? 0 : 600;

      setTimeout(() => {
        this.setState({ isLoaded: true });
        this.hideLoadingSpinner();
      }, loadingIndicatorDelay);

      if (this.isConfirmationLink(pathname)) {
        const { code, email, redirectType, url } =
          this.handleConfirmationLink(pathname);
        const { data } = await wrapCatch(checkUserStatus({ query: email }));

        const modalType = {
          [USER_STATUSES.UNKNOWN]: WELCOME_MODAL_TYPES.REGISTER,
          [USER_STATUSES.KNOWN]:
            redirectType === 'code'
              ? WELCOME_MODAL_TYPES.LOGIN
              : WELCOME_MODAL_TYPES.CREATE_PASSWORD,
          [USER_STATUSES.PASSWORD]: WELCOME_MODAL_TYPES.RESET_PASSWORD,
        };

        const selectModalType = modalType[data.data];

        return (
          url &&
          this.props.history.push({
            pathname: url,
            state: {
              detail: {
                code,
                email,
                isAutoSubmit: true,
                modalType: selectModalType,
              },
            },
          })
        );
      } else {
        if (isIE() && this.props.history.length <= 4) {
          const path = getPathnameWithoutModal(
            this.props.history.location.pathname
          );
          return this.props.history.push(path + '/ie-warning');
        } else {
          const originalPathname = getOriginalPathname(pathname);
          return originalPathname && this.props.history.push(originalPathname);
        }
      }
    };

    const handleInitWidget = ({ content }) => {
      const { parentHeight, parentLocation, embedAttributes } = content;

      this.parentLocation = parentLocation;
      this.parentHeight = parentHeight;

      this.setState(
        { appUrl: parentLocation.origin + parentLocation.pathname + '?xrcl=' },
        () => (window.appUrl = this.state.appUrl)
      );

      if (document.body && isMobileDevice()) {
        document.body.style.height = content.parentHeight + 'px';

        if (this.isIOS12) {
          const [wrapper] = document.getElementsByClassName('wrapper');
          if (wrapper) {
            wrapper.style.height = content.parentHeight + 'px';
          }
        }
      }

      const [body] = document.getElementsByTagName('body');
      body.classList.add('xircles-widget');

      this.setState(
        {
          isInsideWidget: true,
          embedAttributes,
        },
        () => {
          window.addEventListener('click', this.handleAppClick);
        }
      );

      const pathname = this.getPathnameFromLocation(this.parentLocation);

      setTimeout(() => {
        this.setState({ isLoaded: true });
        this.hideLoadingSpinner();
      }, 600);

      const originalPathname = getOriginalPathname(pathname);
      return originalPathname && this.props.history.push(originalPathname);
    };

    const handleCollapseWidget = () => {
      window.dispatchEvent(new CustomEvent('close-help-list'));
      disableBodyScroll(document.getElementById('editable'), {
        reserveScrollBarGap: true,
      });
      this.props.onCurrentActionChange(null);
    };

    const handlePopState = () => {
      const { location, history } = this.props;

      if ((isIOS() || isIE()) && isInsideFrame()) {
        if (
          isConversationRoute(location.pathname) &&
          !isConversationModalRoute(location.pathname)
        ) {
          return history.replace('/questions');
        }

        if (
          isDocumentsRoute(location.pathname) &&
          !isDocumentsModalRoute(location.pathname)
        ) {
          return history.replace(this.state.prevConversation);
        }
      }

      return history.goBack();
    };

    const handleOrientationChange = ({ content }) => {
      this.props.onOverlay(content.orientation, true);
    };

    const handleParentResize = ({ content }) => {
      if (document.body) {
        this.parentHeight = content.parentHeight;

        const height =
          this.state.isInsideWidget && !isMobileDevice()
            ? 612
            : this.parentHeight;
        document.body.style.height = height + 'px';

        if (this.isIOS12) {
          const [wrapper] = document.getElementsByClassName('wrapper');
          if (wrapper) {
            wrapper.style.height = content.parentHeight + 'px';
          }
        }
      }
    };

    const handleGoToStartPage = () => {
      this.props.history.replace('/');
      window.scrollTo(0, 0);
    };

    const handleOpenProductPage = ({ content: { product: productParam } }) => {
      if (productParam && this.state.brands) {
        const productParamLowerCase = productParam.toLowerCase();
        const isProductExists = this.state.brands.find(
          (brand) => brand.toLowerCase() === productParamLowerCase
        );

        if (isProductExists) {
          this.props.history.push(
            `/medikamente/${slugify(productParamLowerCase)}`
          );
        }
      }
    };

    const handleMessageType = {
      'init-frame': handleInitFrame,
      'init-widget': handleInitWidget,
      'collapse-widget': handleCollapseWidget,
      'pop-state': handlePopState,
      'orientation-change': handleOrientationChange,
      resize: handleParentResize,
      'open-product': handleOpenProductPage,
      'go-to-start-page': handleGoToStartPage,
    };

    return (
      _has(handleMessageType, parsedMessage.id) &&
      handleMessageType[parsedMessage.id](parsedMessage)
    );
  };

  handleSearchInputChange = (searchInput) =>
    this.setState({ prefilledQuestion: searchInput });

  appendUrlWithPathname = (source, match, replacer) => {
    const updatedReplacer = replacer === '/xrcl' ? match + replacer : replacer;
    return source.replace(
      source.substring(source.indexOf(match), source.length),
      updatedReplacer
    );
  };

  updateParentUrl = (pathname, childHash, childSearch, parentLocation) => {
    const locationSource = this.getLocationSource(parentLocation);
    const { hash, href, search } = parentLocation;

    const url = {
      [true]: href + `?xrcl=${pathname}`,
      [locationSource.includes('?xrcl=')]:
        pathname === '/questions'
          ? `?xrcl=${pathname}`
          : href.replace(search, `?xrcl=${pathname}`),
      [locationSource.includes('#')]: href.replace(hash, `#${pathname}`),
      [locationSource.includes('/xrcl')]: this.appendUrlWithPathname(
        href,
        '/xrcl',
        pathname
      ),
      [locationSource.includes('/questions')]: this.appendUrlWithPathname(
        href,
        '/questions',
        pathname
      ),
    };

    if (this.state.isInsideWidget && isQuestionsRoute(pathname)) {
      const [widgetBackButton] = document.getElementsByClassName(
        'widget__back-button'
      );
      widgetBackButton &&
        widgetBackButton.classList.remove('widget__back-button--hidden');
    }

    const urlWithSearch = childSearch
      ? `${url[true]}`.replace(search, '').concat(`${childSearch}`)
      : url[true];
    const urlWithHash = childHash
      ? `${urlWithSearch}`.replace(hash, '').concat(`${childHash}`)
      : urlWithSearch;

    window.parentIFrame.sendMessage({
      id: 'redirect',
      content: { pathname, url: urlWithHash },
    });
    return pathname + childSearch + hash;
  };

  handleChildSearchParams = (search, pathname) => {
    const params = search.slice(search.indexOf('?') + 1).split('&');

    if (params.length) {
      const shQuery = params.find((param) => param.includes('sh-query'));

      if (shQuery) {
        window.parentIFrame.sendMessage({
          id: 'not-embed-view',
          content: { pathname },
        });
      }
    }
  };

  handleSearchParams = ({ pathname, search }) => {
    if (isQuestionsRoute(pathname) && search.length) {
      const params = search.slice(search.indexOf('?') + 1).split('&');

      if (params.length) {
        const shQuery = params.find((param) => param.includes('sh-query'));

        if (shQuery) {
          const question = decodeURIComponent(
            shQuery.replace('xircles-sh-query=', '')
          );
          this.props.createConversation(question, {
            product: _get(this.state.product, 'id'),
            productName: _get(this.state.product, 'name'),
          });
        }
      }
    }
  };

  handleReleaseIdParam = async ({ pathname, search }) => {
    if (search) {
      const params = search.slice(search.indexOf('?') + 1).split('&');

      if (params.length) {
        const releaseQuery = params.find((param) =>
          param.includes('release_id')
        );

        if (releaseQuery) {
          const release_id = decodeURIComponent(
            releaseQuery.replace('release_id=', '')
          );
          await wrapCatch(updateSession({ release_id }));
          window.dispatchEvent(
            new CustomEvent('change-route', {
              detail: { url: pathname },
            })
          );
        }
      }
    }
  };

  updateBodyHeight = (pathname) => {
    const isUpdateBodyHeight = [
      isConversationRoute(pathname),
      isConversationModalRoute(pathname),
      isDocumentsRoute(pathname),
    ].some((condition) => !!condition);

    if (isUpdateBodyHeight) {
      document.body.style.height = this.parentHeight + 'px';
    } else {
      document.body.style.removeProperty('height');
    }
  };

  hideLoadingSpinner = () => {
    const [startSpinner] = document.getElementsByClassName('start-spinner');
    startSpinner &&
      startSpinner.classList.add('start-spinner__document-page--loaded');
  };

  handlePageLoading = () => {
    let timerId;
    if (isInsideFrame()) {
      timerId = window.setTimeout(this.handlePageLoading, 100);
      if (
        document.readyState === 'complete' &&
        window.parentIFrame &&
        !this.state.isLoaded
      ) {
        timerId && clearTimeout(timerId);
        window.parentIFrame.sendMessage({ id: 'page-is-loaded' });
        setTimeout(() => {
          this.setState({ isLoaded: true });
          document.getElementsByClassName('start-spinner')[0] &&
            document
              .getElementsByClassName('start-spinner')[0]
              .classList.add('start-spinner__document-page--loaded');
        }, 600);
      }
    } else {
      if (document.readyState === 'complete') {
        document.getElementsByClassName('start-spinner')[0] &&
          document
            .getElementsByClassName('start-spinner')[0]
            .classList.add('start-spinner__document-page--loaded');
        !this.state.isLoaded && this.setState({ isLoaded: true });
      }
    }
  };

  handleContextMenuClick = (e) => {
    if (e.target && e.target.href && isConversationRoute(e.target.href)) {
      e.preventDefault();
    }
  };

  applyStylesFromStoryblok = (additionalCss) => {
    var style = document.createElement('style');
    style.innerHTML = additionalCss;

    var ref = document.querySelector('script');
    return ref.parentNode.insertBefore(style, ref);
  };

  handleAddReCaptchaScript = () => {
    let reCaptchaScript = document.createElement('script');
    reCaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${config.ReCaptchaKey}&hl=${config.Language}`;
    document.head.appendChild(reCaptchaScript);
  };

  /**
   * @function handleAddFreshChatScript
   * @param {object} user
   * @param {string} sessionToken
   */
  handleAddFreshChatScript = (user, sessionToken) => {
    // No user no chat
    console.log('User for chat: ', user);
    if (!user || !user.userId) {
      return;
    }
    // Check if not created yet
    const freshchatElId = 'freshchat-js-sdk';
    const el = document.getElementById(freshchatElId);
    if (el) {
      return this.handleInitFreshChat(user, sessionToken);
    }
    // Create script from URL
    console.log('Loading Freshchat script');
    const freshChatScript = document.createElement('script');
    freshChatScript.id = freshchatElId;
    freshChatScript.async = true;
    freshChatScript.src = `${config.FreshChatUrl}/js/widget.js`;
    // Attach load event
    freshChatScript.onload = () =>
      this.setState({ freshChatLoaded: true }, () =>
        this.handleInitFreshChat(user, sessionToken)
      );
    // Add to DOM
    document.head.appendChild(freshChatScript);
  };

  /**
   * @async
   * @function handleInitFreshChat
   * @param {object} user
   * @param {string} sessionToken
   */
  handleInitFreshChat = async (user, sessionToken) => {
    // No user no init
    if (!user || !user.userId) {
      return;
    }
    user.metadata = user.metadata || {};
    // Set hcp status
    user.metadata.is_hcp = isHcp(user);
    // Skip if not set or already initialized
    if (!window.fcWidget || window.fcWidget.isInitialized()) {
      return;
    }
    // Init widget for load
    const fcwOpts = {
      token: config.FreshChatToken,
      host: config.FreshChatUrl,
      externalId: user.userId,
      restoredId: user.metadata.freshchat_restore_id || null,
      locale: config.Language,
    };
    // Listen for widget load
    window.fcWidget.on('widget:loaded', async () => {
      console.log('Freshchat widget loaded');
      // Try to get user from freshchat
      let resp;
      try {
        resp = await window.fcWidget.user.get();
        console.log('Freshchat user data: ', resp.status, resp.data);
        // No user got, create it
      } catch (e) {
        const usrData = {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email_address,
          phone: user.phone_number,
          // TODO: Uncomment this if needed, to adapt our format to theirs is a chore
          // phoneCountryCode: '+1'
        };
        console.log('Setting freshchat user', usrData);
        // This one does seem to wait for user to send a message in order to be created
        // We may want to annoy the user, don't we?
        // resp = await window.fcWidget.user.setProperties(usrData);
        // Force user creation (see comments above)
        try {
          resp = await window.fcWidget.user.create(usrData);
        } catch (e) {
          return console.error('Could not create freshchat user', e);
        }
        const status = resp && resp.status;
        const data = resp && resp.data;
        console.log('Freshchat user created', status, data);
        // Update id/restoreId in DB/local storage
        if (data.alias && data.restoreId && user.userId) {
          user.metadata.freshchat_restore_id = data.restoreId;
          console.log('Persisting user in DB', data);
          try {
            const usrData = await updateUser({
              userId: user.userId,
              data: [
                {
                  operation: 'set',
                  property: 'metadata.freshchat_restore_id',
                  value: data.restoreId,
                },
                {
                  operation: 'set',
                  property: 'metadata.freshchat_id',
                  value: data.alias,
                },
              ],
            });
            console.log('User updated, persisting in LocalStorage', usrData);
            persistUser({ ...usrData.data, userId: usrData.data.user_id });
          } catch (err) {
            console.error('Error updating user', err, user);
          }
        }
      }
      // Apparently this didn't work before and may nt work here either?
      window.fcWidget.user.setLocale(config.Language);
      // Keep track of conversation state, listen for changes
      let currConversationState = '';
      Layer.client.on('conversations:change', async (evt) => {
        const lastStatus = evt.target.metadata.xircles_status;
        console.log(
          `Status change from ${
            currConversationState || 'none'
          } to ${lastStatus}`
        );
        if (
          this.expertFeedbackStatus.includes(lastStatus) &&
          !this.expertFeedbackStatus.includes(currConversationState)
        ) {
          // On any state change to feedback, refresh identity, as metadata may have changed
          const { data } = await wrapCatch(
            getIdentityById({
              user_id: user.userId,
              session_token: sessionToken,
            })
          );
          console.log('User from layer: ', data);
          user = { ...data, userId: data.user_id };
          persistUser(user);
          await this.handleAddFreshChatUserMetadata(user.metadata);
        }
        currConversationState = lastStatus;
      });
      await this.handleAddFreshChatUserMetadata(user.metadata);
    });
    console.log('Freshchat widget init options', fcwOpts);
    window.fcWidget.init(fcwOpts);
  };

  handleAddFreshChatUserMetadata = async (metadata = {}) => {
    try {
      // Set only such metadata into freshchat
      const { feedback_count, is_hcp } = metadata;
      const resp = await window.fcWidget.user.setMeta({
        feedback_count,
        is_hcp,
      });
      console.log('Freshchat user metadata updated', resp.status, resp.data);
    } catch (e) {
      console.error('Could not update Freshchat user metadata', e);
    }
  };

  handleAddIframeResizerScript = () => {
    let iframeResizerScript = document.createElement('script');
    iframeResizerScript.src = '/js/iframe-resizer.min.js';
    document.head.appendChild(iframeResizerScript);
  };

  async componentDidMount(): void {
    if (isReactSnap()) {
      this.hideLoadingSpinner();
    }

    if (!isInsideFrame() && config.GtmContainerId.match(/[A-Z0-9]{7}/)) {
      this.setState({ isTriggerGtmEventsFromApp: true }, () => {
        window.addEventListener('click', this.handleClickTrackingEvent);
        window.addEventListener('tr-event', this.handleClickTrackingEvent);
        window.addEventListener(
          'xircles-choice-reframe',
          this.handleCustomTrackingEvent
        );
        window.addEventListener(
          'xircles-choice-cancel',
          this.handleCustomTrackingEvent
        );
        //
        document.addEventListener(
          'tr-custom-event',
          this.handleCustomTrackingEvent
        );
        document.addEventListener(
          'xircles-choice-send-track',
          this.handleCustomTrackingEvent
        );
      });
    }

    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('message', this.handleMessage);
    window.addEventListener('change-route', this.changeRoute);
    window.addEventListener('close-welcome-modal', this.closeWelcomeModal);
    window.addEventListener(
      'redirect-share-modal',
      this.handleRedirectShareModal
    );
    window.addEventListener(
      CONVERSATION_ACTIONS.share,
      this.showShareConversation
    );
    window.addEventListener('load', this.handlePageLoading);
    window.addEventListener('contextmenu', this.handleContextMenuClick);
    window.addEventListener('select-drug', this.handleSelectDrug);
    window.addEventListener('set-mmi-data', this.handleMMIBannerDate);

    if (!isReactSnap()) {
      this.handleAddReCaptchaScript();
      this.handleAddIframeResizerScript();
    }

    this.setState({ themeSettings });

    const { data: dictionaries } = await wrapCatch(getAllDictionaries());
    this.setState({
      tags: _get(dictionaries, `tags[${config.Language}]`, []),
      brands: _get(dictionaries, 'brands', []),
    });

    const additionalCss = _get(themeSettings, 'additionalCss', '');
    if (additionalCss.length > 0) {
      this.applyStylesFromStoryblok(additionalCss);
    }

    if (isIE() && this.props.history.length <= 3 && !isInsideFrame()) {
      const path = getPathnameWithoutModal(
        this.props.history.location.pathname
      );
      this.props.history.push(path + '/ie-warning');
    }

    this.handleSearchParams(this.props.history.location);

    if (!isInsideFrame()) {
      this.handleReleaseIdParam(window.location);
    }

    setTimeout(() => {
      if (!this.state.isLoaded) {
        this.handlePageLoading();
      }

      if (window.parentIFrame) {
        window.parentIFrame.sendMessage({ id: 'init-iframe' });
      }
    }, 1200);

    if (isConversationRoute(this.props.history.location.pathname)) {
      document.body.classList.add('conversation-view');
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('message', this.handleMessage);
    window.removeEventListener('change-route', this.changeRoute);
    window.removeEventListener('close-welcome-modal', this.closeWelcomeModal);
    window.removeEventListener(
      'redirect-share-modal',
      this.handleRedirectShareModal
    );
    window.removeEventListener(
      CONVERSATION_ACTIONS.share,
      this.showShareConversation
    );
    window.removeEventListener('load', this.handlePageLoading);
    window.removeEventListener('contextmenu', this.handleContextMenuClick);
    window.removeEventListener('select-drug', this.handleSelectDrug);
    window.removeEventListener('set-mmi-data', this.handleMMIBannerDate);

    if (this.state.isInsideWidget) {
      window.removeEventListener('click', this.handleAppClick);
    }

    if (this.state.isInsideIframe || this.state.isTriggerGtmEventsFromApp) {
      window.removeEventListener('click', this.handleClickTrackingEvent);
      window.removeEventListener('tr-event', this.handleClickTrackingEvent);
      window.removeEventListener(
        'xircles-choice-reframe',
        this.handleCustomTrackingEvent
      );
      window.removeEventListener(
        'xircles-choice-cancel',
        this.handleCustomTrackingEvent
      );
      //
      document.removeEventListener(
        'tr-custom-event',
        this.handleCustomTrackingEvent
      );
      document.removeEventListener(
        'xircles-choice-send-track',
        this.handleCustomTrackingEvent
      );
    }
    window.fcWidget && window.fcWidget.destroy();
  }

  async componentWillReceiveProps(
    nextProps: Readonly<P>,
    nextContext: any
  ): void {
    let routeChanged =
      nextProps.location.pathname !== this.props.location.pathname;

    const stateUpdate = { firstTime: !routeChanged };
    if (routeChanged) {
      stateUpdate.prevLocation = this.props.location.pathname;
      if (
        isConversationRoute(this.props.location.pathname) &&
        !isConversationModalRoute(this.props.location.pathname)
      ) {
        stateUpdate.prevConversation = this.props.location.pathname;
      }
    }
    this.setState(stateUpdate);

    /* console.info('[TST] Auth status: ', {
      wasAuthenticated: this.isAuthenticated,
      wasLoggedIn: this.props.isLoggedIn,
      isLoggedIn: nextProps.isLoggedIn,
      isExpert: isExpert(this.props.layerClient.user)
    }) */
    if (
      this.passwordProtected &&
      !this.isAuthenticated &&
      nextProps.cookies.get('xir-test-login-success')
    ) {
      this.isAuthenticated =
        this.props.cookies.get('xir-test-login-success') ||
        nextProps.cookies.get('xir-test-login-success');
    }

    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn &&
      isOperator(this.props.layerClient.user)
    ) {
      this.isAuthenticated = true;
    }

    if (
      this.passwordProtected &&
      _get(nextProps, 'location.state.detail.isSuccessLogin')
    ) {
      this.isAuthenticated = true;
    }

    let { user, sessionToken } = this.props.layerClient;
    // Sync user if logged in, it only comes with props from layer
    if (nextProps.isLoggedIn && !this.state.userResynced) {
      const { data } = await wrapCatch(
        getIdentityById({
          user_id: user.userId,
          session_token: sessionToken,
        })
      );
      console.log('User from layer: ', data);
      user = { ...data, userId: data.user_id };
      persistUser(user);
      // Set as resynced, so we don't run this many times
      this.setState({ userResynced: true });
    }
    // Add freshchat stuff if not added, enabled and AI trainer user (expert)
    if (
      !this.state.freshChatLoaded &&
      nextProps.isLoggedIn &&
      isExpert(user) &&
      config.FreshChatToken &&
      config.FreshChatUrl
    ) {
      this.handleAddFreshChatScript(user, sessionToken);
    }
    // Remove freshchat stuff if loaded but no longer logged in or not expert
    if (
      this.state.freshChatLoaded &&
      (!nextProps.isLoggedIn || !isExpert(user)) &&
      config.FreshChatToken &&
      config.FreshChatUrl
    ) {
      window.fcWidget && window.fcWidget.destroy();
      this.setState({ freshChatLoaded: false });
    }

    if (isConversationRoute(nextProps.history.location.pathname)) {
      document.body.classList.add('conversation-view');
    }

    if (this.props.newConversationId !== nextProps.newConversationId) {
      const { search } = this.props.location;
      const params = search.slice(search.indexOf('?') + 1).split('&');

      if (params.length) {
        const isHcp = params.find((param) => param.includes('is_hcp'));

        if (isHcp) {
          return this.props.history.push(
            `/questions/${nextProps.newConversationId}?${isHcp}`
          );
        }
      }

      return this.props.history.push(
        `/questions/${nextProps.newConversationId}`
      );
    }
  }

  handleRenderQuestionPage = (props) => (
    <QuestionPageContainer
      {...props}
      firstTime={this.state.firstTime}
      isIOS12={this.isIOS12}
      themeSettings={this.state.themeSettings}
      operatorFilterSelected={this.state.operatorFilterSelected}
      handleOperatorFilterSelection={this.handleOperatorFilterSelection}
      appUrl={this.state.appUrl}
      navigationType={this.state.navigationType}
      prevLocation={this.state.prevLocation}
      isInsideWidget={this.state.isInsideWidget}
      onProductChange={this.handleProductChange}
      customizeConversationList={this.customizeConversationList()}
    />
  );

  handleStartPage = (props) => {
    const isStartPageRedirect = !this.passwordProtected;
    const isSuccessLogin = _get(
      this.props.location,
      'state.detail.isSuccessLogin'
    );

    if (isSuccessLogin) {
      this.isSuccessLogin = isSuccessLogin;
    }

    if (isStartPageRedirect || this.isSuccessLogin) {
      return (
        <StartPage
          {...props}
          tags={this.state.tags}
          appUrl={this.state.appUrl}
          onSearchInputChange={this.handleSearchInputChange}
          parentDocumentId={this.state.parentDocumentId}
          parentDocumentBrandSlug={this.state.parentDocumentBrandSlug}
          themeSettings={
            isReactSnap() ? themeSettings : this.state.themeSettings
          }
          customizeConversationList={this.customizeConversationList()}
        />
      );
    } else {
      return <SplashScreenPage {...props} />;
    }
  };

  handleConversationLoaded = (hasDocumentConversation) =>
    this.setState({
      isMMIBannerMessageVisible: true,
      hasDocumentConversation,
    });

  handleDocumentLoaded = () =>
    this.setState({ isMMIBannerMessageVisible: true });

  handleProductChange = (product) => this.setState({ product });

  handleMMIBannerDate = (date) => {
    const { externalUrl, publishedDate } = date.detail;

    this.setState({
      hasDocumentConversation: true,
      mmiBannerDate: publishedDate,
      mmiExternalUrl: externalUrl,
    });
  };

  customizeConversationList() {
    const t = this.t;

    return {
      conversationRowLeftSide: () => {
        const div = document.createElement('div');
        const avatar = document.createElement('layer-avatar');
        avatar.setAttribute('layer-id', 'avatar');
        avatar.size = this.size;
        const presence = document.createElement('layer-presence');
        presence.setAttribute('layer-id', 'presence');
        presence.size = 'medium';
        const groupCounter = document.createElement('div');
        groupCounter.classList.add('layer-group-counter');
        groupCounter.setAttribute('layer-id', 'groupCounter');
        div.appendChild(avatar);
        div.appendChild(presence);
        div.appendChild(groupCounter);
        return div;
      },
      emptyNode: () => {
        const message = isDeletedRequestsRoute(this.props.location.pathname)
          ? `${t('COMMON_NO_DELETED_CONVERSATION_YET')}`
          : `${t('COMMON_NO_CONVERSATION_YET')}`;

        return message;
      },
    };
  }

  onConversationSelected(evt: CustomEvent) {
    if (!evt.detail.item || isCrawler()) return;

    const conversation = evt.detail.item.toObject();
    if (conversation.metadata && conversation.metadata.isPublic) {
      this.props.createConversation(conversation.metadata.conversationName, {
        product: _get(this.state.product, 'id'),
        productName: _get(this.state.product, 'name'),
      });
    } else {
      document.dispatchEvent(
        new CustomEvent('tr-custom-event', {
          detail: {
            name: 'active-conversation',
            conversationId: uuid(conversation.id),
          },
        })
      );
      this.props.history.push(`/questions/${uuid(conversation.id)}`);
    }
  }

  handleSelectDrug = (e) => {
    const selectedProduct = _get(e, 'detail.selectedProduct');
    const documentId = _get(e, 'detail.selectedProduct.id');
    this.setState({ selectedProduct, documentId });
  };

  render() {
    if (this.state.isLoaded) {
      if (
        this.isAuthenticated ||
        (!this.passwordProtected &&
          (!this.isOnlyEmbeddedAccessible || isInsideFrame()))
      ) {
        return (
          <div>
            <BrowserCheck />
            <DeviceOrientation
              lockOrientation={'portrait'}
              className={'landscape-overlay-wrap'}
            >
              <Orientation orientation={'landscape'}>
                <div id={'landscape-overlay'}>
                  <div className={'lo-content'}>
                    <h2>{this.t('COMMON_COMPANY_NAME')}</h2>
                    <img
                      className="app-logo"
                      width="24"
                      height="24"
                      alt="Logo"
                    />
                    <br />
                    {this.t('COMMON_SMARTPHONE_MODE_ADVISE')}
                  </div>
                </div>
              </Orientation>
            </DeviceOrientation>
            {_get(this.state.themeSettings, 'isMMIBannerVisible', false) &&
              (isConversationRoute(this.props.location.pathname) ||
                isConversationModalRoute(this.props.location.pathname) ||
                isDocumentsRoute(this.props.location.pathname) ||
                isDocumentsModalRoute(this.props.location.pathname)) && (
                <MMIBanner
                  isFixedPosition={
                    isDocumentsRoute(this.props.location.pathname) ||
                    isDocumentsModalRoute(this.props.location.pathname)
                  }
                  content={
                    this.state.isMMIBannerMessageVisible
                      ? this.t(
                          this.state.hasDocumentConversation
                            ? 'COMMON_MMI_BANNER_CONTENT'
                            : 'COMMON_MMI_BANNER_CONTENT_FALLBACK',
                          {
                            link: `<a target="_blank" href="${
                              this.state.mmiExternalUrl
                            }">${prepareMmiExternalUrl(
                              this.state.mmiExternalUrl
                            )}</a>`,
                            date: this.state.mmiBannerDate
                              ? moment(this.state.mmiBannerDate)
                                  .locale(config.Language)
                                  .format('MMMM YYYY')
                              : '',
                          }
                        )
                      : ''
                  }
                />
              )}
            <div
              className={`wrapper ${
                isDrugCatalogueRoute(this.props.location.pathname)
                  ? 'drugs-catalogue-wrapper'
                  : ''
              }`}
            >
              <HelmetWrapper />
              <TransitionGroup className="transition-group">
                <Switch location={this.props.location}>
                  <Route
                    exact
                    path={[
                      '/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|doccheck-welcome|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/code/:email/:code',
                    ]}
                    render={(props) =>
                      this.handleStartPage({ ...props, ...this.props })
                    }
                  />
                  <Route
                    exact
                    path={[
                      '/questions/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/questions/code/:email/:code',
                      '/questions/deleted-requests/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|code|password|set-password|create-password|reset-password|success|welcome|save-changes|too-many-requests|no-product|future-product)?',
                      '/questions/deleted-requests/:email/:code',
                    ]}
                    component={this.handleRenderQuestionPage}
                  />
                  <Route
                    exact
                    path="/about/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|select-drug|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?"
                    render={(props) => (
                      <AboutPageContainer
                        {...props}
                        tags={this.state.tags}
                        themeSettings={this.state.themeSettings}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/medikamente/katalog/:pagination/:subpagination?/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|select-drug|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?"
                    render={(props) => (
                      <DrugsCataloguePageContainer
                        {...props}
                        tags={this.state.tags}
                        themeSettings={this.state.themeSettings}
                        appUrl={this.state.appUrl}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={[
                      '/medikamente/:productName/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|doccheck-welcome|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|select-drug|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/medikamente/:productName/code/:email/:code',
                    ]}
                    render={(props) => (
                      <ProductPageContainer
                        {...props}
                        onSearchInputChange={this.handleSearchInputChange}
                        documentId={this.state.documentId}
                        selectedProduct={this.state.selectedProduct}
                        appUrl={this.state.appUrl}
                        tags={this.state.tags}
                        themeSettings={this.state.themeSettings}
                      />
                    )}
                  />
                  <Route
                    path={[
                      '/documents/*',
                      '/medikamente/:productName/:documentPath/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/medikamente/:productName/:documentPath/code/:email/:code',
                    ]}
                    render={(props) => (
                      <DocumentPageContainer
                        {...this.props}
                        {...props}
                        pxColor={this.state.pxColor}
                        isMMIBannerMessageVisible={
                          this.state.isMMIBannerMessageVisible
                        }
                        onDocumentLoaded={this.handleDocumentLoaded}
                        onMMIBannerDate={this.handleMMIBannerDate}
                        parentLocation={this.parentLocation}
                        prevLocation={this.state.prevLocation}
                        isIOS12={this.isIOS12}
                        updateParentUrl={this.updateParentUrl}
                        themeSettings={this.state.themeSettings}
                        selectedProduct={this.state.selectedProduct}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={[
                      '/questions/:conversationId(.{36})/:hash?',
                      '/:productName/:conversationName/:hash?',
                      '/:productName/:conversationId(.{36})/:hash?',
                      '/:companyName/:productName/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|doccheck-welcome|scan-efn|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|select-drug|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/:companyName/:productName/code/:email/:code',
                      '/medikamente/:productName/(code-confirm|login|my-account|logout|send-code|password-code|password-submit|login-unregister|code|password-confirm|password|set-password|create-password|reset-password|success|welcome|legacy-login|doccheck|doccheck-welcome|scan-efn|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer|need-to-login|external-disclaimer|deleted-requests|something-went-wrong|network-error|ie-warning|save-changes|select-drug|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?/(network-error|ie-warning|too-many-requests|no-product|future-product|data-privacy|privacy-notice|terms-of-use)?',
                      '/medikamente/:productName/code/:email/:code',
                    ]}
                    render={(props) => (
                      <ProductPageSwitch
                        {...props}
                        tags={this.state.tags}
                        appUrl={this.state.appUrl}
                        brands={this.state.brands}
                        documentId={this.state.documentId}
                        prevLocation={this.state.prevLocation}
                        themeSettings={this.state.themeSettings}
                        selectedProduct={this.state.selectedProduct}
                        onSearchInputChange={this.handleSearchInputChange}
                        onConversationLoaded={this.handleConversationLoaded}
                        onPxColorChange={(pxColor) =>
                          this.setState({ pxColor })
                        }
                      />
                    )}
                  />
                  <Route
                    path="/video/:videoName"
                    render={(props) => (
                      <Video
                        {...props}
                        prevLocation={this.state.prevLocation}
                      />
                    )}
                  />
                  <Route
                    path="/long-copy/:contentName"
                    render={(props) => (
                      <LongCopy
                        {...props}
                        prevLocation={this.state.prevLocation}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/dchcpv"
                    component={withRouter(DocCheckTarget)}
                  />
                </Switch>
              </TransitionGroup>
              <LoginModalContainer
                {...this.props}
                prevLocation={this.state.prevLocation}
                product={this.state.product}
              />
              <ShareModalContainer />
              <OperatorModalContainer />
              <InfoModalContainer themeSettings={this.state.themeSettings} />
              <SelectModalContainer />
              <NeedToLoginModalContainer
                {...this.props}
                product={this.state.product}
              />
            </div>
          </div>
        );
      }
      if (!this.props.isLoading) {
        if (this.passwordProtected && !this.isAuthenticated) {
          return (
            // keep outer div
            <div>
              <HelmetWrapper />
              <Switch location={this.props.location}>
                <Route
                  exact
                  path="/"
                  component={withRouter(SplashScreenPage)}
                />
                <Redirect to={'/'} />
              </Switch>
            </div>
          );
        }
        if (this.isOnlyEmbeddedAccessible) {
          // keep outer div to prevent copy style during transition
          return (
            <div>
              <HelmetWrapper />
              <div style={{ background: 'white', color: 'white' }}>
                Access Denied
              </div>
            </div>
          );
        }
      }
      if (this.props.isLoading && this.isOnlyEmbeddedAccessible) {
        return null;
      }
    }
    return <div style={{ height: '100vh' }}></div>;
  }
}

const AppWithConversationManager = conversationManager(App);

const AppContainer = (props) => (
  <LayerContext.Consumer>
    {({
      displayName,
      layerClient,
      isLoading,
      isLoggedIn,
      onLoginAnonymously,
      userId,
    }) => (
      <CommonContext.Consumer>
        {({ onCurrentActionChange }) => (
          <AppWithConversationManager
            {...props}
            userId={userId}
            displayName={displayName}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
            layerClient={layerClient}
            onLoginAnonymously={onLoginAnonymously}
            onCurrentActionChange={onCurrentActionChange}
            onLogin={(e) => {
              e.preventDefault();
              props.history.push(
                normalizePathname(props.history.location.pathname) +
                  '/login' +
                  props.history.location.hash
              );
            }}
            onDisplayName={(e) => {
              e.preventDefault();
              const redirectPath = config.UserManagementEnabled
                ? '/my-account'
                : '/logout';
              props.history.push(
                normalizePathname(props.history.location.pathname) +
                  redirectPath +
                  props.history.location.hash
              );
            }}
            onToggleMenu={() => onCurrentActionChange(null)}
          />
        )}
      </CommonContext.Consumer>
    )}
  </LayerContext.Consumer>
);

export default withTranslation()(withRouter(withCookies(AppContainer)));
