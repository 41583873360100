import Layer from '@layerhq/web-xdk';
import { layerClient } from '../../get-layer';
import { slugify } from '../../utils/common';

import './button-link-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-button-link-message-view', {
  mixins: [MessageViewMixin],
  style: `
    .xircles-button-link-message-view > * > .layer-card-body-outer {
        display: none;
    }
    .xircles-button-link-message-view {
        max-width: 100% !important;
        width: unset !important;
        min-width: unset !important;
    }
    xircles-button-link-message-view {
      display: block;
      text-align: right;
    }
    .layer-root-viewer.xircles-button-link-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  template:
    '<span layer-id="link" class="xircles-button xircles-button-primary"></span>',
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata
          ? Widths.FLEX
          : Widths.ANY;
      },
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container',
    },
  },
  methods: {
    _isFeedbackFlow: function () {
      const disclaimerMessage = document.querySelector(
        'layer-message-item-received.layer-message-item-xircles-disclaimer-message-view:not(.layer-list-item-last)'
      );
      return !!disclaimerMessage;
    },
    onAfterCreate() {
      this.nodes.link.addEventListener(
        'ontouchstart' in document.documentElement ? 'touchstart' : 'click',
        () => {
          this.trigger('xircles-choice-send-track', {
            detail: {
              id: this.model.id,
              title: this.model.title,
            },
            customData: {
              isFeedbackFlow: this._isFeedbackFlow(),
            },
          });

          const conversationId = this.model.message.conversationId.replace(
            'layer:///conversations/',
            ''
          );
          const conversation = layerClient.getConversation(
            conversationId,
            true
          );

          const url =
            conversation && conversation.metadata.goBackPath
              ? conversation.metadata.goBackPath
              : conversation.metadata.product
              ? `/medikamente/${slugify(conversation.metadata.product)}`
              : '/questions';
          dispatchEvent(
            new CustomEvent('change-route', {
              detail: {
                type: 'replace',
                url,
                state: {
                  isProductXircle: conversation.metadata.isPX,
                },
              },
            }),
            { passive: true }
          );
        }
      );
    },
    onRerender() {
      this.nodes.link.innerHTML = this.model.title;
      this.nodes.link.style.align = this.model.align;
    },
  },
});
