import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

const LoginWasRemovedModalFragment = ({ loginType, onContinue, t }) => (
  <Fragment>
    <Box fontSize="h5.fontSize" textAlign="center">
      {t(`MODAL_${loginType}_WAS_REMOVED`)}
    </Box>
    <Box mt={2}>
      <Button outlined style={{ width: '100%' }} onClick={onContinue}>
        {t('COMMON_OK')}
      </Button>
    </Box>
  </Fragment>
);

export default withTranslation()(LoginWasRemovedModalFragment);
