// @flow
import React, { Fragment } from 'react';

import { LayerReactComponents } from '../../get-layer';

import DiseaseCard from '../DiseaseCard';
import InstructionCard from '../InstructionCard';
import InfoCard from '../InfoCard';
import MedicineCard from '../MedicineCard';

import './styles.less';
import { QUERY_TYPES } from '../../const/query-types';

const { ConversationList } = LayerReactComponents;

const ContentPanel = ({
  appUrl,
  filterPublicList,
  isPublicListVisible,
  isDiseaseCardsVisible,
  isInfoCardsVisible,
  themeSettings,
  customizeConversationList,
  onConversationSelected,
}) => {
  const {
    diseases,
    informationForHCPs,
    infos,
    medicines,
    patientInformationCard,
  } = themeSettings;

  return (
    <div className="content-panel">
      <div
        className={`content-panel__row ${
          !isDiseaseCardsVisible ? 'content-panel__row--start' : ''
        }`}
      >
        <div className="content-panel__large-block">
          {medicines.map((medicine) => (
            <Fragment key={medicine.id}>
              <MedicineCard
                className="content-panel__medicine-card"
                title={medicine.title}
                subtitle={medicine.subtitle}
                subtitleLink={medicine.subtitleLink}
                images={medicine.images}
              />

              <InstructionCard
                className="content-panel__instruction-card"
                linkColor={medicine.linkColor}
                links={medicine.links}
                healthcareLinks={medicine.healthcareLinks}
                appUrl={appUrl}
                informationForHCPs={informationForHCPs}
                patientInformationCard={patientInformationCard}
              />
            </Fragment>
          ))}
        </div>
        {
          <div className="content-panel__right-block">
            {!isInfoCardsVisible &&
              isDiseaseCardsVisible &&
              diseases.map((disease) => (
                <DiseaseCard
                  key={disease.id}
                  className="content-panel__small-block"
                  title={disease.title}
                  link={disease.link.url}
                  url={disease.url.url}
                />
              ))}
            {isInfoCardsVisible &&
              infos.map((info) => (
                <InfoCard
                  key={info.id}
                  className="content-panel__small-block"
                  title={info.title}
                  link={info.link.url}
                  url={info.url.url}
                />
              ))}
            {!isInfoCardsVisible && isPublicListVisible && (
              <ConversationList
                className="public-conversations"
                filter={filterPublicList}
                _queryModel={QUERY_TYPES.PUBLIC_QUESTIONS}
                replaceableContent={customizeConversationList}
                onConversationSelected={onConversationSelected}
              />
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default ContentPanel;
