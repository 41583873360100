// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './styles.less';
import { checkIsBrowserValid } from '../../utils/browserValidation';
import { isInsideFrame, isIOSSafari } from '../../utils/common';

class BrowserCheck extends Component<State> {
  componentDidMount() {
    if (isInsideFrame()) {
      const [body] = document.getElementsByTagName('body');
      body.classList.add('default-background');
    }
  }

  componentWillUnmount() {
    if (isInsideFrame()) {
      const [body] = document.getElementsByTagName('body');
      body.classList.remove('default-background');
    }
  }

  render() {
    const { t } = this.props;
    return checkIsBrowserValid() ? null : (
      <div
        className={`splash-screen${
          isIOSSafari() ? ' splash-screen--fixed' : ''
        }`}
      >
        <div className={'splash-screen__container'}>
          <div className={'splash-screen__container--outdated-chrome'}>
            <p>{t('SPLASH_PLEASE_USE_LATEST_CHROME_VERSION')}</p>
            <p>{t('SPLASH_VERSIONS_FOR_OTHER')}</p>
          </div>
          <img className="app-logo" alt="Application Logo" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(BrowserCheck);
