// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onRequestCode: Function,
  onLoginWithPassword: Function,
  onCreatePassword: Function,
  t: Function,
};

function ResetPasswordModalFragment({
  phoneNumberOrEmail,
  onRequestCode,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mb={2} mt={3} fontSize="fontSize">
        {t('LOGIN_RESET_PASSWORD_WILL_SENT')}
        &nbsp;
        <span style={{ fontWeight: 'bold' }}>{phoneNumberOrEmail}</span>. &nbsp;
        {t('LOGIN_RESET_PASSWORD_AFTER_ENTERING')}
      </Box>
      <Button
        id="reset-password-send-code"
        data-keystroke
        outlined
        style={{ width: '100%' }}
        onClick={onRequestCode}
      >
        {t('LOGIN_RESET_PASSWORD_SEND_CODE')}
      </Button>
    </React.Fragment>
  );
}

export default withTranslation()(ResetPasswordModalFragment);
