// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import CodeInput from '../CodeInput';
import A from '../../A';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';
import './styles.less';

type Props = {
  phoneNumberOrEmail: string | null,
  code: string | null,
  onCodeChange: Function,
  onRequestCode: Function,
  onNext: Function,
  t: Function,
};

function PasswordCodeModalFragment({
  phoneNumberOrEmail,
  code,
  onCodeChange,
  onRequestCode,
  onNext,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mb={1.75}>
        <div className="code-modal-fragment__success-text">
          {t('LOGIN_SET_PASSWORD_TITLE')}
          &nbsp;
          <span style={{ fontWeight: 'bold' }}>{phoneNumberOrEmail}</span>
        </div>
        <div>
          {t('LOGIN_SET_PASSWORD_ENTER_CODE')}{' '}
          <A
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onRequestCode(e);
            }}
          >
            {t('LOGIN_SET_PASSWORD_SEND_CODE_AGAIN')}
          </A>
          .
        </div>
      </Box>
      <Box mb={1.25}>
        <CodeInput
          length={5}
          value={code}
          onChange={onCodeChange}
          onNext={onNext}
        />
      </Box>
      <Button
        id="password-code-next"
        data-keystroke
        outlined
        style={{ width: '100%' }}
        onClick={(e) => {
          e.preventDefault();
          return onNext(e);
        }}
      >
        {t('LOGIN_NEXT_BUTTON')}
      </Button>
    </React.Fragment>
  );
}

export default withTranslation()(PasswordCodeModalFragment);
