// @flow
import React from 'react';
import LayerContext from '../LayerContext';
import start from '../../common/start';
import logout from '../../common/logout';
import { layerClient } from '../../get-layer';
import { authenticateAnonymous } from '../../utils/api';

type Props = {
  children: any,
  layerClient: any,
};

type State = {
  isReady: boolean,
  isLoading: boolean,
  isLoggedIn: boolean,
  userId: ?string,
  username: ?string,
  displayName: ?string,
  isHcp: boolean,
  loginState: ?string,
  loginError: ?any,
  onLoginAnonymously: Function,
  layerClient: any,
};

export default class LayerProvider extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isLoading: true,
      isLoggedIn: false,
      userId: null,
      username: null,
      displayName: null,
      isHcp: false,
      loginState: null,
      loginError: null,
      onLoginAnonymously: this.loginAnonymously,
      layerClient: props.layerClient,
    };
  }

  componentDidMount() {
    const { layerClient } = this.props;
    this.handleReady();

    layerClient.on('ready', this.handleReady);
    layerClient.on('challenge', this.handleChallenge);
    layerClient.on('authenticated', this.handleAuthenticated);
    layerClient.on('deauthenticated', this.handleDeauthenticated);
    layerClient.on('identities:change', this.handleIdentitiesChange);

    const session = start();
    if (session) {
      this.setState(session);
    }
  }

  componentWillUnmount() {
    const { layerClient } = this.props;
    layerClient.off('ready', this.handleReady);
    layerClient.off('challenge', this.handleChallenge);
    layerClient.off('authenticated', this.handleAuthenticated);
    layerClient.off('deauthenticated', this.handleDeauthenticated);
    layerClient.off('identities:change', this.handleIdentitiesChange);

    if (!layerClient.isAuthenticated) {
      this.loginAnonymously();
    }
  }

  loginAnonymously = () => {
    this.setState({ loginState: 'loading', loginError: null });
    authenticateAnonymous()
      .then(({ data }) => {
        const { user_id, session_token } = data;
        layerClient.connectWithSession(user_id, session_token);
        this.setState({ loginState: 'loaded' });
      })
      .catch((err) => {
        this.setState({ loginState: 'error', loginError: err });
      });
  };

  handleReady = () => {
    const { layerClient } = this.props;
    this.setState({ isReady: layerClient.isReady });
  };

  handleChallenge = ({ nonce, callback }) => {
    logout().then(this.loginAnonymously);
  };

  handleAuthenticated = () => {
    const { layerClient } = this.props;
    this.setState({ userId: layerClient.userId });
  };

  handleDeauthenticated = () => {
    this.setState({
      userId: null,
      username: null,
      displayName: null,
      isLoggedIn: false,
      isHcp: false,
    });
  };

  //TODO: emailAddress shouldn't be stored state.username. It isn't obvious.
  handleIdentitiesChange = () => {
    const { layerClient } = this.props;
    const user_status = layerClient.user.metadata.user_status;
    if (user_status === 'confirmed' || user_status === 'anonymous') {
      this.setState({
        userId: layerClient.userId,
        username: layerClient.user.emailAddress,
        displayName: layerClient.user.displayName,
        isHcp: layerClient.user.metadata.is_hcp === 'true',
        isLoggedIn: user_status === 'confirmed',
        isLoading: layerClient.user.isLoading,
      });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <LayerContext.Provider value={this.state}>
        {children}
      </LayerContext.Provider>
    );
  }
}
