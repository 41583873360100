import React from 'react';
import { withRouter } from 'react-router-dom';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import Header from '../components/Header';

function HeaderOperatorContainer({
  history,
  location,
  match,
  staticContext,
  ...props
}) {
  return (
    <LayerContext.Consumer>
      {({ displayName, isLoading, isLoggedIn }) => (
        <CommonContext.Consumer>
          {({ onCurrentActionChange }) => (
            <Header
              {...props}
              isLoggedIn={isLoggedIn}
              isLoading={isLoading}
              displayName={displayName}
              onLogin={(e) => {
                e.preventDefault();
                history.push('/questions/login');
              }}
              onDisplayName={(e) => {
                e.preventDefault();
                history.push('/questions/logout');
              }}
              onToggleMenu={() => onCurrentActionChange('menu')}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  );
}

export default withRouter(HeaderOperatorContainer);
