import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesQuestionModel extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata(['text', 'slug', 'data']);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesQuestionModel.prototype.text = '';
XirclesQuestionModel.prototype.slug = '';
XirclesQuestionModel.prototype.data = null;

XirclesQuestionModel.MIMEType = 'application/xircles.question+json';
XirclesQuestionModel.LabelSingular = 'Question';
XirclesQuestionModel.messageRenderer = 'xircles-question-message-view';

Root.initClass.apply(XirclesQuestionModel, [
  XirclesQuestionModel,
  'XirclesQuestionModel',
]);
Client.registerMessageTypeModelClass(
  XirclesQuestionModel,
  'XirclesQuestionModel'
);

export default XirclesQuestionModel;
