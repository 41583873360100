import Bowser from 'bowser';
import MobileDetect from 'mobile-detect/mobile-detect';

// Some handy aliases
const aliases = {
  Android: 'Android',
  AndroidOS: 'Android',
  iOS: 'iOS',
  ios: 'iOS',
  'Samsung Internet for Android': 'samsung_internet',
};

// Min OS versions by browser
const osMinVersions = {
  default: {
    Android: 7.0,
    iOS: 13.0,
  },
  Safari: {
    iOS: 14.0,
  },
};

// Browsers default min versions
const browsersMinVersions = {
  default: {
    chrome: 75.0,
    safari: 14.0,
    firefox: 104.0,
    edge: 105.0,
    opera: 90,
    samsung: 12.0,
  },
  ios: {
    safari: 14.0,
  },
  android: {},
};

// Additional detection method for some devices
const vendorRegexpMap = {
  Huawei: new RegExp(
    'ALP-|AMN-|ANA-|ANE-|ANG-|AQM-|ARS-|ART-|ATU-|BAC-|BLA-|BRQ-|CAG-|CAM-|CAN-|CAZ-|CDL-|CDY-|CLT-|CRO-|CUN-|DIG-' +
      '|DRA-|DUA-|DUB-|DVC-|ELE-|ELS-|EML-|EVA-|EVR-|FIG-|FLA-|FRL-|GLK-|HMA-|HW-|HWI-|INE-|JAT-|JEF-|JER-|JKM-|JNY-' +
      '|JSC-|LDN-|LIO-|LON-|LUA-|LYA-|LYO-|MAR-|MED-|MHA-|MLA-|MRD-|MYA-|NCE-|NEO-|NOH-|NOP-|OCE-|PAR-|PIC-|POT-|PPA-' +
      '|PRA-|RNE-|SEA-|SLA-|SNE-|SPN-|STK-|TAH-|TAS-|TET-|TRT-|VCE-|VIE-|VKY-|VNS-|VOG-|VTR-|WAS-|WKG-|WLZ-|JAD-|WKG-' +
      '|MLD-|RTE-|NAM-|NEN-|BAL-|JAD-|JLN-|YAL',
    'i'
  ),
  Xiaomi: new RegExp('Mi A1|MI 8 Pro', 'i'),
};

// Supported android devices out of the box (apart from Apple ones)
const validAndroidMobileDevicesVersionsMap = {
  Samsung: 7.0,
  SamsungTablet: 7.0,
  Huawei: 9.0,
  HuaweiTablet: 9.0,
  Xiaomi: 9.0,
  XiaomiTablet: 9.0,
};

// This is shared validation for mobile/tablet,
const mobileBrowserValidation = () => ({
  android: {
    chrome: `>=${
      browsersMinVersions.android.chrome || browsersMinVersions.default.chrome
    }`,
    samsung_internet: `>=${browsersMinVersions.default.samsung}`,
  },
  iOS: {
    safari: `>=${
      browsersMinVersions.ios.safari || browsersMinVersions.default.safari
    }`,
    chrome: `>=${
      browsersMinVersions.ios.chrome || browsersMinVersions.default.chrome
    }`,
  },
});

// This generates some input Bowser library can understand and check
const browserValidation = () => ({
  windows: {
    chrome: `>=${browsersMinVersions.default.chrome}`,
    edge: `>=${browsersMinVersions.default.edge}`,
    firefox: `>=${browsersMinVersions.default.firefox}`,
    opera: `>=${browsersMinVersions.default.opera}`,
  },
  macos: {
    chrome: `>=${browsersMinVersions.default.chrome}`,
    safari: `>=${browsersMinVersions.default.safari}`,
    firefox: `>=${browsersMinVersions.default.firefox}`,
    opera: `>=${browsersMinVersions.default.opera}`,
  },
  linux: {
    firefox: `>=${browsersMinVersions.default.firefox}`,
    samsung_internet: `>=${browsersMinVersions.default.samsung}`,
  },
  tablet: mobileBrowserValidation(),
  mobile: mobileBrowserValidation(),
});

export const checkIsBrowserValid = () => {
  // Comment this and uncomment below for local / browserstack debugging of UA strings
  const ua = window.navigator.userAgent;
  // alert(ua);
  // const ua = 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/17.0 Chrome/96.0.4664.104 Safari/537.36';
  // const ua = 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/92.0.4515.90 Mobile/15E148 Safari/604.1';
  // We use 2 libraries, that somehow complement themselves
  const md = new MobileDetect(ua);
  const browser = Bowser.getParser(ua);
  const mdOs = aliases[md.os() || ''] || '';
  const mdObj = {
    os: mdOs,
    version: md.version(mdOs),
    userAgent: md.userAgent(),
    mobile: md.mobile(),
  };
  // These are for local / browserstack debugging of UA strings
  console.log(ua, mdObj, browser.parsedResult);
  // const dumpStr = `${ua}\n${JSON.stringify(mdObj)}\n${JSON.stringify(browser.parsedResult)}}`;
  // alert(dumpStr);
  // Try to get device vendor by different means
  let deviceVendor = md.mobile() || md.tablet() || md.phone(); // Check mobile-detect first
  if (
    ['Unknown', 'UnknownMobile', 'UnknownPhone', 'UnknownTablet'].includes(
      deviceVendor || ''
    )
  ) {
    // Check Bowser vendor or model
    let altVendor = browser.getPlatform().vendor;
    if (altVendor === 'Apple') {
      altVendor = browser.getPlatform().model;
    }
    // Check specific regexp
    altVendor =
      altVendor ||
      Object.keys(vendorRegexpMap).find((k) => vendorRegexpMap[k].test(ua));
    deviceVendor = altVendor || deviceVendor;
  }
  // Get device OS and Browser
  const deviceOS = browser.getOSName() || md.os();
  const deviceBrowser = browser.getBrowserName() || md.userAgent();
  // Calculate min OS version by device, browser or default one
  const minBrowserOSVersion = osMinVersions[deviceBrowser];
  const minOSVersion =
    validAndroidMobileDevicesVersionsMap[deviceVendor] ||
    (minBrowserOSVersion && minBrowserOSVersion[deviceOS]) ||
    osMinVersions.default[deviceOS];
  // Check proper OS version here, or just exit early
  const osVersion =
    md.version(deviceOS) || parseInt(browser.getOSVersion(), 10);
  if (osVersion < minOSVersion) {
    return false;
  }
  // Wrap into validation input for Bowser to check everything
  const bowserValidationInput = browserValidation();
  const isValidBrowser = browser.satisfies(bowserValidationInput);
  return isValidBrowser;
};
