// @flow
import React from 'react';
import range from 'lodash/range';

import TextField, { Input } from '../../../material-components/TextField';
import AutoFocusInput from '../../AutoFocusInput';
import Box from '../../../material-components/Box';
import './style.less';

type Props = {
  length: number,
  value: string | null,
  onChange: Function,
  onNext: Function,
};

export default class CodeInput extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { length } = this.props;
    this.inputRefs = range(0, length).map(() => React.createRef());
  }

  handleChangeSymbol = (symbol, index) => {
    const { length, value, onChange } = this.props;
    // to clone
    const symbols = value.split('');
    if (symbol) {
      symbols[index] = symbol;
    } else {
      symbols.splice(index, 1);
    }
    onChange(symbols.join(''));

    if (symbol.length === 1) {
      if (symbol && index < length - 1) {
        setTimeout(
          () => this.inputRefs[value.length + 1].current.inputElement.focus(),
          0
        );
      }
    } else {
      if (symbol && symbol.length < length) {
        setTimeout(
          () => this.inputRefs[symbol.length].current.inputElement.focus(),
          0
        );
      } else if (!symbol && index > 0) {
        setTimeout(() => {
          value.length > 1
            ? this.inputRefs[value.length - 1].current.inputElement.focus()
            : this.inputRefs[0].current.input.inputElement.focus();
        }, 0);
      }
    }
  };

  handleChange = (e, index) => {
    const symbolRaw = e.target.value;
    const symbol =
      symbolRaw.length > 1
        ? symbolRaw
        : symbolRaw.length === 1
        ? symbolRaw[0]
        : '';
    this.handleChangeSymbol(symbol, index);

    setTimeout(() => {
      if (
        this.props.value.length === 5 ||
        (index === 4 && this.props.value.length === 5)
      ) {
        this.props.onNext();
      }
    });
  };

  handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && !e.target.value) {
      this.handleChangeSymbol('', index);
    }
  };

  render() {
    const { length, value } = this.props;
    const safeValue = value || '';

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {range(0, length).map((index) => {
          const InputWrapper = index === 0 ? AutoFocusInput : Input;

          return (
            <Box key={index} ml={index > 0 ? 1 : undefined}>
              <TextField
                className="code-input"
                outlined
                style={{ width: '100%' }}
              >
                <InputWrapper
                  // it does not support new react refs
                  ref={this.inputRefs[index]}
                  type="tel"
                  style={{ textAlign: 'center', fontSize: 30, width: '51px' }}
                  value={safeValue[index] || ''}
                  onChange={(e) => this.handleChange(e, index)}
                  onKeyDown={(e) => this.handleKeyDown(e, index)}
                  disabled={safeValue.length < index}
                />
              </TextField>
            </Box>
          );
        })}
      </div>
    );
  }
}
