// @flow

/**
 *      Creates stateless UI component for QuestionPage with list of questions
 *
 *      suggestions                 - String[] with questions, NOT required(but it has no sense without suggestions)
 *      suggestionMouseDownHandler  - (text: string) => {}, callback for suggestion, NOT required
 *      headerText                  - text for header, NOT required
 */

import React from 'react';

import './styles.less';
import MaterialIcon from '@material/react-material-icon';

import Suggestion from './Suggestion';
import IconButton from '../../material-components/IconButton';

type Props = {
  isSuggestionsShown: boolean,
  helpList?: Array<any>,
  onCloseSuggestionsClick: Function,
  onSuggestionClick?: Function,
  headerText?: string,
};

const Suggestions = (props: Props) => (
  <div>
    <div
      className={`suggestions-overlay ${
        props.isSuggestionsShown ? 'suggestions-overlay-shown' : ''
      }`}
    />
    <div id="suggestions" className="suggestions">
      <header>{props.headerText}</header>
      <div className="close-button-wrapper">
        <IconButton size="small" onClick={props.onCloseSuggestionsClick}>
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
      <ul>
        {props.helpList.map(({ text, question }, i) => (
          <Suggestion
            id={i}
            text={text}
            question={question}
            onClick={props.onSuggestionClick}
            key={i}
          />
        ))}
      </ul>
    </div>
  </div>
);

Suggestions.defaultProps = {
  helpList: [],
  onSuggestionClick: null,
  headerText: '',
};

export default Suggestions;
