import React from 'react';
import './styles.less';
import { getSafeHtml } from '../../../utils/common';

const SelectOption = ({ selectKey, value, selectedOption }) => (
  <li
    className={`select-list__option${
      selectedOption === value ? ' select-list__option--selected' : ''
    }`}
    key={selectKey}
    dangerouslySetInnerHTML={{ __html: getSafeHtml(value) }}
  ></li>
);

const SelectModalFragment = ({
  selectedOption,
  onOptionSelect,
  selectOptions,
}) => (
  <ul className="select-list" onClick={onOptionSelect}>
    {selectOptions.map((selectOption, index) => (
      <SelectOption
        key={`select-${index}`}
        selectKey={`select-${index}`}
        value={selectOption.title}
        selectedOption={selectedOption}
      />
    ))}
  </ul>
);

export default SelectModalFragment;
