import * as React from 'react';
import { withTranslation } from 'react-i18next';
import config from '../../config.json';
import _get from 'lodash/get';
import './styles.less';
import { isHcp } from '../../utils/user';

import SearchArea from '../SearchArea';

const BrandHeader = ({
  isLoginAllowed = true,
  isLogoVisible = true,
  isMenuVisible = true,
  ...restProps
}) => (
  <div
    className={`brand-header ${restProps.className ? restProps.className : ''}`}
  >
    {isLogoVisible && (
      <img
        className="xircles-logo"
        alt="Xircle"
        data-tr-event
        id="xircles-logo"
        onClick={restProps.onLogoClick}
      />
    )}
    <div className="brand-header__login">
      {config.RegistrationEnabled &&
        isLoginAllowed &&
        (restProps.isLoggedIn ? (
          <h6 className="only-desktop" onClick={restProps.onDisplayName}>
            {restProps.displayName}
          </h6>
        ) : (
          <h6
            className="only-desktop"
            data-tr-event
            onClick={restProps.onLogin}
          >
            {restProps.t('COMMON_MENU_LOGIN')}
          </h6>
        ))}
      {!config.RegistrationEnabled && isHcp(restProps.layerClient.user) && (
        <h6 onClick={restProps.onDisplayName}>
          {restProps.t('LOGOUT_DOCCHECK_TITLE')}
        </h6>
      )}
      {isMenuVisible && _get(restProps.themeSettings, 'isMenuVisible', false) && (
        <button id="menu-button" data-tr-event onClick={restProps.onToggleMenu}>
          <i data-tr-event className="material-icons menu-icon">
            menu
          </i>
        </button>
      )}
    </div>
    {restProps.children}
    <SearchArea {...restProps} onPostQuestion={restProps.onPostQuestion} />
  </div>
);

export default withTranslation()(BrandHeader);
