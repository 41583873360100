// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import { withTranslation } from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onRequestCode: Function,
  createPasswordLink: string,
  onCreatePassword: Function,
  t: Function,
};

function SendCodeModalFragment({
  phoneNumberOrEmail,
  onRequestCode,
  createPasswordLink,
  onCreatePassword,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.25}>
        {phoneNumberOrEmail}
      </Box>
      <Box textAlign="center" mb={1.5}>
        {t('LOGIN_SEND_CODE_FIRST_ROW')} <br />
        {t('LOGIN_SEND_CODE_SECOND_ROW')}
      </Box>
      <Box mb={1}>
        <Button
          id="send-login-code"
          data-keystroke
          outlined
          style={{ width: '100%' }}
          onClick={onRequestCode}
        >
          {t('LOGIN_SEND_LOGIN_CODE')}
        </Button>
      </Box>
      <Box
        mt={7}
        textAlign="center"
        onClick={onCreatePassword}
        dangerouslySetInnerHTML={{ __html: createPasswordLink }}
      />
    </React.Fragment>
  );
}

export default withTranslation()(SendCodeModalFragment);
