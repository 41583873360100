// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';

import IconButton from '../../../material-components/IconButton';
import MaterialIcon from '@material/react-material-icon';
import Dialog, {
  DialogTitle,
  DialogContent,
} from '../../../material-components/Dialog';
import NeedToLoginModalFragment from '../NeedToLoginModalFragment/NeedToLoginModalFragment';
import AuthWrapper from '../AuthWrapper';
import _get from 'lodash/get';

type Props = {
  t: Function,
  onToggle: Function,
  isOpen: Boolean,
};

class NeedToLoginModal extends React.Component<Props> {
  handleSignUp = () => {
    const signUpMessage = this.props.t('LOGIN_I_WANT_TO_REGISTER');
    return this.props.createConversation(signUpMessage, {
      product: _get(this.props.product, 'id'),
      productName: _get(this.props.product, 'name'),
    });
  };

  handleRenderNeedToLoginFragment = () => {
    const { hash } = this.props.location;
    const pathname = _get(this.props.location, 'state.detail.baseUrl');

    return (
      <AuthWrapper {...this.props} isAllowedWithoutAuth>
        <NeedToLoginModalFragment
          onLogin={() =>
            this.props.history.replace({
              pathname: `${pathname}/login`,
              hash,
            })
          }
          onRegister={this.handleSignUp}
        />
      </AuthWrapper>
    );
  };

  onClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { onToggle } = this.props;

    onToggle(ev);
  };

  renderCloseButton() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '-11px',
          right: '0px',
          color: 'xSlateLighter',
        }}
      >
        <IconButton
          id="need-to-login-close"
          size="small"
          className="closeBtn"
          onClick={this.onClose}
        >
          <MaterialIcon icon="clear" />
        </IconButton>
      </div>
    );
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (event) => {
      this.props.isOpen && this.props.onToggle(event);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.newConversationId !== nextProps.newConversationId) {
      this.props.history.replace(`/questions/${nextProps.newConversationId}`);
    }
  }

  render() {
    const { t, isOpen, onToggle } = this.props;

    return (
      <Dialog
        className="xircles-share-content-dialog"
        open={isOpen}
        onClose={onToggle}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {t('MODAL_INFO_TITLE')}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          <div className="my-wrapper">
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={this.props.location.key}
                timeout={{ enter: 400, exit: 400 }}
                classNames="fade"
              >
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route
                      path={[
                        '/video/:videoId/need-to-login',
                        '/documents/*/need-to-login',
                      ]}
                      exact
                      render={this.handleRenderNeedToLoginFragment}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(NeedToLoginModal));
